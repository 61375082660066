"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index");
var _accounts = require("@/api/accounts");
var _user = require("@/api/user.js");
var _echarts = require("echarts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    listLoading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object,
      default: {}
    },
    isUser: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      extractRefusedShow: false,
      refundView: {
        show: false,
        refundPrice: 0
      },
      showImg: false,
      showImgUrl: '',
      showRefund: false,
      refundData: {},
      refusedData: {
        fail_msg: '',
        uid: 0,
        extract_id: 0
      },
      searchQuery: {},
      companyInfo: {}
    };
  },
  created: function created() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      _this.companyInfo = res.data;
    });
  },
  methods: {
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.$emit('tableChange', e);
    },
    showRefundAction: function showRefundAction(item) {
      this.refundView = {
        uid: item.user.uid,
        refundPrice: ((0, _index.formatPrice)(item.extract_price) - (0, _index.formatPrice)(item.already_refund_price)) / 100,
        id: item.extract_id,
        data: [],
        show: true
      };
      this.loadList();
    },
    extractRefusedClick: function extractRefusedClick(item) {
      this.extractRefusedShow = true;
      this.refusedData.uid = item.uid;
      this.refusedData.extract_id = item.extract_id;
      this.refusedData.fail_msg = '';
    },
    onPayment: function onPayment(row) {
      var _this2 = this;
      this.$confirm('确定付款到零钱？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _accounts.extractTransferApi)({
          extract_id: row.extract_id,
          uid: row.uid
        }).then(function (res) {
          _this2.$message.success('操作成功');
          _this2.$emit('refreshList');
        }).catch(function (err) {
          _this2.$message.error('打款失败');
        });
      });
    },
    goExtractRefused: function goExtractRefused() {
      var _this3 = this;
      this.$confirm('确认拒绝？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _accounts.refuseExtractApi)(_this3.refusedData).then(function () {
          _this3.$message.success('操作成功');
          _this3.extractRefusedShow = false;
          _this3.loadList();
        }).catch(function () {});
      }).catch(function (err) {
        console.error(err);
      });
    },
    loadList: function loadList() {
      var _this4 = this;
      (0, _accounts.userRechargeList)({
        page: 1,
        limit: 100,
        uid: this.refundView.uid,
        pay_status: 2
      }).then(function (res) {
        _this4.refundView.data = res.data.list;
      }).catch(function () {});
    },
    showEvidenceAction: function showEvidenceAction(img) {
      if (img) {
        this.showImgUrl = img;
        this.showImg = true;
      } else {
        this.$message.error('此记录没有凭证');
      }
    },
    closeRefundAction: function closeRefundAction() {
      this.showRefund = false;
      this.refundData = {};
    },
    goRefundAction: function goRefundAction() {
      var _this5 = this;
      // if (!this.refundData.pay_evidence) {
      //   this.$message.error('请先上传退款凭证');
      //   return false;
      // }
      this.confirmReturnOk(function () {
        _this5.closeRefundAction();
      });
    },
    confirmReturnOk: function confirmReturnOk(cb) {
      var _this6 = this;
      (0, _accounts.extractRechargeRefund)(this.refundData).then(function (res) {
        _this6.$message.success('操作成功');
        if (cb) cb();
        _this6.loadList();
        _this6.$emit('refreshList');
      }).catch(function () {});
    },
    confirmReturn: function confirmReturn(item) {
      var _this7 = this;
      var hasMoney = ((0, _index.formatPrice)(item.total_fee) - (0, _index.formatPrice)(item.refund_money)) / 100;
      if (hasMoney <= 0) {
        this.$message.error('可退款金额不足');
        return false;
      }
      // 判断如果微信订单的创建时间超过一年，退款方式转换为银行转账
      var rechargeDate = new Date(item.create_time).getTime();
      if (new Date().getTime() > rechargeDate + 365 * 24 * 60 * 60 * 1000) {
        item.pay_type = 'bank_transfer';
      }
      if (item.pay_type !== 'wechat_h5' && item.pay_type !== 'wechat_mini' && item.pay_type !== 'wechat_app') {
        this.pay_evidence = '';
        this.showRefund = true;
        this.refundData = {
          extract_id: this.refundView.id,
          recharge_id: item.recharge_id,
          refund_type: 'bank_transfer',
          refund_price: hasMoney > this.refundView.refundPrice ? this.refundView.refundPrice : hasMoney,
          pay_evidence: ''
        };
        return false;
      }
      this.$confirm('确认退款', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this7.refundData = {
          extract_id: _this7.refundView.id,
          recharge_id: item.recharge_id,
          refund_price: hasMoney > _this7.refundView.refundPrice ? _this7.refundView.refundPrice : hasMoney
        };
        _this7.confirmReturnOk(function () {
          _this7.closeRefundAction();
        });
      }).catch(function (err) {
        console.error(err);
      });
    },
    changeImg: function changeImg() {
      var _this8 = this;
      this.$modalUpload(function (img) {
        _this8.refundData.pay_evidence = img[0];
      }, 1, '1');
    },
    openUserInfoAction: function openUserInfoAction(uid) {
      this.$emit('openUserInfoAction', uid);
    },
    showRefundActionNew: function showRefundActionNew(item) {
      this.$emit('showRefund', item);
    }
  }
};