var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
    },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox", staticStyle: { top: "-50px" } },
          [
            [
              !_vm.isClickCreate
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.creatPlan },
                    },
                    [_vm._v("创建计划")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "4px" },
                  on: { click: _vm.onClickLog },
                },
                [_vm._v("视频生成进度")]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "bottom" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "4px" },
                      attrs: { type: "primary" },
                    },
                    [_vm._v("新增")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.onOpenDialog(1)
                              },
                            },
                          },
                          [_vm._v("拍卖会视频")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.onOpenDialog(2)
                              },
                            },
                          },
                          [_vm._v("专场视频")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.onOpenDialog(3)
                              },
                            },
                          },
                          [_vm._v("拍品视频")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c" },
        [
          _c(
            "el-radio-group",
            {
              attrs: { type: "button" },
              on: { change: _vm.onChangeStatus },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v("拍卖会"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v("专场/专题"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "3" } }, [
                _vm._v("单件拍品"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "4" } }, [
                _vm._v("文化"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "5" } }, [
                _vm._v("知识"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("VideoMake", {
        ref: "videoMakeRef",
        attrs: { venue_id: _vm.venue_id, status: _vm.status },
        on: {
          isShowCreate: function (val) {
            return (_vm.isClickCreate = val)
          },
          goCreateTweet: _vm.goCreateTweet,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拍卖会视频",
            visible: _vm.venueVisible,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.venueVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c("el-form-item", { attrs: { label: "拍卖名称" } }, [
                _vm._v(_vm._s(_vm.venueForm.venueTitle)),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容定位", required: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.venueForm.make_tag,
                        callback: function ($$v) {
                          _vm.$set(_vm.venueForm, "make_tag", $$v)
                        },
                        expression: "venueForm.make_tag",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("拍卖导览"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("结拍快讯"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容形式", required: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.venueForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.venueForm, "type", $$v)
                        },
                        expression: "venueForm.type",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("视频"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("推文"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "3" } }, [
                        _vm._v("图文"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.venueSubmit } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.venueVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml20" }, [
                _vm._v("当前1个拍卖会  创建 条制作计划"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "专场视频",
            visible: _vm.albumVisible,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.albumVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择专场", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "选择专场",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.onSearchAlbum,
                      },
                      on: { change: _vm.onAlbumChange },
                      model: {
                        value: _vm.albumForm.album_ids,
                        callback: function ($$v) {
                          _vm.$set(_vm.albumForm, "album_ids", $$v)
                        },
                        expression: "albumForm.album_ids",
                      },
                    },
                    _vm._l(_vm.albumList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.album_name, value: item.album_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "业务场景", required: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.albumForm.make_tag,
                        callback: function ($$v) {
                          _vm.$set(_vm.albumForm, "make_tag", $$v)
                        },
                        expression: "albumForm.make_tag",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("拍卖导览"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("结拍快讯"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容形式", required: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.albumForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.albumForm, "type", $$v)
                        },
                        expression: "albumForm.type",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "1" } }, [
                        _vm._v("视频"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "2" } }, [
                        _vm._v("推文"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: "3" } }, [
                        _vm._v("图文"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.albumSubmit } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.albumVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml20" }, [
                _vm._v("当前1个专场  创建 条制作计划"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SelectAuctionView", {
        ref: "selectAuctionViewRef",
        attrs: {
          venue_id: _vm.venue_id,
          openTitle: "拍品视频",
          openType: "auctionVideo",
        },
        on: { createAuction: _vm.onAuctionMessage },
      }),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: {
          "has-more-data": "1",
          "all-image": "true",
          query: { venue_id: _vm.venue_id, album_id: _vm.album_id },
          "current-data": [],
        },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: " ",
            width: "1300px",
            top: "5vh",
            "lock-scroll": "",
            "show-close": false,
            "close-on-click-modal": false,
            "before-close": _vm.closeAction,
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-flex-center" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: {
                    height: "50px",
                    width: "200px",
                    "font-size": "16px",
                  },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.goCreateTweet({ make_tag: _vm.make_tag }, 1)
                    },
                  },
                },
                [_vm._v("换一换")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex mt20",
              staticStyle: { "justify-content": "center" },
            },
            [
              _vm.videoLogList[0]
                ? _c(
                    "div",
                    { staticStyle: { width: "390px" } },
                    [
                      _c("preview", {
                        attrs: {
                          configuration: _vm.configuration,
                          pages: _vm.previewData,
                          temid: _vm.previewDetail.template_id,
                          marketid: _vm.marketid,
                          title: _vm.previewDetail.title,
                          "page-info": _vm.previewDetail.config_data,
                          price: _vm.previewDetail.template_price,
                          type: "outer",
                        },
                        on: {
                          closeAction: _vm.closeAction,
                          edit: function ($event) {
                            return _vm.editAction(1)
                          },
                          setColor: function (i) {
                            return _vm.setThemeAction(1, i)
                          },
                          setConfiguration: function (i) {
                            return _vm.setConfiguration(1, i)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.videoLogList[1]
                ? _c(
                    "div",
                    { staticStyle: { width: "390px", "margin-left": "20px" } },
                    [
                      _c("preview", {
                        attrs: {
                          configuration: _vm.configuration,
                          pages: _vm.previewDataTwo,
                          temid: _vm.previewDetailTwo.template_id,
                          marketid: _vm.marketid,
                          title: _vm.previewDetailTwo.title,
                          "page-info": _vm.previewDetailTwo.config_data,
                          price: _vm.previewDetailTwo.template_price,
                          type: "outer",
                        },
                        on: {
                          closeAction: _vm.closeAction,
                          edit: function ($event) {
                            return _vm.editAction(2)
                          },
                          setColor: function (i) {
                            return _vm.setThemeAction(2, i)
                          },
                          setConfiguration: function (i) {
                            return _vm.setConfiguration(2, i)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.videoLogList[2]
                ? _c(
                    "div",
                    { staticStyle: { width: "390px", "margin-left": "20px" } },
                    [
                      _c("preview", {
                        attrs: {
                          configuration: _vm.configuration,
                          pages: _vm.previewDataThree,
                          temid: _vm.previewDetailThree.template_id,
                          marketid: _vm.marketid,
                          title: _vm.previewDetailThree.title,
                          "page-info": _vm.previewDetailThree.config_data,
                          price: _vm.previewDetailThree.template_price,
                          type: "outer",
                        },
                        on: {
                          closeAction: _vm.closeAction,
                          edit: function ($event) {
                            return _vm.editAction(3)
                          },
                          setColor: function (i) {
                            return _vm.setThemeAction(3, i)
                          },
                          setConfiguration: function (i) {
                            return _vm.setConfiguration(3, i)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }