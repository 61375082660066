"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var panels = [{
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/202487/image/1722999670626570691.png',
  auction_weChat: {
    content: {
      attrlist: [{
        'key': 'lot',
        'name': '图录号',
        'selected': true
      }, {
        'key': 'name',
        'name': '拍品名称',
        'selected': true
      }, {
        'key': 'attr',
        'name': '拍品属性',
        'selected': true
      }, {
        'key': 'desc',
        'name': '一句话介绍',
        'selected': false
      }, {
        'key': 'content',
        'name': '拍品详情',
        'selected': false
      }, {
        'key': 'category',
        'name': '拍品分类',
        'selected': false
      }, {
        'key': 'album',
        'name': '所属专场',
        'selected': false
      }, {
        'key': 'reference_price',
        'name': '估价',
        'selected': false
      }, {
        'key': 'start_price',
        'name': '起拍价',
        'selected': false
      }, {
        'key': 'success_price',
        'name': '成交价',
        'selected': true
      }]
    },
    facade: {
      'set': true,
      'temp': ['price_color', 'content_background', 'auction_spacing_color'],
      'style': 4,
      'theme': 1,
      'title': '结拍快讯',
      'radius': 0,
      'coverImg': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893240619978780.jpg',
      'longMode': '1',
      'lot_size': 14,
      'desc_size': 14,
      'show_area': '1',
      'lot_margin': 0,
      'lot_weight': 400,
      'price_size': 14,
      'title_size': 14,
      'desc_margin': 6,
      'desc_weight': 400,
      'item_margin': 25,
      'page_margin': 0,
      'borderEffect': '1',
      'border_width': 0,
      'content_size': 14,
      'img_position': 'center',
      'item_padding': 0,
      'price_margin': 6,
      'price_weight': 600,
      'title_margin': 6,
      'title_weight': 600,
      'border_radius': 0,
      'showLongImage': true,
      'border_padding': 15,
      'content_margin': 6,
      'content_weight': 400,
      'lot_text_align': 'left',
      'desc_text_align': 'left',
      'lot_line_height': 20,
      'border_shawdow_x': 0,
      'border_shawdow_y': 0,
      'desc_line_height': 20,
      'price_text_align': 'left',
      'title_text_align': 'left',
      'price_line_height': 20,
      'content_text_align': 'left',
      'content_toppadding': 0,
      'content_line_height': 22,
      'auction_spacing_type': 'dashed',
      'auction_spacing_image': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024815/image/1723728879187655050.gif',
      'auction_spacing_height': 1,
      'background_padding_top': 0,
      'background_padding_left': 0,
      'background_padding_right': 0,
      'background_padding_bottom': 0,
      'auction_spacing_image_width': 25,
      'auction_spacing_image_margin': 15,
      'content_background': '',
      'border_padding_top': 10,
      'border_padding_bottom': 10,
      'border_padding_left': 10,
      'border_padding_right': 10,
      'borderStyle': 'solid'
    }
  }
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/202487/image/1722999670626570691.png',
  auction_weChat: {
    content: {
      attrlist: [{
        'key': 'lot',
        'name': '图录号',
        'selected': true
      }, {
        'key': 'name',
        'name': '拍品名称',
        'selected': true
      }, {
        'key': 'attr',
        'name': '拍品属性',
        'selected': true
      }, {
        'key': 'desc',
        'name': '一句话介绍',
        'selected': true
      }, {
        'key': 'reference_price',
        'name': '估价',
        'selected': true
      }, {
        'key': 'content',
        'name': '拍品详情',
        'selected': true
      }, {
        'key': 'category',
        'name': '拍品分类',
        'selected': false
      }, {
        'key': 'album',
        'name': '所属专场',
        'selected': false
      }, {
        'key': 'start_price',
        'name': '起拍价',
        'selected': false
      }, {
        'key': 'success_price',
        'name': '成交价',
        'selected': false
      }]
    },
    facade: {
      'set': true,
      'temp': ['content_color', 'title_color', 'price_color', 'content_background'],
      'style': 2,
      'theme': 1,
      'title': '测试模板的标题',
      'radius': 0,
      'bg_image': '',
      'coverImg': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893241052978250.jpg',
      'longMode': '1',
      'lot_size': 13,
      'desc_size': 13,
      'lot_margin': 0,
      'lot_weight': 400,
      'price_size': 13,
      'title_size': 13,
      'desc_margin': 5,
      'desc_weight': 400,
      'item_margin': 20,
      'page_margin': 0,
      'borderEffect': '1',
      'border_width': 0,
      'content_size': 13,
      'img_position': 'center',
      'item_padding': 0,
      'price_margin': 5,
      'price_weight': 600,
      'title_margin': 5,
      'title_weight': 600,
      'bg_image_mode': 4,
      'border_radius': 0,
      'border_padding': 18,
      'content_margin': 22,
      'content_weight': 400,
      'lot_text_align': 'left',
      'desc_text_align': 'left',
      'lot_line_height': 20,
      'border_shawdow_x': 0,
      'border_shawdow_y': 0,
      'desc_line_height': 20,
      'price_text_align': 'left',
      'title_text_align': 'left',
      'price_line_height': 20,
      'content_text_align': 'left',
      'content_toppadding': 0,
      'content_line_height': 28,
      'auction_spacing_height': 0,
      'auction_spacing_image_width': 0,
      'auction_spacing_image_margin': 0,
      'background_padding_top': 5,
      'background_padding_bottom': 0,
      'background_padding_left': 0,
      'background_padding_right': 0,
      'border_padding_top': 15,
      'border_padding_bottom': 15,
      'border_padding_left': 15,
      'border_padding_right': 15,
      'content_background': '',
      'show_area': '1'
    }
  }
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/202487/image/1722999670626570691.png',
  auction_weChat: {
    content: {
      attrlist: [{
        'key': 'lot',
        'name': '图录号',
        'selected': false
      }, {
        'key': 'name',
        'name': '拍品名称',
        'selected': true
      }, {
        'key': 'attr',
        'name': '拍品属性',
        'selected': true
      }, {
        'key': 'desc',
        'name': '一句话介绍',
        'selected': false
      }, {
        'key': 'content',
        'name': '拍品详情',
        'selected': true
      }, {
        'key': 'category',
        'name': '拍品分类',
        'selected': false
      }, {
        'key': 'album',
        'name': '所属专场',
        'selected': false
      }, {
        'key': 'reference_price',
        'name': '估价',
        'selected': false
      }, {
        'key': 'start_price',
        'name': '起拍价',
        'selected': false
      }, {
        'key': 'success_price',
        'name': '成交价',
        'selected': false
      }]
    },
    facade: {
      'set': true,
      'temp': ['title_color', 'border_color'],
      'style': 4,
      'theme': 1,
      'title': '立秋启画卷，秋韵满人间',
      'radius': 4,
      'bg_image': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024821/image/172417357375873525.webp',
      'coverImg': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893241579590133.jpg',
      'longMode': '1',
      'lot_size': 13,
      'desc_size': 13,
      'lot_margin': 0,
      'lot_weight': 400,
      'price_size': 13,
      'title_size': 13,
      'desc_margin': 8,
      'desc_weight': 400,
      'item_margin': 20,
      'page_margin': 0,
      'borderEffect': '1',
      'border_width': 1,
      'content_size': 13,
      'img_position': 'center',
      'item_padding': 0,
      'price_margin': 5,
      'price_weight': 400,
      'title_margin': 0,
      'title_weight': 600,
      'bg_image_mode': 3,
      'border_radius': 0,
      'border_padding': 20,
      'content_margin': 5,
      'lot_text_align': 'left',
      'desc_text_align': 'left',
      'lot_line_height': 20,
      'border_shawdow_x': 0,
      'border_shawdow_y': 0,
      'desc_line_height': 22,
      'price_text_align': 'left',
      'title_text_align': 'left',
      'price_line_height': 34,
      'content_toppadding': 0,
      'content_line_height': 24,
      'auction_spacing_height': 0,
      'auction_spacing_image_width': 0,
      'auction_spacing_image_margin': 0,
      'background_padding_top': 0,
      'background_padding_bottom': 0,
      'background_padding_left': 0,
      'background_padding_right': 0,
      'border_padding_top': 10,
      'border_padding_bottom': 10,
      'border_padding_left': 10,
      'border_padding_right': 10,
      'content_background': '',
      'borderStyle': 'solid',
      'show_area': '1',
      'content_weight': 400,
      'content_text_align': 'left'
    }
  }
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/202487/image/1722999670626570691.png',
  auction_weChat: {
    content: {
      attrlist: [{
        'key': 'lot',
        'name': '图录号',
        'selected': true
      }, {
        'key': 'name',
        'name': '拍品名称',
        'selected': true
      }, {
        'key': 'attr',
        'name': '拍品属性',
        'selected': true
      }, {
        'key': 'desc',
        'name': '一句话介绍',
        'selected': true
      }, {
        'key': 'content',
        'name': '拍品详情',
        'selected': false
      }, {
        'key': 'category',
        'name': '拍品分类',
        'selected': false
      }, {
        'key': 'album',
        'name': '所属专场',
        'selected': false
      }, {
        'key': 'reference_price',
        'name': '估价',
        'selected': false
      }, {
        'key': 'start_price',
        'name': '起拍价',
        'selected': true
      }, {
        'key': 'success_price',
        'name': '成交价',
        'selected': false
      }]
    },
    facade: {
      'set': true,
      'temp': ['border_color', 'border_shawdow_color', 'price_color', 'auction_spacing_color'],
      'style': 7,
      'theme': 1,
      'title': '测试模板',
      'radius': 0,
      'coverImg': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024712/image/1720762973089191173.png',
      'longMode': '1',
      'lot_size': 14,
      'desc_size': 14,
      'lot_margin': 5,
      'lot_weight': 400,
      'price_size': 14,
      'title_size': 14,
      'desc_margin': 5,
      'desc_weight': 400,
      'item_margin': 30,
      'page_margin': 0,
      'borderEffect': '1',
      'border_width': 1,
      'content_size': 13,
      'img_position': 'center',
      'item_padding': 0,
      'price_margin': 5,
      'price_weight': 600,
      'title_margin': 5,
      'title_weight': 500,
      'border_radius': 0,
      'animate_margin': 0,
      'border_padding': 15,
      'content_margin': 5,
      'content_weight': 400,
      'lot_text_align': 'left',
      'pageBackground': 'rgba(255, 255, 255, 1)',
      'desc_text_align': 'left',
      'lot_line_height': 20,
      'border_shawdow_x': 6,
      'border_shawdow_y': 6,
      'desc_line_height': 20,
      'price_text_align': 'left',
      'title_text_align': 'left',
      'price_line_height': 20,
      'content_text_align': 'left',
      'content_toppadding': 6,
      'content_line_height': 22,
      'auction_spacing_type': 'dashed',
      'auction_spacing_image': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024815/image/1723728879187655050.gif',
      'auction_spacing_height': 1,
      'auction_spacing_image_width': 30,
      'auction_spacing_image_margin': 11,
      'border_padding_top': 15,
      'border_padding_bottom': 15,
      'border_padding_left': 15,
      'border_padding_right': 15,
      'content_background': '#fff',
      'borderStyle': 'solid'
    }
  }
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/202487/image/1722999670626570691.png',
  auction_weChat: {
    content: {
      attrlist: [{
        'key': 'lot',
        'name': '图录号',
        'selected': true
      }, {
        'key': 'name',
        'name': '拍品名称',
        'selected': true
      }, {
        'key': 'attr',
        'name': '拍品属性',
        'selected': false
      }, {
        'key': 'desc',
        'name': '一句话介绍',
        'selected': false
      }, {
        'key': 'content',
        'name': '拍品详情',
        'selected': true
      }, {
        'key': 'category',
        'name': '拍品分类',
        'selected': false
      }, {
        'key': 'album',
        'name': '所属专场',
        'selected': false
      }, {
        'key': 'reference_price',
        'name': '估价',
        'selected': false
      }, {
        'key': 'start_price',
        'name': '起拍价',
        'selected': false
      }, {
        'key': 'success_price',
        'name': '成交价',
        'selected': false
      }]
    },
    facade: {
      'set': true,
      'temp': ['border_color', 'lot_color', 'title_color'],
      'style': 4,
      'theme': 1,
      'radius': 4,
      'longMode': '1',
      'lot_size': 13,
      'desc_size': 12,
      'show_area': '1',
      'lot_margin': 0,
      'lot_weight': 600,
      'price_size': 12,
      'title_size': 13,
      'borderStyle': 'solid',
      'desc_margin': 15,
      'desc_weight': 400,
      'item_margin': 15,
      'page_margin': 0,
      'borderEffect': '2',
      'border_width': 1,
      'content_size': 13,
      'img_position': 'center',
      'item_padding': 0,
      'price_margin': 15,
      'price_weight': 400,
      'title_margin': 5,
      'title_weight': 600,
      'border_radius': 0,
      'showLongImage': true,
      'content_margin': 5,
      'lot_text_align': 'left',
      'desc_text_align': 'left',
      'lot_line_height': 20,
      'border_shawdow_x': 0,
      'border_shawdow_y': 0,
      'desc_line_height': 20,
      'price_text_align': 'left',
      'title_text_align': 'left',
      'price_line_height': 20,
      'border_padding_top': 5,
      'content_toppadding': 0,
      'border_padding_left': 10,
      'content_line_height': 22,
      'border_padding_right': 10,
      'border_padding_bottom': 5,
      'auction_spacing_height': 0,
      'background_padding_top': 5,
      'background_padding_left': 3,
      'background_padding_right': 3,
      'background_padding_bottom': 5,
      'auction_spacing_image_width': 0,
      'auction_spacing_image_margin': 0
    }
  }
}];
var _default = exports.default = panels;