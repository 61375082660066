var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    {
      attrs: {
        title: "专场",
        "has-template": _vm.$route.query.type == "manage" ? "1" : "",
      },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("手动选择专场"),
                ]),
              ]),
              _vm._v(" "),
              _vm.content.data && _vm.content.data.length > 0
                ? _c(
                    "div",
                    { staticClass: "pt10" },
                    _vm._l(_vm.content.data, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kuang1 item-align-center mt10",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex-one flex item-align-center",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c("div", { staticClass: "album-img" }, [
                                _c("img", {
                                  attrs: { src: item.image, alt: "" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-one ml10 ft12",
                                  staticStyle: { overflow: "hidden" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", [_vm._v("名称：")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-one" },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "100%",
                                              "font-size": "12px",
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value: item.name,
                                              callback: function ($$v) {
                                                _vm.$set(item, "name", $$v)
                                              },
                                              expression: "item.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c mt5" },
                                    [
                                      _c("div", [_vm._v("预展：")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-one" },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "100%",
                                              "font-size": "12px",
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value:
                                                item.scheduled_show_time_format,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "scheduled_show_time_format",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.scheduled_show_time_format",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c mt5" },
                                    [
                                      _c("div", [_vm._v("开拍：")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-one" },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "100%",
                                              "font-size": "12px",
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value: item.stime_format,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "stime_format",
                                                  $$v
                                                )
                                              },
                                              expression: "item.stime_format",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-down-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDown(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-up-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUp(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del-img",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("img", {
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "margin-right": "5px",
                    "margin-top": "2px",
                  },
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fontW500 ft16",
                    on: { click: _vm.handleAdditem },
                  },
                  [_vm._v("选择专场")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "100px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域外间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginTop", $$v)
                            },
                            expression: "facade.pageMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginBottom", $$v)
                            },
                            expression: "facade.pageMarginBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginLeft", $$v)
                            },
                            expression: "facade.pageMarginLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginRight", $$v)
                            },
                            expression: "facade.pageMarginRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域内间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingTop", $$v)
                            },
                            expression: "facade.pagePaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingBottom", $$v)
                            },
                            expression: "facade.pagePaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingLeft", $$v)
                            },
                            expression: "facade.pagePaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingRight", $$v)
                            },
                            expression: "facade.pagePaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusTopLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusTopLeft", $$v)
                            },
                            expression: "facade.pageRadiusTopLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusTopRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusTopRight", $$v)
                            },
                            expression: "facade.pageRadiusTopRight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusBottomLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusBottomLeft", $$v)
                            },
                            expression: "facade.pageRadiusBottomLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusBottomRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusBottomRight", $$v)
                            },
                            expression: "facade.pageRadiusBottomRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.pageBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageBackground", $$v)
                      },
                      expression: "facade.pageBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框大小" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderTop", $$v)
                            },
                            expression: "facade.borderTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderBottom", $$v)
                            },
                            expression: "facade.borderBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderLeft", $$v)
                            },
                            expression: "facade.borderLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderRight", $$v)
                            },
                            expression: "facade.borderRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.borderColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "borderColor", $$v)
                      },
                      expression: "facade.borderColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgRadiusTopLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadiusTopLeft", $$v)
                            },
                            expression: "facade.imgRadiusTopLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgRadiusTopRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadiusTopRight", $$v)
                            },
                            expression: "facade.imgRadiusTopRight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgRadiusBottomLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadiusBottomLeft", $$v)
                            },
                            expression: "facade.imgRadiusBottomLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgRadiusBottomRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadiusBottomRight", $$v)
                            },
                            expression: "facade.imgRadiusBottomRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "展示图片" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.noShowImg,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "noShowImg", $$v)
                            },
                            expression: "facade.noShowImg",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("展示"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("不展示"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字宽度" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.textWidth,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "textWidth", $$v)
                          },
                          expression: "facade.textWidth",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字垂直位置" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.textItemArea,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textItemArea", $$v)
                            },
                            expression: "facade.textItemArea",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "flex-start" } }, [
                            _vm._v("顶部"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "center" } }, [
                            _vm._v("垂直居中"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "flex-end" } }, [
                            _vm._v("底部"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字对齐" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.textAlign,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textAlign", $$v)
                            },
                            expression: "facade.textAlign",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "left" } }, [
                            _vm._v("居左"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "center" } }, [
                            _vm._v("居中"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "right" } }, [
                            _vm._v("居右"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.textBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "textBackground", $$v)
                      },
                      expression: "facade.textBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字距离" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.textPaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textPaddingTop", $$v)
                            },
                            expression: "facade.textPaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.textPaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textPaddingBottom", $$v)
                            },
                            expression: "facade.textPaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.textPaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textPaddingLeft", $$v)
                            },
                            expression: "facade.textPaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.textPaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textPaddingRight", $$v)
                            },
                            expression: "facade.textPaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500" }, [_vm._v("标题区域")]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "距上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.titleMarginTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "titleMarginTop", $$v)
                      },
                      expression: "facade.titleMarginTop",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.titleColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "titleColor", $$v)
                      },
                      expression: "facade.titleColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.titleFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "titleFontSize", $$v)
                      },
                      expression: "facade.titleFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.titleFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "titleFontWeight", $$v)
                        },
                        expression: "facade.titleFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500" }, [
                _vm._v("预展时间标签区域"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标签文字" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.facade.preTimeText,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preTimeText", $$v)
                      },
                      expression: "facade.preTimeText",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.preTimeTextStyle,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "preTimeTextStyle", $$v)
                        },
                        expression: "facade.preTimeTextStyle",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("单独展示"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("同行展示"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "距上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.preTimeTextMarginTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preTimeTextMarginTop", $$v)
                      },
                      expression: "facade.preTimeTextMarginTop",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.preTimeTextColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preTimeTextColor", $$v)
                      },
                      expression: "facade.preTimeTextColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.preTimeTextFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preTimeTextFontSize", $$v)
                      },
                      expression: "facade.preTimeTextFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.preTimeTextFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "preTimeTextFontWeight", $$v)
                        },
                        expression: "facade.preTimeTextFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500" }, [
                _vm._v("预展时间区域"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "距上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.preTimeMarginTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preTimeMarginTop", $$v)
                      },
                      expression: "facade.preTimeMarginTop",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.preTimeColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preTimeColor", $$v)
                      },
                      expression: "facade.preTimeColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.preTimeFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preTimeFontSize", $$v)
                      },
                      expression: "facade.preTimeFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.preTimeFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "preTimeFontWeight", $$v)
                        },
                        expression: "facade.preTimeFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500" }, [
                _vm._v("开拍时间标签区域"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标签文字" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.facade.timeText,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "timeText", $$v)
                      },
                      expression: "facade.timeText",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.timeTextStyle,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "timeTextStyle", $$v)
                        },
                        expression: "facade.timeTextStyle",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("单独展示"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("同行展示"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "距上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.timeTextMarginTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "timeTextMarginTop", $$v)
                      },
                      expression: "facade.timeTextMarginTop",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.timeTextColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "timeTextColor", $$v)
                      },
                      expression: "facade.timeTextColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.timeTextFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "timeTextFontSize", $$v)
                      },
                      expression: "facade.timeTextFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.timeTextFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "timeTextFontWeight", $$v)
                        },
                        expression: "facade.timeTextFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500" }, [
                _vm._v("开拍时间区域"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "距上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.timeMarginTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "timeMarginTop", $$v)
                      },
                      expression: "facade.timeMarginTop",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.timeColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "timeColor", $$v)
                      },
                      expression: "facade.timeColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.timeFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "timeFontSize", $$v)
                      },
                      expression: "facade.timeFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.timeFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "timeFontWeight", $$v)
                        },
                        expression: "facade.timeFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "temp" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "模版字段" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.facade.temp,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "temp", $$v)
                        },
                        expression: "facade.temp",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "pageBackground" } },
                            [_vm._v("背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "borderColor" } },
                            [_vm._v("边框颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "textBackground" } },
                            [_vm._v("内容背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "titleColor" } },
                            [_vm._v("标题文字颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "preTimeTextColor" } },
                            [_vm._v("预展时间标签颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "preTimeColor" } },
                            [_vm._v("预展时间颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "timeTextColor" } },
                            [_vm._v("开拍时间标签颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-checkbox", { attrs: { label: "timeColor" } }, [
                            _vm._v("开拍时间颜色"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "has-more-data": "1", "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }