"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _warehouse = require("@/api/warehouse");
var _basic = require("@/api/basic");
var _product = require("@/api/product");
var _settings = require("@/settings");
var _printJs = _interopRequireDefault(require("print-js"));
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _pushwareOrder = _interopRequireDefault(require("@/components/printOrder/pushwareOrder"));
var _user = require("@/api/user.js");
var _setting = require("@/api/setting");
var _review = require("@/api/review");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Review',
  components: {
    'el-image-viewer': function elImageViewer() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('element-ui/packages/image/src/image-viewer'));
      });
    },
    fileList: _fileList.default,
    pushwareOrder: _pushwareOrder.default
  },
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      roterPre: _settings.roterPre,
      showconfig: false,
      logisticsName: 'refund',
      id: 0,
      type: '',
      tableData: {
        data: [],
        total: 0
      },
      storeList: [],
      listLoading: true,
      tableFrom: {
        order_type: 1,
        username: '',
        type: '',
        date: '',
        page: 1,
        limit: 20,
        receipt_sn: '',
        keyword: '',
        push_status: ''
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      showreview: true,
      mask: '',
      showlabel: false,
      labelarr: [],
      showdetail: false,
      detailjson: {},
      showhouse: false,
      house: {
        image: [],
        remark: ''
      },
      rules: {
        image: [{
          required: true,
          message: '请上传凭证'
        }]
      },
      showshelf: false,
      shelf: {
        image: '',
        remark: '',
        id: ''
      },
      shelfList: [{
        shelf_id: 0,
        shelf_no: ' '
      }, {
        shelf_id: '0',
        shelf_no: ' '
      }],
      showouthouse: false,
      showimage: false,
      imagearr: [],
      urlshow: false,
      urlshowList: [],
      showalldetail: false,
      column: [{
        label: '入库时间',
        width: 120,
        type: 'enter_time'
      }, {
        label: '入库单号',
        width: 120,
        type: 'order_no'
      }, {
        label: '委托人',
        width: 100,
        type: 'salesmanAdmin'
      }, {
        label: '手机号',
        width: 100,
        type: 'phone'
      }, {
        label: '数量',
        width: 80,
        type: 'erp_product_number'
      }, {
        label: '入库库房',
        width: 100,
        type: 'enterWarehouse'
      }, {
        label: '入库类型',
        width: 100,
        type: 'enterType'
      }, {
        label: '业务员',
        width: 80,
        type: 'enterAdmin'
      }, {
        label: '制单人',
        width: 80,
        type: 'createAdmin'
      }, {
        label: '入库状态',
        width: 100,
        type: 'enter_status'
      }, {
        label: '制单时间',
        width: 120,
        type: 'create_time'
      }],
      showsearch: false,
      searchjson: {},
      rowkeys: [],
      timeval: [],
      merData: {},
      // 默认数据

      pushheaderList: [],
      pushHideList: [],
      searchQuery: {},
      userList: [],
      merchant_admin_id: ''
    }, "mask", ''), "showreview", false), "audit_id", ''), "rowLinkID", ''), "sdjusVisible", false), "sdjusForm", {}), "sdjusList", []);
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_enter_warehouse_list'
    }).then(function (res) {
      _this2.pushheaderList = res.data.erp_v2_enter_warehouse_list.show_list;
      _this2.pushHideList = res.data.erp_v2_enter_warehouse_list.hide_list;
      // console.log(this.pushheaderList);
    });
    this.getList(1);
    this.getStoreList(1);
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this2.merData = res.data;
      _this2.merchant_admin_id = res.data.merchantAdmin.merchant_admin_id;
    });
    (0, _setting.adminListApi)().then(function (res) {
      // //console.log(res);
      _this2.userList = res.data.list.map(function (item) {
        return {
          label: item.real_name,
          value: item.merchant_admin_id
        };
      });
    });
  },
  methods: {
    onSdjus: function onSdjus(row, index) {
      var _this3 = this;
      (0, _basic.shelfList)({
        warehouse_id: row.warehouse_id,
        page: 1,
        limit: 9999
      }).then(function (res) {
        _this3.sdjusList = res.data.list;
        _this3.sdjusForm = {
          id: row.id,
          order_id: row.order_id,
          index: index
        };
        _this3.sdjusVisible = true;
      });
    },
    onSubmit: function onSubmit() {
      var _this4 = this;
      (0, _warehouse.updateShelfApi)(this.sdjusForm).then(function (res) {
        _this4.$message.success('操作成功');
        // this.rowkeys = [];
        // this.getList();
        _this4.sdjusVisible = false;
        (0, _warehouse.getwarehouseOrderGoodsListApiV2)({
          order_id: _this4.rowkeys[0]
        }).then(function (res) {
          _this4.tableData.data[_this4.sdjusForm.index].expandData = res.data;
          // console.log(this.tableData.data,this.sdjusForm.index,res.data);
          // this.$forceUpdate()
        });
      });
    },
    revieworder: function revieworder(row) {
      this.audit_id = row.audit_admin_id;
      this.rowLinkID = row.id;
      this.showreview = true;
      this.mask = '';
    },
    handlereview: function handlereview(state) {
      var _this5 = this;
      if (state == -1 && !this.mask) {
        this.$message.error('请填写拒绝备注');
        return;
      }
      (0, _review.review)(this.audit_id, {
        status: state,
        remark: this.mask,
        type: 'erp_v2_enter_warehoue',
        link_id: this.rowLinkID
      }).then(function (res) {
        _this5.$message.success('操作成功');
        _this5.getList();
        _this5.showreview = false;
      }).catch(function (err) {
        _this5.$message.error(err.message);
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        order_type: 1,
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    changeColumnWidth: function changeColumnWidth(newWidth, oldWidth, column) {
      // console.log(column);
      var index = column.index;
      this.pushheaderList[index].width = newWidth;
      (0, _system.setCustomHeader)({
        types: 'erp_v2_enter_warehouse_list',
        show_data: this.pushheaderList,
        hide_data: this.pushHideList
      }).then(function (res) {}).catch(function (res) {});
      // this.columnWidthArr[index] = newWidth;
      // this.setColumnsWidth();
    },
    exchangeExpand: function exchangeExpand(row) {
      if (!this.$store.getters.userPression.merchantErpV2WarehouseOrderProductList) return;
      if (row.expandData.length) {
        return;
      }
      if (!row.id) {
        return;
      }
      this.rowkeys = [row.id];
      row.loading = true;
      (0, _warehouse.getwarehouseOrderGoodsListApiV2)({
        order_id: row.id
      }).then(function (res) {
        row.expandData = res.data;
        row.loading = false;
      });
    },
    cellClick: function cellClick(row, column, cell, event) {
      if (!this.rowkeys.includes(row.id)) {
        // this.rowkeys.push(row.id);
        this.rowkeys = [row.id];
        this.exchangeExpand(row);
      } else {
        this.rowkeys.splice(this.rowkeys.indexOf(row.id), 1);
      }
      // console.log(this.rowkeys);
    },
    searchList: function searchList(type) {
      var _this6 = this;
      this.showsearch = false;
      this.showsearch = true;
      if (type == 'order_no') {
        this.searchjson.order_no = this.tableFrom.order_no;
      } else if (type == 'enterWarehouse') {
        this.storeList.forEach(function (item) {
          if (item.warehouse_id == _this6.tableFrom.enter_id) {
            _this6.searchjson.enterWarehouse = item.name;
          }
        });
      } else if (type == 'enter_status') {
        var json = {
          1: '待入库',
          0: '已确认',
          '-1': '已作废'
        };
        this.searchjson.enter_status = json[this.tableFrom.enter_status];
      } else if (type == 'salesmanAdmin') {
        this.searchjson.salesmanAdmin = this.tableFrom.entrust_keyword;
      } else if (type == 'phone') {
        this.searchjson.phone = this.tableFrom.entrust_phone;
      } else if (type == 'createAdmin') {
        this.searchjson.createAdmin = this.tableFrom.create_admin_keyword;
      } else if (type == 'enterAdmin') {
        this.searchjson.enterAdmin = this.tableFrom.salesman_admin_keyword;
      }
      // this.searchjson[type] = '1';
      this.getList(1);
    },
    clearsearch: function clearsearch(type) {
      if (type == 'all') {
        this.searchjson = {};
        this.timeVal = [];
        this.tableFrom.order_no = '';
        this.tableFrom.entrust_keyword = '';
        this.tableFrom.entrust_phone = '';
        this.tableFrom.enter_id = '';
        this.tableFrom.enter_status = '';
        this.tableFrom.salesman_admin_keyword = '';
        this.tableFrom.create_admin_keyword = '';
      }
      var flag = false;
      for (var i in this.searchjson) {
        if (this.searchjson[i]) {
          flag = true;
        }
      }
      if (!flag) {
        this.showsearch = false;
      }
    },
    delwarehouseorder: function delwarehouseorder(row) {
      var _this7 = this;
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '不删除',
        type: 'warning'
      }).then(function () {
        (0, _warehouse.delwarehouse)(row.order_id).then(function () {
          _this7.$message.success('删除成功');
          _this7.getList();
        });
      });
    },
    losewarehouseorder: function losewarehouseorder(row) {
      var _this8 = this;
      var that = this;
      this.$prompt('', '提示', {
        confirmButtonText: '确认作废',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入作废理由',
        inputErrorMessage: '请输入作废理由'
      }).then(function (_ref2) {
        var value = _ref2.value;
        (0, _warehouse.invalidpushOrderApiV2)({
          id: row.id,
          remark: value
        }).then(function () {
          that.$message.success('作废成功');
          that.getList();
        });
      }).catch(function () {
        _this8.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    showpro: function showpro(item) {
      this.urlshow = true;
      // console.log(item.credentials);
      this.urlshowList = [item.credentials];
    },
    getIntoCard: function getIntoCard(row) {
      //  this.showimage = true;
      //  this.imagearr = [row.credentials]
      this.urlshow = true;
      this.urlshowList = [row.credentials];
    },
    getIntoshelfCard: function getIntoshelfCard(row) {
      this.showimage = true;
      var imagearr = [];
      row.orderProduct.forEach(function (item) {
        imagearr.push(item.credentials);
      });
      this.imagearr = imagearr;
    },
    getStoreList: function getStoreList(num) {
      var _this9 = this;
      (0, _product.getStoreList)({
        page: 1
      }).then(function (res) {
        _this9.storeList = res.data.list.map(function (item) {
          return {
            label: item.name,
            value: item.warehouse_id
          };
        });
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    outhouse: function outhouse(row) {
      var _this10 = this;
      this.$confirm('确认出库？', '提示', {
        confirmButtonText: '出库',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _warehouse.confirmoutOrderApiV2)({
          id: row.id
        }).then(function (res) {
          _this10.$message.success('出库成功');
          _this10.getList();
        });
      });
      // this.currentrow = row;
      // this.showouthouse = true;
    },
    cursorouthouse: function cursorouthouse() {
      var _this11 = this;
      if (!this.house.image || !this.house.image.length) {
        this.$message.error('请上传凭证');
        return;
      }
      (0, _warehouse.outwarehouse)(this.currentrow.order_id, {
        credentials: this.house.image.join(','),
        remark: this.house.remark
      }).then(function (res) {
        if (res.status == 200) {
          _this11.$message.success('出库成功');
          _this11.showouthouse = false;
        }
      }).catch(function (err) {
        // console.log(err);
        _this11.$message.error(err.message);
      });
    },
    pushshelf: function pushshelf(row, item) {
      var _this12 = this;
      // //console.log(item);
      this.currentitem = item;
      this.shelf.image = '';
      this.shelf.id = item.shelf_id || '';
      (0, _basic.shelfList)({
        warehouse_id: row.push_warehouse_id
      }).then(function (res) {
        _this12.shelfList = res.data.list;
      });
      this.showshelf = true;
    },
    cursorshelf: function cursorshelf() {
      var _this13 = this;
      (0, _warehouse.giveshelf)(this.currentitem.order_product_id, {
        shelf_id: this.shelf.id,
        credentials: this.shelf.image,
        remark: this.shelf.remark,
        status: 1
      }).then(function (res) {
        if (res.status == 200) {
          _this13.$message.success('入位成功');
          _this13.getList();
          _this13.showshelf = false;
        }
      });
    },
    pushhouse: function pushhouse(row) {
      this.house = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
        image: []
      });
      this.showhouse = true;
    },
    cursorpush: function cursorpush() {
      var _this14 = this;
      if (!this.house.image || this.house.image.length == 0) {
        this.$message.error('请上传凭证');
        return;
      }
      (0, _warehouse.confirmpushOrderApiV2)({
        id: this.house.id,
        enter_image: this.house.image.join(',')
      }).then(function () {
        _this14.$message.success('入库成功');
        _this14.showhouse = false;
        _this14.getList();
      });
      // intowarehouse(this.currentrow.order_id, {
      //   credentials: this.house.image.join(","),
      //   remark: this.house.remark,
      // }).then((res) => {
      //   if (res.status == 200) {
      //     this.$message.success("入库成功");
      //     this.getList();
      //     this.showhouse = false;
      //   }
      // });
    },
    edithouse: function edithouse(row) {
      this.$router.push("".concat(_settings.roterPre, "/warehouse/createpushorder?id=").concat(row.order_id));
    },
    getdetail: function getdetail(id) {
      var _this15 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(id).then(function (res) {
        if (res.status == 200) {
          _this15.detailjson = res.data;
          _this15.showdetail = true;
        }
      });
    },
    onSee: function onSee(row) {
      var _this16 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(row.id).then(function (res) {
        console.log(res);
        if (res.status == 200) {
          res.data.enterImageList = res.data.enter_image ? res.data.enter_image.split(',') : [];
          _this16.detailjson = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data), {}, {
            type: 'view'
          });
          _this16.showdetail = true;
        }
      });
    },
    onUpd: function onUpd(row) {
      this.$router.push({
        path: '/merchant/warehouse/createpushorder',
        query: {
          id: row.id
        }
      });
    },
    getalldetail: function getalldetail() {
      var _this17 = this;
      if (!this.multipleSelection.length) {
        this.$message.error('请选择入库单');
        return;
      }
      if (this.multipleSelection.length > 1) {
        this.$message.error('请选择单个入库单');
        return;
      }
      // //console.log(this.multipleSelection[0]);
      (0, _warehouse.getwarehouseOrderDetailApiV2)(this.multipleSelection[0].id).then(function (res) {
        console.log(res);
        if (res.status == 200) {
          _this17.detailjson = res.data;
          _this17.showdetail = true;
        }
      });
      // this.showalldetail = true;
      // //console.log(this.multipleSelection);
      // let ids = []
      // this.multipleSelection.forEach(item=>{
      //    item.orderProduct.forEach(m=>{
      //      ids.push(m.product_id);
      //    })
      // })
    },
    getmorelabel: function getmorelabel() {
      if (!this.multipleSelection.length) {
        this.$message.error('请选择入库单');
        return;
      }
      var ids = [];
      this.multipleSelection.forEach(function (item) {
        // console.log(item);
        // item.expandData.forEach((m) => {
        ids.push(item.id);
        // });
        // item.orderProduct.forEach((m) => {
        //   ids.push(m.product_id);
        // });
      });
      this.showlabel = true;
      this.getecode(ids.join(','));
    },
    getlabel: function getlabel(row) {
      // console.log(row);
      this.showlabel = true;
      var ids = row.map(function (item) {
        return item.id;
      });
      this.getecode(ids.join(','));
    },
    getecode: function getecode(ids) {
      var _this18 = this;
      (0, _warehouse.getOrderbarcodeV2)({
        order_ids: ids
      }).then(function (res) {
        console.log(res);
        if (res.status == 200) {
          _this18.labelarr = res.data;
        }
      });
    },
    bindPrint: function bindPrint() {
      (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        printable: 'printTable',
        type: 'html',
        header: null
      }, "header", null), "targetStyles", ['*']), "style", '@page {margin:0 10mm}'));
    },
    bindPrintorder: function bindPrintorder() {
      // console.log("是不是修改的这里啊");
      (0, _printJs.default)({
        printable: 'printorder',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        scanStyles: true,
        maxWidth: 1000,
        font_size: 'auto',
        style: "@page {margin:0 10mm}"
      });
    },
    // revieworder(row) {
    //   this.handlerow = row;
    //   this.showreview = true;
    //   this.mask = "";
    // },
    // handlereview(state) {
    //   if (state == -1 && !this.mask) {
    //     this.$message.error("请填写拒绝备注");
    //     return;
    //   }
    //   review(this.handlerow.id, { status: state, remark: this.mask }).then((res) => {
    //     this.$message.success("审核成功");
    //   });
    // },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: 'OrderList',
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this19 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _warehouse.getwarehouseOrderListApiV2)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.expandData = [];
        });
        if (_this19.showsearch) {
          res.data.list.unshift({});
        }
        _this19.orderChartType = res.data.stat;
        _this19.tableData.data = res.data.list;
        _this19.tableData.total = res.data.count;
        _this19.listLoading = false;
        _this19.$nextTick(function () {
          if (_this19.$refs.multipleSelection) _this19.$refs.multipleSelection.doLayout();
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this19.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this20 = this;
      // console.log(tit, num, i);
      var _this = this;
      this.$modalUpload(function (img) {
        // console.log(img);
        if (tit === '1' && !num) {
          _this.house.image = [].concat((0, _toConsumableArray2.default)(_this.house.image), (0, _toConsumableArray2.default)(img));
          // console.log(_this.house.image);
        }
        if (tit === '2' && !num) {
          _this.shelf.image = img[0];
        }
        _this20.$forceUpdate();
      }, tit === '1' ? 9 : 1);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this21 = this;
      this.tableFrom.export_type = 'erpEnterWarehouseOrder';
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this21.$createElement;
        _this21.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this21.$msgbox.close();
                _this21.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('erpEnterWarehouseOrder');
    },
    exportLabelOrder: function exportLabelOrder() {
      var _this22 = this;
      if (!this.multipleSelection.length) {
        this.$message.error('请选择入库单');
        return;
      }
      var ids = [];
      this.multipleSelection.forEach(function (item) {
        // item.orderProduct.forEach((m) => {
        ids.push(item.id);
        // });
      });
      var json = {};
      json.order_ids = ids.join(',');
      json.export_type = 'erpV2WarehouseProductRfid';
      (0, _system.exportexcel)(json).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this22.$createElement;
        _this22.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this22.$msgbox.close();
                _this22.getExportFileLabelList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileLabelList: function getExportFileLabelList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('erpV2WarehouseProductRfid');
    },
    onAfterEnter: function onAfterEnter(type, field) {
      // console.log(this.$refs[type]);
      this.$refs[type][1].focus();
    }
  }
};