var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "mobile",
      staticClass: "package",
      style: _vm.pageInfo.commonData
        ? {
            backgroundColor: _vm.pageInfo.commonData.pageBackground,
            backgroundImage: "url(" + _vm.pageInfo.commonData.bg_image + ")",
            backgroundRepeat:
              _vm.pageInfo.commonData.bg_image_mode === 3
                ? "repeat-y"
                : "no-repeat",
            backgroundPosition:
              _vm.pageInfo.commonData.bg_image_mode == 5
                ? "center bottom"
                : _vm.pageInfo.commonData.bg_image_mode == 3 ||
                  _vm.pageInfo.commonData.bg_image_mode == 4
                ? "center top"
                : "center",
            backgroundSize:
              _vm.pageInfo.commonData.bg_image_mode == 2 ? "cover" : "100%",
            fontFamily:
              (_vm.pageInfo.commonData.fontFamily || "PingFangSC-Light") +
              ", PingFangSC-Light",
          }
        : {},
      attrs: { id: "min-page", draggable: false, "data-index": -2 },
      on: {
        dragover: [
          function ($event) {
            return _vm.allowDrop($event)
          },
          function ($event) {
            $event.preventDefault()
          },
        ],
        drop: _vm.drop,
      },
    },
    [
      _vm._l(_vm.pages, function (item, index) {
        return _c(
          "div",
          { key: index + 1, attrs: { role: "widgetgroup" } },
          [
            _vm.dragActive(index, "top") ? _c("Covermask") : _vm._e(),
            _vm._v(" "),
            item.name === "title_weChat"
              ? _c("TitleWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "rubik_weChat"
              ? _c("Rubik", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "blank"
              ? _c("Blank", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "textView"
              ? _c("textView", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auction_weChat"
              ? _c("AuctionWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auction_album_weChat"
              ? _c("AuctionAlbumWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "banner_weChat"
              ? _c("BannerWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    "is-preview": "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "contact_information"
              ? _c("ContactInformation", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "logo_weChat"
              ? _c("logoWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    "is-preview": "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "contract_weChat"
              ? _c("contractWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    "is-preview": "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "position_weChat"
              ? _c("positionWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    "is-preview": "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auction_notice_weChat"
              ? _c("auctionNoticeWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    "is-preview": "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "img_weChat"
              ? _c("ImgWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "text_weChat"
              ? _c("TextWeChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "weChatVideo_weChat"
              ? _c("WeChatVideo_weChat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.dragActive(index, "down") ? _c("Covermask") : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "package-empty", attrs: { "data-index": -2 } },
        [
          _vm.dragActive(-2, "down")
            ? _c("Covermask", { attrs: { "data-index": -2 } })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }