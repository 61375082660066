"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "FileList",
  data: function data() {
    return {
      fileVisible: false,
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        type: ""
      },
      timer: null
    };
  },
  watch: {
    fileVisible: function fileVisible(val) {
      if (val) {
        this.listQuery();
      } else {
        if (this.timer) clearInterval(this.timer);
      }
    }
  },
  methods: {
    listQuery: function listQuery() {
      var _this = this;
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(function () {
        _this.cccList(_this.tableFrom.type);
      }, 2000);
    },
    cccList: function cccList(type) {
      var _this2 = this;
      this.tableFrom.type = type;
      (0, _order.exportFileLstApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
      }).catch(function (res) {
        if (_this2.timer) clearInterval(_this2.timer);
      });
    },
    exportFileList: function exportFileList(type) {
      var _this3 = this;
      // this.tableFrom.type = type;
      this.loading = true;
      this.tableFrom.type = type;
      (0, _order.exportFileLstApi)(this.tableFrom).then(function (res) {
        _this3.fileVisible = true;
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    // 下载
    downLoad: function downLoad(id) {
      window.open(_settingMer.default.https + "/excel/download/".concat(id, "?token=") + (0, _auth.getToken)());
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.exportFileList(this.tableFrom.type);
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.exportFileList(this.tableFrom.type);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.exportFileList(this.tableFrom.type);
    }
  }
};