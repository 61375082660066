var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: "background: " + _vm.facade.backgroundColor + ";" },
    [
      _vm.content.style == 1
        ? [
            _c(
              "div",
              {
                staticClass: "auction-list column-one",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.pageMargin / 2 +
                  "px",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item",
                    class: { m0: i == 0 },
                    style: {
                      background: _vm.facade.itemGradientBackground
                        ? "linear-gradient(" +
                          _vm.facade.itemGradientDirection +
                          ", " +
                          _vm.facade.itemBackground +
                          ", " +
                          _vm.facade.itemGradientBackground +
                          ")"
                        : _vm.facade.itemBackground,
                      "margin-top": _vm.facade.itemMargin / 2 + "px",
                      "border-top-left-radius":
                        _vm.facade.itemTopLeftRadius / 2 + "px",
                      "border-top-right-radius":
                        _vm.facade.itemTopRightRadius / 2 + "px",
                      "border-bottom-left-radius":
                        _vm.facade.itemBottomLeftRadius / 2 + "px",
                      "border-bottom-right-radius":
                        _vm.facade.itemBottomRightRadius / 2 + "px",
                      "box-shadow": _vm.facade.itemShadow
                        ? "0 0 " +
                          _vm.facade.itemShadowSize / 2 +
                          "px " +
                          _vm.facade.itemShadow
                        : "",
                      overflow: "hidden",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          background: _vm.facade.imgBackground,
                          overflow: "hidden",
                          "border-top-left-radius":
                            _vm.facade.imgTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.imgTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.imgBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.imgBottomRightRadius / 2 + "px",
                        },
                      },
                      [_c("img", { attrs: { src: m.image } })]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc",
                        style: {
                          margin:
                            _vm.facade.descMarginTop / 2 +
                            "px " +
                            _vm.facade.descMarginRight / 2 +
                            "px " +
                            _vm.facade.descMarginBottom / 2 +
                            "px " +
                            _vm.facade.descMarginLeft / 2 +
                            "px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style: {
                              color: _vm.facade.nameColor,
                              "font-weight": _vm.facade.nameFontWeight,
                              "font-size": _vm.facade.nameFontSize / 2 + "px",
                              "margin-top": _vm.facade.nameMarginTop / 2 + "px",
                            },
                          },
                          [_vm._v("LOT109 " + _vm._s(m.name))]
                        ),
                        _vm._v(" "),
                        m.status === 1
                          ? _c(
                              "div",
                              {
                                staticClass: "desc",
                                style: {
                                  height: _vm.facade.statusHeight / 2 + "px",
                                  "line-height":
                                    _vm.facade.statusHeight / 2 + "px",
                                  color: _vm.facade.appraisalPriceColor,
                                  "font-weight":
                                    _vm.facade.appraisalPriceFontWeight,
                                  "font-size":
                                    _vm.facade.appraisalPriceFontSize / 2 +
                                    "px",
                                  "margin-top":
                                    _vm.facade.appraisalPriceMarginTop / 2 +
                                    "px",
                                },
                              },
                              [_vm._v("估价：RMB 3500-10万")]
                            )
                          : m.status !== 1
                          ? _c(
                              "div",
                              {
                                staticClass: "status-view flex align-items-c",
                                style: {
                                  color: _vm.facade.statusColor,
                                  height: _vm.facade.statusHeight / 2 + "px",
                                  background: _vm.facade.statusBackground,
                                  "font-weight": _vm.facade.statusFontWeight,
                                  "font-size":
                                    _vm.facade.statusFontSize / 2 + "px",
                                  "margin-top":
                                    _vm.facade.statusMarginTop / 2 + "px",
                                  "border-radius":
                                    _vm.facade.statusRadius / 2 + "px",
                                  overflow: "hidden",
                                },
                              },
                              [
                                m.status === 2
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          style: {
                                            height:
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            "line-height":
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            background:
                                              _vm.facade.auctionIngBackground,
                                            color:
                                              _vm.facade.auctionStatusColor,
                                            padding: "0 2px",
                                          },
                                        },
                                        [_vm._v("拍卖中")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(0, true),
                                    ]
                                  : m.status === 3
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          style: {
                                            height:
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            "line-height":
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            background:
                                              _vm.facade.auctionEndBackground,
                                            color:
                                              _vm.facade.auctionStatusColor,
                                            padding: "0 2px",
                                          },
                                        },
                                        [_vm._v("已结拍")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(1, true),
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          style: {
                                            height:
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            "line-height":
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            background:
                                              _vm.facade.auctionWaitBackground,
                                            color:
                                              _vm.facade.auctionStatusColor,
                                            padding: "0 2px",
                                          },
                                        },
                                        [_vm._v("即将开拍")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(2, true),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "price",
                            style: {
                              color: _vm.facade.priceTextColor,
                              "font-weight": _vm.facade.priceTextFontWeight,
                              "font-size":
                                _vm.facade.priceTextFontSize / 2 + "px",
                              "margin-top":
                                _vm.facade.priceMarginTop / 2 + "px",
                            },
                          },
                          [
                            m.status === 1
                              ? [_vm._v("起拍价")]
                              : m.status === 2
                              ? [_vm._v("当前价")]
                              : m.status === 3
                              ? [_vm._v("落锤价")]
                              : [_vm._v("起拍价")],
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                style: {
                                  color:
                                    m.status === 3
                                      ? _vm.facade.priceTextColor
                                      : _vm.facade.priceColor,
                                  "font-weight": _vm.facade.priceFontWeight,
                                  "font-size":
                                    _vm.facade.priceFontSize / 2 + "px",
                                },
                              },
                              [_vm._v(_vm._s(m.price))]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 2
        ? [
            _c(
              "div",
              {
                staticClass: "flex",
                style: {
                  padding:
                    (_vm.isComponent ? "30px" : "0") +
                    " " +
                    (_vm.facade.pageMargin > _vm.facade.itemMargin
                      ? (_vm.facade.pageMargin - _vm.facade.itemMargin) / 2
                      : 0) +
                    "px " +
                    (_vm.isComponent ? "30px" : "0") +
                    " " +
                    (_vm.facade.pageMargin > _vm.facade.itemMargin
                      ? _vm.facade.pageMargin / 2
                      : _vm.facade.itemMargin / 2) +
                    "px ",
                },
              },
              _vm._l(2, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "auctionC" + i,
                    style: {
                      flex: 1,
                      "margin-right": _vm.facade.itemMargin / 2 + "px",
                      overflow: "hidden",
                    },
                  },
                  _vm._l(2, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "auctionD" + index,
                        staticClass: "item",
                        class: { m0: index == 0 },
                        style: {
                          background: _vm.facade.itemGradientBackground
                            ? "linear-gradient(" +
                              _vm.facade.itemGradientDirection +
                              ", " +
                              _vm.facade.itemBackground +
                              ", " +
                              _vm.facade.itemGradientBackground +
                              ")"
                            : _vm.facade.itemBackground,
                          "margin-top": _vm.facade.itemMargin / 2 + "px",
                          "border-top-left-radius":
                            _vm.facade.itemTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.itemTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.itemBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.itemBottomRightRadius / 2 + "px",
                          "box-shadow": _vm.facade.itemShadow
                            ? "0 0 " +
                              _vm.facade.itemShadowSize / 2 +
                              "px " +
                              _vm.facade.itemShadow
                            : "",
                          overflow: "hidden",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style: {
                              background: _vm.facade.imgBackground,
                              overflow: "hidden",
                              "border-top-left-radius":
                                _vm.facade.imgTopLeftRadius / 2 + "px",
                              "border-top-right-radius":
                                _vm.facade.imgTopRightRadius / 2 + "px",
                              "border-bottom-left-radius":
                                _vm.facade.imgBottomLeftRadius / 2 + "px",
                              "border-bottom-right-radius":
                                _vm.facade.imgBottomRightRadius / 2 + "px",
                              height:
                                _vm.facade.imgStyle === 1 ? "auto" : "150px",
                            },
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                display: "block",
                              },
                              attrs: {
                                src: _vm.defaultData[i * 2 + index].image,
                                fit:
                                  _vm.facade.imgStyle == 3
                                    ? "cover"
                                    : "contain",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            style: {
                              overflow: "hidden",
                              margin:
                                _vm.facade.descMarginTop / 2 +
                                "px " +
                                _vm.facade.descMarginRight / 2 +
                                "px " +
                                _vm.facade.descMarginBottom / 2 +
                                "px " +
                                _vm.facade.descMarginLeft / 2 +
                                "px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "name line1",
                                style: {
                                  color: _vm.facade.nameColor,
                                  "font-weight": _vm.facade.nameFontWeight,
                                  "font-size":
                                    _vm.facade.nameFontSize / 2 + "px",
                                  "margin-top":
                                    _vm.facade.nameMarginTop / 2 + "px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.facade.pageMargin) +
                                    " " +
                                    _vm._s(_vm.facade.itemMargin) +
                                    " LOT109 " +
                                    _vm._s(_vm.defaultData[i * 2 + index].name)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.defaultData[i * 2 + index].status === 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "desc",
                                    style: {
                                      height:
                                        _vm.facade.statusHeight / 2 + "px",
                                      "line-height":
                                        _vm.facade.statusHeight / 2 + "px",
                                      color: _vm.facade.appraisalPriceColor,
                                      "font-weight":
                                        _vm.facade.appraisalPriceFontWeight,
                                      "font-size":
                                        _vm.facade.appraisalPriceFontSize / 2 +
                                        "px",
                                      "margin-top":
                                        _vm.facade.appraisalPriceMarginTop / 2 +
                                        "px",
                                    },
                                  },
                                  [_vm._v("估价：RMB 3500-10万")]
                                )
                              : _vm.defaultData[i * 2 + index].status !== 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "status-view flex align-items-c",
                                    style: {
                                      color: _vm.facade.statusColor,
                                      height:
                                        _vm.facade.statusHeight / 2 + "px",
                                      background: _vm.facade.statusBackground,
                                      "font-weight":
                                        _vm.facade.statusFontWeight,
                                      "font-size":
                                        _vm.facade.statusFontSize / 2 + "px",
                                      "margin-top":
                                        _vm.facade.statusMarginTop / 2 + "px",
                                      "border-radius":
                                        _vm.facade.statusRadius / 2 + "px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _vm.defaultData[i * 2 + index].status === 2
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex",
                                              style: {
                                                height:
                                                  _vm.facade.statusHeight / 2 +
                                                  "px",
                                                "line-height":
                                                  _vm.facade.statusHeight / 2 +
                                                  "px",
                                                background:
                                                  _vm.facade
                                                    .auctionIngBackground,
                                                color:
                                                  _vm.facade.auctionStatusColor,
                                                padding: "0 2px",
                                              },
                                            },
                                            [_vm._v("拍卖中")]
                                          ),
                                          _vm._v(" "),
                                          _vm._m(3, true),
                                        ]
                                      : _vm.defaultData[i * 2 + index]
                                          .status === 3
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex",
                                              style: {
                                                height:
                                                  _vm.facade.statusHeight / 2 +
                                                  "px",
                                                "line-height":
                                                  _vm.facade.statusHeight / 2 +
                                                  "px",
                                                background:
                                                  _vm.facade
                                                    .auctionEndBackground,
                                                color:
                                                  _vm.facade.auctionStatusColor,
                                                padding: "0 2px",
                                              },
                                            },
                                            [_vm._v("已结拍")]
                                          ),
                                          _vm._v(" "),
                                          _vm._m(4, true),
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex",
                                              style: {
                                                height:
                                                  _vm.facade.statusHeight / 2 +
                                                  "px",
                                                "line-height":
                                                  _vm.facade.statusHeight / 2 +
                                                  "px",
                                                background:
                                                  _vm.facade
                                                    .auctionWaitBackground,
                                                color:
                                                  _vm.facade.auctionStatusColor,
                                                padding: "0 2px",
                                              },
                                            },
                                            [_vm._v("即将开拍")]
                                          ),
                                          _vm._v(" "),
                                          _vm._m(5, true),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "price",
                                style: {
                                  color: _vm.facade.priceTextColor,
                                  "font-weight": _vm.facade.priceTextFontWeight,
                                  "font-size":
                                    _vm.facade.priceTextFontSize / 2 + "px",
                                  "margin-top":
                                    _vm.facade.priceMarginTop / 2 + "px",
                                },
                              },
                              [
                                m.status === 1
                                  ? [_vm._v("起拍价")]
                                  : m.status === 2
                                  ? [_vm._v("当前价")]
                                  : m.status === 3
                                  ? [_vm._v("落锤价")]
                                  : [_vm._v("起拍价")],
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        m.status === 3
                                          ? _vm.facade.priceTextColor
                                          : _vm.facade.priceColor,
                                      "font-weight": _vm.facade.priceFontWeight,
                                      "font-size":
                                        _vm.facade.priceFontSize / 2 + "px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.defaultData[i * 2 + index].price
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 3
        ? [
            _c(
              "div",
              {
                staticClass: "flex flex-wrap",
                style: {
                  padding:
                    (_vm.isComponent ? "30px" : "0") +
                    " " +
                    (_vm.facade.pageMargin > _vm.facade.itemMargin
                      ? (_vm.facade.pageMargin - _vm.facade.itemMargin) / 2
                      : 0) +
                    "px " +
                    (_vm.isComponent ? "30px" : "0") +
                    " " +
                    (_vm.facade.pageMargin > _vm.facade.itemMargin
                      ? _vm.facade.pageMargin / 2
                      : _vm.facade.itemMargin / 2) +
                    "px ",
                },
              },
              _vm._l(_vm.defaultData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "auctionD" + index,
                    style: {
                      "margin-right": _vm.facade.itemMargin / 2 + "px",
                      overflow: "hidden",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        class: { m0: index < 3 },
                        style: {
                          background: _vm.facade.itemGradientBackground
                            ? "linear-gradient(" +
                              _vm.facade.itemGradientDirection +
                              ", " +
                              _vm.facade.itemBackground +
                              ", " +
                              _vm.facade.itemGradientBackground +
                              ")"
                            : _vm.facade.itemBackground,
                          "margin-top": _vm.facade.itemMargin / 2 + "px",
                          "border-top-left-radius":
                            _vm.facade.itemTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.itemTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.itemBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.itemBottomRightRadius / 2 + "px",
                          "box-shadow": _vm.facade.itemShadow
                            ? "0 0 " +
                              _vm.facade.itemShadowSize / 2 +
                              "px " +
                              _vm.facade.itemShadow
                            : "",
                          overflow: "hidden",
                          width: _vm.listThreeWidth,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style: {
                              background: _vm.facade.imgBackground,
                              overflow: "hidden",
                              "border-top-left-radius":
                                _vm.facade.imgTopLeftRadius / 2 + "px",
                              "border-top-right-radius":
                                _vm.facade.imgTopRightRadius / 2 + "px",
                              "border-bottom-left-radius":
                                _vm.facade.imgBottomLeftRadius / 2 + "px",
                              "border-bottom-right-radius":
                                _vm.facade.imgBottomRightRadius / 2 + "px",
                              height:
                                _vm.facade.imgStyle === 1 ? "auto" : "120px",
                            },
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                display: "block",
                              },
                              attrs: {
                                src: item.image,
                                fit:
                                  _vm.facade.imgStyle == 3
                                    ? "cover"
                                    : "contain",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            style: {
                              overflow: "hidden",
                              margin:
                                _vm.facade.descMarginTop / 2 +
                                "px " +
                                _vm.facade.descMarginRight / 2 +
                                "px " +
                                _vm.facade.descMarginBottom / 2 +
                                "px " +
                                _vm.facade.descMarginLeft / 2 +
                                "px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "name line1",
                                style: {
                                  color: _vm.facade.nameColor,
                                  "font-weight": _vm.facade.nameFontWeight,
                                  "font-size":
                                    _vm.facade.nameFontSize / 2 + "px",
                                  "margin-top":
                                    _vm.facade.nameMarginTop / 2 + "px",
                                },
                              },
                              [_vm._v("LOT109 " + _vm._s(item.name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "price",
                                style: {
                                  color: _vm.facade.priceTextColor,
                                  "font-weight": _vm.facade.priceTextFontWeight,
                                  "font-size":
                                    _vm.facade.priceTextFontSize / 2 + "px",
                                  "margin-top":
                                    _vm.facade.priceMarginTop / 2 + "px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color:
                                        item.status === 3
                                          ? _vm.facade.priceTextColor
                                          : _vm.facade.priceColor,
                                      "font-weight": _vm.facade.priceFontWeight,
                                      "font-size":
                                        _vm.facade.priceFontSize / 2 + "px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.price))]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 4
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column-line",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.pageMargin / 2 +
                  "px",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item flex",
                    class: { m0: i == 0 },
                    style: {
                      background: _vm.facade.itemGradientBackground
                        ? "linear-gradient(" +
                          _vm.facade.itemGradientDirection +
                          ", " +
                          _vm.facade.itemBackground +
                          ", " +
                          _vm.facade.itemGradientBackground +
                          ")"
                        : _vm.facade.itemBackground,
                      "margin-top": _vm.facade.itemMargin / 2 + "px",
                      "border-top-left-radius":
                        _vm.facade.itemTopLeftRadius / 2 + "px",
                      "border-top-right-radius":
                        _vm.facade.itemTopRightRadius / 2 + "px",
                      "border-bottom-left-radius":
                        _vm.facade.itemBottomLeftRadius / 2 + "px",
                      "border-bottom-right-radius":
                        _vm.facade.itemBottomRightRadius / 2 + "px",
                      "box-shadow": _vm.facade.itemShadow
                        ? "0 0 " +
                          _vm.facade.itemShadowSize / 2 +
                          "px " +
                          _vm.facade.itemShadow
                        : "",
                      overflow: "hidden",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          width: _vm.facade.imgWidth / 2 + "px",
                          height: _vm.facade.imgHeight / 2 + "px",
                          background: _vm.facade.imgBackground,
                          overflow: "hidden",
                          "border-top-left-radius":
                            _vm.facade.imgTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.imgTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.imgBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.imgBottomRightRadius / 2 + "px",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100%",
                            display: "block",
                          },
                          attrs: {
                            src: m.image,
                            fit:
                              _vm.facade.imgStyle === 2 ? "contain" : "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc flex-one",
                        style: {
                          overflow: "hidden",
                          margin:
                            _vm.facade.descMarginTop / 2 +
                            "px " +
                            _vm.facade.descMarginRight / 2 +
                            "px " +
                            _vm.facade.descMarginBottom / 2 +
                            "px " +
                            _vm.facade.descMarginLeft / 2 +
                            "px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex align-items-c",
                            style: {
                              color: _vm.facade.lotColor,
                              "font-weight": _vm.facade.lotFontWeight,
                              "font-size": _vm.facade.lotFontSize / 2 + "px",
                              "margin-top": _vm.facade.lotMarginTop / 2 + "px",
                            },
                          },
                          [
                            _c("div", { staticClass: "flex-one" }, [
                              _vm._v("\n              LOT109\n            "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.facade.ingStatusColor,
                                  "font-weight": _vm.facade.ingStatusFontWeight,
                                  "font-size":
                                    _vm.facade.ingStatusFontSize / 2 + "px",
                                },
                              },
                              [_vm._v("3分12秒 结拍")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style: {
                              color: _vm.facade.nameColor,
                              "font-weight": _vm.facade.nameFontWeight,
                              "font-size": _vm.facade.nameFontSize / 2 + "px",
                              "margin-top": _vm.facade.nameMarginTop / 2 + "px",
                            },
                          },
                          [_vm._v(_vm._s(m.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "price",
                            style: {
                              color: _vm.facade.priceTextColor,
                              "font-weight": _vm.facade.priceTextFontWeight,
                              "font-size":
                                _vm.facade.priceTextFontSize / 2 + "px",
                              "margin-top":
                                _vm.facade.priceMarginTop / 2 + "px",
                            },
                          },
                          [
                            m.status === 1
                              ? [_vm._v("起拍价")]
                              : m.status === 2
                              ? [_vm._v("当前价")]
                              : m.status === 3
                              ? [_vm._v("落锤价")]
                              : [_vm._v("起拍价")],
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                style: {
                                  color:
                                    m.status === 3
                                      ? _vm.facade.priceTextColor
                                      : _vm.facade.priceColor,
                                  "font-weight": _vm.facade.priceFontWeight,
                                  "font-size":
                                    _vm.facade.priceFontSize / 2 + "px",
                                },
                              },
                              [_vm._v(_vm._s(m.price))]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 5
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column flex",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.pageMargin / 2 +
                  "px;overflow: hidden;",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item",
                    style: {
                      background: _vm.facade.itemGradientBackground
                        ? "linear-gradient(" +
                          _vm.facade.itemGradientDirection +
                          ", " +
                          _vm.facade.itemBackground +
                          ", " +
                          _vm.facade.itemGradientBackground +
                          ")"
                        : _vm.facade.itemBackground,
                      "border-top-left-radius":
                        _vm.facade.itemTopLeftRadius / 2 + "px",
                      "border-top-right-radius":
                        _vm.facade.itemTopRightRadius / 2 + "px",
                      "border-bottom-left-radius":
                        _vm.facade.itemBottomLeftRadius / 2 + "px",
                      "border-bottom-right-radius":
                        _vm.facade.itemBottomRightRadius / 2 + "px",
                      "box-shadow": _vm.facade.itemShadow
                        ? "0 0 " +
                          _vm.facade.itemShadowSize / 2 +
                          "px " +
                          _vm.facade.itemShadow
                        : "",
                      overflow: "hidden",
                      width: _vm.facade.imgWidth / 2 + "px",
                      "margin-right": _vm.facade.itemMargin / 2 + "px",
                      "flex-shrink": 0,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          width: _vm.facade.imgWidth / 2 + "px",
                          height: _vm.facade.imgHeight / 2 + "px",
                          background: _vm.facade.imgBackground,
                          overflow: "hidden",
                          "border-top-left-radius":
                            _vm.facade.imgTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.imgTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.imgBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.imgBottomRightRadius / 2 + "px",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100%",
                            display: "block",
                          },
                          attrs: {
                            src: m.image,
                            fit:
                              _vm.facade.imgStyle === 2 ? "contain" : "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc",
                        style: {
                          overflow: "hidden",
                          margin:
                            _vm.facade.descMarginTop / 2 +
                            "px " +
                            _vm.facade.descMarginRight / 2 +
                            "px " +
                            _vm.facade.descMarginBottom / 2 +
                            "px " +
                            _vm.facade.descMarginLeft / 2 +
                            "px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style: {
                              color: _vm.facade.nameColor,
                              "font-weight": _vm.facade.nameFontWeight,
                              "font-size": _vm.facade.nameFontSize / 2 + "px",
                              "margin-top": _vm.facade.nameMarginTop / 2 + "px",
                            },
                          },
                          [_vm._v("LOT109 " + _vm._s(m.name))]
                        ),
                        _vm._v(" "),
                        m.status === 1
                          ? _c(
                              "div",
                              {
                                staticClass: "desc",
                                style: {
                                  height: _vm.facade.statusHeight / 2 + "px",
                                  "line-height":
                                    _vm.facade.statusHeight / 2 + "px",
                                  color: _vm.facade.appraisalPriceColor,
                                  "font-weight":
                                    _vm.facade.appraisalPriceFontWeight,
                                  "font-size":
                                    _vm.facade.appraisalPriceFontSize / 2 +
                                    "px",
                                  "margin-top":
                                    _vm.facade.appraisalPriceMarginTop / 2 +
                                    "px",
                                },
                              },
                              [_vm._v("估价：RMB 3500-10万")]
                            )
                          : m.status !== 1
                          ? _c(
                              "div",
                              {
                                staticClass: "status-view flex align-items-c",
                                style: {
                                  color: _vm.facade.statusColor,
                                  height: _vm.facade.statusHeight / 2 + "px",
                                  background: _vm.facade.statusBackground,
                                  "font-weight": _vm.facade.statusFontWeight,
                                  "font-size":
                                    _vm.facade.statusFontSize / 2 + "px",
                                  "margin-top":
                                    _vm.facade.statusMarginTop / 2 + "px",
                                  "border-radius":
                                    _vm.facade.statusRadius / 2 + "px",
                                  overflow: "hidden",
                                },
                              },
                              [
                                m.status === 2
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          style: {
                                            height:
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            "line-height":
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            background:
                                              _vm.facade.auctionIngBackground,
                                            color:
                                              _vm.facade.auctionStatusColor,
                                            padding: "0 2px",
                                          },
                                        },
                                        [_vm._v("拍卖中")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(6, true),
                                    ]
                                  : m.status === 3
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          style: {
                                            height:
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            "line-height":
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            background:
                                              _vm.facade.auctionEndBackground,
                                            color:
                                              _vm.facade.auctionStatusColor,
                                            padding: "0 2px",
                                          },
                                        },
                                        [_vm._v("已结拍")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(7, true),
                                    ]
                                  : [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          style: {
                                            height:
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            "line-height":
                                              _vm.facade.statusHeight / 2 +
                                              "px",
                                            background:
                                              _vm.facade.auctionWaitBackground,
                                            color:
                                              _vm.facade.auctionStatusColor,
                                            padding: "0 2px",
                                          },
                                        },
                                        [_vm._v("即将开拍")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(8, true),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "price",
                            style: {
                              color: _vm.facade.priceTextColor,
                              "font-weight": _vm.facade.priceTextFontWeight,
                              "font-size":
                                _vm.facade.priceTextFontSize / 2 + "px",
                              "margin-top":
                                _vm.facade.priceMarginTop / 2 + "px",
                            },
                          },
                          [
                            m.status === 1
                              ? [_vm._v("起拍价")]
                              : m.status === 2
                              ? [_vm._v("当前价")]
                              : m.status === 3
                              ? [_vm._v("落锤价")]
                              : [_vm._v("起拍价")],
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                style: {
                                  color:
                                    m.status === 3
                                      ? _vm.facade.priceTextColor
                                      : _vm.facade.priceColor,
                                  "font-weight": _vm.facade.priceFontWeight,
                                  "font-size":
                                    _vm.facade.priceFontSize / 2 + "px",
                                },
                              },
                              [_vm._v(_vm._s(m.price))]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [
        _c("div", [_vm._v("出价5次")]),
        _vm._v(" "),
        _c("div", [_vm._v("10月20日 18:30结拍")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [
        _c("div", [_vm._v("出价5次")]),
        _vm._v(" "),
        _c("div", [_vm._v("10月20日 18:30结拍")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [_c("div", [_vm._v("估价：RMB 3500-10万")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [
        _c("div", [_vm._v("出价5次")]),
        _vm._v(" "),
        _c("div", [_vm._v("10月20日 18:30结拍")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [
        _c("div", [_vm._v("出价5次")]),
        _vm._v(" "),
        _c("div", [_vm._v("10月20日 18:30结拍")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [_c("div", [_vm._v("估价：RMB 3500-10万")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [
        _c("div", [_vm._v("出价5次")]),
        _vm._v(" "),
        _c("div", [_vm._v("10月20日 18:30结拍")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [
        _c("div", [_vm._v("出价5次")]),
        _vm._v(" "),
        _c("div", [_vm._v("10月20日 18:30结拍")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex justify-b flex-one",
        staticStyle: { padding: "0 4px" },
      },
      [_c("div", [_vm._v("估价：RMB 3500-10万")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }