"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ProductClassify',
  data: function data() {
    return {
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      }
      // tableFrom: {
      //   page: 1,
      //   limit: 20
      // }
    };
  },
  filters: {
    attrFilter: function attrFilter(arr) {
      if (!arr || arr.length == 0) return '';else {
        var newArr = arr.map(function (item) {
          return item.attribute.attr_name;
        });
        return newArr.join(',');
      }
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _product.storeCategoryListApi)().then(function (res) {
        _this.tableData.data = res.data;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    // 添加
    onAdd: function onAdd() {
      var _this2 = this;
      this.$modalForm((0, _product.storeCategoryCreateApi)()).then(function () {
        return _this2.getList();
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this3 = this;
      this.$modalForm((0, _product.storeCategoryUpdateApi)(id)).then(function () {
        return _this3.getList();
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _product.storeCategoryDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this4.$message.success(message);
          _this4.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this4.$message.error(message);
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this5 = this;
      (0, _product.storeCategoryStatusApi)(row.store_category_id, row.is_show).then(function (_ref3) {
        var message = _ref3.message;
        _this5.$message.success(message);
      }).catch(function (_ref4) {
        var message = _ref4.message;
        _this5.$message.error(message);
      });
    }
  }
};