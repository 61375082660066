var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm.isPreview
      ? _c("section", [
          _c(
            "section",
            {
              staticClass: "tn-comp-pin tn-comp-style-pin",
              attrs: { "tn-link": "compAttr.link" },
            },
            [
              _c(
                "section",
                {
                  staticClass:
                    "tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope",
                  staticStyle: {
                    display: "inline-block",
                    width: "100%",
                    "vertical-align": "top",
                    overflow: "hidden",
                    "align-self": "flex-start",
                  },
                  attrs: {
                    "tn-cell-type": "group",
                    "tn-child-orientation": "flow-canvas",
                    "tn-link": "cell.link",
                    "tn-animate":
                      "(!isCellBound ? '__NOT_MOUNT__' : cell.anim)",
                    "tn-animate-on-self": "true",
                    "tn-ra-cell": "box.cubes:0.pages:0.layers:0.comps:3.col1",
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticStyle: { display: "block", margin: "0 auto" },
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox:
                          "0 0 " +
                          _vm.content.view_width +
                          " " +
                          _vm.content.view_height,
                        width: "100%",
                      },
                    },
                    [
                      _c("g", { attrs: { transform: "translate(0 0)" } }, [
                        _c(
                          "g",
                          [
                            _c(
                              "foreignObject",
                              {
                                attrs: {
                                  width: _vm.content.view_width,
                                  height: _vm.content.view_width,
                                  x: "0",
                                  y: "0",
                                },
                              },
                              [
                                _c("svg", {
                                  style:
                                    "width: 100%; background-color: transparent; background-position: 0% 0%;background-repeat: no-repeat; background-size: 100%; background-image: url(" +
                                    _vm.viewData[0].image +
                                    ");background-attachment: scroll;        -webkit-tap-highlight-color: transparent; user-select: none; visibility: visible; pointer-events: none;",
                                  attrs: {
                                    viewBox:
                                      "0 0 " +
                                      _vm.content.view_width +
                                      " " +
                                      _vm.content.view_height,
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ])
      : _c(
          "section",
          {
            staticClass:
              "tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope",
            staticStyle: { position: "static" },
            attrs: {
              "tn-comp-pose": "compConstraint.pose.tplPose || compAttr.pose",
              "tn-uuid": "",
              "tn-animate": "(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)",
              "tn-ra-comp": "box.cubes:0.pages:0.layers:0.comps:3",
            },
          },
          [
            _c(
              "section",
              {
                staticClass: "tn-comp-pin tn-comp-style-pin",
                attrs: { "tn-link": "compAttr.link" },
              },
              [
                _c(
                  "section",
                  {
                    staticClass:
                      "tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope",
                    staticStyle: {
                      display: "inline-block",
                      width: "100%",
                      "vertical-align": "top",
                      overflow: "hidden",
                      "align-self": "flex-start",
                    },
                    attrs: {
                      "tn-cell-type": "group",
                      "tn-child-orientation": "flow-canvas",
                      "tn-link": "cell.link",
                      "tn-animate":
                        "(!isCellBound ? '__NOT_MOUNT__' : cell.anim)",
                      "tn-animate-on-self": "true",
                      "tn-ra-cell": "box.cubes:0.pages:0.layers:0.comps:3.col1",
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticStyle: { display: "block", margin: "0 auto" },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox:
                            "0 0 " +
                            _vm.content.view_width +
                            " " +
                            _vm.content.view_height,
                          width: "100%",
                        },
                      },
                      _vm._l(_vm.viewData, function (m, i) {
                        return _c(
                          "g",
                          {
                            key: i,
                            attrs: {
                              transform:
                                "translate(" + _vm.content.view_width + " 0)",
                            },
                          },
                          [
                            _c(
                              "g",
                              [
                                _c(
                                  "foreignObject",
                                  {
                                    attrs: {
                                      width: _vm.content.view_width,
                                      height: _vm.content.view_width,
                                      x: "0",
                                      y: 0 - _vm.content.view_width / 2,
                                    },
                                  },
                                  [
                                    _c("svg", {
                                      style:
                                        "width: 100%; background-color: transparent; background-position: 0% 0%;background-repeat: no-repeat; background-size: 100%; background-image: url(" +
                                        m.image +
                                        ");background-attachment: scroll;        -webkit-tap-highlight-color: transparent; user-select: none; visibility: visible; pointer-events: none;",
                                      attrs: {
                                        viewBox:
                                          "0 0 " +
                                          _vm.content.view_width +
                                          " " +
                                          _vm.content.view_height,
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("animateTransform", {
                                  attrs: {
                                    begin: i * 3 + (i > 1 ? i - 1 : 0) + "s",
                                    calcMode: "undefined",
                                    fill: "freeze",
                                    attributeName: "transform",
                                    type: "scale",
                                    repeatCount: "indefinite",
                                    dur: _vm.viewData.length * 4,
                                    values: i > 0 ? _vm.scale2 : _vm.scale1,
                                    keyTimes:
                                      i > 0 ? _vm.keyTimes2 : _vm.keyTimes1,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("animateTransform", {
                              attrs: {
                                attributeName: "transform",
                                type: "translate",
                                fill: "freeze",
                                begin: i * 3 + (i > 1 ? i - 1 : 0) + "s",
                                restart: "never",
                                repeatCount: "indefinite",
                                dur: _vm.viewData.length * 4,
                                values: i > 0 ? _vm.values2 : _vm.values1,
                                keyTimes: i > 0 ? _vm.keyTimes2 : _vm.keyTimes1,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }