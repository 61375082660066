"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminCreateApi = adminCreateApi;
exports.adminDeleteApi = adminDeleteApi;
exports.adminListApi = adminListApi;
exports.adminLogApi = adminLogApi;
exports.adminPasswordApi = adminPasswordApi;
exports.adminStatusApi = adminStatusApi;
exports.adminUpdateApi = adminUpdateApi;
exports.appPackageListApi = appPackageListApi;
exports.appRenewCreateApi = appRenewCreateApi;
exports.appUseVersionApi = appUseVersionApi;
exports.editRuleApi = editRuleApi;
exports.getAgreement = getAgreement;
exports.getApprovalApi = getApprovalApi;
exports.getPressionApi = getPressionApi;
exports.getRuleListApi = getRuleListApi;
exports.menuRoleApi = menuRoleApi;
exports.roleCreateApi = roleCreateApi;
exports.roleDeleteApi = roleDeleteApi;
exports.roleStatusApi = roleStatusApi;
exports.roleUpdateApi = roleUpdateApi;
exports.saveRuleApi = saveRuleApi;
exports.setAgreement = setAgreement;
exports.storeGetInfoApi = storeGetInfoApi;
exports.storeUpdateApi = storeUpdateApi;
exports.uploadAppPackageApi = uploadAppPackageApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 身份管理 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function menuRoleApi(data) {
  return _request.default.get("system/role/lst", data);
}

/**
 * @description 身份管理 -- 新增
 * @param {Object} param params {Object} 传值参数
 */
function roleCreateApi() {
  return _request.default.get("system/role/create/form");
}

/**
 * @description 身份管理 -- 编辑
 * @param {Object} param params {Object} 传值参数
 */
function roleUpdateApi(id) {
  return _request.default.get("system/role/update/form/".concat(id));
}

/**
 * @description 身份管理 -- 删除
 * @param {Object} param params {Object} 传值参数
 */
function roleDeleteApi(id) {
  return _request.default.delete("system/role/delete/".concat(id));
}

/**
 * @description 身份管理 -- 修改状态
 * @param {Object} param params {Object} 传值参数
 */
function roleStatusApi(id, status) {
  return _request.default.post("system/role/status/".concat(id), {
    status: status
  });
}
/**
 * @description 管理员 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function adminListApi(data) {
  return _request.default.get("system/admin/lst", data);
}

/**
 * @description 管理员 -- 新增
 * @param {Object} param params {Object} 传值参数
 */
function adminCreateApi() {
  return _request.default.get("/system/admin/create/form");
}

/**
 * @description 管理员 -- 编辑
 * @param {Object} param params {Object} 传值参数
 */
function adminUpdateApi(id) {
  return _request.default.get("system/admin/update/form/".concat(id));
}

/**
 * @description 管理员 -- 删除
 * @param {Object} param params {Object} 传值参数
 */
function adminDeleteApi(id) {
  return _request.default.delete("system/admin/delete/".concat(id));
}

/**
 * @description 管理员 -- 修改状态
 * @param {Object} param params {Object} 传值参数
 */
function adminStatusApi(id, status) {
  return _request.default.post("system/admin/status/".concat(id), {
    status: status
  });
}
/**
 * @description 管理员 -- 修改密码表单
 * @param {Object} param params {Object} 传值参数
 */
function adminPasswordApi(id) {
  return _request.default.get("system/admin/password/form/".concat(id));
}
/**
 * @description 操作日志 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function adminLogApi(data) {
  return _request.default.get("system/admin/log", data);
}
/**
 * @description 自提 -- 详情
 * @param {Object} param params {Object} 传值参数
 */
function storeGetInfoApi() {
  return _request.default.get("take/info");
}
/**
 * @description 自提 -- 提交
 * @param {Object} param params {Object} 传值参数
 */
function storeUpdateApi(data) {
  return _request.default.post("take/update", data);
}

/**
 * @description 获取协议
 * @param {Object} param params {Object} 传值参数
 */
function getAgreement(data) {
  return _request.default.get("system/merchant/getAgreement", data);
}

/**
 * @description 保存协议
 * @param {Object} param params {Object} 传值参数
 */
function setAgreement(data) {
  return _request.default.post("system/merchant/setAgreement", data);
}

/**
 * @description 创建自动更新
 */
function appRenewCreateApi(data) {
  return _request.default.post("/appRenew/create", data);
}

// 上传apk包
function uploadAppPackageApi(data) {
  return _request.default.post('/appRenew/uploadAppPackage', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

// app版本列表
function appPackageListApi(data) {
  return _request.default.get('/appRenew/list', data);
}

// app 使用版本
function appUseVersionApi(data) {
  return _request.default.post('/appRenew/useVersion', data);
}

// 获取商户权限列表
function getRuleListApi(data) {
  return _request.default.get('/roleMenus', data);
}

// 保存商户权限列表
function saveRuleApi(data) {
  return _request.default.post('/system/role/create', data);
}

// 修改商户的权限
function editRuleApi(id, data) {
  return _request.default.post("/system/role/update/".concat(id), data);
}

// 获取商户权限列表
function getPressionApi(data) {
  return _request.default.get("/permissionsV2", data);
}

// 获取审批权限
function getApprovalApi(data) {
  return _request.default.get("config/getApprovalData", data);
}