"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SystemRole',
  data: function data() {
    return {
      grid: {
        xl: 7,
        lg: 7,
        md: 12,
        sm: 24,
        xs: 24
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  computed: {
    pression: function pression() {
      // let gopath = this.$route.path.replace('/merchant', '');
      var gopath = '/setting/systemRule/systemRole/systemAdmin';
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _setting.adminListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      (0, _setting.adminStatusApi)(row.merchant_admin_id, row.status).then(function (_ref) {
        var message = _ref.message;
        _this2.$message.success(message);
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    // 添加
    onAdd: function onAdd() {
      var _this3 = this;
      this.$modalForm((0, _setting.adminCreateApi)()).then(function () {
        return _this3.getList();
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this4 = this;
      this.$modalForm((0, _setting.adminUpdateApi)(id)).then(function () {
        return _this4.getList();
      });
    },
    // 修改密码表单
    onPassword: function onPassword(id) {
      this.$modalForm((0, _setting.adminPasswordApi)(id));
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this5 = this;
      this.$modalSure().then(function () {
        (0, _setting.adminDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this5.$message.success(message);
          _this5.tableData.data.splice(idx, 1);
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      });
    }
  }
};