var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { clearable: "", filterable: "" },
          on: { change: _vm.changeAuctionId },
          model: {
            value: _vm.lastAuctionId,
            callback: function ($$v) {
              _vm.lastAuctionId = $$v
            },
            expression: "lastAuctionId",
          },
        },
        _vm._l(_vm.lastAuction, function (item) {
          return _c("el-option", {
            key: item.venue_id,
            attrs: { label: item.title, value: item.venue_id },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 216px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                height: "100%",
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.id
                },
                data: _vm.tableData.data,
                size: "mini",
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "30",
                },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方",
                  width: "160",
                  prop: "",
                  "search-key": "entrust_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(scope.row.entrustUser.nickname) +
                                    "\n          "
                                ),
                                scope.row.entrustUser.real_name
                                  ? [
                                      _vm._v(
                                        "\n            (" +
                                          _vm._s(
                                            scope.row.entrustUser.real_name
                                          ) +
                                          ")\n          "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "图录号",
                  width: "80",
                  prop: "",
                  "search-key": "lot",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAuction
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.pmAuction.lot) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "合同号",
                  width: "130",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "contract_no",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contract
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.contract.contract_no) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品名称",
                  width: "130",
                  prop: "",
                  "search-key": "auction_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAuction
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.pmAuction.product.store_name
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "专场",
                  width: "130",
                  prop: "",
                  "search-key": "album_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAlbum
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.pmAlbum.album_name) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "销售状态",
                  width: "90",
                  prop: "",
                  "search-key": "auction_status",
                  "search-type": "radio",
                  options: [
                    { label: "交割失败", value: "-1" },
                    { label: "流拍", value: "4" },
                    { label: "成交", value: "5" },
                    { label: "私洽成交", value: "7" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.auctionStatusText) +
                              "\n        "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "业务员",
                  width: "90",
                  prop: "",
                  "search-key": "salesman_admin_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.salesmanAdmin
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.salesmanAdmin.real_name) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "出库状态",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                scope.row.erpProduct.product_warehouse_status ==
                                3
                                  ? [_vm._v("待出库")]
                                  : scope.row.erpProduct
                                      .product_warehouse_status == 4
                                  ? [_vm._v("已出库")]
                                  : [_vm._v("-")],
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "确认物流",
                  width: "80",
                  prop: "logistics_confirmer",
                  "search-key": "",
                  "search-type": "radio",
                  options: [
                    { label: "客户本人", value: "1" },
                    { label: "后台员工", value: "2" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.logistics_confirmer == 1
                          ? _c("div", [_vm._v("客户本人")])
                          : scope.row.logistics_confirmer == 2 &&
                            scope.row.logisticsConfirmAdmin
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.logisticsConfirmAdmin.real_name
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "物流方式",
                  width: "90",
                  prop: "",
                  "search-key": "logistics_type",
                  "search-type": "radio",
                  options: [
                    { label: "快递发货", value: "express" },
                    { label: "自提", value: "self_take" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.logistics_type == "express"
                          ? _c("div", [_vm._v("快递发货")])
                          : scope.row.logistics_type == "self_take"
                          ? _c("div", [_vm._v("自提")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "收件地址",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.logistics_confirm_admin_id
                          ? _c("div", [
                              _vm._v(_vm._s(scope.row.logistics_address)),
                            ])
                          : _c("div", [_vm._v("未确定")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editstatic(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            修改物流\n          ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            {
              staticClass: "flex-one flex",
              staticStyle: { "margin-left": "4px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.createPushOrder },
                },
                [_vm._v("创建出库单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.logisticsOrder },
                },
                [_vm._v("确认物流")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes ",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            visible: _vm.showconfig,
            direction: "rtl",
            size: "450px",
            "destroy-on-close": true,
            "show-close": true,
            wrapperClosable: true,
            "custom-class": "overfloyy",
            "before-close": _vm.saveconfig,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showconfig = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticStyle: { "font-weight": "bold" },
              attrs: { "label-width": "40px", "label-position": "left" },
            },
            [
              _c("el-form-item", [_c("span", [_vm._v("委托标的清单配置")])]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("售出后收费")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.sold_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "sold_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.sold_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("未售出收费")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.unsold_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "unsold_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.unsold_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("其他收费项目")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.other_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "other_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.other_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.inventoryConfig.switch.sold_price_switch
                ? [
                    _c("el-form-item", [
                      _c("span", [_vm._v("售出后收费项目")]),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.sold_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "C_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 2, label: "%" } },
                                  [_vm._v("%落槌价")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.inventoryConfig.switch.unsold_price_switch
                ? [
                    _c("el-form-item", [_c("span", [_vm._v("未售出收费")])]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.unsold_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "U_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 2, label: "%" } },
                                  [_vm._v("%保留价")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.inventoryConfig.switch.other_price_switch
                ? [
                    _c("el-form-item", [_c("span", [_vm._v("其他收费项目")])]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.other_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "o_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "确认物流", visible: _vm.showflag, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.showflag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formvisible,
                "label-width": "80px",
                inline: false,
                size: "normal",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "express" },
                      model: {
                        value: _vm.formvisible.logistics_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formvisible, "logistics_type", $$v)
                        },
                        expression: "formvisible.logistics_type",
                      },
                    },
                    [_vm._v("快递发货")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "self_take" },
                      model: {
                        value: _vm.formvisible.logistics_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formvisible, "logistics_type", $$v)
                        },
                        expression: "formvisible.logistics_type",
                      },
                    },
                    [_vm._v("客户自提")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formvisible.logistics_type == "express"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "姓名" } },
                      [
                        _c("el-input", {
                          staticStyle: { "margin-right": "40px" },
                          model: {
                            value: _vm.formvisible.logistics_user_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formvisible,
                                "logistics_user_name",
                                $$v
                              )
                            },
                            expression: "formvisible.logistics_user_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "地址" } },
                      [
                        _c("el-input", {
                          staticStyle: { "margin-right": "40px" },
                          model: {
                            value: _vm.formvisible.logistics_address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formvisible,
                                "logistics_address",
                                $$v
                              )
                            },
                            expression: "formvisible.logistics_address",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "电话" } },
                      [
                        _c("el-input", {
                          staticStyle: { "margin-right": "40px" },
                          model: {
                            value: _vm.formvisible.logistics_phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.formvisible, "logistics_phone", $$v)
                            },
                            expression: "formvisible.logistics_phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showflag = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确认修改")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }