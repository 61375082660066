var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout", staticStyle: { background: "#f7f7f7" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            {
              staticStyle: {
                position: "fixed",
                left: "0",
                top: "0",
                right: "0",
              },
              attrs: { height: "64px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-align-center",
                  staticStyle: {
                    width: "1345px",
                    margin: "0 auto",
                    height: "64px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "page-back item-align-center",
                      on: { click: _vm.backAction },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/b8b0d796b5afef0149e76265443f1cbd.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("返回")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-shu" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-name item-align-center" }, [
                    _c("div", { staticClass: "l" }, [_vm._v("基础页面")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/6390304e322470c6b1a238158de1ccf4.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "r" }, [
                      _vm._v(_vm._s(_vm.pageInfo.name || "")),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "page-btn item-align-center" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "large" },
                          on: { click: _vm.handlePageSave },
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: { click: _vm.handleCancelAction },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: { click: _vm.handlePageSetting },
                        },
                        [_vm._v("预览/同步")]
                      ),
                      _vm._v(" "),
                      _vm.type == "manage"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "info", size: "large" },
                              on: { click: _vm.handlePageModelSave },
                            },
                            [_vm._v("保存模板")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-main",
            {
              staticStyle: {
                "padding-top": "64px",
                "box-sizing": "border-box",
                width: "1345px",
                margin: "0 auto",
              },
            },
            [
              _c(
                "div",
                { staticClass: "layout-tool" },
                [
                  _c(
                    "el-scrollbar",
                    { style: _vm.scrollHeight },
                    [
                      _c(
                        "el-collapse",
                        {
                          attrs: {
                            value: [
                              "basics",
                              "advanced",
                              "enterprise",
                              "other",
                              "marketing",
                              "company",
                            ],
                          },
                        },
                        _vm._l(_vm.panels, function (mounted, index) {
                          return _c(
                            "el-collapse-item",
                            { key: index, attrs: { name: index } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("i", {
                                  staticClass: "le-icon le-icon-arrow",
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(mounted.title))]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "fm-collapse-tool" },
                                [
                                  _vm._l(
                                    mounted.children,
                                    function (item, index) {
                                      return [
                                        item.name == "task" &&
                                        _vm.$manifest("task", "status")
                                          ? _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "widget",
                                                attrs: {
                                                  id:
                                                    item.title == "视频"
                                                      ? "guide001"
                                                      : "",
                                                  draggable: true,
                                                  "data-type": "module",
                                                },
                                                on: {
                                                  dragstart: function ($event) {
                                                    return _vm.dragStart(item)
                                                  },
                                                  dragend: _vm.dragEnd,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "widget-icon",
                                                  attrs: { src: item.icon },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ]
                                            )
                                          : _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "widget",
                                                attrs: {
                                                  id:
                                                    item.title == "视频"
                                                      ? "guide001"
                                                      : "",
                                                  draggable: true,
                                                  "data-type": "module",
                                                },
                                                on: {
                                                  dragstart: function ($event) {
                                                    return _vm.dragStart(item)
                                                  },
                                                  dragend: _vm.dragEnd,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "widget-icon",
                                                  attrs: { src: item.icon },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ]
                                            ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "clears" }),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            2
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-body" },
                [
                  _c(
                    "el-scrollbar",
                    {
                      ref: "scrollbar",
                      style: _vm.scrollHeight,
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handlePackage($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "layout-phone",
                          style: {
                            minHeight:
                              !_vm.pageInfo.open_header ||
                              _vm.pageInfo.headerSetting.header_air
                                ? "700px"
                                : "765px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "layout-phone-body",
                              attrs: { id: "guide002" },
                            },
                            [_c("router-view", { ref: "childView" })],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.is_lock
                ? _c(
                    "div",
                    {
                      staticClass: "layout-attribute display-flex",
                      attrs: { id: "guide004" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _vm.index >= 0
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _vm.modules[_vm.attribute]
                                    ? _c(_vm.modules[_vm.attribute].attribute, {
                                        tag: "component",
                                        on: {
                                          editText: function (e) {
                                            return _vm.editTextAction(
                                              e,
                                              _vm.index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.index == -1
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _c("pageAttribute", {
                                    attrs: { configuration: _vm.configuration },
                                    on: {
                                      setTheme: _vm.setThemeAction,
                                      setConfiguration: _vm.setConfiguration,
                                    },
                                    model: {
                                      value: _vm.pageInfo.commonData,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pageInfo,
                                          "commonData",
                                          $$v
                                        )
                                      },
                                      expression: "pageInfo.commonData",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPreview
        ? [
            _c("preview", {
              attrs: {
                pages: _vm.previewData,
                "page-info": _vm.pageInfo.commonData,
                title: _vm.pageInfo.commonData.title,
                price: _vm.modelprice,
                marketid: _vm.marketid,
              },
              on: { closeAction: _vm.closeAction },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showModelFlag, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showModelFlag = $event
            },
            close: function ($event) {
              _vm.showModelFlag = false
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "内容类型", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      model: {
                        value: _vm.modeltype,
                        callback: function ($$v) {
                          _vm.modeltype = $$v
                        },
                        expression: "modeltype",
                      },
                    },
                    _vm._l(_vm.makeTagOption, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模板费用" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入模板费用", size: "normal" },
                    model: {
                      value: _vm.modelprice,
                      callback: function ($$v) {
                        _vm.modelprice = $$v
                      },
                      expression: "modelprice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模板名称" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入模板名称", size: "normal" },
                    model: {
                      value: _vm.templatename,
                      callback: function ($$v) {
                        _vm.templatename = $$v
                      },
                      expression: "templatename",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.temid
                ? _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.createType,
                            callback: function ($$v) {
                              _vm.createType = $$v
                            },
                            expression: "createType",
                          },
                        },
                        [_vm._v("新增")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.createType,
                            callback: function ($$v) {
                              _vm.createType = $$v
                            },
                            expression: "createType",
                          },
                        },
                        [_vm._v("覆盖原来")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-button",
                {
                  staticClass: "w300",
                  attrs: { type: "primary" },
                  on: { click: _vm.modelSubmit },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "w300",
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      _vm.showModelFlag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }