var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
      },
    },
    _vm._l(_vm.content.data, function (m, i) {
      return _c(
        "section",
        {
          key: i,
          style: {
            backgroundColor: _vm.facade.pageBackground,
            padding:
              (i == 0 ? 0 : _vm.facade.pagePaddingTop) +
              "px " +
              _vm.facade.pagePaddingRight +
              "px " +
              (i + 1 == _vm.content.data.length
                ? 0
                : _vm.facade.pagePaddingBottom) +
              "px " +
              _vm.facade.pagePaddingLeft +
              "px",
            borderWidth:
              (i == 0 ? 0 : _vm.facade.borderTop) +
              "px " +
              _vm.facade.borderRight +
              "px " +
              (i + 1 == _vm.content.data.length ? 0 : _vm.facade.borderBottom) +
              "px " +
              _vm.facade.borderLeft +
              "px",
            borderRadius:
              _vm.facade.pageRadiusTopLeft +
              "px " +
              _vm.facade.pageRadiusTopRight +
              "px " +
              _vm.facade.pageRadiusBottomRight +
              "px " +
              _vm.facade.pageRadiusBottomLeft +
              "px",
            borderStyle: "solid",
            borderColor: _vm.facade.borderColor,
            color: _vm.facade.pageColor,
            fontSize: _vm.facade.fontSize + "px",
            fontWeight: "" + _vm.facade.fontWeight,
            display: "flex",
            flexDirection: _vm.facade.textArea == 1 ? "row" : "column",
          },
        },
        [
          _c(
            "section",
            {
              style: {
                borderRadius:
                  _vm.facade.imgRadiusTopLeft +
                  "px " +
                  _vm.facade.imgRadiusTopRight +
                  "px " +
                  _vm.facade.imgRadiusBottomRight +
                  "px " +
                  _vm.facade.imgRadiusBottomLeft +
                  "px",
                flex: _vm.facade.textArea == 1 ? 1 : "unset",
              },
            },
            [
              m.link && m.link.link && m.link.link.indexOf("http") == 0
                ? _c(
                    "a",
                    {
                      attrs: {
                        title: m.link.link,
                        formlinkparm: [{ href: m.link.link }],
                        href: m.link.link,
                        target: "_blank",
                        "data-linktype": "1",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "rich_pages wxw-img",
                        staticStyle: { display: "block", width: "100%" },
                        attrs: {
                          "data-ratio": "1",
                          "data-s": "300,640",
                          "data-fail": "0",
                          "data-src": m.url,
                          src: m.url,
                          alt: "",
                        },
                      }),
                    ]
                  )
                : m.link.link && _vm.miniAppid
                ? _c(
                    "a",
                    {
                      staticClass: "weapp_image_link",
                      attrs: {
                        "data-miniprogram-appid": _vm.miniAppid,
                        "data-miniprogram-path": m.link.link,
                        "data-miniprogram-nickname": _vm.miniName,
                        href: "",
                        "data-miniprogram-type": "image",
                        "data-miniprogram-servicetype": "2",
                        "wah-hotarea": "click",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "rich_pages wxw-img",
                        staticStyle: { display: "block", width: "100%" },
                        attrs: {
                          "data-ratio": "1",
                          "data-s": "300,640",
                          "data-fail": "0",
                          "data-src": m.url,
                          src: m.url,
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _c("img", {
                    staticStyle: { display: "block", width: "100%" },
                    attrs: { src: m.url, alt: "" },
                  }),
            ]
          ),
          _vm._v(" "),
          m.title && _vm.facade.textWidth != 0
            ? _c(
                "section",
                {
                  style: {
                    display: _vm.facade.textArea == 1 ? "flex" : "block",
                    flexDirection:
                      _vm.facade.textArea == 1 ? "column" : "unset",
                    justifyContent:
                      _vm.facade.textArea == 1
                        ? _vm.facade.textItemArea
                        : "unset",
                    textAlign: _vm.facade.textAlign,
                    padding:
                      _vm.facade.textPaddingTop +
                      "px " +
                      _vm.facade.textPaddingRight +
                      "px " +
                      _vm.facade.textPaddingBottom +
                      "px " +
                      _vm.facade.textPaddingLeft +
                      "px",
                    backgroundColor: _vm.facade.textBackground,
                    width:
                      _vm.facade.textArea == 1
                        ? _vm.facade.textWidth + "%"
                        : "100%",
                    boxSzing: "border-box",
                    lineHeight: "20px",
                  },
                },
                [
                  _c(
                    "section",
                    {
                      style:
                        "color: " +
                        _vm.facade.color +
                        ";font-size: " +
                        _vm.facade.fontSize +
                        "px;font-weight: " +
                        _vm.facade.fontWeight +
                        ";margin-top: " +
                        _vm.facade.marginTop +
                        "px;",
                    },
                    [_vm._v(_vm._s(m.title))]
                  ),
                ]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }