"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _index = _interopRequireDefault(require("./systemRole/index.vue"));
var _index2 = _interopRequireDefault(require("./systemAdmin/index.vue"));
var _index3 = _interopRequireDefault(require("./systemLog/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    systemRole: _index.default,
    systemAdmin: _index2.default,
    systemLog: _index3.default
  },
  data: function data() {
    return {
      status: '1'
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    if (this.$route.path == '/merchant/setting/systemRule/systemRole/role') this.status = '1';
    if (this.$route.path == '/merchant/setting/systemRule/systemRole/systemAdmin') this.status = '2';
    if (this.$route.path == '/merchant/setting/systemRule/systemRole/systemLog') this.status = '3';
  },
  methods: {
    changeStatusAction: function changeStatusAction(e) {
      // if (this.status == '1') { 
      //   this.$router.replace({ path: '/merchant/setting/systemRule/systemRole/role' })
      // }
      // if (this.status == '2') {
      //   this.$router.replace({ path: '/merchant/setting/systemRule/systemRole/systemAdmin' })
      // }
      // if (this.status == '3') {
      //   this.$router.replace({ path: '/merchant/setting/systemRule/systemRole/systemLog' })
      // }
    },
    onGOUrl: function onGOUrl(url) {
      this.$router.push({
        name: url
      });
    },
    changeTab: function changeTab(data) {
      if (data !== this.$route.path) {
        this.$router.replace({
          path: data
        });
      }
    }
  }
};