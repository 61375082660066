var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "顶部导航" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c(
                  "div",
                  {
                    staticClass: "fontW400 ft14 color-black",
                    class: { w80: _vm.content.style === 1 },
                  },
                  [
                    _vm._v(
                      "导航内容" +
                        _vm._s(_vm.content.style === 2 ? "和跳转链接" : "")
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pt10" },
                _vm._l(_vm.content.data, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "kuang1 item-align-center mt10",
                    },
                    [
                      index === 0
                        ? _c(
                            "div",
                            {
                              staticClass: "go-down-img",
                              on: {
                                click: function ($event) {
                                  return _vm.goDown(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      index > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "go-up-img",
                              on: {
                                click: function ($event) {
                                  return _vm.goUp(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "del-img",
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction(index)
                            },
                          },
                        },
                        [
                          item.url
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.url
                            ? _c("img", { attrs: { src: item.url + "!120a" } })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-view ml4" },
                        [
                          _c("el-input", {
                            style:
                              _vm.content.style === 1
                                ? "width: 80px;"
                                : "width: 140px;",
                            attrs: {
                              type: "text",
                              placeholder: "设置文字",
                              maxlength: "10",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value: item.title,
                              callback: function ($$v) {
                                _vm.$set(item, "title", $$v)
                              },
                              expression: "item.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      item.link.name1 || item.link.name
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end ml10 cur-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.setCategory(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v(
                                  _vm._s(item.link.name1 || item.link.name)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.setCategory(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v("设置跳转链接"),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("img", {
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "margin-right": "5px",
                    "margin-top": "2px",
                  },
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fontW500 ft16",
                    on: { click: _vm.handleAdditem },
                  },
                  [_vm._v("新增一条")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c("choose", {
            staticClass: "mt20",
            attrs: {
              data: _vm.styleData,
              title: "风格类型",
              margin: "0 10px 0 0",
            },
            model: {
              value: _vm.content.style,
              callback: function ($$v) {
                _vm.$set(_vm.content, "style", $$v)
              },
              expression: "content.style",
            },
          }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("跳转"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.content.jump_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "jump_type", $$v)
                        },
                        expression: "content.jump_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("新窗口打开"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("替换当前页面打开"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面跟随" } },
                [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-one",
                          staticStyle: { "font-size": "12px", color: "#999" },
                        },
                        [_vm._v("页面上滑时导航固定在顶部展示")]
                      ),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#009406",
                          "inactive-color": "#cccccc",
                        },
                        model: {
                          value: _vm.facade.fixed_top,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "fixed_top", $$v)
                          },
                          expression: "facade.fixed_top",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图标位置" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.nav_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "nav_style", $$v)
                        },
                        expression: "facade.nav_style",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("居左")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("居中")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "左侧边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 375 },
                    model: {
                      value: _vm.facade.page_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_padding", $$v)
                      },
                      expression: "facade.page_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.facade.nav_style == 1
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "图标间距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.facade.item_padding,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "item_padding", $$v)
                          },
                          expression: "facade.item_padding",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style == 1
                ? [
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("图片尺寸")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.img_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "img_width", $$v)
                                  },
                                  expression: "facade.img_width",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { margin: "0 6px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.img_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "img_height", $$v)
                                  },
                                  expression: "facade.img_height",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item-align-center",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [
                        _c("div", {
                          staticClass: "w80 fontW400 ft14 color-black",
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: { "font-size": "12px", color: "#999" },
                          },
                          [_vm._v("宽x高，所有图片统一尺寸比例")]
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _vm.content.style == 1
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "图标距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.text_top,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "text_top", $$v)
                          },
                          expression: "facade.text_top",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.text_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_color", $$v)
                      },
                      expression: "facade.text_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.text_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "text_style", $$v)
                        },
                        expression: "facade.text_style",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 40 },
                    model: {
                      value: _vm.facade.text_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_size", $$v)
                      },
                      expression: "facade.text_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.style == 2
                ? [
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "选中模式" } },
                      [
                        _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex-one",
                                staticStyle: {
                                  "font-size": "12px",
                                  color: "#999",
                                },
                              },
                              [_vm._v("开启后可设置某个导航高亮展示")]
                            ),
                            _vm._v(" "),
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#009406",
                                "inactive-color": "#cccccc",
                              },
                              model: {
                                value: _vm.facade.is_hover,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "is_hover", $$v)
                                },
                                expression: "facade.is_hover",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("高亮导航")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c(
                              "span",
                              { staticClass: "mr10 ft12 color-gray" },
                              [_vm._v("第")]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: _vm.facade.hover_index,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "hover_index", $$v)
                                },
                                expression: "facade.hover_index",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "ml10 ft12 color-gray" },
                              [_vm._v("个导航高亮")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "高亮颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.hover_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hover_color", $$v)
                            },
                            expression: "facade.hover_color",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }