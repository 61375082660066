var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "all-bg" }, [
    _vm.auctionData && _vm.albumData
      ? _c("div", { staticClass: "tap" }, [
          _c("div", { staticClass: "logo-view flex align-items-c" }, [
            _vm.logo
              ? _c("img", {
                  attrs: {
                    src: _vm.logo + "!m640",
                    alt: _vm.albumData.venue.title,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.albumData.venue.title))]),
            _vm._v(" "),
            _c("img", {
              staticClass: "jt",
              attrs: {
                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024617/image/1718604417945892478.png!120a",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.albumData.album_name))]),
            _vm._v(" "),
            _c("div", { staticClass: "count" }, [
              _vm._v("第"),
              _c("span", [_vm._v(_vm._s(_vm.auctionData.order_num))]),
              _vm._v("件 / 共" + _vm._s(_vm.albumData.auction_num) + "件"),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.auctionData && _vm.albumData
      ? _c(
          "div",
          { staticClass: "home", attrs: { id: "home" } },
          [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "w450 ml15" }, [
                _c("div", { staticClass: "lt" }, [
                  _c("div", { staticClass: "ltt" }, [
                    _c("div", { staticClass: "item-flex-center" }, [
                      _c(
                        "div",
                        {
                          staticClass: "ltbutton color-black fWeight500",
                          on: { click: _vm.handleAdditem },
                        },
                        [_vm._v("跳拍(Q)")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ltbutton color-black fWeight500",
                          on: {
                            click: function ($event) {
                              return _vm.confirmAction("remakeAction")
                            },
                          },
                        },
                        [_vm._v("重拍(W)")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ltbutton color-black fWeight500",
                          on: {
                            click: function ($event) {
                              return _vm.confirmAction("revokeAuctionAction")
                            },
                          },
                        },
                        [_vm._v("撤拍(E)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pm-now-data fWeight500 textAlignC" },
                      [
                        _c("div", { staticClass: "lot pt10" }, [
                          _vm._v("LOT " + _vm._s(_vm.auctionData.lot)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "price" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.fee_type == "CNY" ? "RMB" : _vm.fee_type
                              )
                            ),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.auctionData.now_price,
                                2
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "btns item-flex-center" },
                          [
                            _vm.albumData.album_type == 2 &&
                            _vm.albumData.end_type == 2
                              ? [
                                  _vm.goTime &&
                                  _vm.showS == "00" &&
                                  _vm.cd == "00"
                                    ? _c("div", { staticClass: "btn r mr10" }, [
                                        _vm._v("结束中"),
                                      ])
                                    : _vm.goTime
                                    ? _c("div", { staticClass: "btn l mr10" }, [
                                        _c("span", { staticClass: "l" }, [
                                          _vm._v(_vm._s(_vm.showS)),
                                        ]),
                                        _c("span", { staticClass: "l" }, [
                                          _vm._v(":"),
                                        ]),
                                        _c("span", { staticClass: "r" }, [
                                          _vm._v(_vm._s(_vm.cd)),
                                        ]),
                                      ])
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "btn l mr10",
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmAction(
                                                "auctionEndTimeAction"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("倒数落槌(L)")]
                                      ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.auctionData.now_price &&
                            _vm.recordList &&
                            _vm.recordList.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "btn l",
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmAction(
                                          "auctionEndAction",
                                          1
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("落槌(Y)")]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "btn l hui",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$message.error(
                                          "没有出价用户，不能落槌"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("落槌(Y)")]
                                ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "btn r ml10",
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmAction(
                                      "auctionEndAction",
                                      2
                                    )
                                  },
                                },
                              },
                              [_vm._v("流拍(N)")]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "common-c mt20 relative" },
                  [
                    _vm.countDownTime.length > 0
                      ? _vm._l(_vm.countDownTime, function (m, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "bid-count-down" },
                            [
                              _vm._v(
                                "网络买家 " +
                                  _vm._s(m.number) +
                                  "号 正在充值 00:" +
                                  _vm._s(m.time)
                              ),
                            ]
                          )
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "common-c-title" }, [
                      _vm._v("出价记录"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "record-list",
                        staticStyle: { height: "380px", "overflow-y": "auto" },
                      },
                      _vm._l(_vm.recordList, function (m, i) {
                        return _c(
                          "div",
                          {
                            key: "record" + i,
                            staticClass: "item flex align-items-c",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "num", class: { lv: i == 0 } },
                              [_vm._v(_vm._s(i + 1))]
                            ),
                            _vm._v(" "),
                            m.user
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "number",
                                    class: { hui: i != 0 },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(m.user.nickname) + " "),
                                      m.user.number &&
                                      m.user.number.number_type == "免保号牌"
                                        ? _c("span", [_vm._v("免")])
                                        : m.user.number &&
                                          m.user.number.number_type ==
                                            "特殊号牌"
                                        ? _c("span", [_vm._v("特")])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    m.user.nickname_encrypt
                                      ? _c(
                                          "div",
                                          { staticClass: "ft12 line1" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.decrypt(
                                                  m.user.nickname_encrypt,
                                                  "1234567890123456",
                                                  "1234567890abcde1"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _c("div", { staticClass: "number" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "price",
                                class: { hui: i != 0 },
                                style:
                                  m.is_confirm === -1
                                    ? "text-decoration: line-through;color: #999;"
                                    : "",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(m.bid_price, 2)
                                    ) +
                                    "\n                "
                                ),
                                i === 0 && m.is_confirm === 0
                                  ? _c("span", { staticClass: "wait" }, [
                                      _vm._v("待确认"),
                                    ])
                                  : m.is_confirm === -1
                                  ? _c("span", { staticClass: "fail" }, [
                                      _vm._v("无效"),
                                    ])
                                  : i !== 0
                                  ? _c("span", { staticClass: "out" }, [
                                      _vm._v("出局"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "right" }, [
                              i === 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "cz",
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmAction(
                                            "delBidPriceAction",
                                            m.bid_id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("撤销(C)")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w450 ml15" }, [
                _c("div", { staticClass: "ct" }, [
                  _c(
                    "div",
                    {
                      staticClass: "bid-price",
                      class: {
                        curr:
                          !(
                            _vm.recordList &&
                            _vm.recordList[0] &&
                            _vm.recordList[0].uid != 8888 &&
                            _vm.recordList[0].is_confirm == 0
                          ) || _vm.isEditPrice,
                      },
                    },
                    [
                      _c("div", { staticClass: "bid-price-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.auctionData.time_status == 1
                              ? "确认起拍价"
                              : "现场出价"
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "bid-price-view mt5" },
                        _vm._l(_vm.bidPriceList, function (m, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "bid-price-item",
                              class: {
                                curr: _vm.bidPriceIndex === i,
                                hui: i < _vm.huiNum,
                                fh: i === 0 || i === 3 || i === 6,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeBidPrice(i)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "num",
                                  class: { curr: _vm.bidPriceIndex === i },
                                },
                                [_vm._v(_vm._s(m || 0))]
                              ),
                              i >= _vm.huiNum && (i === 0 || i === 3 || i === 6)
                                ? _c("span", [_vm._v(",")])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.auctionData.time_status == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "bid-price-btn",
                              on: { click: _vm.startBid },
                            },
                            [
                              _vm._v("确认起拍价 "),
                              _c("span", [_vm._v("(空格)")]),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "bid-price-btn",
                              on: { click: _vm.bidPrice },
                            },
                            [_vm._v("出价 "), _c("span", [_vm._v("(空格)")])]
                          ),
                      _vm._v(" "),
                      _vm.changeUser.uid
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "show-change-bid-user flex item-align-center",
                            },
                            [
                              _c("div", { staticClass: "flex-one" }, [
                                _vm._v(
                                  "出价号牌：" +
                                    _vm._s(_vm.changeUser.user.nickname) +
                                    " " +
                                    _vm._s(_vm.changeUser.number)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "a",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.changeUser = {}
                                    },
                                  },
                                },
                                [_vm._v("取消选择")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "common-c mt30" }, [
                  _c("div", { staticClass: "common-c-title" }, [
                    _vm._v("竞价阶梯"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.plus_price &&
                    !_vm.inpFocus &&
                    _vm.auctionData.bid_type === 1
                      ? _c(
                          "div",
                          {
                            staticClass: "edit",
                            on: { click: _vm.editPlusPrice },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.formatPriceNumber(_vm.plus_price, 2)) +
                                "\n            "
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "edit-inp",
                            class: { curr: _vm.inpFocus },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.set_plus_price,
                                  expression: "set_plus_price",
                                },
                              ],
                              ref: "editPlusPriceEL",
                              attrs: {
                                type: "text",
                                placeholder: "输入临时加价幅度",
                              },
                              domProps: { value: _vm.set_plus_price },
                              on: {
                                focus: _vm.focusNewAction,
                                blur: _vm.blurNewAction,
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  return _vm.setPlusAction($event)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.set_plus_price = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "enter" }, [
                              _vm._v("回车保存"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "240px",
                        width: "100%",
                        overflow: "hidden",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "bid-range-list flex flex-wrap" },
                        [
                          _vm.oldRange
                            ? _c(
                                "div",
                                {
                                  staticClass: "item",
                                  class: {
                                    curr:
                                      _vm.oldRange.id ==
                                        _vm.auctionData.bid_range_id &&
                                      _vm.auctionData.bid_type === 2,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeRangeAction(
                                        _vm.oldRange.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.oldRange.title))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.rangeList, function (m, i) {
                            return _c(
                              "div",
                              {
                                key: "rangeList" + i,
                                staticClass: "item",
                                class: {
                                  curr:
                                    m.id == _vm.auctionData.bid_range_id &&
                                    _vm.auctionData.bid_type === 2,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeRangeAction(m.id)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(m.title))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "r w450 ml15 mr15" }, [
                _c("div", { staticClass: "rt fWeight500 textAlignC" }, [
                  _c(
                    "div",
                    { staticStyle: { height: "305px" } },
                    [
                      _vm.recordList &&
                      _vm.recordList[0] &&
                      _vm.recordList[0].uid != 8888 &&
                      _vm.recordList[0].is_confirm == 0
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "next-bid-price",
                                class: _vm.isEditPrice ? "" : "curr",
                                style: _vm.isEditPrice ? "cursor: pointer" : "",
                                on: { click: _vm.closeEditPriceAction },
                              },
                              [
                                _c("div", { staticClass: "bid-price-title" }, [
                                  _vm._v("网络出价"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "number" }, [
                                  _vm._v("号牌"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.recordList[0].user &&
                                          _vm.recordList[0].user.number
                                          ? _vm.recordList[0].user.number.number
                                          : _vm.recordList[0].user.nickname
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "price" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.fee_type == "CNY"
                                          ? "RMB"
                                          : _vm.fee_type
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.recordList[0].bid_price,
                                        2
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "btns flex" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn l",
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmBidPriceAction(
                                            _vm.recordList[0].bid_id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("有效(SHIFT)")]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        : [_vm._m(1)],
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "notive common-c" }, [
                  _c("div", { staticClass: "common-c-title" }, [
                    _vm._v("网络买家通知"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "n-content flex flex-wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "item cur",
                        on: {
                          click: function ($event) {
                            return _vm.sendLiveNoticeAction("请尽快出价")
                          },
                        },
                      },
                      [_vm._v("请尽快出价(A)")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-n pl10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "item cur",
                          on: {
                            click: function ($event) {
                              return _vm.sendLiveNoticeAction(
                                "即将落槌，请尽快出价"
                              )
                            },
                          },
                        },
                        [_vm._v("即将落槌，请尽快出价(S)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item cur",
                        on: {
                          click: function ($event) {
                            return _vm.sendLiveNoticeAction("最后一次叫价")
                          },
                        },
                      },
                      [_vm._v("最后一次叫价(D)")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-n pl10" }, [
                      _c(
                        "div",
                        {
                          staticClass: "item",
                          staticStyle: { "border-color": "#ddd" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.txt,
                                expression: "txt",
                              },
                            ],
                            staticClass: "inp",
                            attrs: { placeholder: "自定义通知内容" },
                            domProps: { value: _vm.txt },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.stopPropagation()
                                return _vm.setNotiveAction($event)
                              },
                              blur: _vm.blurAction,
                              focus: _vm.focusAction,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.txt = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "enter" }, [
                            _vm._v("回车发送"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.proxyBidList && _vm.proxyBidList.length > 0
                  ? _c("div", { staticClass: "common-c mt20" }, [
                      _c("div", { staticClass: "common-c-title" }, [
                        _vm._v("委托任务明细"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "bid-proxy-list" },
                        _vm._l(_vm.proxyBidList, function (m, i) {
                          return _c(
                            "div",
                            {
                              key: "proxyBidList" + i,
                              staticClass: "flex-one",
                              class: { pl10: i % 2 != 0 },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item flex align-items-c" },
                                [
                                  _c("div", { staticClass: "number" }, [
                                    _vm._v(
                                      "号牌" +
                                        _vm._s(
                                          m.number ? m.number.number : "网络"
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "price" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          Number(m.proxy_price),
                                          2
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "buy-number common-c" }, [
                  _c("div", { staticClass: "common-c-title" }, [
                    _vm._v("用特殊号牌出价"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "list flex flex-wrap" },
                    _vm._l(_vm.showNumberList, function (m, i) {
                      return _c(
                        "div",
                        {
                          key: "showNumberList" + i,
                          staticClass: "item",
                          class: { curr: _vm.changeUser.uid == m.uid },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.changeUserAction(m)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "l line1" }, [
                            _vm._v(_vm._s(m.user.nickname)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(m.number)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.actionProp.show,
                  "close-on-click-modal": false,
                  "show-close": false,
                  "close-on-press-escape": false,
                  width: "30%",
                },
                on: {
                  "update:visible": function ($event) {
                    return _vm.$set(_vm.actionProp, "show", $event)
                  },
                },
              },
              [
                _vm.actionProp.show
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "item-flex-center" }, [
                          _c(
                            "span",
                            {
                              staticClass: "fWeight500",
                              staticStyle: {
                                "font-size": "24px",
                                "line-height": "34px",
                                "text-align": "center",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.actionProp.title == "撤拍"
                                    ? "确认撤拍"
                                    : _vm.actionProp.title
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.actionProp.title == "撤拍"
                          ? _c(
                              "div",
                              { staticClass: "item-flex-center mt10" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fWeight500",
                                    staticStyle: {
                                      "font-size": "16px",
                                      "font-weight": "400",
                                      "line-height": "30px",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "撤拍后该拍品将继续展示在专场中，但不能参与出价。拍品状态显示撤拍。"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.action == "jumpAuctionAction"
                          ? [
                              _c(
                                "div",
                                { staticClass: "item-flex-center mt20" },
                                [
                                  _vm.actionProp.step == 1
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "cz-l-inp" },
                                          [
                                            _c("el-input", {
                                              ref: "inputEL",
                                              attrs: {
                                                type: "text",
                                                placeholder: "输入LOT号",
                                              },
                                              model: {
                                                value: _vm.actionProp.number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.actionProp,
                                                    "number",
                                                    $$v
                                                  )
                                                },
                                                expression: "actionProp.number",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cz-r-btn btn-confirm",
                                            on: {
                                              click: _vm.searchProductAction,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.actionProp.step == 1
                                                  ? "搜索"
                                                  : "确认"
                                              ) + "(回车)"
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm.actionProp.step == 2 &&
                                      _vm.actionProp.dataList &&
                                      _vm.actionProp.dataList.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "max-height": "300px",
                                              "overflow-y": "auto",
                                            },
                                          },
                                          _vm._l(
                                            _vm.actionProp.dataList,
                                            function (m, i) {
                                              return _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass:
                                                    "item-flex-center mt10",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "cz-l-inp bornone",
                                                      staticStyle: {
                                                        width: "300px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement:
                                                              "top-start",
                                                            title: m.albumName,
                                                            width: "300",
                                                            trigger: "hover",
                                                            content:
                                                              "LOT" +
                                                              m.lot +
                                                              " " +
                                                              m.name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex align-items-c cur",
                                                              attrs: {
                                                                slot: "reference",
                                                              },
                                                              slot: "reference",
                                                            },
                                                            [
                                                              m.img
                                                                ? _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        m.img +
                                                                        "!120a",
                                                                      alt: "",
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "LOT" +
                                                                    _vm._s(
                                                                      m.lot
                                                                    )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex-one line1 pl10 pr10",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      m.name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            i ===
                                                            _vm.actionProp
                                                              .dataIndex,
                                                          expression:
                                                            "i === actionProp.dataIndex",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "cz-r-btn btn-confirm ml10",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.searchProductAction(
                                                            0
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "确认(回车)\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            i !==
                                                            _vm.actionProp
                                                              .dataIndex,
                                                          expression:
                                                            "i !== actionProp.dataIndex",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "cz-r-btn btn-confirm ml10",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.searchProductAction(
                                                            i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "选择\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    : _vm.actionProp.step == 2
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "cz-l-inp align-items-c bornone",
                                          },
                                          [
                                            _vm.actionProp.showData.img
                                              ? _c("img", {
                                                  attrs: {
                                                    src:
                                                      _vm.actionProp.showData
                                                        .img + "!120a",
                                                    alt: "",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.actionProp.showData.lot
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cz-r-btn btn-confirm",
                                            on: {
                                              click: _vm.searchProductAction,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.actionProp.step == 1
                                                  ? "搜索"
                                                  : "确认"
                                              ) + "(回车)\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          : _vm.action == "auctionEndAction" &&
                            _vm.finish_confirm_number &&
                            _vm.actionData.type == 1
                          ? [
                              _c(
                                "div",
                                { staticClass: "item-flex-center mt20" },
                                [
                                  _vm.actionProp.step == 1
                                    ? _c(
                                        "div",
                                        { staticClass: "cz-l-inp" },
                                        [
                                          _c("el-input", {
                                            ref: "inputEL",
                                            attrs: {
                                              type: "text",
                                              placeholder: "输入号牌",
                                            },
                                            model: {
                                              value: _vm.actionProp.number,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.actionProp,
                                                  "number",
                                                  $$v
                                                )
                                              },
                                              expression: "actionProp.number",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.actionProp.step == 2
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cz-l-inp align-items-c bornone",
                                        },
                                        [
                                          _vm.actionProp.showData.img
                                            ? _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.actionProp.showData
                                                      .img + "!120a",
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.actionProp.showData.lot
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cz-r-btn btn-confirm",
                                      on: { click: _vm.searchUserAction },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.actionProp.step == 1
                                            ? "搜索"
                                            : "落槌"
                                        ) + "(回车)"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "div",
                                { staticClass: "item-flex-center mt20" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cz-btn btn-confirm",
                                      on: { click: _vm.czOkAction },
                                    },
                                    [_vm._v(_vm._s(_vm.actionProp.txt))]
                                  ),
                                ]
                              ),
                            ],
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item-flex-center mt10",
                            staticStyle: { "padding-bottom": "30px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cz-btn btn-cancel",
                                on: { click: _vm.closeCZ },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.actionProp.step === 2
                                      ? "返回上一步"
                                      : "取消操作"
                                  ) + "（ESC）"
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.end
              ? _c("div", { staticClass: "is-end item-flex-center" }, [
                  _c("div", { staticClass: "end-view" }, [
                    _c("div", { staticClass: "top-name" }, [
                      _vm._v(_vm._s(_vm.albumData.album_name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "top1" }, [
                      _vm._v(
                        _vm._s(
                          _vm.parseTime(
                            _vm.albumEndData.end_time,
                            "{y}年{m}月{d}日 {h}:{i}"
                          )
                        ) + " 结拍"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "center1" }, [
                      _vm._v(
                        "本场" +
                          _vm._s(_vm.albumEndData.total_num) +
                          "件/成交" +
                          _vm._s(_vm.albumEndData.deal_num) +
                          "件/成交率" +
                          _vm._s(_vm.albumEndData.deal_rate) +
                          "%"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "center2" }, [
                      _vm._v(
                        "本场成交额" +
                          _vm._s(
                            _vm.formatPriceNumber(
                              Number(_vm.albumEndData.deal_price),
                              2
                            )
                          ) +
                          "元"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom-copyright" }, [
                      _vm._v("艺天下提供技术支持"),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bid-price-view" }, [
      _c("div", { staticClass: "bid-price-item fh" }, [_vm._v("十亿")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item" }, [_vm._v("亿")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item" }, [_vm._v("千万")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item fh" }, [_vm._v("百万")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item" }, [_vm._v("十万")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item" }, [_vm._v("万")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item fh" }, [_vm._v("千")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item" }, [_vm._v("百")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item" }, [_vm._v("十")]),
      _vm._v(" "),
      _c("div", { staticClass: "bid-price-item" }, [_vm._v("个")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "next-bid-price flex" }, [
      _c("div", { staticClass: "bid-price-title" }, [_vm._v("网络出价")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-one item-flex-center" }, [_vm._v("暂无")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }