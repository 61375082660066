"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      userNumberPlate: {},
      user: {},
      use_wallet_price: 0,
      use_entrust_wallet_price: 0,
      total_pay_price: 0,
      admin_discount_all_price: [],
      pay_evidence: '',
      pay_type: '',
      adminInfo: _jsCookie.default.set('MerName')
    };
  },
  computed: {
    pay_money: function pay_money() {
      return this.collectMoney.data.total_pay_price - Number(this.use_wallet_price || 0) - Number(this.use_entrust_wallet_price || 0) > 0 ? ((0, _index.formatPrice)(this.collectMoney.data.total_pay_price) - (0, _index.formatPrice)(this.use_wallet_price || 0) - (0, _index.formatPrice)(this.use_entrust_wallet_price || 0)) / 100 : 0;
    }
  },
  methods: {
    changeImg: function changeImg() {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.pay_evidence = img[0];
      }, 1, '1');
    },
    editDiscountPrice: function editDiscountPrice(i) {
      if (isNaN(this.admin_discount_all_price[i])) {
        this.admin_discount_all_price[i] = 0;
      } else if (this.admin_discount_all_price[i] > this.collectMoney.data.orderList[i].product_price) {
        this.admin_discount_all_price[i] = Number(this.collectMoney.data.orderList[i].product_price);
      }
      this.admin_discount_all_price[i] = Number(this.admin_discount_all_price[i]);
      this.all_admin_discount_price = 0 - this.admin_discount_all_price.reduce(function (total, current) {
        return Number(total) + Number(current);
      }, 0);
      this.collectMoney.data.total_pay_price = this.total_pay_price + this.all_admin_discount_price;
      if (this.collectMoney.data.userWalletInfo.balance > this.collectMoney.data.total_pay_price) {
        this.use_wallet_price = this.collectMoney.data.total_pay_price;
      } else {
        this.use_wallet_price = this.collectMoney.data.userWalletInfo.balance;
      }
    },
    getSummaries: function getSummaries(param) {
      var _this2 = this;
      var h = this.$createElement;
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (index === 6) {
          sums[index] = h("div", {
            "style": 'line-height: 40px'
          }, [(0, _index.formatPriceNumber)(0 - _this2.admin_discount_all_price.reduce(function (total, current) {
            return Number(total) + Number(current);
          }, 0), 2)]);
          return false;
        }
        if (index < 3) {
          return;
        }
        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        if (!values.every(function (value) {
          return isNaN(value);
        })) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index === 7) {
            sums[index] = h("div", {
              "style": 'line-height: 40px'
            }, [(0, _index.formatPriceNumber)(sums[index] - _this2.admin_discount_all_price.reduce(function (total, current) {
              return Number(total) + Number(current);
            }, 0), 2)]);
          } else {
            sums[index] = h("div", {
              "style": 'line-height: 40px'
            }, [(0, _index.formatPriceNumber)(sums[index], 2)]);
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    loadData: function loadData(ids, uid, user, userNumberPlate) {
      var _this3 = this;
      this.ids = ids;
      this.user = user;
      this.userNumberPlate = userNumberPlate;
      (0, _order.getAuctionOrderSettlementInfo)({
        order_ids: ids,
        uid: uid
      }).then(function (res) {
        var product_price = 0;
        var commission_price = 0;
        var total_postage = 0;
        var pay_price = 0;
        res.data.orderList.forEach(function (m) {
          product_price += m.product_price;
          commission_price += m.commission_price;
          total_postage += m.total_postage;
          pay_price += m.pay_price;
          // if (m.admin_discount_price) { m.admin_discount_price = 0 - m.admin_discount_price; }
        });
        _this3.admin_discount_all_price = res.data.orderList.map(function (m) {
          return Number(m.admin_discount_price);
        });
        res.data.userWalletInfo.balance += res.data.availableFrozen && res.data.availableFrozen.price ? res.data.availableFrozen.price : 0;
        _this3.total_pay_price = res.data.total_pay_price;
        if (res.data.userWalletInfo.balance > res.data.total_pay_price) {
          _this3.use_wallet_price = res.data.total_pay_price;
        } else {
          _this3.use_wallet_price = res.data.userWalletInfo.balance;
        }
        _this3.pay_evidence = '';
        _this3.all_admin_discount_price = 0 - _this3.admin_discount_all_price.reduce(function (total, current) {
          return Number(total) + Number(current);
        }, 0);
        res.data.orderList.push({
          isAllToall: 1,
          auction_info: {},
          product_price: product_price,
          commission_price: commission_price,
          total_postage: total_postage,
          pay_price: pay_price
        });
        // this.use_wallet_price = res.data.
        _this3.collectMoney = {
          data: res.data,
          show: true
        };
      }).catch(function () {});
    },
    payAction: function payAction() {
      var _this4 = this;
      this.$confirm('确认结算该订单', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = [];
        _this4.collectMoney.data.orderList.forEach(function (m, i) {
          if (!m.isAllToall) {
            data.push({
              order_id: m.order_id,
              admin_discount_price: _this4.admin_discount_all_price[i] || 0
            });
          }
        });
        (0, _order.auctionOrderSettlement)({
          order_ids: _this4.ids,
          uid: _this4.user.uid,
          pay_evidence: _this4.pay_evidence,
          pay_type: _this4.pay_type,
          use_wallet_price: _this4.use_wallet_price,
          use_entrust_wallet_price: _this4.use_entrust_wallet_price,
          orderData: data
        }).then(function (res) {
          _this4.$message.success('操作成功');
          _this4.collectMoney.show = false;
          _this4.$emit('payOk');
        }).catch(function () {});
      }).catch(function (err) {
        console.error(err);
      });
    },
    focus: function focus(e) {
      e.currentTarget.select();
    }
  }
};