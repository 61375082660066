var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
      },
    },
    _vm._l(_vm.viewData, function (m, i) {
      return _c(
        "section",
        {
          key: i,
          style: {
            backgroundColor: _vm.facade.pageBackground,
            padding:
              (i == 0 ? 0 : _vm.facade.pagePaddingTop) +
              "px " +
              _vm.facade.pagePaddingRight +
              "px " +
              (i + 1 == _vm.viewData.length
                ? 0
                : _vm.facade.pagePaddingBottom) +
              "px " +
              _vm.facade.pagePaddingLeft +
              "px",
            borderWidth:
              (i == 0 ? 0 : _vm.facade.borderTop) +
              "px " +
              _vm.facade.borderRight +
              "px " +
              (i + 1 == _vm.viewData.length ? 0 : _vm.facade.borderBottom) +
              "px " +
              _vm.facade.borderLeft +
              "px",
            borderRadius:
              _vm.facade.pageRadiusTopLeft +
              "px " +
              _vm.facade.pageRadiusTopRight +
              "px " +
              _vm.facade.pageRadiusBottomRight +
              "px " +
              _vm.facade.pageRadiusBottomLeft +
              "px",
            borderStyle: "solid",
            borderColor: _vm.facade.borderColor,
            color: _vm.facade.pageColor,
            fontSize: _vm.facade.fontSize + "px",
            fontWeight: "" + _vm.facade.fontWeight,
            display: "flex",
            flexDirection: m.image_show_style == 2 ? "row" : "column",
          },
        },
        [
          !_vm.facade.noShowImg
            ? _c(
                "section",
                {
                  style: {
                    overflow: "hidden",
                    borderRadius:
                      _vm.facade.imgRadiusTopLeft +
                      "px " +
                      _vm.facade.imgRadiusTopRight +
                      "px " +
                      _vm.facade.imgRadiusBottomRight +
                      "px " +
                      _vm.facade.imgRadiusBottomLeft +
                      "px",
                    flex: m.image_show_style == 2 ? 1 : "unset",
                  },
                },
                [
                  m.id && _vm.miniAppid
                    ? _c(
                        "a",
                        {
                          staticClass: "weapp_image_link",
                          attrs: {
                            "data-miniprogram-appid": _vm.miniAppid,
                            "data-miniprogram-path":
                              "pages/albumDetail/index?id=" + m.id,
                            "data-miniprogram-nickname": _vm.miniName,
                            href: "",
                            "data-miniprogram-type": "image",
                            "data-miniprogram-servicetype": "2",
                            "wah-hotarea": "click",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "rich_pages wxw-img",
                            staticStyle: { display: "block", width: "100%" },
                            attrs: {
                              "data-ratio": "1",
                              "data-s": "300,640",
                              "data-fail": "0",
                              "data-src": m.image,
                              src: m.image,
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _c("img", {
                        staticStyle: { display: "block", width: "100%" },
                        attrs: { src: m.image, alt: "" },
                      }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "section",
            {
              style: {
                display: m.image_show_style == 2 ? "flex" : "block",
                flexDirection: m.image_show_style == 2 ? "column" : "unset",
                justifyContent:
                  m.image_show_style == 2 ? _vm.facade.textItemArea : "unset",
                textAlign:
                  m.image_show_style == 2 || _vm.facade.noShowImg
                    ? _vm.facade.textAlign
                    : "left",
                padding:
                  _vm.facade.textPaddingTop +
                  "px " +
                  _vm.facade.textPaddingRight +
                  "px " +
                  _vm.facade.textPaddingBottom +
                  "px " +
                  _vm.facade.textPaddingLeft +
                  "px",
                backgroundColor: _vm.facade.textBackground,
                width:
                  m.image_show_style == 2 ? _vm.facade.textWidth + "%" : "100%",
                boxSzing: "border-box",
                lineHeight: "20px",
              },
            },
            [
              _c(
                "section",
                {
                  style:
                    "color: " +
                    _vm.facade.titleColor +
                    ";font-size: " +
                    _vm.facade.titleFontSize +
                    "px;font-weight: " +
                    _vm.facade.titleFontWeight +
                    ";margin-top: " +
                    (m.image_show_style == 2 &&
                    _vm.facade.textItemArea == "center"
                      ? 0
                      : _vm.facade.titleMarginTop) +
                    "px;",
                },
                [_vm._v(_vm._s(m.name))]
              ),
              _vm._v(" "),
              m.scheduled_show_time_format
                ? _c(
                    "section",
                    {
                      style:
                        "display: " +
                        (m.image_show_style == 2 ||
                        _vm.facade.preTimeTextStyle == 1
                          ? "block"
                          : "flex") +
                        ";margin-top:" +
                        (m.image_show_style == 2 ||
                        _vm.facade.preTimeTextStyle == 1
                          ? _vm.facade.preTimeTextMarginTop
                          : _vm.facade.preTimeMarginTop) +
                        "px;",
                    },
                    [
                      _vm.facade.preTimeText
                        ? _c(
                            "section",
                            {
                              style:
                                "color: " +
                                _vm.facade.preTimeTextColor +
                                ";font-size: " +
                                _vm.facade.preTimeTextFontSize +
                                "px;font-weight: " +
                                _vm.facade.preTimeTextFontWeight +
                                ";",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.facade.preTimeText) +
                                  _vm._s(
                                    m.image_show_style == 2 ||
                                      _vm.facade.timeTextStyle == 1
                                      ? ""
                                      : "："
                                  )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "section",
                        {
                          style:
                            "color: " +
                            _vm.facade.preTimeColor +
                            ";font-size: " +
                            _vm.facade.preTimeFontSize +
                            "px;font-weight: " +
                            _vm.facade.preTimeFontWeight +
                            ";margin-top:" +
                            (m.image_show_style == 2 ||
                            _vm.facade.preTimeTextStyle == 1
                              ? _vm.facade.preTimeMarginTop
                              : 0) +
                            "px;",
                        },
                        [_vm._v(_vm._s(m.scheduled_show_time_format))]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              m.stime_format
                ? _c(
                    "section",
                    {
                      style:
                        "display: " +
                        (m.image_show_style == 2 ||
                        _vm.facade.timeTextStyle == 1
                          ? "block"
                          : "flex") +
                        ";margin-top:" +
                        (m.image_show_style == 2 ||
                        _vm.facade.timeTextStyle == 1
                          ? _vm.facade.timeTextMarginTop
                          : _vm.facade.timeMarginTop) +
                        "px;",
                    },
                    [
                      _vm.facade.timeText
                        ? _c(
                            "section",
                            {
                              style:
                                "color: " +
                                _vm.facade.timeTextColor +
                                ";font-size: " +
                                _vm.facade.timeTextFontSize +
                                "px;font-weight: " +
                                _vm.facade.timeTextFontWeight +
                                ";",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.facade.timeText) +
                                  _vm._s(
                                    m.image_show_style == 2 ||
                                      _vm.facade.timeTextStyle == 1
                                      ? ""
                                      : "："
                                  )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "section",
                        {
                          style:
                            "color: " +
                            _vm.facade.timeColor +
                            ";font-size: " +
                            _vm.facade.timeFontSize +
                            "px;font-weight: " +
                            _vm.facade.timeFontWeight +
                            ";margin-top:" +
                            (m.image_show_style == 2 ||
                            _vm.facade.timeTextStyle == 1
                              ? _vm.facade.timeMarginTop
                              : 0) +
                            "px;",
                        },
                        [_vm._v(_vm._s(m.stime_format))]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }