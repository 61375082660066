var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tag-view flex align-items-c" }, [
      _c(
        "div",
        {
          staticClass: "item flex align-items-c",
          style: {
            background: _vm.itemBackgound,
            "border-radius":
              _vm.facade.topLeftRadius / 2 +
              "px " +
              _vm.facade.topRightRadius / 2 +
              "px " +
              _vm.facade.bottomRightRadius / 2 +
              "px " +
              _vm.facade.bottomLeftRadius / 2 +
              "px",
            color: _vm.facade.textColor,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "status",
              style: {
                background: _vm.statusBackgound(1),
                "border-radius":
                  _vm.facade.statusTopLeftRadius / 2 +
                  "px " +
                  _vm.facade.statusTopRightRadius / 2 +
                  "px " +
                  _vm.facade.statusBottomRightRadius / 2 +
                  "px " +
                  _vm.facade.statusBottomLeftRadius / 2 +
                  "px",
                color: _vm.facade.preColor,
              },
            },
            [_vm._v("预展中")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [_vm._v("距开拍 20分20秒")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tag-view flex align-items-c mt10" }, [
      _c(
        "div",
        {
          staticClass: "item flex align-items-c",
          style: {
            background: _vm.itemBackgound,
            "border-radius":
              _vm.facade.topLeftRadius / 2 +
              "px " +
              _vm.facade.topRightRadius / 2 +
              "px " +
              _vm.facade.bottomRightRadius / 2 +
              "px " +
              _vm.facade.bottomLeftRadius / 2 +
              "px",
            color: _vm.facade.textColor,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "status",
              style: {
                background: _vm.statusBackgound(2),
                "border-radius":
                  _vm.facade.statusTopLeftRadius / 2 +
                  "px " +
                  _vm.facade.statusTopRightRadius / 2 +
                  "px " +
                  _vm.facade.statusBottomRightRadius / 2 +
                  "px " +
                  _vm.facade.statusBottomLeftRadius / 2 +
                  "px",
                color: _vm.facade.ingColor,
              },
            },
            [_vm._v("竞拍中")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [
            _vm._v("当前第 "),
            _c("span", { style: { color: _vm.facade.specialColor } }, [
              _vm._v("20"),
            ]),
            _vm._v("/156件"),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tag-view flex align-items-c mt10" }, [
      _c(
        "div",
        {
          staticClass: "item flex align-items-c",
          style: {
            background: _vm.itemBackgound,
            "border-radius":
              _vm.facade.topLeftRadius / 2 +
              "px " +
              _vm.facade.topRightRadius / 2 +
              "px " +
              _vm.facade.bottomRightRadius / 2 +
              "px " +
              _vm.facade.bottomLeftRadius / 2 +
              "px",
            color: _vm.facade.textColor,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "status",
              style: {
                background: _vm.statusBackgound(3),
                "border-radius":
                  _vm.facade.statusTopLeftRadius / 2 +
                  "px " +
                  _vm.facade.statusTopRightRadius / 2 +
                  "px " +
                  _vm.facade.statusBottomRightRadius / 2 +
                  "px " +
                  _vm.facade.statusBottomLeftRadius / 2 +
                  "px",
                color: _vm.facade.endColor,
              },
            },
            [_vm._v("已结拍")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [_vm._v("2024-05-15 14:24:14")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }