var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index === 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item.image, fit: "cover" },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    style:
                      "padding: " +
                      _vm.facade.descPaddingTop / 2 +
                      "px " +
                      _vm.facade.descPaddingRight / 2 +
                      "px " +
                      _vm.facade.descPaddingBottom / 2 +
                      "px " +
                      _vm.facade.descPaddingLeft / 2 +
                      "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text color-black",
                        style:
                          "color: " +
                          _vm.facade.name_color +
                          ";font-size:" +
                          (_vm.facade.name_fontsize || 28) / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    item.tags && item.tags.length
                      ? _c(
                          "div",
                          { staticClass: "tags-box" },
                          _vm._l(item.tags, function (m, i) {
                            return _c("div", { key: i, staticClass: "tag" }, [
                              _vm._v(_vm._s(m)),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bottom-box",
                        style:
                          "margin-top:" +
                          (_vm.facade.time_marginTop || 10) / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sign",
                            style:
                              "color: " +
                              _vm.facade.time_color +
                              ";font-size:" +
                              (_vm.facade.time_fontsize || 24) / 2 +
                              "px;",
                          },
                          [_vm._v(_vm._s(item.sign))]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2 && _vm.content.img_style != 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "two-list",
                class: { mt0: index < 2, mr0: index % 2 !== 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  marginRight: _vm.facade.item_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                  width:
                    (750 -
                      _vm.facade.page_padding -
                      _vm.facade.page_padding -
                      _vm.facade.item_padding) /
                      4 +
                    "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    style: {
                      width:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding) /
                          4 +
                        "px",
                      height:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding) /
                          4 +
                        "px",
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    style:
                      "padding: " +
                      _vm.facade.descPaddingTop / 2 +
                      "px " +
                      _vm.facade.descPaddingRight / 2 +
                      "px " +
                      _vm.facade.descPaddingBottom / 2 +
                      "px " +
                      _vm.facade.descPaddingLeft / 2 +
                      "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text color-black",
                        style:
                          "color: " +
                          _vm.facade.name_color +
                          ";font-size:" +
                          (_vm.facade.name_fontsize || 28) / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    item.tags && item.tags.length
                      ? _c(
                          "div",
                          { staticClass: "tags-box" },
                          _vm._l(item.tags, function (m, i) {
                            return _c("div", { key: i, staticClass: "tag" }, [
                              _vm._v(_vm._s(m)),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bottom-box",
                        style:
                          "margin-top:" +
                          (_vm.facade.time_marginTop || 10) / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sign",
                            style:
                              "color: " +
                              _vm.facade.time_color +
                              ";font-size:" +
                              (_vm.facade.time_fontsize || 24) / 2 +
                              "px;",
                          },
                          [_vm._v(_vm._s(item.sign))]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 3
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: {
              padding: "0 " + _vm.facade.page_padding / 2 + "px",
              justifyContent: "flex-start",
            },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "three-list",
                class: { mt0: index < 3, mr0: index % 3 === 2 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  marginRight: _vm.facade.item_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                  width:
                    (750 -
                      _vm.facade.page_padding -
                      _vm.facade.page_padding -
                      _vm.facade.item_padding -
                      _vm.facade.item_padding) /
                      6 +
                    "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    style: {
                      width:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding -
                          _vm.facade.item_padding) /
                          6 +
                        "px",
                      height:
                        (750 -
                          _vm.facade.page_padding -
                          _vm.facade.page_padding -
                          _vm.facade.item_padding -
                          _vm.facade.item_padding) /
                          6 +
                        "px",
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    style:
                      "padding: " +
                      _vm.facade.descPaddingTop / 2 +
                      "px " +
                      _vm.facade.descPaddingRight / 2 +
                      "px " +
                      _vm.facade.descPaddingBottom / 2 +
                      "px " +
                      _vm.facade.descPaddingLeft / 2 +
                      "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text color-black",
                        style:
                          "color: " +
                          _vm.facade.name_color +
                          ";font-size:" +
                          (_vm.facade.name_fontsize || 28) / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bottom-box",
                        style:
                          "margin-top:" +
                          (_vm.facade.time_marginTop || 10) / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sign",
                            style:
                              "color: " +
                              _vm.facade.time_color +
                              ";font-size:" +
                              (_vm.facade.time_fontsize || 24) / 2 +
                              "px;",
                          },
                          [_vm._v(_vm._s(item.sign))]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 4
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "list-horizontal",
                class: { mt0: index < 1 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                },
              },
              [
                !_vm.facade.no_show_img
                  ? _c(
                      "div",
                      {
                        staticClass: "list-h-left",
                        style: {
                          width: _vm.facade.img_width / 2 + "px",
                          height: _vm.facade.img_height / 2 + "px",
                        },
                      },
                      [
                        _c(
                          "el-image",
                          {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: item.image,
                              fit:
                                _vm.content.img_style === 2
                                  ? "contain"
                                  : "cover",
                            },
                            on: { error: _vm.error },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "list-h-right",
                    style:
                      "padding-bottom: " +
                      (_vm.facade.no_show_img ? "20px;" : "") +
                      ";margin: " +
                      _vm.facade.descPaddingTop / 2 +
                      "px " +
                      _vm.facade.descPaddingRight / 2 +
                      "px " +
                      _vm.facade.descPaddingBottom / 2 +
                      "px " +
                      _vm.facade.descPaddingLeft / 2 +
                      "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text color-black",
                        style:
                          "color: " +
                          _vm.facade.name_color +
                          ";font-size:" +
                          (_vm.facade.name_fontsize || 28) / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm._v(" "),
                    item.tags && item.tags.length
                      ? _c(
                          "div",
                          { staticClass: "tags-box" },
                          _vm._l(item.tags, function (m, i) {
                            return _c("div", { key: i, staticClass: "tag" }, [
                              _vm._v(_vm._s(m)),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bottom-box",
                        style:
                          "margin-top:" +
                          (_vm.facade.time_marginTop || 10) / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sign",
                            style:
                              "color: " +
                              _vm.facade.time_color +
                              ";font-size:" +
                              (_vm.facade.time_fontsize || 24) / 2 +
                              "px;",
                          },
                          [_vm._v(_vm._s(item.sign))]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 5
      ? _c("div", { staticClass: "swiper-list-box" }, [
          _c(
            "div",
            {
              staticClass: "swiper-content",
              style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
            },
            _vm._l(_vm.list, function (item, index) {
              return index < 2
                ? _c(
                    "div",
                    {
                      key: index,
                      staticClass: "swiper-list",
                      style: {
                        marginRight: _vm.facade.item_padding / 2 + "px",
                        borderTopRightRadius: _vm.facade.img_radius / 2 + "px",
                        borderTopLeftRadius: _vm.facade.img_radius / 2 + "px",
                        borderBottomRightRadius:
                          _vm.facade.status_radius / 2 + "px",
                        borderBottomLeftRadius:
                          _vm.facade.status_radius / 2 + "px",
                        background: _vm.facade.status_background,
                        width: _vm.facade.img_width / 2 + "px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "swiper-list-img",
                          style: {
                            width: _vm.facade.img_width / 2 + "px",
                            height: _vm.facade.img_height / 2 + "px",
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: item.image,
                                fit:
                                  _vm.content.img_style === 2
                                    ? "contain"
                                    : "cover",
                              },
                              on: { error: _vm.error },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          style:
                            "padding: " +
                            _vm.facade.descPaddingTop / 2 +
                            "px " +
                            _vm.facade.descPaddingRight / 2 +
                            "px " +
                            _vm.facade.descPaddingBottom / 2 +
                            "px " +
                            _vm.facade.descPaddingLeft / 2 +
                            "px;",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text",
                              style:
                                "color: " +
                                _vm.facade.name_color +
                                ";width: " +
                                (_vm.facade.img_width / 2 - 20) +
                                "px;font-size:" +
                                (_vm.facade.name_fontsize || 28) / 2 +
                                "px;",
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm._v(" "),
                          item.tags && item.tags.length
                            ? _c(
                                "div",
                                { staticClass: "tags-box" },
                                _vm._l(item.tags, function (m, i) {
                                  return _c(
                                    "div",
                                    { key: i, staticClass: "tag" },
                                    [_vm._v(_vm._s(m))]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bottom-box",
                              style:
                                "margin-top:" +
                                (_vm.facade.time_marginTop || 10) / 2 +
                                "px;",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "sign line1",
                                  style:
                                    "color: " +
                                    _vm.facade.time_color +
                                    ";font-size:" +
                                    (_vm.facade.time_fontsize || 24) / 2 +
                                    "px;",
                                },
                                [_vm._v(_vm._s(item.sign))]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2 && _vm.content.img_style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          [
            _c(
              "div",
              {
                style: {
                  width:
                    (750 -
                      _vm.facade.page_padding -
                      _vm.facade.page_padding -
                      _vm.facade.item_padding) /
                      4 +
                    "px",
                },
              },
              _vm._l(_vm.list, function (item, index) {
                return _c("div", { key: "l" + index }, [
                  index % 2 == 0
                    ? _c(
                        "div",
                        {
                          style: {
                            marginTop:
                              _vm.facade.item_bottom_padding / 2 + "px",
                            borderTopRightRadius:
                              _vm.facade.img_radius / 2 + "px",
                            borderTopLeftRadius:
                              _vm.facade.img_radius / 2 + "px",
                            borderBottomRightRadius:
                              _vm.facade.status_radius / 2 + "px",
                            borderBottomLeftRadius:
                              _vm.facade.status_radius / 2 + "px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "two-list",
                              style: {
                                width:
                                  (750 -
                                    _vm.facade.page_padding -
                                    _vm.facade.page_padding -
                                    _vm.facade.item_padding) /
                                    4 +
                                  "px",
                                background: _vm.facade.status_background,
                              },
                            },
                            [
                              _c("div", {}, [
                                _c("img", {
                                  attrs: { src: item.image, alt: "" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  style:
                                    "padding: " +
                                    _vm.facade.descPaddingTop / 2 +
                                    "px " +
                                    _vm.facade.descPaddingRight / 2 +
                                    "px " +
                                    _vm.facade.descPaddingBottom / 2 +
                                    "px " +
                                    _vm.facade.descPaddingLeft / 2 +
                                    "px;",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text",
                                      style:
                                        "color: " +
                                        _vm.facade.name_color +
                                        ";font-size:" +
                                        (_vm.facade.name_fontsize || 28) / 2 +
                                        "px;",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _vm._v(" "),
                                  item.tags && item.tags.length
                                    ? _c(
                                        "div",
                                        { staticClass: "tags-box" },
                                        _vm._l(item.tags, function (m, i) {
                                          return _c(
                                            "div",
                                            { key: i, staticClass: "tag" },
                                            [_vm._v(_vm._s(m))]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "bottom-box",
                                      style:
                                        "margin-top:" +
                                        (_vm.facade.time_marginTop || 10) / 2 +
                                        "px;",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "sign",
                                          style:
                                            "color: " +
                                            _vm.facade.time_color +
                                            ";font-size:" +
                                            (_vm.facade.time_fontsize || 24) /
                                              2 +
                                            "px;",
                                        },
                                        [_vm._v(_vm._s(item.sign))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                style: {
                  width:
                    (750 -
                      _vm.facade.page_padding -
                      _vm.facade.page_padding -
                      _vm.facade.item_padding) /
                      4 +
                    "px",
                },
              },
              _vm._l(_vm.list, function (item, index) {
                return _c("div", { key: "r" + index }, [
                  index % 2 != 0
                    ? _c(
                        "div",
                        {
                          style: {
                            marginTop:
                              _vm.facade.item_bottom_padding / 2 + "px",
                            borderTopRightRadius:
                              _vm.facade.img_radius / 2 + "px",
                            borderTopLeftRadius:
                              _vm.facade.img_radius / 2 + "px",
                            borderBottomRightRadius:
                              _vm.facade.status_radius / 2 + "px",
                            borderBottomLeftRadius:
                              _vm.facade.status_radius / 2 + "px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "two-list",
                              style: {
                                width:
                                  (750 -
                                    _vm.facade.page_padding -
                                    _vm.facade.page_padding -
                                    _vm.facade.item_padding) /
                                    4 +
                                  "px",
                                background: _vm.facade.status_background,
                              },
                            },
                            [
                              _c("div", {}, [
                                _c("img", {
                                  attrs: { src: item.image, alt: "" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  style:
                                    "padding: " +
                                    _vm.facade.descPaddingTop / 2 +
                                    "px " +
                                    _vm.facade.descPaddingRight / 2 +
                                    "px " +
                                    _vm.facade.descPaddingBottom / 2 +
                                    "px " +
                                    _vm.facade.descPaddingLeft / 2 +
                                    "px;",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text",
                                      style:
                                        "color: " +
                                        _vm.facade.name_color +
                                        ";font-size:" +
                                        (_vm.facade.name_fontsize || 28) / 2 +
                                        "px;",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _vm._v(" "),
                                  item.tags && item.tags.length
                                    ? _c(
                                        "div",
                                        { staticClass: "tags-box" },
                                        _vm._l(item.tags, function (m, i) {
                                          return _c(
                                            "div",
                                            { key: i, staticClass: "tag" },
                                            [_vm._v(_vm._s(m))]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "bottom-box",
                                      style:
                                        "margin-top:" +
                                        (_vm.facade.time_marginTop || 10) / 2 +
                                        "px;",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "sign",
                                          style:
                                            "color: " +
                                            _vm.facade.time_color +
                                            ";font-size:" +
                                            (_vm.facade.time_fontsize || 24) /
                                              2 +
                                            "px;",
                                        },
                                        [_vm._v(_vm._s(item.sign))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 6
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style: { padding: "0 " + _vm.facade.page_padding / 2 + "px" },
          },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index === 0 },
                style: {
                  marginTop: _vm.facade.item_bottom_padding / 2 + "px",
                  borderTopRightRadius: _vm.facade.view_radius / 2 + "px",
                  borderTopLeftRadius: _vm.facade.view_radius / 2 + "px",
                  borderBottomRightRadius: _vm.facade.status_radius / 2 + "px",
                  borderBottomLeftRadius: _vm.facade.status_radius / 2 + "px",
                  background: _vm.facade.status_background,
                  padding:
                    "0 " +
                    _vm.facade.item_padding / 2 +
                    "px 8px " +
                    _vm.facade.item_padding / 2 +
                    "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "margin-top": "13px",
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        style: {
                          width: _vm.viewImg,
                          height: _vm.viewImg,
                          borderRadius: _vm.facade.img_radius / 2 + "px",
                        },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-image",
                      {
                        style: {
                          width: _vm.viewImg,
                          height: _vm.viewImg,
                          borderRadius: _vm.facade.img_radius / 2 + "px",
                        },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-image",
                      {
                        style: {
                          width: _vm.viewImg,
                          height: _vm.viewImg,
                          borderRadius: _vm.facade.img_radius / 2 + "px",
                        },
                        attrs: {
                          src: item.image,
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                        on: { error: _vm.error },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "text",
                    style:
                      "color: " +
                      _vm.facade.name_color +
                      ";font-size:" +
                      (_vm.facade.name_fontsize || 28) / 2 +
                      "px;margin-top: " +
                      _vm.facade.descPaddingTop / 2 +
                      "px",
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _vm._v(" "),
                item.tags && item.tags.length
                  ? _c(
                      "div",
                      { staticClass: "tags-box" },
                      _vm._l(item.tags, function (m, i) {
                        return _c("div", { key: i, staticClass: "tag" }, [
                          _vm._v(_vm._s(m)),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "bottom-box",
                    style:
                      "margin-top:" +
                      (_vm.facade.time_marginTop || 10) / 2 +
                      "px;",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "sign",
                        style:
                          "color: " +
                          _vm.facade.time_color +
                          ";font-size:" +
                          (_vm.facade.time_fontsize || 24) / 2 +
                          "px;",
                      },
                      [_vm._v(_vm._s(item.sign))]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dot-box" }, [
      _c("div", { staticClass: "dot" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot active" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }