"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
var _user = require("@/api/user.js");
var _warehouse = require("@/api/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    detailjson: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      merData: {},
      showFiled: [],
      attrItem: ['190', '198', '203', '204', '209', '210', '211', '212', '213', '214', '215', '232', '233', '234', '235', '236', '237', '238', '239', '240', '241']
    };
  },
  filters: {
    englis: function englis(str) {
      var arr = str.split('|');
      if (arr.length > 1) return arr[1];else return ' ';
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this2.merData = res.data;
    });
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_enter_warehouse_print'
    }).then(function (res) {
      _this2.showFiled = res.data.erp_v2_enter_warehouse_print.show_list;
      console.log(_this2.showFiled);
    });
  },
  methods: {
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this3 = this;
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(img);
        if (tit === '1' && !num) {
          _this3.$set(_this.detailjson, 'enterImageList', [].concat((0, _toConsumableArray2.default)(_this.detailjson.enterImageList), (0, _toConsumableArray2.default)(img)));
          (0, _warehouse.confirmpushOrderApiV2)({
            id: _this.detailjson.id,
            enter_image: _this.detailjson.enterImageList.join(',')
          }).then(function (res) {
            // console.log(res);
            _this.$message.success('修改成功');
          });
        }
        _this3.$forceUpdate();
      }, 9);
    }
  }
};