"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _index = require("@/utils/index");
var _auction = require("@/api/auction");
var _system = require("@/api/system");
var _user = require("@/api/user");
var _webSocket = _interopRequireDefault(require("@/utils/webSocket"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cd = 99;
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      decrypt: _index.decrypt,
      recordList: [],
      huiNum: 9,
      bidPriceList: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      bidPriceIndex: 9,
      lastEndAuctionInfo: null,
      oldRange: null,
      txt: '',
      rangeList: [],
      proxyBidList: [],
      sdata: {
        socketServe: _webSocket.default.Instance
      },
      album_id: '',
      v_id: '',
      client_id: '',
      finish_confirm_number: 0,
      auctionData: null,
      actionProp: {
        show: false
      },
      focus: false,
      albumData: null,
      priceData: [{
        min: 0,
        max: '∞',
        price: 100000,
        type: 1
      }],
      price: 0,
      // 要出的价格
      end: false,
      albumEndData: {},
      inpFocus: false,
      inpAutoFocus: false,
      plus_price: '',
      set_plus_price: '',
      confirm_start_price: 1,
      goTime: '',
      cd: '',
      showS: '',
      showNumberList: [],
      changeUser: {},
      countDownTime: [],
      countDownTimeUser: [],
      countDownInt: null,
      fee_type: '',
      logo: '',
      isEditPrice: false
    };
  },
  created: function created() {
    var _this = this;
    this.album_id = this.$route.query.id;
    this.v_id = this.$route.query.v_id || '';
    (0, _system.getConfigClassKeys)('auction_rule_set').then(function (res) {
      // this.ruleData = res.data;
      _this.finish_confirm_number = res.data.finish_confirm_number;
      _this.confirm_start_price = res.data.confirm_start_price;
    }).catch(function () {});
    (0, _user.getBaseInfo)().then(function (res) {
      _this.logo = res.data.mer_logo;
      _this.fee_type = res.data.fee_type;
    }).catch(function (_ref) {
      var message = _ref.message;
    });
    this.listenEvent();
    (0, _auction.bidRangeList)({
      is_system_bidRange: 1
    }).then(function (res) {
      _this.rangeList = res.data;
    });
    if (!_webSocket.default.Instance.ws) {
      _webSocket.default.Instance.connect();
      this.sdata.socketServe = _webSocket.default.Instance;
      this.sdata.socketServe.registerCallBack('getMessage', function (data) {
        _this.sockedMes(data);
      });
      this.sdata.socketServe.registerCallBack('openSocket', function () {
        // sendData({
        //   type: "join_auction_room",
        //   auction_id: id.value,
        // });
      });
    } else if (this.client_id) {
      this.joinRoomAction();
      // sendData({
      //   type: "join_auction_room",
      //   auction_id: id.value,
      // });
    }
  },
  methods: {
    closeEditPriceAction: function closeEditPriceAction() {
      if (this.isEditPrice) this.isEditPrice = false;
      this.initPrice(this.auctionData);
    },
    czOkAction: function czOkAction() {
      this[this.action]();
    },
    closeCZ: function closeCZ() {
      this.action = '';
      this.focus = false;
      this.actionProp = {
        show: false
      };
      this.actionData = {};
    },
    blurAction: function blurAction() {
      this.speck = false;
    },
    focusAction: function focusAction() {
      this.speck = true;
    },
    changeBidPrice: function changeBidPrice(i) {
      if (!this.isEditPrice) this.isEditPrice = true;
      if (i !== this.bidPriceIndex) {
        this.bidPriceIndex = i;
      }
    },
    setNotiveAction: function setNotiveAction() {
      if (this.txt) {
        this.sendLiveNoticeAction(this.txt);
      }
    },
    sendLiveNoticeAction: function sendLiveNoticeAction(txt) {
      var _this2 = this;
      // 发送系统消息
      (0, _auction.sendLiveNoticeApi)({
        live_id: this.albumData.live_id,
        msg: txt
      }).then(function () {
        return _this2.$message.success('发送成功');
      }).catch(function () {});
      this.txt = '';
    },
    bidPrice: function bidPrice() {
      var _this3 = this;
      // 现场出价
      if (this.auctionData) {
        if (this.bidTimeout) clearTimeout(this.bidTimeout);
        this.bidTimeout = setTimeout(function () {
          // this.auctionData.bidNowPrice = this.auction;
          _this3.bidTimeout = null;
          _this3.initPrice(_this3.auctionData);
        }, 500);
        var hasTimeout = !!this.bidTimeout;
        var price = parseInt(this.auctionData.bidNowPrice, 10);
        if (this.isEditPrice) {
          price = parseInt(this.bidPriceList.join(''), 10);
        }
        // const price = parseInt(this.bidPriceList.join(''), 10);
        if (price <= 0) {
          this.$message.error('请输入正确的金额');
          return;
        }
        if (isNaN(price)) {
          this.$message.error('请输入正确的金额');
          return;
        }
        if (this.isEditPrice) this.isEditPrice = false;
        if (this.changeUser.uid) {
          (0, _auction.bidPriceApi)({
            auction_id: this.auctionData.product_id,
            bid_price: price,
            bid_uid: this.changeUser.uid,
            name_hidden: 1
          }).then(function () {
            _this3.changeUser = {};
          }).catch(function () {});
          this.initBidPrice(this.auctionData);
          return false;
        }
        (0, _auction.sceneBidPrice)({
          auction_id: this.auctionData.product_id,
          bid_price: price
        }).then(function () {}).catch(function (_ref2) {
          var message = _ref2.message;
          if (hasTimeout && message === '你似乎慢了别人一步，请重新出价！') {} else {
            _this3.$message.error(message);
          }
        });
        this.initBidPrice(this.auctionData);
      }
    },
    startBid: function startBid() {
      // 确认起拍价
      if (this.album_id) {
        var price = parseInt(this.bidPriceList.join(''), 10);
        if (isNaN(price)) {
          this.$message.error('请输入正确的金额');
          return;
        }
        if (this.isEditPrice) this.isEditPrice = false;
        (0, _auction.startNextAuction)({
          album_id: this.album_id,
          start_price: price,
          auction_id: this.auctionData.product_id
        }).then(function () {}).catch(function () {});
      }
    },
    confirmAction: function confirmAction(action, id) {
      var _this4 = this;
      // 取消出价确认
      if (this.auctionData) {
        this.action = action;
        var data = {};
        var txt = '';
        var title = '';
        switch (action) {
          case 'delBidPriceAction':
            title = '撤销最高出价';
            txt = "\u786E\u8BA4\u64A4\u9500\u51FA\u4EF7".concat((0, _index.formatPriceNumber)(this.recordList[0].bid_price, 2));
            data = {
              bid_id: id,
              auction_id: this.auctionData.product_id
            };
            break;
          case 'auctionEndAction':
            title = id === 1 ? '成交' : '流拍';
            txt = "\u786E\u8BA4".concat(id === 1 ? '落槌' : '流拍', "\uFF08\u56DE\u8F66\uFF09");
            data = {
              type: id,
              auction_id: this.auctionData.product_id
            };
            break;
          case 'remakeAction':
            title = ' ';
            txt = "\u786E\u8BA4\u91CD\u62CD ".concat(this.auctionData.lot ? this.auctionData.lot : '');
            data = {
              auction_id: this.auctionData.product_id
            };
            break;
          case 'revokeAuctionAction':
            title = '撤拍';
            txt = "\u786E\u8BA4\u64A4\u62CD ".concat(this.auctionData.lot ? this.auctionData.lot : '');
            data = {
              auction_id: this.auctionData.product_id
            };
            break;
          case 'auctionEndTimeAction':
            title = '结束';
            txt = "\u786E\u8BA4".concat(this.albumData.album_config.click_hammer_num, "\u79D2\u540E\u7ED3\u675F\u6B64\u62CD\u54C1");
            data = {
              auction_id: this.auctionData.product_id
            };
            break;
        }
        this.actionData = data;
        this.actionProp = {
          title: title,
          txt: txt,
          step: 1,
          number: '',
          showData: null,
          dataList: [],
          dataIndex: 0,
          show: true
        };
        if (this.recordList[0] && this.recordList[0].uid !== 8888 && this.recordList[0].is_confirm !== 0) {
          this.actionProp.showData = {
            img: this.recordList[0].user.avatar,
            lot: this.recordList[0].user.nickname,
            id: this.recordList[0].uid
          };
          this.actionProp.step = 2;
        }
        if (action === 'auctionEndAction' && this.actionData.type === 1 && this.finish_confirm_number && this.recordList[0] && this.recordList[0].uid === 8888) {
          this.$nextTick(function () {
            setTimeout(function () {
              _this4.actionProp.number = '';
              _this4.$refs['inputEL'].focus();
            }, 100);
          });
        }
        // this.$confirm(txt, '操作提示')
        //   .then(() => {
        //     this[this.action]();
        //     document.getElementById('home').click();
        //   })
        //   .catch(() => {
        //     this.action = '';
        //     this.actionData = {};
        //     document.getElementById('home').click();
        //   });
      }
    },
    delBidPriceAction: function delBidPriceAction() {
      var _this5 = this;
      // 取消出价
      (0, _auction.delBidPrice)(this.actionData).then(function () {
        _this5.closeCZ();
      }).catch(function (message) {
        _this5.closeCZ();
      });
    },
    auctionEndAction: function auctionEndAction() {
      var _this6 = this;
      // 拍品结束   落槌  流拍
      (0, _auction.auctionEnd)(this.actionData).then(function () {
        _this6.closeCZ();
      }).catch(function (message) {
        _this6.closeCZ();
      });
    },
    confirmBidPriceAction: function confirmBidPriceAction(id) {
      if (this.auctionData.time_status == 1) {
        this.$message.error('请先确认起拍价');
        return false;
      }
      // 网络出价有效
      (0, _auction.confirmBidPrice)({
        bid_id: id,
        auction_id: this.auctionData.product_id
      }).then().catch(function () {});
    },
    remakeAction: function remakeAction() {
      var _this7 = this;
      (0, _auction.remakeAuction)(this.actionData).then(function () {
        _this7.closeCZ();
      }).catch(function (message) {
        _this7.closeCZ();
      });
    },
    jumpAuctionAction: function jumpAuctionAction() {
      var _this8 = this;
      // 跳拍
      (0, _auction.jumpAuction)(this.actionData).then(function () {
        _this8.closeCZ();
      }).catch(function (message) {
        _this8.closeCZ();
      });
    },
    revokeAuctionAction: function revokeAuctionAction() {
      var _this9 = this;
      // 撤拍
      (0, _auction.revokeAuction)(this.actionData).then(function () {
        _this9.closeCZ();
      }).catch(function (message) {
        _this9.closeCZ();
      });
    },
    changeRangeAction: function changeRangeAction(id) {
      // 修改竞价阶梯
      if (this.auctionData.bid_range_id !== id || this.auctionData.bid_type === 1) {
        (0, _auction.editAuctionBidRange)({
          bidRange_id: id,
          auction_id: this.auctionData.product_id,
          bid_type: 2
        }).catch(function () {});
      }
    },
    auctionEndTimeAction: function auctionEndTimeAction() {
      var _this10 = this;
      (0, _auction.auctionStartEndApi)(this.actionData).then(function () {
        _this10.closeCZ();
      }).catch(function (message) {
        _this10.closeCZ();
      });
    },
    listenEvent: function listenEvent() {
      var _this11 = this;
      document.onkeydown = function (e) {
        var e1 = e || event || window.event;
        if (_this11.$route.name === 'auctionConsole' && !_this11.end) {
          if (_this11.isEditPrice && e1.keyCode === 27) {
            _this11.isEditPrice = false;
            _this11.initPrice(_this11.auctionData);
            return false;
          }
          if (_this11.action && !_this11.speck && !_this11.inpFocus) {
            if (e1.keyCode === 27) {
              // esc关闭
              if (_this11.action === 'jumpAuctionAction' || _this11.action === 'auctionEndAction') {
                if (_this11.actionProp.step === 2) {
                  _this11.actionProp.number = '';
                  _this11.actionProp.step = 1;
                  _this11.$nextTick(function () {
                    setTimeout(function () {
                      _this11.actionProp.number = '';
                      _this11.$refs['inputEL'].focus();
                    }, 100);
                  });
                } else {
                  _this11.closeCZ();
                }
              } else {
                _this11.closeCZ();
              }
            } else if (_this11.action === 'jumpAuctionAction' && e1.keyCode === 13) {
              _this11.searchProductAction();
            } else if (_this11.action === 'auctionEndAction' && e1.keyCode === 13) {
              _this11.searchUserAction();
            } else if (e1.keyCode === 13) {
              // 确认
              _this11[_this11.action]();
              _this11.closeCZ();
            }
          } else if (!_this11.action && !_this11.speck && !_this11.inpFocus) {
            if (e1.keyCode === 89) {
              // 落槌
              if (_this11.auctionData.now_price && _this11.recordList && _this11.recordList.length > 0) {
                _this11.confirmAction('auctionEndAction', 1);
              } else {
                _this11.$message.error('没有出价用户，不能落槌');
              }
            } else if (e1.keyCode === 78) {
              // 流拍
              _this11.confirmAction('auctionEndAction', 2);
            } else if (e1.keyCode === 81) {
              // 跳拍
              _this11.handleAdditem();
            } else if (e1.keyCode === 67) {
              // 删除最高价
              if (_this11.recordList[0]) {
                _this11.confirmAction('delBidPriceAction', _this11.recordList[0].bid_id);
              }
            } else if (e1.keyCode === 76 && _this11.albumData.album_type === 2 && _this11.albumData.end_type === 2 && !_this11.goTime) {
              // 倒数落槌
              _this11.confirmAction('auctionEndTimeAction');
            } else if (e1.keyCode === 16) {
              if (_this11.recordList[0] && _this11.recordList[0].is_confirm === 0) {
                _this11.confirmBidPriceAction(_this11.recordList[0].bid_id);
              }
            } else if (e1.keyCode === 87) {
              _this11.confirmAction('remakeAction');
            } else if (e1.keyCode === 69) {
              _this11.confirmAction('revokeAuctionAction');
            } else if (e1.keyCode === 32) {
              if (_this11.auctionData.time_status === 1) {
                _this11.startBid();
              } else if (_this11.auctionData.time_status === 2) {
                _this11.bidPrice();
              }
            } else if (_this11.action) {
              console.log('有弹出确认框');
            } else if (e1.keyCode >= 48 && e1.keyCode <= 57 || e1.keyCode >= 96 && e1.keyCode <= 105) {
              if (!_this11.isEditPrice) _this11.isEditPrice = true;
              var num = e1.keyCode > 57 ? e1.keyCode - 48 : e1.keyCode;
              var bidPriceList = JSON.parse(JSON.stringify(_this11.bidPriceList));
              bidPriceList[_this11.bidPriceIndex] = "".concat(num - 48);
              _this11.bidPriceList = bidPriceList;
              _this11.huiNum = 10 - "".concat(Number(bidPriceList.join(''))).split('').length;
              if (_this11.bidPriceIndex < 9) {
                _this11.bidPriceIndex += 1;
              }
            } else if (e1.keyCode === 39) {
              if (_this11.bidPriceIndex < 9) {
                if (!_this11.isEditPrice) _this11.isEditPrice = true;
                _this11.bidPriceIndex += 1;
              }
            } else if (e1.keyCode === 37) {
              if (_this11.bidPriceIndex > 0) {
                if (!_this11.isEditPrice) _this11.isEditPrice = true;
                _this11.bidPriceIndex -= 1;
              }
            } else if (e1.keyCode === 65 || e1.keyCode === 83 || e1.keyCode === 68) {
              var txt = '请尽快出价';
              if (e1.keyCode === 83) {
                txt = '即将落槌，请尽快出价';
              } else if (e1.keyCode === 68) {
                txt = '最后一次叫价';
              }
              _this11.sendLiveNoticeAction(txt);
            }
          }
        }
      };
    },
    sockedMes: function sockedMes(d) {
      var _this12 = this;
      if (d.type === 'init') {
        // 初始化
        this.client_id = d.client_id;
        this.joinRoomAction();
      } else if (d.type === 'auction_start') {
        // 确认起拍价
        if (this.isEditPrice) this.isEditPrice = false;
        this.auctionData = d.data.auction_info;
        if (d.data.auction_info && d.data.auction_info.bid_record) {
          this.recordList = d.data.auction_info.bid_record;
        }
        this.loadProxy();
        this.initPriceRule(this.auctionData);
        this.initPrice(this.auctionData);
      } else if (d.type === 'auction_bid') {
        // 出价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          if (this.goTime && d.data.goTime > 0) {
            this.goTime = d.data.goTime;
            this.countDownAction(this.goTime);
          }
          this.auctionData.bid_num = d.data.bid_num;
          this.recordList = [d.data.bid_record].concat(this.recordList);
          if (d.data.bid_record.is_confirm !== 0) {
            this.auctionData.now_price = d.data.now_price;
            // this.settingPrice(d.data.now_price);
          } else if (d.data.last_bid_record && d.data.last_bid_record.is_confirm === 1) {
            this.auctionData.now_price = d.data.last_bid_record.bid_price;
          }
          if (d.data.last_bid_record && this.recordList[1] && this.recordList[1].is_confirm !== d.data.last_bid_record.is_confirm) {
            this.recordList[1].is_confirm = d.data.last_bid_record.is_confirm;
          }
          this.initPrice(this.auctionData);
        }
      } else if (d.type === 'auction_bid_proxy') {
        // 出价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          if (this.goTime && d.data.goTime > 0) {
            this.goTime = d.data.goTime;
            this.countDownAction(this.goTime);
          }
          this.auctionData.bid_num = d.data.bid_num;
          this.recordList = d.data.bid_record_list;
          if (d.data.bid_record_list[1]) {
            this.auctionData.now_price = d.data.bid_record_list[1].bid_price;
          }
          this.initPrice(this.auctionData);
        }
      } else if (d.type === 'auction_bid_del') {
        // 删除最高价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          this.auctionData.bid_num = d.data.bid_num;
          this.recordList = d.data.bid_list;
          // if (this.recordList.length > 0) {
          //   for (let i = 0; i < this.recordList.length; i += 1) {
          //     const m = this.recordList[i];
          //     if (m.bid_id === d.data.bid_record.bid_id) {
          //       this.recordList.splice(i, 1);
          //       break;
          //     }
          //   }
          // }
          this.auctionData.now_price = d.data.now_price;
          this.settingPrice(d.data.now_price);
          // this.settingPrice(this.auctionData.now_price);
        }
      } else if (d.type === 'auction_end' || d.type === 'auction_revoke') {
        if (this.sInt) clearInterval(this.sInt);
        if (this.cdhm) clearInterval(this.cdhm);
        if (this.isEditPrice) this.isEditPrice = false;
        this.goTime = '';
        if (d.data.next_auction) {
          var val = JSON.parse(JSON.stringify(this.auctionData));
          this.lastEndAuctionInfo = {
            lot: val.lot,
            userInfo: null,
            now_price: ''
          };
          this.inpFocus = false;
          this.set_plus_price = '';
          this.plus_price = '';
          if (d.data.auction_info && d.data.type === 1) {
            this.lastEndAuctionInfo.userInfo = {
              nickname: d.data.auction_info.userInfo.nickname
            };
            this.lastEndAuctionInfo.now_price = this.recordList[0].bid_price;
          }
          if (d.type === 'auction_revoke') {
            this.albumData.auction_num = d.data.auction_count;
          }
          this.recordList = [];
          this.auctionData = d.data.next_auction;
          this.loadProxy();
          // this.confirm_start_price
          this.initPriceRule(this.auctionData);
          if (this.auctionData.start_price) {
            this.setPrice(this.auctionData.start_price);
          } else {
            this.setPrice(0);
          }
          this.loadNumberList(this.auctionData.product_id);
          if (d.type === 'auction_end' && !this.confirm_start_price) {
            this.startBid();
          }
        } else {
          (0, _auction.albumAuctionStatistical)({
            album_id: this.album_id
          }).then(function (res) {
            _this12.albumEndData = res.data;
            _this12.albumEndData.end_time = res.server_time;
            _this12.end = true;
          });
          (0, _auction.getAlbumLiveGroupNextInfoApi)({
            album_id: this.album_id
          }).then(function (res) {
            if (res.data.data) {
              _this12.$confirm("\u7ACB\u5373\u5F00\u59CB\u4E0B\u4E00\u573A?", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(function () {
                (0, _auction.switchLiveGroupNextInfoApi)({
                  album_id: _this12.album_id
                }).then().catch(function () {});
              }).catch(function () {});
            }
          });
        }
      } else if (d.type === 'auction_remake') {
        this.recordList = [];
        this.auctionData = d.data.auction_info;
        this.initPriceRule(this.auctionData);
        this.initPrice(this.auctionData);
      } else if (d.type === 'auction_bid_confirm') {
        if (this.recordList.length > 0) {
          for (var i = 0; i < this.recordList.length; i += 1) {
            var m = this.recordList[i];
            if (m.bid_id === d.data.bid_record.bid_id) {
              this.recordList[i].is_confirm = 1;
              break;
            }
          }
        }
        this.auctionData.now_price = d.data.now_price;
        this.initPrice(this.auctionData);
      } else if (d.type === 'auction_edit_bidRange') {
        if (this.auctionData && this.auctionData.product_id === d.data.auction_id) {
          this.auctionData.bid_type = d.data.bid_type;
          if (d.data.plus_price) {
            this.auctionData.plus_price = d.data.plus_price;
          } else if (d.data.bidRange_info) {
            this.auctionData.bid_range_id = d.data.bidRange_info.id;
            this.auctionData.bidRange = d.data.bidRange_info;
            this.inpFocus = false;
            this.set_plus_price = '';
            this.plus_price = '';
          }
          this.initPriceRule(this.auctionData);
          this.initPrice(this.auctionData);
        }
      } else if (d.type === 'auction_start_end') {
        if (this.auctionData && this.auctionData.product_id === d.data.auction_id) {
          this.goTime = d.data.goTime;
          this.countDownAction(this.goTime);
        }
      } else if (d.type === 'album_switch' && d.data && d.data.next_album_info) {
        if (this.isEditPrice) this.isEditPrice = false;
        window.location.href = '/merchant/auctionConsole?id=' + d.data.next_album_info.album_id + '&v_id=' + this.v_id;
      } else if (d.type === 'auction_bid_price_remind_set') {
        this.countDownTime.push({
          time: d.data.remindSecond + 1,
          uid: d.data.userNumber.uid,
          number: d.data.userNumber.number,
          countDownInt: null
        });
        this.countDownTimeUser = this.countDownTime.map(function (m) {
          return m.uid;
        });
        var _i = this.countDownTime.length - 1;
        this.djs(this.countDownTime[_i].uid, this.countDownTime[_i].time);
      } else if (d.type === 'auction_bid_price_remind_delayed') {
        var _i2 = this.countDownTimeUser.indexOf(d.data.userNumber.uid);
        if (_i2 !== -1) {
          if (this.countDownTime[_i2].countDownInt) clearTimeout(this.countDownTime[_i2].countDownInt);
          this.countDownTime[_i2].time = d.data.surplusSecond + d.data.remindSecond + 1;
          this.djs(this.countDownTime[_i2].uid, this.countDownTime[_i2].time);
        }
      } else if (d.type === 'auction_bid_price_remind_cancel') {
        var _i3 = this.countDownTimeUser.indexOf(d.data.userNumber.uid);
        if (_i3 !== -1) {
          if (this.countDownTime[_i3].countDownInt) clearTimeout(this.countDownTime[_i3].countDownInt);
          this.countDownTime.splice(_i3, 1);
          this.countDownTimeUser = this.countDownTime.map(function (m) {
            return m.uid;
          });
        }
      }
    },
    djs: function djs(uid, time) {
      var _this13 = this;
      (0, _index.countDown)(time, function (h, m, s) {
        var i = _this13.countDownTimeUser.indexOf(uid);
        if (s) {
          if (_this13.countDownTime[i]) _this13.countDownTime[i].time = s;
        } else {
          _this13.countDownTime.splice(i, 1);
          _this13.countDownTimeUser = _this13.countDownTime.map(function (m) {
            return m.uid;
          });
        }
      }, function (sInt) {
        var i = _this13.countDownTimeUser.indexOf(uid);
        if (_this13.countDownTime[i]) _this13.countDownTime[i].countDownInt = sInt;
      });
    },
    joinRoomAction: function joinRoomAction() {
      var _this14 = this;
      (0, _auction.joinRoom)({
        album_id: this.album_id,
        client_id: this.client_id
      }).then(function (res) {
        _this14.albumData = res.data.album_info;
        _this14.auctionData = res.data.now_auction;
        _this14.lastEndAuctionInfo = res.data.lastEndAuctionInfo;
        _this14.recordList = res.data.now_auction && res.data.now_auction.bid_record && res.data.now_auction.bid_record.length > 0 ? res.data.now_auction.bid_record : [];
        if (res.data.now_auction) {
          _this14.oldRange = res.data.now_auction.bidRange;
        }
        if (_this14.auctionData) {
          _this14.loadNumberList(_this14.auctionData.product_id);
          _this14.initPriceRule(_this14.auctionData);
          if (_this14.auctionData.auction_status === 2) {
            if (_this14.auctionData.start_price) {
              _this14.setPrice(_this14.auctionData.start_price);
            } else {
              _this14.setPrice(0);
            }
          } else {
            _this14.initPrice(_this14.auctionData);
          }
        }
        _this14.loadProxy();
      }).catch(function () {});
    },
    loadNumberList: function loadNumberList(id) {
      var _this15 = this;
      (0, _auction.numberPlateUserList)({
        auction_id: id,
        page: 1,
        limit: 50,
        number_type: 2,
        venue_id: this.v_id
      }).then(function (res) {
        _this15.changeUser = {};
        _this15.showNumberList = res.data.list;
      }).catch(function () {
        _this15.showNumberList = [];
      });
    },
    loadProxy: function loadProxy() {
      var _this16 = this;
      (0, _auction.proxyBidListApi)({
        auction_id: this.auctionData.product_id
      }).then(function (res) {
        _this16.proxyBidList = res.data.list;
      }).catch(function () {});
    },
    // 计算下一口价格
    settingPrice: function settingPrice(price) {
      price = Number(price);
      var area = this.getPriceArea(price);
      var p = 0;
      switch (area.type) {
        case 1:
          p = price + area.price;
          break;
        case 2:
          p = this.getBidMoney258(price);
          break;
        case 3:
          p = this.getBidMoneyThree258(price);
          break;
        default:
          p = this.getBidMoney258(price);
          break;
      }
      this.setPrice(p);
    },
    // 计算下一口价格
    settingBidPrice: function settingBidPrice(price) {
      price = Number(price);
      var area = this.getPriceArea(price);
      var p = 0;
      switch (area.type) {
        case 1:
          p = price + area.price;
          break;
        case 2:
          p = this.getBidMoney258(price);
          break;
        case 3:
          p = this.getBidMoneyThree258(price);
          break;
        default:
          p = this.getBidMoney258(price);
          break;
      }
      this.auctionData.bidNowPrice = p;
    },
    setPrice: function setPrice(price) {
      // 展示价格
      if (!this.bidTimeout) {
        this.auctionData.bidNowPrice = price;
      }
      var nowP = this.auctionData.now_price;
      this.price = price;
      var bidPriceList = "".concat(this.price).split('');
      var newBidPrice = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      bidPriceList.forEach(function (m, i) {
        newBidPrice[10 - bidPriceList.length + i] = m;
      });
      this.bidPriceList = newBidPrice;
      if (nowP && this.price && this.price > nowP) {
        this.bidPriceIndex = 10 - "".concat(((0, _index.formatPrice)(this.price) - (0, _index.formatPrice)(nowP)) / 100).length;
      } else if (nowP && this.price && nowP - this.price === 0) {
        this.bidPriceIndex = 11 - "".concat(this.price).length;
      } else if (nowP && this.price && nowP - this.price > 0) {
        this.bidPriceIndex = 10 - "".concat(((0, _index.formatPrice)(nowP) - (0, _index.formatPrice)(this.price)) / 100).length;
      } else {
        this.bidPriceIndex = 10 - bidPriceList.length;
      }
      this.huiNum = 10 - bidPriceList.length;
    },
    getPriceArea: function getPriceArea(price) {
      // 获取价格区间
      var data = this.priceData;
      var index = null;
      for (var i in data) {
        var m = data[i];
        if (price >= m.min && price < m.max) {
          index = i;
          break;
        }
      }
      if (index === null) {
        index = data.length - 1;
      }
      return data[index];
    },
    getBidMoney258: function getBidMoney258(price) {
      // 2580价格
      var money = price.toString();
      var numCount = money.length;
      var firstNum = Number(money.substr(0, 1));
      var secondNum = money.substr(1, 1);
      if (numCount <= 2) {
        return 200;
      } else if (numCount <= 3) {
        if (firstNum < 2) {
          return 200;
        } else if (firstNum < 5) {
          return 500;
        } else if (firstNum < 8) {
          return 800;
        } else {
          return 1000;
        }
      }
      if (firstNum && secondNum === 0) {
        secondNum = 2;
      } else {
        if (secondNum < 2) {
          secondNum = 2;
        } else if (secondNum < 5) {
          secondNum = 5;
        } else if (secondNum < 8) {
          secondNum = 8;
        } else {
          firstNum += 1;
          secondNum = 0;
          if ("".concat(firstNum).length > 1) {
            numCount += "".concat(firstNum).length - 1;
          }
        }
      }
      return Number(this.prefixZero("".concat(firstNum).concat(secondNum), numCount));
    },
    getBidMoneyThree258: function getBidMoneyThree258(price) {
      // 3位2580价格
      var money = price.toString();
      var numCount = money.length;
      if (numCount < 5) {
        return this.getBidMoney258(price);
      }
      var firstNum = Number(money.substr(0, 1));
      var secondNum = money.substr(1, 1);
      var threeNum = money.substr(2, 1);
      if (threeNum < 2) {
        threeNum = Number(this.prefixZero(2, numCount - 2));
      } else if (threeNum < 5) {
        threeNum = Number(this.prefixZero(5, numCount - 2));
      } else if (threeNum < 8) {
        threeNum = Number(this.prefixZero(8, numCount - 2));
      } else {
        threeNum = Number(this.prefixZero(1, numCount - 1));
      }
      return Number(this.prefixZero("".concat(firstNum).concat(secondNum), numCount)) + threeNum;
    },
    prefixZero: function prefixZero(num, n) {
      return (num + Array(n).join(0)).slice(0, n);
    },
    initPriceRule: function initPriceRule(newVal) {
      if (Number(newVal.plus_price) && newVal.bid_type === 1) {
        this.plus_price = Number(newVal.plus_price);
      }
      if (newVal.bid_range_id && newVal.bid_type === 2) {
        if (newVal.bidRange) {
          this.priceData = newVal.bidRange.content;
        }
      } else {
        this.priceData = [{
          min: 0,
          max: '∞',
          price: Number(newVal.plus_price),
          type: 1
        }];
      }
    },
    initPrice: function initPrice(newVal) {
      if (this.isEditPrice) return false;

      // 初始化价格信息
      if (newVal && newVal.product_id) {
        if (this.recordList[0] && this.recordList[0].bid_price && this.recordList[0].is_confirm) {
          this.settingPrice(this.recordList[0].bid_price);
        } else if (newVal.now_price) {
          this.settingPrice(newVal.now_price);
        } else {
          if (newVal.start_price) {
            this.setPrice(newVal.start_price);
          } else {
            this.settingPrice(0);
          }
        }
      }
    },
    initBidPrice: function initBidPrice(newVal) {
      if (this.isEditPrice) return false;

      // 初始化价格信息
      if (newVal && newVal.product_id) {
        if (newVal.bidNowPrice) {
          this.settingBidPrice(newVal.bidNowPrice);
        } else {
          if (newVal.start_price) {
            this.auctionData.bidNowPrice = newVal.start_price;
          } else {
            this.settingBidPrice(0);
          }
        }
      }
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      var _this17 = this;
      if (this.auctionData.time_status === 1) {
        // this.$refs.selectView.queryParams.album_id = this.album_id;
        // this.$refs.selectView.changeshow();
        this.action = 'jumpAuctionAction';
        this.actionProp = {
          show: true,
          step: 1,
          number: '',
          showData: null,
          dataList: [],
          dataIndex: 0,
          title: '跳拍'
        };
        this.$nextTick(function () {
          setTimeout(function () {
            _this17.actionProp.number = '';
            _this17.$refs['inputEL'].focus();
          }, 100);
        });
      } else {
        this.$message.error('请在确认起拍价前操作跳拍');
      }
    },
    searchProductAction: function searchProductAction(i) {
      var _this18 = this;
      if (this.actionProp.step === 1) {
        if (!this.actionProp.number) {
          this.$message.error('请输入LOT');
          return false;
        }
        // 图录号搜索拍品
        (0, _auction.auctionList)({
          // album_id: this.album_id,
          venue_id: this.v_id,
          lot: this.actionProp.number,
          page: 1,
          limit: 30,
          auction_type: 3
        }).then(function (res) {
          var m = res.data.list[0];
          if (m && res.data.list.length > 1) {
            _this18.actionProp.showData = {
              img: m.product.image,
              lot: m.lot,
              id: m.product_id
            };
            _this18.actionProp.dataList = res.data.list.map(function (m) {
              return {
                img: m.product.image,
                lot: m.lot,
                id: m.product_id,
                name: m.product.store_name,
                albumName: m.album.album_name
              };
            });
            _this18.actionProp.dataIndex = 0;
            _this18.actionProp.step = 2;
          } else if (m && res.data.list.length === 1) {
            _this18.actionProp.showData = {
              img: m.product.image,
              lot: m.lot,
              id: m.product_id
            };
            _this18.actionProp.dataList = [];
            _this18.actionProp.dataIndex = 0;
            _this18.actionProp.step = 2;
          } else {
            _this18.actionProp.showData = {
              img: '',
              lot: '没有此拍品',
              id: ''
            };
            _this18.actionProp.step = 2;
          }
        }).catch(function () {});
      } else if (this.actionProp.step === 2) {
        if (i && this.actionProp.dataList && this.actionProp.dataList.length) {
          this.actionData = {
            auction_id: this.actionProp.dataList[i].id,
            album_id: this.album_id
          };
          this.jumpAuctionAction();
        } else {
          if (!this.actionProp.showData.id) {
            this.$message.error('请确认要跳拍的拍品');
            return false;
          }
          this.actionData = {
            auction_id: this.actionProp.showData.id,
            album_id: this.album_id
          };
          this.jumpAuctionAction();
        }
      }
    },
    searchUserAction: function searchUserAction() {
      var _this19 = this;
      if (this.actionProp.step === 1) {
        // 号牌搜索用户
        if (!this.actionProp.number) {
          this.actionProp.showData = {
            img: '',
            lot: '不选择号牌',
            id: ''
          };
          this.actionProp.step = 2;
          return false;
        }
        (0, _auction.numberPlateUserList)({
          album_id: this.album_id,
          number: this.actionProp.number,
          page: 1,
          limit: 1,
          venue_id: this.v_id
        }).then(function (res) {
          var m = res.data.list[0];
          if (m) {
            _this19.actionProp.showData = {
              img: m.user.avatar,
              lot: m.number,
              id: m.uid
            };
            _this19.actionProp.step = 2;
          } else {
            _this19.actionProp.showData = {
              img: '',
              lot: '没有此号牌',
              id: ''
            };
            _this19.actionProp.step = 2;
          }
        }).catch(function () {});
      } else if (this.actionProp.step === 2) {
        this.actionData.uid = this.actionProp.showData.id;
        this.auctionEndAction();
      }
    },
    focusNewAction: function focusNewAction() {
      this.inpFocus = true;
    },
    blurNewAction: function blurNewAction() {
      this.inpFocus = false;
      this.set_plus_price = '';
    },
    setPlusAction: function setPlusAction() {
      if (Number(this.set_plus_price)) {
        (0, _auction.editAuctionBidRange)({
          plus_price: Number(this.set_plus_price),
          auction_id: this.auctionData.product_id,
          bid_type: 1
        }).catch(function () {});
        this.plus_price = this.set_plus_price;
        this.inpFocus = false;
      }
    },
    editPlusPrice: function editPlusPrice() {
      var _this20 = this;
      this.set_plus_price = '';
      this.inpFocus = true;
      this.$nextTick(function () {
        _this20.$refs.editPlusPriceEL.focus();
      });
    },
    countDownAction: function countDownAction(time) {
      var _this21 = this;
      if (this.sInt) clearInterval(this.sInt);
      if (this.cdhm) clearInterval(this.cdhm);
      this.cDownAction();
      this.cdhm = setInterval(function () {
        _this21.cDownAction();
      }, 47);
      (0, _index.countDown)(time, function (h, m, s) {
        if (!s) {
          if (_this21.sInt) clearInterval(_this21.sInt);
          if (_this21.cdhm) clearInterval(_this21.cdhm);
          _this21.cd = '00';
          _this21.showS = '00';
        } else {
          _this21.showS = s;
        }
      }, function (sInt) {
        _this21.sInt = sInt;
      });
    },
    cDownAction: function cDownAction() {
      if (cd - 4.7 < 0) {
        cd = 99;
      }
      cd -= 4.7;
      this.cd = (0, _index.formatDateNumber)(parseInt(cd, 10));
    },
    changeUserAction: function changeUserAction(m) {
      if (this.changeUser.uid === m.uid) {
        this.changeUser = {};
      } else {
        this.changeUser = JSON.parse(JSON.stringify(m));
      }
    }
  }
};