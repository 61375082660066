var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "information" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.merData,
            rules: _vm.rules,
            "label-width": "0px",
            size: "normal",
          },
        },
        [
          _vm.merModel
            ? _c(
                "div",
                {
                  staticClass: "business-msg",
                  staticStyle: { "padding-bottom": "54px" },
                },
                [
                  _c("div", { staticClass: "moLable" }, [_vm._v("企业LOGO")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex moCon" }, [
                    _c("div", { staticClass: "moConItem" }, [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "750*750px" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1")
                            },
                          },
                        },
                        [
                          _vm.merData.mer_logo
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.merData.mer_logo },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v("LOGO")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("130*65px")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "moConItem" }, [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "750*750px" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("2")
                            },
                          },
                        },
                        [
                          _vm.merData.mer_avatar
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.merData.mer_avatar },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v("商户头像")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "moLable",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [_vm._v("基本信息")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "moTHead" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("字段")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-one" }, [_vm._v("内容")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [_vm._v("用途描述")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("商户名称")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "",
                              size: "normal",
                              prop: "mer_name",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入商户名称" },
                              model: {
                                value: _vm.merData.mer_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.merData, "mer_name", $$v)
                                },
                                expression: "merData.mer_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("企业全称")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "",
                              size: "normal",
                              prop: "company_name",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入企业名称" },
                              model: {
                                value: _vm.merData.company_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.merData, "company_name", $$v)
                                },
                                expression: "merData.company_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("企业英文名称"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "",
                              size: "normal",
                              prop: "company_english_name",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入企业英文名称" },
                              model: {
                                value: _vm.merData.company_english_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.merData,
                                    "company_english_name",
                                    $$v
                                  )
                                },
                                expression: "merData.company_english_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("客服电话")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入客服电话" },
                          model: {
                            value: _vm.merData.contact_phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "contact_phone", $$v)
                            },
                            expression: "merData.contact_phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("开户名称")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入账户名称" },
                          model: {
                            value: _vm.merData.bank_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "bank_name", $$v)
                            },
                            expression: "merData.bank_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同 "),
                      _c("br"),
                      _vm._v(" 用户充值收款 "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("开户行")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入开户行" },
                          model: {
                            value: _vm.merData.bank,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "bank", $$v)
                            },
                            expression: "merData.bank",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同 "),
                      _c("br"),
                      _vm._v(" 用户充值收款"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("银行帐号")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入银行账号" },
                          model: {
                            value: _vm.merData.bank_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "bank_number", $$v)
                            },
                            expression: "merData.bank_number",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同 "),
                      _c("br"),
                      _vm._v(" 用户充值收款"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("详细地址")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "enter-button": "查找位置",
                            placeholder:
                              "请输入地址（地址中请包含城市名称，否则会影响搜索精度）",
                          },
                          model: {
                            value: _vm.merData.company_address,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "company_address", $$v)
                            },
                            expression: "merData.company_address",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("企业简介")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "",
                              size: "normal",
                              prop: "mer_info",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                placeholder: "文字简介，200字以内",
                              },
                              model: {
                                value: _vm.merData.mer_info,
                                callback: function ($$v) {
                                  _vm.$set(_vm.merData, "mer_info", $$v)
                                },
                                expression: "merData.mer_info",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "formFixedBox" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.submitLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }