"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      }
    };
  },
  methods: {
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      (0, _printJs.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page {margin:0 10mm}"
      });
    },
    loadData: function loadData(row) {
      var model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
        user_verify: JSON.parse(row.user_verify),
        user_address: JSON.parse(row.user_address),
        DxMoney: this.convertToChineseCapital(Number(row.add_wallet_price))
      });
      console.log(model);
      this.collectMoney = {
        data: model,
        show: true
      };
    },
    convertToChineseCapital: function convertToChineseCapital(money) {
      var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
      var cnIntRadice = ['', '拾', '佰', '仟'];
      var cnIntUnits = ['', '拾', '佰', '仟', '万', '拾', '佰', '仟', '亿', '拾', '佰', '仟'];
      var cnDecUnits = ['角', '分', '厘', '几'];
      var cnInteger = '整';
      var cnIntLast = '元';
      var maxNum = 999999999999999.9999;
      var integerNum;
      var decimalNum;
      var chineseStr = '';
      var parts;
      if (money == '') {
        return '';
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        // 超出最大金额
        return '';
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntUnits[0] + cnIntLast + cnDecUnits[0];
        return chineseStr;
      }
      // 转换整数部分
      if (money.toString().indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
      } else {
        parts = money.toString().split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == '0') {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            // 假设围绕几个零后是几
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      // 转换小数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntUnits[0] + cnIntLast + cnDecUnits[0];
      } else if (decimalNum == '') {
        chineseStr += cnInteger;
      }
      return chineseStr;
    }
  }
};