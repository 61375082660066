var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "screen-box",
        style: {
          "background-color": _vm.background_color,
          color: _vm.word_color,
          "background-image": "url(" + _vm.background_image + ")",
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "screen-center" }, [
          _c(
            "div",
            {
              staticClass: "screen-center-box",
              style: {
                "background-color": _vm.main_color,
                "background-image": "url(" + _vm.main_image + ")",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.origin != 3,
                      expression: "origin != 3",
                    },
                  ],
                  staticClass: "screen-center-slide",
                },
                [
                  _c("img", {
                    style:
                      "" +
                      (_vm.showmodel == 1
                        ? "object-fit: contain;"
                        : "object-fit: cover"),
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20210129/5ee9bc05f909f664560fe751dac83b5f.jpg!m640",
                      alt: "",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm._m(1),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "screen-bottom" }, [
      _c("div", { staticClass: "screen-bottom-title" }, [_vm._v("风格设置")]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-bottom-list" }, [
        _c(
          "div",
          { staticClass: "corlor-box" },
          [
            _c("span", { staticClass: "color-name f-500" }, [
              _vm._v("页面背景色"),
            ]),
            _vm._v(" "),
            _c("colour", {
              attrs: { colour: "#2e3b58" },
              model: {
                value: _vm.background_color,
                callback: function ($$v) {
                  _vm.background_color = $$v
                },
                expression: "background_color",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "upload-img-view item-flex-center",
            on: {
              click: function ($event) {
                return _vm.modalPicTap(1, "background_image")
              },
            },
          },
          [
            _vm.background_image
              ? _c(
                  "div",
                  {
                    staticClass: "del-upload-img",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.delImg("background_image")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.background_image
              ? _c("img", { attrs: { src: _vm.background_image + "!300" } })
              : _c("img", {
                  staticClass: "upload-icon",
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                    alt: "",
                  },
                }),
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "color-name f-500",
            staticStyle: { "margin-right": "30px" },
          },
          [_vm._v("背景图")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "corlor-box" },
          [
            _c("span", { staticClass: "color-name f-500" }, [
              _vm._v("主区域背景色"),
            ]),
            _vm._v(" "),
            _c("colour", {
              attrs: { colour: "#ffffff" },
              model: {
                value: _vm.main_color,
                callback: function ($$v) {
                  _vm.main_color = $$v
                },
                expression: "main_color",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "upload-img-view item-flex-center",
            on: {
              click: function ($event) {
                return _vm.modalPicTap(1, "main_image")
              },
            },
          },
          [
            _vm.main_image
              ? _c(
                  "div",
                  {
                    staticClass: "del-upload-img",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.delImg("main_image")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.main_image
              ? _c("img", { attrs: { src: _vm.main_image + "!300" } })
              : _c("img", {
                  staticClass: "upload-icon",
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                    alt: "",
                  },
                }),
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "color-name f-500",
            staticStyle: { "margin-right": "30px" },
          },
          [_vm._v("背景图")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "corlor-box" },
          [
            _c("span", { staticClass: "color-name f-500" }, [
              _vm._v("文字颜色"),
            ]),
            _vm._v(" "),
            _c("colour", {
              attrs: { colour: "#ffffff" },
              model: {
                value: _vm.word_color,
                callback: function ($$v) {
                  _vm.word_color = $$v
                },
                expression: "word_color",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-bottom-title" }, [_vm._v("主图设置")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c("div", { staticClass: "f-500 mr5" }, [_vm._v("主图来源")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              staticClass: "le-radio-group",
              model: {
                value: _vm.origin,
                callback: function ($$v) {
                  _vm.origin = $$v
                },
                expression: "origin",
              },
            },
            [
              _c("el-radio", { attrs: { label: "3" } }, [_vm._v("不显示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("拍品主图")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("全部拍品图")]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.origin == 2
            ? [
                _c("div", { staticClass: "f-500 mr5 ml10" }, [
                  _vm._v("轮播间隔"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "setting-input",
                  attrs: { size: "normal" },
                  model: {
                    value: _vm.gap,
                    callback: function ($$v) {
                      _vm.gap = $$v
                    },
                    expression: "gap",
                  },
                }),
                _vm._v("\n        秒\n        "),
                _c("div", { staticClass: "f-500 mr5 ml10" }, [
                  _vm._v("转场效果"),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "setting-input",
                    model: {
                      value: _vm.transition,
                      callback: function ($$v) {
                        _vm.transition = $$v
                      },
                      expression: "transition",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "无", value: "0" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { label: "渐隐", value: "1" } }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "左右切换", value: "2" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "上下切换", value: "3" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.origin == 1
            ? [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "ml10",
                    attrs: { label: "3" },
                    model: {
                      value: _vm.showvideo,
                      callback: function ($$v) {
                        _vm.showvideo = $$v
                      },
                      expression: "showvideo",
                    },
                  },
                  [_vm._v("展示拍品视频")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c("div", { staticClass: "f-500 mr5" }, [_vm._v("显示方式")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              staticClass: "le-radio-group",
              model: {
                value: _vm.showmodel,
                callback: function ($$v) {
                  _vm.showmodel = $$v
                },
                expression: "showmodel",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("全图")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("裁切")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "3" } }, [_vm._v("动态")]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showmodel == 3
            ? [
                _c("div", { staticClass: "f-500 mr5 ml10" }, [
                  _vm._v("运动速度"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "setting-input",
                  model: {
                    value: _vm.second,
                    callback: function ($$v) {
                      _vm.second = $$v
                    },
                    expression: "second",
                  },
                }),
                _vm._v("\n        秒/100px\n      "),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "screen-bottom-title" }, [_vm._v("货币设置")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.selectall,
                callback: function ($$v) {
                  _vm.selectall = $$v
                },
                expression: "selectall",
              },
            },
            [
              _c("el-checkbox", { attrs: { label: "0" } }, [_vm._v("人民币")]),
              _vm._v(" "),
              _c("el-checkbox", { attrs: { label: "1" } }, [_vm._v("美元")]),
              _vm._v(" "),
              _c("el-checkbox", { attrs: { label: "2" } }, [_vm._v("欧元")]),
              _vm._v(" "),
              _c("el-checkbox", { attrs: { label: "3" } }, [_vm._v("港元")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-button-box" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.refresh },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "screen-goods-dis" }, [
      _c("div", { staticClass: "screen-goods-title" }, [
        _c("span", { staticClass: "f-30" }, [_vm._v("明青花瓷专场")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-img-box" }, [
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20210129/5ee9bc05f909f664560fe751dac83b5f.jpg!m640",
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-name" }, [_vm._v("LOT 1 测试")]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-list" }, [
        _c("div", { staticClass: "screen-goods-price" }, [_vm._v("RMB")]),
        _vm._v(" "),
        _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-list" }, [
        _c("div", { staticClass: "screen-goods-price" }, [_vm._v("USD")]),
        _vm._v(" "),
        _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-list" }, [
        _c("div", { staticClass: "screen-goods-price" }, [_vm._v("EUR")]),
        _vm._v(" "),
        _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-list" }, [
        _c("div", { staticClass: "screen-goods-price" }, [_vm._v("HKD")]),
        _vm._v(" "),
        _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-sign" }, [
        _c("div", [_vm._v("已拍 0")]),
        _vm._v(" "),
        _c("div", { staticClass: "ml20" }, [_vm._v("剩余 0")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "screen-goods-dis" }, [
      _c("div", { staticClass: "screen-goods-title flex-end" }, [
        _c("span", { staticClass: "ml20 f-30" }, [_vm._v("明青花瓷专场")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-img-box flex-end" }, [
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20210129/5ee9bc05f909f664560fe751dac83b5f.jpg!m640",
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-name text-right" }, [
        _vm._v("LOT 1 测试"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-list" }, [
        _c("div", { staticClass: "screen-goods-price" }, [_vm._v("RMB")]),
        _vm._v(" "),
        _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-list" }, [
        _c("div", { staticClass: "screen-goods-price" }, [_vm._v("USD")]),
        _vm._v(" "),
        _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-list" }, [
        _c("div", { staticClass: "screen-goods-price" }, [_vm._v("EUR")]),
        _vm._v(" "),
        _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-list" }, [
        _c("div", { staticClass: "screen-goods-price" }, [_vm._v("HKD")]),
        _vm._v(" "),
        _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-sign flex-end" }, [
        _c("div", [_vm._v("已拍 0")]),
        _vm._v(" "),
        _c("div", { staticClass: "ml20" }, [_vm._v("剩余 0")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }