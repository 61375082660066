var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm ft14 color-black",
          attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "0" },
        },
        [
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("开启")]),
                _vm._v(" "),
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.ruleForm.isOpen,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "isOpen", $$v)
                    },
                    expression: "ruleForm.isOpen",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ml20 ft14" }, [
                  _vm._v("（微信小程序、抖音小程序、APP）"),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("显示频率")]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.ruleForm.showFrequency,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "showFrequency", $$v)
                      },
                      expression: "ruleForm.showFrequency",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("每次启动"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("一天一次"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("停留时间")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticClass: "w100",
                  attrs: { controls: false },
                  model: {
                    value: _vm.ruleForm.residenceTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "residenceTime", $$v)
                    },
                    expression: "ruleForm.residenceTime",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ft14 ml10 fWeight500" }, [
                  _vm._v("秒"),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _vm.ruleForm.postImage.length
                  ? _vm._l(_vm.ruleForm.postImage, function (m, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass:
                            "upload-view flex align-items-c justify-c mr20",
                          staticStyle: { position: "relative" },
                          on: {
                            click: function ($event) {
                              return _vm.changeImg("postImage", i)
                            },
                          },
                        },
                        [
                          m
                            ? _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: m + "!m640", fit: "cover" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          m
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-img item-flex-center",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImgAction(i)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "upload-view flex align-items-c justify-c mr20",
                    staticStyle: { position: "relative" },
                    on: {
                      click: function ($event) {
                        return _vm.changeImg("postImage")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "item-flex-center flex-col" }, [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" "),
                      _c("div", [_vm._v("上传海报")]),
                    ]),
                  ]
                ),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c("div", { staticClass: "flex align-items-c" }, [
              _c("div", { staticClass: "ft14" }, [
                _vm._v(
                  "上传海报尺寸为1080*1920，因屏幕分辨率不同，请在图片周周围留出安全区域，避免内容被裁切"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("跳转地址")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "w300",
                  attrs: { controls: false },
                  model: {
                    value: _vm.ruleForm.jumpUrl,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "jumpUrl", $$v)
                    },
                    expression: "ruleForm.jumpUrl",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "big",
                    attrs: { type: "primary" },
                    on: { click: _vm.saveStartPage },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }