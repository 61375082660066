"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _selectCategory = _interopRequireDefault(require("@/components/customChildren/selectCategory.vue"));
var _system = require("@/api/system");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default,
    selectCategory: _selectCategory.default
  },
  data: function data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: "content",
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: "facade",
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    }
  },
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    changeType: function changeType(e) {
      var _this = this;
      if (e === '2' || e === '3') {
        (0, _system.getConfigClassKeys)('weChatTemplateContactInformation').then(function (res) {
          if (res.data.contact_image && e === '3') {
            _this.content = JSON.parse(res.data.contact_image);
          } else if (res.data.recommend_articles && e === '2') {
            _this.content = JSON.parse(res.data.recommend_articles);
          }
        });
      }
    },
    /**
     * 删除元素
     * @return {[type]} [description]
     */
    handleDelete: function handleDelete(index) {
      this.content.data.splice(index, 1);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      if (this.content.data.length < 20) {
        this.content.data.push({
          title: '文字',
          url: '',
          link: {}
        });
      } else {
        this.$message({
          message: '最多添加20条内容',
          type: 'warning'
        });
      }
    },
    goUp: function goUp(index) {
      if (index === 0) {
        return;
      }
      this.content.data.splice(index - 1, 0, this.content.data[index]);
      this.content.data.splice(index + 1, 1);
    },
    goDown: function goDown(index) {
      if (index === this.content.data - 1) {
        return;
      }
      this.content.data.splice(index + 2, 0, this.content.data[index]);
      this.content.data.splice(index, 1);
    },
    uploadIconAction: function uploadIconAction(i) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.content.data[i].url = img[0];
      }, 1);
    },
    delImg: function delImg(i) {
      this.content.data[i].url = '';
    },
    setCategory: function setCategory(i) {
      this.saveIndex = i;
      this.$refs.setCategoryView.changeshow(this.content.data[i].link);
    },
    selectMessage: function selectMessage(data) {
      this.content.data[this.saveIndex].link = data.data;
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {}
};