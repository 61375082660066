var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt20" }, [
    _c("div", { staticStyle: { "padding-bottom": "50px" } }, [
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("el-input-number", {
              staticClass: "w50 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.order_timeout,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "order_timeout", $$v)
                },
                expression: "ruleData.order_timeout",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("小时")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("el-input-number", {
              staticClass: "w50 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.product_order_timeout,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "product_order_timeout", $$v)
                },
                expression: "ruleData.product_order_timeout",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("分钟")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("div", { staticClass: "l" }, [_vm._v("拍卖")]),
            _vm._v(" "),
            _c("el-input-number", {
              staticClass: "w50 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.order_auto_confirm_receive_day,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "order_auto_confirm_receive_day", $$v)
                },
                expression: "ruleData.order_auto_confirm_receive_day",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("天")]),
            _vm._v(" "),
            _c("div", { staticClass: "l ml20" }, [_vm._v("商城")]),
            _vm._v(" "),
            _c("el-input-number", {
              staticClass: "w50 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.product_order_auto_confirm_receive_day,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.ruleData,
                    "product_order_auto_confirm_receive_day",
                    $$v
                  )
                },
                expression: "ruleData.product_order_auto_confirm_receive_day",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("天")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("div", { staticClass: "l" }, [_vm._v("拍卖：")]),
            _vm._v(" "),
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.order_refund,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "order_refund", $$v)
                },
                expression: "ruleData.order_refund",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "l ml20" }, [_vm._v("商城：")]),
            _vm._v(" "),
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.product_order_refund,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "product_order_refund", $$v)
                },
                expression: "ruleData.product_order_refund",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c minh50" }, [
        _vm._m(4),
        _vm._v(" "),
        _c("div", {}, [
          _c(
            "div",
            {
              staticClass: "flex justify-e",
              staticStyle: { "margin-bottom": "10px" },
            },
            [
              _c("div", { staticClass: "l" }, [_vm._v("支持开票：")]),
              _vm._v(" "),
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleData.is_open_invoice,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "is_open_invoice", $$v)
                  },
                  expression: "ruleData.is_open_invoice",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "l ml20" }, [_vm._v("并主动提示：")]),
              _vm._v(" "),
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.ruleData.invoice_reminder,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "invoice_reminder", $$v)
                  },
                  expression: "ruleData.invoice_reminder",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-input", {
                staticClass: "ml10 mr10",
                staticStyle: { width: "800px" },
                attrs: {
                  type: "textarea",
                  controls: false,
                  resize: "none",
                  min: 0,
                },
                model: {
                  value: _vm.ruleData.invoice_description,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "invoice_description", $$v)
                  },
                  expression: "ruleData.invoice_description",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("退货地址信息")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("div", [_vm._v("收货人")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "w100 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.refund_address_name,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "refund_address_name", $$v)
                },
                expression: "ruleData.refund_address_name",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("收货电话")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "w150 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.refund_address_phone,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "refund_address_phone", $$v)
                },
                expression: "ruleData.refund_address_phone",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("收货地址")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "w300 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.refund_address_info,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "refund_address_info", $$v)
                },
                expression: "ruleData.refund_address_info",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(5),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.ruleData.order_is_valuation,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "order_is_valuation", $$v)
                  },
                  expression: "ruleData.order_is_valuation",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("默认保价")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 2 } }, [
                  _vm._v("买家选择保价"),
                ]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("不保价")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item flex align-items-c",
          staticStyle: { padding: "10px 0" },
        },
        [
          _c("div", { staticClass: "flex-one" }, [
            _vm._v("提交订单页面发货描述"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-input", {
                staticClass: "ml10 mr10",
                staticStyle: { width: "800px" },
                attrs: {
                  type: "textarea",
                  controls: false,
                  resize: "none",
                  min: 0,
                },
                model: {
                  value: _vm.ruleData.delivery_description,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "delivery_description", $$v)
                  },
                  expression: "ruleData.delivery_description",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "formFixedBox" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("拍卖订单违约时间"),
      _c("span", [
        _vm._v("（超时未付款视为违约，保证金扣除。设置0表示没有违约时间。）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("商品订单自动关闭时间"),
      _c("span", [_vm._v("（未付款的订单，超时后自动取消）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("自动确认收货时间"),
      _c("span", [
        _vm._v("（买家收到货后，没有操作确认收货的，到达时间后系统自动确认）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("订单支持售后"),
      _c("span", [_vm._v("（开启后，买家可申请售后退款和退货）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("订单支持开发票"),
      _c("span", [_vm._v("（开启后，买家提交订单时可填写开票信息）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("拍卖订单发货保价"),
      _c("span", [_vm._v("（买家提交订单时选择保价或默认保价）")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }