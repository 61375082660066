"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _marketing = require("@/api/marketing");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableList: [{
        bus: '拍卖',
        filed: 'auctionStartNotice',
        con: '拍品开拍通知',
        ywcj: '限时拍',
        tsrq: '点“拍品提醒”的交“拍品保证金”的',
        tssj: '用户自选时间',
        bz: ''
      }, {
        bus: '拍卖',
        filed: 'auctionStartNoticeSyn',
        con: '拍品开拍通知',
        ywcj: '同步拍',
        tsrq: '点“拍品提醒”的交“拍品保证金”的',
        tssj: '提前2件推送',
        bz: ''
      }, {
        bus: '拍卖',
        filed: 'albumEndNotice',
        con: '专场即将结拍通知',
        ywcj: '限时拍',
        tsrq: '点“专场提醒”的“办理号牌的”',
        tssj: '用户自选时间',
        bz: '逐件结拍以第一件时间为准'
      }, {
        bus: '拍卖',
        filed: 'auctionSoonEndNotice',
        con: '拍品即将结拍通知',
        ywcj: '限时拍',
        tsrq: '点“拍品提醒”的交“拍品保证金”的对“拍品出价”的',
        tssj: '用户自选时间',
        bz: ''
      }, {
        bus: '拍卖',
        filed: 'auctionDealNotice',
        con: '拍品中拍通知',
        ywcj: '限时拍,同步拍',
        tsrq: '已中拍',
        tssj: '实时推送',
        bz: ''
      }, {
        bus: '拍卖',
        filed: 'auctionBidBeyond',
        con: '出价被超越通知',
        ywcj: '限时拍',
        tsrq: '对“拍品出价”的	',
        tssj: '实时推送',
        bz: ''
      }, {
        bus: '拍卖',
        filed: 'auctionBidBeyondSyn',
        con: '出价被超越通知',
        ywcj: '同步拍',
        tsrq: '对“拍品出价”的',
        tssj: '实时推送',
        bz: '不在直播间时推送'
      }, {
        bus: '拍卖',
        filed: 'auctionCollectResult',
        con: '征集结果通知',
        ywcj: '在线征集',
        tsrq: '提交征集的用户',
        tssj: '实时推送',
        bz: ''
      }, {
        bus: '鉴定',
        filed: 'identificationPay',
        con: '鉴定支付通知',
        ywcj: '在线鉴定',
        tsrq: '用户提问的专家',
        tssj: '实时推送',
        bz: '系统自动'
      }, {
        bus: '鉴定',
        filed: 'identificationResult',
        con: '鉴定结果通知',
        ywcj: '在线鉴定',
        tsrq: '提问的用户',
        tssj: '鉴定结果后2小时',
        bz: '系统自动'
      }, {
        bus: '订单',
        filed: 'orderPaymentReminder',
        con: '订单待结算通知',
        ywcj: '订单待付款',
        tsrq: '买家',
        tssj: '规则描述',
        bz: '系统自动'
      }, {
        bus: '订单',
        filed: 'orderDelivery',
        con: '订单已发货通知',
        ywcj: '订单已发货',
        tsrq: '买家',
        tssj: '实时推送',
        bz: '发货完成，系统自动'
      }, {
        bus: '委托方',
        filed: 'entrustContractSigning',
        con: '合同待签约通知',
        ywcj: '委托合同',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '业务员主动推送'
      }, {
        bus: '委托方',
        filed: 'entrustContractChange',
        con: '合同变动通知',
        ywcj: '委托合同',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '业务员主动推送'
      }, {
        bus: '委托方',
        filed: 'entrustContractSigned',
        con: '合同已签约通知',
        ywcj: '委托合同',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '签约完成，系统自动'
      }, {
        bus: '委托方',
        filed: 'entrustAuctionWarehousing',
        con: '拍品入库通知',
        ywcj: '拍品入库',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '确认入库，系统自动'
      }, {
        bus: '委托方',
        filed: 'entrustAuctionPush',
        con: '上拍通知',
        ywcj: '委托拍品上拍',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '预展上线，系统自动'
      }, {
        bus: '委托方',
        filed: 'entrustAuctionEnd',
        con: '结拍通知',
        ywcj: '委托拍品结拍',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '拍品结拍，系统自动'
      }, {
        bus: '委托方',
        filed: 'entrustContractCheck',
        con: '对账通知',
        ywcj: '委托合同对账',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '财务对账，系统自动'
      }, {
        bus: '委托方',
        filed: 'entrustAuctionRefundConfirm',
        con: '返货确认通知',
        ywcj: '未成交拍品',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '管理员主动推送群发'
      }, {
        bus: '委托方',
        filed: 'entrustAuctionLeaveWarehouse',
        con: '出库通知',
        ywcj: '拍品已出库',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '确认出库，系统自动'
      }, {
        bus: '委托方',
        filed: 'entrustAuctionSettlement',
        con: '结算通知',
        ywcj: '拍品款项已结算',
        tsrq: '委托方',
        tssj: '实时推送',
        bz: '财务打款，系统自动'
      }, {
        bus: '工作人员',
        filed: 'newProductOrderToAdmin',
        con: '新订单提醒',
        ywcj: '商城新订单',
        tsrq: '指定员工',
        tssj: '实时推送',
        bz: ''
      }, {
        bus: '工作人员',
        filed: 'entrustNewTaskToAdmin',
        con: '新任务通知',
        ywcj: '委托方确认返货',
        tsrq: '指定员工',
        tssj: '实时推送',
        bz: ''
      }, {
        bus: '工作人员',
        filed: 'entrustContractSignedToAdmin',
        con: '签约完成通知',
        ywcj: '委托方签约完成',
        tsrq: '业务负责人',
        tssj: '实时推送',
        bz: ''
      }, {
        bus: '工作人员',
        filed: 'entrustAuctionLeaveWarehouseToAdmin',
        con: '出库通知',
        ywcj: '委托拍品已退回',
        tsrq: '业务负责人',
        tssj: '实时推送',
        bz: ''
      }],
      tableOptions: {
        auctionStartNotice: {},
        auctionStartNoticeSyn: {},
        albumEndNotice: {},
        auctionSoonEndNotice: {},
        auctionDealNotice: {},
        auctionBidBeyond: {},
        auctionBidBeyondSyn: {},
        auctionCollectResult: {},
        identificationPay: {},
        identificationResult: {},
        orderPaymentReminder: {},
        orderDelivery: {},
        entrustContractSigning: {},
        entrustContractChange: {},
        entrustContractSigned: {},
        entrustAuctionWarehousing: {},
        entrustAuctionPush: {},
        entrustAuctionEnd: {},
        entrustContractCheck: {},
        entrustAuctionRefundConfirm: {},
        entrustAuctionLeaveWarehouse: {},
        entrustAuctionSettlement: {},
        newProductOrderToAdmin: {},
        entrustNewTaskToAdmin: {},
        entrustContractSignedToAdmin: {},
        entrustAuctionLeaveWarehouseToAdmin: {}
      },
      handOptions: {},
      userList: []
    };
  },
  created: function created() {
    var _this = this;
    this.init();
    (0, _marketing.merchantChannelConfigApi)().then(function (res) {
      // console.log(res);
      _this.handOptions = res.data;
    });
    (0, _setting.adminListApi)().then(function (res) {
      //   console.log(res);
      _this.userList = res.data.list;
    });
  },
  methods: {
    init: function init() {
      var _this2 = this;
      (0, _marketing.pushConfigListApi)().then(function (res) {
        console.log(res.data);
        res.data.forEach(function (item) {
          if (item.push_data.admin_id == 0) item.push_data.admin_id = '';
          _this2.tableOptions[item.push_key] = item.push_data;
        });
        // console.log(this.tableOptions);
      });
    },
    onInputChange: function onInputChange(type) {
      var model = {
        push_key: type,
        push_data: {
          channel: this.tableOptions[type].channel,
          push_time: this.tableOptions[type].push_time
        }
      };
      // console.log(model);
      this.next(model);
    },
    onSelectChange: function onSelectChange(type) {
      var model = {
        push_key: type,
        push_data: {
          channel: this.tableOptions[type].channel,
          admin_id: this.tableOptions[type].admin_id
        }
      };
      // console.log(model);
      this.next(model);
    },
    onChange: function onChange(row, e, type) {
      this.tableOptions[row.filed].channel[type] = e;
      this.$forceUpdate();
      var model = {
        push_key: row.filed,
        push_data: {
          channel: this.tableOptions[row.filed].channel,
          admin_id: this.tableOptions[row.filed].admin_id
        }
      };
      if (this.tableOptions[row.filed].push_time) model.push_data.push_time = this.tableOptions[row.filed].push_time;
      // console.log(model);
      this.next(model);
    },
    next: function next(model) {
      var _this3 = this;
      (0, _marketing.setPushConfigApi)(model).then(function (res) {
        // console.log(res);
        _this3.$message.success("操作成功");
        // this.init()
      });
    },
    spanMethod: function spanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex == 0) {
        if (rowIndex == 0) {
          return {
            rowspan: 8,
            colspan: 1
          };
        } else if (rowIndex < 8) {
          return {
            rowspan: 0,
            colspan: 0
          };
        } else if (rowIndex == 8) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else if (rowIndex == 9) {
          return {
            rowspan: 0,
            colspan: 0
          };
        } else if (rowIndex == 10) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else if (rowIndex < 12) {
          return {
            rowspan: 0,
            colspan: 0
          };
        } else if (rowIndex == 12) {
          return {
            rowspan: 10,
            colspan: 1
          };
        } else if (rowIndex < 22) {
          return {
            rowspan: 0,
            colspan: 0
          };
        } else if (rowIndex == 22) {
          return {
            rowspan: 4,
            colspan: 1
          };
        } else if (rowIndex < 26) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      } else if (columnIndex == 1) {
        if (rowIndex == 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else if (rowIndex == 1) {
          return {
            rowspan: 0,
            colspan: 0
          };
        } else if (rowIndex == 5) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else if (rowIndex == 6) {
          return {
            rowspan: 0,
            colspan: 0
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    }
  }
};