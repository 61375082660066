"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _ChangeMap = _interopRequireDefault(require("@/components/ChangeMap"));
var _index = require("@/utils/index");
var _ticket = require("@/api/ticket");
var _system = require("@/api/system");
var _questionnaire = require("@/api/questionnaire");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default,
    ChangeMap: _ChangeMap.default
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      id: "",
      isAccountPayType: '',
      questionnaireList: [],
      paymentAccountList: [],
      ruleForm: {
        pay_account_id: "",
        name: "",
        is_whether_today: 1,
        ticket_type: 1,
        questionnaire_id: '',
        cover: "",
        times: [],
        address_coord: "",
        address: "",
        is_verified: 1,
        more_verified: 0,
        is_gift: 0,
        message_reminder: "",
        message_introduce: "",
        spec: [],
        is_hide: 0,
        allow_update_time: 0,
        allow_update_time_num: 1
      },
      rules: {
        name: {
          required: true,
          message: "请输入门票名称",
          trigger: "blur"
        },
        ticket_type: {
          required: true,
          message: "请选择门票类型",
          trigger: "blur"
        },
        cover: {
          required: true,
          message: "请上传门票封面",
          trigger: "change"
        },
        times: {
          required: true,
          message: "请选择上下架时间",
          trigger: "change"
        },
        address: {
          required: true,
          message: "请输入地点名称",
          trigger: "blur"
        },
        spec: {
          required: true,
          message: "请填写票种信息",
          trigger: "change"
        },
        allow_update_time_num: {
          required: true,
          message: "请填写可修改预约次数",
          trigger: "change"
        }
      },
      showSetTicketData: false,
      ticketTypeIndex: null,
      ticketData: {
        vip_price: "",
        name: "",
        ticket_type: 1,
        questionnaire_id: '',
        times: [],
        period: [],
        price: "",
        stock: "",
        infinite: false,
        purchase_limit: "",
        use_num: "",
        vip_right: 0,
        vip_right_discount: "",
        vip_right_discount1: "",
        is_show: 1,
        is_period: 0,
        date: []
      },
      showSetTimesData: {
        show: false,
        index: null,
        exclude: [],
        dates: [],
        times: [{
          time_range: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 0, 0)],
          count: ""
        }]
      }
    };
  },
  created: function created() {
    var _this = this;
    this.getPaymentAccountByBusiness();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      (0, _ticket.ticketDetailApi)({
        ticket_id: this.id
      }).then(function (res) {
        var data = res.data;
        data.spec.forEach(function (m) {
          if (!Number(m.purchase_limit)) m.infinite = true;
        });
        _this.ruleForm = {
          pay_account_id: data.product.pay_account_id || "",
          name: data.product.store_name,
          is_whether_today: data.is_whether_today,
          ticket_type: data.ticket_type,
          questionnaire_id: data.questionnaire_id || '',
          cover: data.product.image,
          times: [data.upward_time, data.down_time],
          address_coord: data.address_coord,
          address: data.address,
          is_verified: data.is_verified,
          is_hide: data.is_hide,
          more_verified: data.more_verified,
          is_gift: data.is_gift,
          message_reminder: data.message_reminder,
          message_introduce: data.message_introduce,
          spec: data.spec,
          allow_update_time: data.allow_update_time,
          allow_update_time_num: data.allow_update_time_num
        };
        _this.$refs["editor"].setContent(data.message_reminder || "");
        _this.$refs["editor1"].setContent(data.message_introduce || "");
      });
    }
    this.isAccountPayType = localStorage.getItem('mer_id');
    (0, _questionnaire._gerTitleList)({
      mer_id: this.isAccountPayTyp
    }).then(function (res) {
      _this.questionnaireList = res.data;
    }).catch(function (err) {
      console.log(err);
    });
  },
  methods: {
    backAction: function backAction() {
      this.$router.back();
    },
    getPaymentAccountByBusiness: function getPaymentAccountByBusiness() {
      var _this2 = this;
      (0, _system._getPaymentAccountByBusiness)({
        business_type: "ticket"
      }).then(function (res) {
        _this2.paymentAccountList = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    editTicketTypeAction: function editTicketTypeAction(i) {
      // 编辑票种信息
      if (this.ruleForm.spec && this.ruleForm.spec[i]) {
        var m = JSON.parse(JSON.stringify(this.ruleForm.spec[i]));
        m.times = [m.upward_time, m.down_time]; // 上下架
        m.period = [m.start_time, m.end_time]; // 有效期
        if (Number(m.vip_right) === 2) {
          m.vip_right_discount1 = m.vip_right_discount;
          m.vip_right_discount = "";
        }
        this.ticketData = m;
        this.ticketTypeIndex = i;
        this.showSetTicketData = true;
      }
    },
    delTicketTypeAction: function delTicketTypeAction(i) {
      // 删除票种信息
      if (this.ruleForm.spec && this.ruleForm.spec[i]) {
        this.ruleForm.spec.splice(i, 1);
      }
    },
    saveTicketTypeAction: function saveTicketTypeAction() {
      // 保存票种信息
      var d = JSON.parse(JSON.stringify(this.ticketData));
      if (!d.name) {
        this.$message.error("请输入票种名称");
        return false;
      }
      if (!d.times || !d.times.length) {
        this.$message.error("请选择上下架时间");
        return false;
      }
      if (!d.period || !d.period.length) {
        this.$message.error("请选择票种有效期");
        return false;
      }
      if (!d.stock) {
        this.$message.error("请输入库存");
        return false;
      }
      if (!d.infinite && !d.purchase_limit) {
        this.$message.error("请输入单人限购数量");
        return false;
      }
      if (d.infinite) {
        d.purchase_limit = 0;
      }
      if (!d.use_num) {
        this.$message.error("请输入单张门票可核销次数");
        return false;
      }
      if (d.vip_right === 1 && !d.vip_right_discount) {
        this.$message.error("请输入会员可免费领取数量");
        return false;
      }
      /* if (!Number(d.vip_price) || d.vip_price<0) {
        this.$message.error("会员价格格式错误");
        return false;
      } */
      if (d.vip_right === 2) {
        if (!d.vip_right_discount1) {
          this.$message.error("请输入会员可折扣购买数量");
          return false;
        }
        d.vip_right_discount = d.vip_right_discount1;
      }
      if (d.is_period && !d.date.length) {
        this.$message.error("请设置使用时段");
        return false;
      }
      d.upward_time = d.times[0];
      d.down_time = d.times[1];
      d.start_time = d.period[0];
      d.end_time = d.period[1];
      console.log(d);
      if (this.ticketTypeIndex === null) {
        this.ruleForm.spec.push(d);
      } else {
        this.ruleForm.spec[this.ticketTypeIndex] = d;
      }
      this.showSetTicketData = false;
      this.$refs["ruleForm"].validateField("spec");
    },
    setTimesAction: function setTimesAction() {
      if (!this.showSetTimesData.dates.length) {
        this.$message.error("请先选择日期");
        return false;
      }
      var times = [];
      var dt = [];
      this.showSetTimesData.times.forEach(function (m, i) {
        if (m.time_range[0] < m.time_range[1] && m.count) {
          times.push({
            start_time: (0, _index.parseTime)(m.time_range[0], "{h}:{i}:{s}"),
            end_time: (0, _index.parseTime)(m.time_range[1], "{h}:{i}:{s}"),
            max_num: m.count
          });
          dt.push({
            st: Date.parse(m.time_range[0]),
            et: Date.parse(m.time_range[1])
          });
        }
      });
      if (!times.length) {
        this.$message.error("请设置时间段，以及库存数量");
        return false;
      }
      dt.sort(function (x, y) {
        return x["st"] - y["st"];
      });
      var error = "";
      dt.forEach(function (m, i) {
        if (i > 0) {
          if (m["st"] < dt[i - 1]["et"]) {
            error = "存在重复的时间段，请检查";
          }
        }
      });
      if (error) {
        this.$message.error(error);
        return false;
      }
      if (this.showSetTimesData.index === null) {
        this.ticketData.date.push({
          start_date: (0, _index.parseTime)(this.showSetTimesData.dates[0], "{y}-{m}-{d}"),
          end_date: (0, _index.parseTime)(this.showSetTimesData.dates[1], "{y}-{m}-{d}"),
          time: times,
          exclude: this.showSetTimesData.exclude
        });
      } else {
        this.ticketData.date[this.showSetTimesData.index] = {
          start_date: (0, _index.parseTime)(this.showSetTimesData.dates[0], "{y}-{m}-{d}"),
          end_date: (0, _index.parseTime)(this.showSetTimesData.dates[1], "{y}-{m}-{d}"),
          time: times,
          exclude: this.showSetTimesData.exclude
        };
      }
      this.showSetTimesData = {
        show: false,
        index: null,
        exclude: [],
        dates: [],
        times: [{
          time_range: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 0, 0)],
          count: ""
        }]
      };
    },
    delTimesAction: function delTimesAction(i) {
      if (this.ticketData.date[i]) {
        this.ticketData.date.splice(i, 1);
      }
    },
    editTimesAction: function editTimesAction(i) {
      if (this.ticketData.date[i]) {
        var m = this.ticketData.date[i];
        var sData = new Date(m.start_date.replace(/-/g, ","));
        var eData = new Date(m.end_date.replace(/-/g, ","));
        var time = [];
        m.time.forEach(function (item) {
          var st = item.start_time.split(":");
          var se = item.end_time.split(":");
          var sTime = new Date(2016, 9, 10, st[0], st[1], st[2]);
          var eTime = new Date(2016, 9, 10, se[0], se[1], se[2]);
          time.push({
            time_range: [sTime, eTime],
            count: item.max_num
          });
        });
        this.showSetTimesData = {
          show: true,
          index: i,
          exclude: m.exclude,
          dates: [sData, eData],
          times: time
        };
      }
    },
    addTimesAction: function addTimesAction() {
      this.showSetTimesData = {
        show: true,
        index: null,
        exclude: [],
        dates: [],
        times: [{
          time_range: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 0, 0)],
          count: ""
        }]
      };
    },
    addTicketTypeAction: function addTicketTypeAction() {
      this.ticketData = {
        vip_price: "",
        name: "",
        ticket_type: 1,
        questionnaire_id: '',
        times: [],
        period: [],
        price: "",
        stock: "",
        infinite: false,
        purchase_limit: "",
        use_num: "",
        vip_right: 0,
        vip_right_discount: "",
        vip_right_discount1: "",
        is_show: 1,
        is_period: 0,
        date: []
      };
      this.ticketTypeIndex = null;
      this.showSetTicketData = true;
    },
    clearSetTicketTypeAction: function clearSetTicketTypeAction() {
      this.showSetTicketData = false;
      this.ticketData = {
        vip_price: "",
        name: "",
        ticket_type: 1,
        questionnaire_id: '',
        times: [],
        period: [],
        price: "",
        stock: "",
        infinite: false,
        purchase_limit: "",
        use_num: "",
        vip_right: 0,
        vip_right_discount: "",
        vip_right_discount1: "",
        is_show: 1,
        is_period: 0,
        date: []
      };
    },
    addTimeAction: function addTimeAction() {
      this.showSetTimesData.times.push({
        time_range: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 0, 0)],
        count: ""
      });
    },
    removeTimeAction: function removeTimeAction(i) {
      this.showSetTimesData.times.splice(i, 1);
    },
    changeImg: function changeImg() {
      var _this3 = this;
      this.$modalUpload(function (img) {
        _this3.ruleForm.cover = img[0];
        _this3.$refs["ruleForm"].validateField("cover");
      }, 1, "1");
    },
    changeVerifiedAction: function changeVerifiedAction(e) {
      if (e && this.ruleForm.is_gift) {
        this.ruleForm.is_gift = 0;
      }
      if (!e && this.ruleForm.more_verified) {
        this.ruleForm.more_verified = 0;
      }
    },
    saveAction: function saveAction(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.ruleForm));
          data.upward_time = data.times[0];
          data.down_time = data.times[1];
          if (data.allow_update_time && (!data.allow_update_time_num || isNaN(data.allow_update_time_num))) {
            _this4.$message.error("请输入正确的可修改预约次数");
            return false;
          }
          if (!data.allow_update_time) {
            data.allow_update_time_num = 0;
          }
          var loading = _this4.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          var action = _ticket.ticketCreateApi;
          if (_this4.id) {
            action = _ticket.ticketEditApi;
            data.ticket_id = _this4.id;
          }
          action(data).then(function () {
            loading.close();
            _this4.$message.success("操作成功");
            _this4.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        }
      });
    }
  }
};