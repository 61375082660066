var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "600px", "margin-right": "-20px" } },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "600px", "padding-right": "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-bottom": "20px" },
              attrs: {
                "label-width": "100px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  { staticClass: "w80 ft18 fWeight500 mr10 color-black" },
                  [_vm._v("整体设置")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex-one flex align-items-c" }),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background", $$v)
                      },
                      expression: "facade.background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景渐变" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.gradientBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "gradientBackground", $$v)
                      },
                      expression: "facade.gradientBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "渐变方向" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.gradientDirection,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "gradientDirection", $$v)
                        },
                        expression: "facade.gradientDirection",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "to bottom" } }, [
                        _vm._v("上下"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right" } }, [
                        _vm._v("左右"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right bottom" } }, [
                        _vm._v("左上右下"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt10" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("背景图"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(1, "backgroundImage")
                      },
                    },
                  },
                  [
                    _vm.facade.backgroundImage
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delFacadeImg("backgroundImage")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.backgroundImage
                      ? _c("img", {
                          attrs: { src: _vm.facade.backgroundImage + "!300" },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "整体圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.topLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "topLeftRadius", $$v)
                            },
                            expression: "facade.topLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.topRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "topRightRadius", $$v)
                            },
                            expression: "facade.topRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.bottomLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "bottomLeftRadius", $$v)
                            },
                            expression: "facade.bottomLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.bottomRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "bottomRightRadius", $$v)
                            },
                            expression: "facade.bottomRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "状态圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.statusTopLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "statusTopLeftRadius", $$v)
                            },
                            expression: "facade.statusTopLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.statusTopRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "statusTopRightRadius", $$v)
                            },
                            expression: "facade.statusTopRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.statusBottomLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade,
                                "statusBottomLeftRadius",
                                $$v
                              )
                            },
                            expression: "facade.statusBottomLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.statusBottomRightRadius,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade,
                                "statusBottomRightRadius",
                                $$v
                              )
                            },
                            expression: "facade.statusBottomRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.textColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "textColor", $$v)
                      },
                      expression: "facade.textColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "高亮文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.specialColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "specialColor", $$v)
                      },
                      expression: "facade.specialColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c color-black mt20" },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("预展中"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one flex align-items-c" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.preColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preColor", $$v)
                      },
                      expression: "facade.preColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.preBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preBackground", $$v)
                      },
                      expression: "facade.preBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景渐变" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.preGradientBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "preGradientBackground", $$v)
                      },
                      expression: "facade.preGradientBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "渐变方向" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.preGradientDirection,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "preGradientDirection", $$v)
                        },
                        expression: "facade.preGradientDirection",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "to bottom" } }, [
                        _vm._v("上下"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right" } }, [
                        _vm._v("左右"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right bottom" } }, [
                        _vm._v("左上右下"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt10" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("背景图"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(1, "preBackgroundImage")
                      },
                    },
                  },
                  [
                    _vm.facade.preBackgroundImage
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delFacadeImg("preBackgroundImage")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.preBackgroundImage
                      ? _c("img", {
                          attrs: {
                            src: _vm.facade.preBackgroundImage + "!300",
                          },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c color-black mt20" },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("竞拍中"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one flex align-items-c" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.ingColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "ingColor", $$v)
                      },
                      expression: "facade.ingColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.ingBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "ingBackground", $$v)
                      },
                      expression: "facade.ingBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景渐变" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.ingGradientBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "ingGradientBackground", $$v)
                      },
                      expression: "facade.ingGradientBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "渐变方向" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.ingGradientDirection,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "ingGradientDirection", $$v)
                        },
                        expression: "facade.ingGradientDirection",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "to bottom" } }, [
                        _vm._v("上下"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right" } }, [
                        _vm._v("左右"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right bottom" } }, [
                        _vm._v("左上右下"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt10" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("背景图"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(1, "ingBackgroundImage")
                      },
                    },
                  },
                  [
                    _vm.facade.ingBackgroundImage
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delFacadeImg("ingBackgroundImage")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.ingBackgroundImage
                      ? _c("img", {
                          attrs: {
                            src: _vm.facade.ingBackgroundImage + "!300",
                          },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c color-black mt20" },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("已结拍"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one flex align-items-c" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.endColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "endColor", $$v)
                      },
                      expression: "facade.endColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.endBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "endBackground", $$v)
                      },
                      expression: "facade.endBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景渐变" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.endGradientBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "endGradientBackground", $$v)
                      },
                      expression: "facade.endGradientBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "渐变方向" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.endGradientDirection,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "endGradientDirection", $$v)
                        },
                        expression: "facade.endGradientDirection",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "to bottom" } }, [
                        _vm._v("上下"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right" } }, [
                        _vm._v("左右"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right bottom" } }, [
                        _vm._v("左上右下"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt10" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("背景图"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(1, "endBackgroundImage")
                      },
                    },
                  },
                  [
                    _vm.facade.endBackgroundImage
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delFacadeImg("endBackgroundImage")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.endBackgroundImage
                      ? _c("img", {
                          attrs: {
                            src: _vm.facade.endBackgroundImage + "!300",
                          },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }