"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
var _warehouse = require("@/api/warehouse");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tab: this.$route.query.tab || '1',
      tab_new: this.$route.query.tab || '1',
      proofType: '1',
      listLoading: true,
      all: [{
        tab: '1',
        proofType: '1',
        type: 'erp_v2_enter_warehouse_create',
        list: []
      }, {
        tab: '1',
        proofType: '2',
        type: 'erp_v2_enter_warehouse_list',
        list: []
      }, {
        tab: '1',
        proofType: '3',
        type: 'erp_v2_enter_warehouse_print',
        list: []
      }, {
        tab: '2',
        proofType: '1',
        type: 'erp_v2_out_warehouse_create',
        list: []
      }, {
        tab: '2',
        proofType: '2',
        type: 'erp_v2_out_warehouse_list',
        list: []
      }, {
        tab: '2',
        proofType: '3',
        type: 'erp_v2_out_warehouse_print',
        list: []
      }, {
        tab: '3',
        proofType: '1',
        type: 'erp_v2_transfers_warehouse_create',
        list: []
      }, {
        tab: '3',
        proofType: '2',
        type: 'erp_v2_transfers_warehouse_list',
        list: []
      }, {
        tab: '3',
        proofType: '3',
        type: 'erp_v2_transfers_warehouse_print',
        list: []
      }],
      setPrintColumns: {}
    };
  },
  computed: {
    typeName: function typeName() {
      var txt = '';
      switch (this.tab) {
        case '1':
          txt = '入库单';
          break;
        case '2':
          txt = '出库单';
          break;
        case '3':
          txt = '调拨单';
          break;
      }
      return txt;
    },
    currType: function currType() {
      var _this = this;
      return this.all.find(function (m) {
        return m.tab === _this.tab && m.proofType === _this.proofType;
      }).type;
    },
    list: function list() {
      var _this2 = this;
      return this.all.find(function (m) {
        return m.tab === _this2.tab && m.proofType === _this2.proofType;
      }).list;
    }
  },
  created: function created() {
    var _this3 = this;
    this.getList('all');
    (0, _system.getConfigClassKeys)('setPrintColumns').then(function (res) {
      var d = res.data;
      if (!d.salesOutboundZH) d.salesOutboundZH = '您好！感谢您对我公司的大力支持，现将如下拍品交付于阁下，希望您能一如既往地支持我公司，谢谢！';
      if (!d.salesOutboundEN) d.salesOutboundEN = 'Thanks for your support to our company, deliver the following items to you, we beg your understanding and also hope you can support us as usual!';
      if (!d.salesOutboundStatementZH) d.salesOutboundStatementZH = '* 本人已验收全部拍品，确认无误并毫无损伤，本人签字表示同意。';
      if (!d.salesOutboundStatementEN) d.salesOutboundStatementEN = 'I confirm that all the items mentioned above without any damage after acceptance inspection, and I sign and agree to take delivery of the items.';
      if (!d.returnOutboundZH) d.returnOutboundZH = '您好！感谢您对我公司的大力支持，现将如下拍品退还于阁下，希望您能一如既往地支持我公司，谢谢！';
      if (!d.returnOutboundEN) d.returnOutboundEN = 'Thanks for your support to our company, the following items would be returned to you, we beg your understanding and also hope you can support us as usual!';
      if (!d.returnOutboundStatementZH) d.returnOutboundStatementZH = '* 本人已验收全部拍品，确认无误并毫无损伤，且已退还，本人签字表示同意。';
      if (!d.returnOutboundStatementEN) d.returnOutboundStatementEN = 'I confirm that all the items mentioned above without any damage after acceptance inspection, and I sign and agree to take delivery of the items.';
      _this3.setPrintColumns = d;
    }).catch(function () {});
  },
  methods: {
    getList: function getList(type) {
      var _this4 = this;
      var data = {
        types: type
      };
      if (type === 'all') {
        data.types = this.all.map(function (m) {
          return m.type;
        }).join(',');
      }
      (0, _warehouse.getFieldSetAPI)(data).then(function (res) {
        var types = _this4.all.map(function (m) {
          return m.type;
        });
        for (var key in res.data) {
          var index = types.indexOf(key);
          if (index !== -1) {
            _this4.$set(_this4.all[index], 'list', res.data[key].show_list.map(function (m) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
                checked: true
              });
            }).concat(res.data[key].hide_list.map(function (m) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
                checked: false
              });
            })));
          }
        }
        _this4.listLoading = false;
      });
    },
    changeTab: function changeTab(val) {
      var _this5 = this;
      if (val == 4) {
        this.$router.replace({
          path: '/merchant/warehouse/warehouseList'
        });
        var t = this.tab_new;
        this.tab_new = val;
        setTimeout(function () {
          _this5.tab_new = t;
        });
        return;
      } else {
        this.proofType = '1';
        this.tab = val;
        this.tab_new = val;
      }
    },
    saveAction: function saveAction() {
      var _this6 = this;
      var types = this.currType;
      var show_data = [];
      var hide_data = [];
      this.list.forEach(function (m) {
        if (m.checked) {
          show_data.push(m);
        } else {
          hide_data.push(m);
        }
      });
      (0, _system.setCustomHeader)({
        types: types,
        show_data: show_data,
        hide_data: hide_data
      }).then(function () {
        _this6.$message.success('设置成功');
      });
      if (this.tab == '2' && this.proofType == '3') {
        (0, _system.configClassKeysSave)('setPrintColumns', this.setPrintColumns).then(function (res) {}).catch(function () {});
      }
    },
    focus: function focus(e) {
      e.currentTarget.select();
    }
  }
};