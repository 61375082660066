var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "拍卖管理" },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#3480F4 !important",
                      "margin-right": "20px",
                    },
                    attrs: { type: "text", icon: "el-icon-picture-outline" },
                    on: {
                      click: function ($event) {
                        return _vm.onGOUrl("/merchant/systemForm/picture", 1)
                      },
                    },
                  },
                  [_vm._v("素材库")]
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { color: "#3480F4", cursor: "pointer" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                            alt: "",
                          },
                        }),
                        _vm._v("\n          配置\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/systemForm/systemAuction/numberPlatevip?isNo=true",
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("VIP号牌")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "setting_systemAuctionRule"
                                  )
                                },
                              },
                            },
                            [_vm._v("拍卖规则")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "setting_systemAuctionRange"
                                  )
                                },
                              },
                            },
                            [_vm._v("竞价阶梯")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOOpenUrl(
                                    "/merchant/set_screen_projection"
                                  )
                                },
                              },
                            },
                            [_vm._v("现场投屏")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOOpenUrl(
                                    "/merchant/set_led_setting"
                                  )
                                },
                              },
                            },
                            [_vm._v("LED投屏")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/systemForm/systemAuction/numberPlatets?isNo=false",
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("号牌库")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/basic/goodsConfig/classify",
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("分类管理")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/basic/goodsConfig/attrList",
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("属性管理")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-card", { staticClass: "meetCard" }, [
        _c(
          "div",
          { staticClass: "top-view", staticStyle: { padding: "0 20px" } },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo default-tabs",
                attrs: { "default-active": _vm.routerUrl, mode: "horizontal" },
                on: { select: _vm.changeTab },
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("拍卖会"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "3" } }, [
                  _vm._v("拍卖记录"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "porBox" }, [
          _c(
            "div",
            { staticClass: "absoBox", staticStyle: { top: "-45px" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantAuctionVenueCreate",
                      expression: "'merchantAuctionVenueCreate'",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.createdMeeting },
                },
                [_vm._v("创建拍卖会")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            height: "calc(100vh - 170px)",
            padding: "10px 24px 0",
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.routerUrl == 1,
                  expression: "routerUrl == 1",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              attrs: {
                border: "",
                height: "100%",
                data: _vm.resultData,
                "row-key": "venue_id",
                "expand-row-keys": _vm.rowkeys,
              },
              on: {
                "cell-click": _vm.cellClick,
                "expand-change": _vm.onExpand,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                key: "van111",
                attrs: { type: "expand", width: "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        !props.row.isSearch
                          ? _c("div", { staticClass: "meeting-list" }, [
                              _c("div", { staticClass: "meeting" }, [
                                _c("div", { staticClass: "item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "meeting-item meetOne",
                                          staticStyle: {
                                            background: "#f4faff",
                                            border:
                                              "1px solid rgba(70, 166, 255, .5)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goLinkAction(
                                                "/merchant/pageManagement/auction/meetingData/album",
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-t item-flex-center",
                                            },
                                            [_c("div", [_vm._v("专场")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.row.albumTypeCount
                                                      .album_count
                                                  ) +
                                                  "个专场 "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "meeting-item meetOne",
                                          staticStyle: {
                                            background: "#f4faff",
                                            border:
                                              "1px solid rgba(70, 166, 255, .5)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goLinkAction(
                                                "/merchant/pageManagement/auction/meetingData/auction",
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-t item-flex-center",
                                            },
                                            [_c("div", [_vm._v("拍品")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.row.albumTypeCount
                                                      .auction_count
                                                  ) +
                                                  "件拍品 "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "meeting-item meetTwo",
                                          staticStyle: {
                                            background: "rgb(250 246 255)",
                                            border:
                                              "1px solid rgba(165, 73, 255, .5)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goLinkAction(
                                                "/merchant/pageManagement/auction/meetingData/numberPlate",
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-t item-flex-center",
                                            },
                                            [_c("div", [_vm._v("号牌")])]
                                          ),
                                          _vm._v(" "),
                                          props.row.NumberPlateCount
                                            ? _c(
                                                "div",
                                                { staticClass: "item-center" },
                                                [
                                                  _vm._v(
                                                    " 普通" +
                                                      _vm._s(
                                                        props.row
                                                          .NumberPlateCount
                                                          .online_count
                                                      ) +
                                                      "个 "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          props.row.NumberPlateCount
                                            ? _c(
                                                "div",
                                                { staticClass: "item-center" },
                                                [
                                                  _vm._v(
                                                    " 免保" +
                                                      _vm._s(
                                                        props.row
                                                          .NumberPlateCount
                                                          .exempt_bond_count
                                                      ) +
                                                      "个 "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "meeting-item meetTwo",
                                          staticStyle: {
                                            background: "rgb(250 246 255)",
                                            border:
                                              "1px solid rgba(165, 73, 255, .5)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goLinkAction(
                                                "/merchant/pageManagement/auction/meetingData/realTime",
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-t item-flex-center",
                                            },
                                            [_c("div", [_vm._v("场控")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [_vm._v(" 实时监测 ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [_vm._v(" 精准操盘 ")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "meeting-item meetTwo",
                                          staticStyle: {
                                            background: "rgb(250 246 255)",
                                            border:
                                              "1px solid rgba(165, 73, 255, .5)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goLinkAction(
                                                "/merchant/pageManagement/auction/meetingData/entrust",
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-t item-flex-center",
                                            },
                                            [_c("div", [_vm._v("委托")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [
                                              _vm._v(
                                                " 电话委托：" +
                                                  _vm._s(
                                                    props.row.phoneEntrustNum
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [
                                              _vm._v(
                                                " 代理出价：" +
                                                  _vm._s(
                                                    props.row.proxy_bid_num
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantSystemExcelExport:albumAuctionRecords",
                                              expression:
                                                "'merchantSystemExcelExport:albumAuctionRecords'",
                                            },
                                          ],
                                          staticClass: "meeting-item meetTwo",
                                          staticStyle: {
                                            background: "rgb(250 246 255)",
                                            border:
                                              "1px solid rgba(165, 73, 255, .5)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goLinkAction(
                                                "/merchant/pageManagement/auction/antique",
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-t item-flex-center",
                                            },
                                            [_c("div", [_vm._v("文物局报表")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [_vm._v(" 一键导出表格 ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [_vm._v(" 支持全国格式 ")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      props.row.albumTypeCount.live_count &&
                                      props.row.albumTypeCount.live_count > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "meeting-item meetThe",
                                              staticStyle: {
                                                background: "rgb(255 253 248)",
                                                border:
                                                  "1px solid rgba(255, 186, 0, .5)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goLinkAction(
                                                    "/merchant/pageManagement/auction/meetingData/Live",
                                                    props.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "item-t item-flex-center",
                                                },
                                                [_c("div", [_vm._v("直播")])]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "item-center" },
                                                [_vm._v(" 推流设置 ")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "item-center" },
                                                [_vm._v(" 出价控制台 ")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      props.row.albumTypeCount["2_count"] +
                                        props.row.albumTypeCount["3_count"] >
                                      0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "meeting-item meetThe",
                                              staticStyle: {
                                                background: "rgb(255 253 248)",
                                                border:
                                                  "1px solid rgba(255, 186, 0, .5)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goLinkAction(
                                                    "/merchant/pageManagement/auction/meetingData/LiveOne",
                                                    props.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "item-t item-flex-center",
                                                },
                                                [_c("div", [_vm._v("投屏")])]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "item-center" },
                                                [_vm._v(" 现场大屏 ")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "item-center" },
                                                [_vm._v(" 拍卖师屏 ")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "meeting-item meetFour",
                                          staticStyle: {
                                            background: "#fbfffd",
                                            border:
                                              "1px solid rgba(17, 185, 92, .5)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goLinkAction(
                                                "/merchant/order/list/auctionMeeting",
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-t item-flex-center",
                                            },
                                            [_c("div", [_vm._v("订单")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [
                                              _vm._v(
                                                "\n                      待处理 " +
                                                  _vm._s(
                                                    props.row.OrderHandleCount
                                                      .order_wait_handle_count
                                                  ) +
                                                  "个\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [
                                              _vm._v(
                                                "\n                      已完成 " +
                                                  _vm._s(
                                                    props.row.OrderHandleCount
                                                      .order_complete_count
                                                  ) +
                                                  "个\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "meeting-item meetFour",
                                          staticStyle: {
                                            background: "#fbfffd",
                                            border:
                                              "1px solid rgba(17, 185, 92, .5)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goLinkAction(
                                                "/merchant/pageManagement/auction/meetingData/data",
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-t item-flex-center",
                                            },
                                            [_c("div", [_vm._v("统计")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [_vm._v(" 客户分析 ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "item-center" },
                                            [_vm._v(" 运营分析 ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "van1",
                attrs: {
                  label: "拍卖状态",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.time_status == 1 || row.time_status == 0
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("进行中"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.time_status == 2
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("预展中")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.time_status == 3
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "info" } },
                              [_vm._v("已结束")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "van2",
                attrs: {
                  label: "拍卖会名称",
                  "min-width": "140",
                  prop: "",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            "ID：" +
                              _vm._s(row.venue_id) +
                              " " +
                              _vm._s(row.title)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "van3",
                attrs: {
                  label: "专场信息",
                  "min-width": "140",
                  prop: "",
                  "search-key": "album_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.albumTypeCount && _vm.routerUrl == 1
                          ? [
                              scope.row.albumTypeCount["1_count"] != 0
                                ? _c("span", [
                                    _vm._v(
                                      " 限时拍*" +
                                        _vm._s(
                                          scope.row.albumTypeCount["1_count"]
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.albumTypeCount["2_count"] != 0
                                ? _c("span", [
                                    _vm._v(
                                      " 即时拍*" +
                                        _vm._s(
                                          scope.row.albumTypeCount["2_count"]
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.albumTypeCount["3_count"] != 0
                                ? _c("span", [
                                    _vm._v(
                                      " 同步拍*" +
                                        _vm._s(
                                          scope.row.albumTypeCount["3_count"]
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.albumTypeCount["4_count"] != 0
                                ? _c("span", [
                                    _vm._v(
                                      " 即刻拍*" +
                                        _vm._s(
                                          scope.row.albumTypeCount["4_count"]
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "van4",
                attrs: {
                  label: "预展时间",
                  width: "250",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isSearch
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    scope.row.preview_start_time * 1000,
                                    "{y}年{m}月{d}日"
                                  )
                                ) +
                                  " 至 " +
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.preview_end_time * 1000,
                                      _vm.formatDate(
                                        scope.row.preview_start_time * 1000,
                                        "{m}"
                                      ) ==
                                        _vm.formatDate(
                                          scope.row.preview_end_time * 1000,
                                          "{m}"
                                        )
                                        ? "{d}日"
                                        : "{m}月{d}日"
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "van5",
                attrs: {
                  label: "拍卖时间",
                  width: "220",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isSearch
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    scope.row.start_time * 1000,
                                    "{y}年{m}月{d}日 {h}:{i}"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "van6",
                attrs: {
                  label: "币种",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isSearch
                          ? _c(
                              "span",
                              [
                                scope.row.fee_type === "CNY"
                                  ? [_vm._v("人民币")]
                                  : scope.row.fee_type === "HKD"
                                  ? [_vm._v("港币")]
                                  : scope.row.fee_type === "JPY"
                                  ? [_vm._v("日元")]
                                  : scope.row.fee_type === "TWD"
                                  ? [_vm._v("台币")]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "van7",
                attrs: {
                  label: "地点",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.address_name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "van8",
                attrs: {
                  label: "",
                  width: "36",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isSearch
                          ? [
                              _c(
                                "TableRightBtn",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: "merchantAuctionVenueUpdate",
                                          expression:
                                            "'merchantAuctionVenueUpdate'",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editMeeting(
                                            scope.row.venue_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "icon-class": "commonedit",
                                          "class-name": "blue",
                                        },
                                      }),
                                      _vm._v("编辑"),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "template",
                                    { slot: "pop_btn" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: "merchantAuctionVenueDel",
                                              expression:
                                                "'merchantAuctionVenueDel'",
                                            },
                                          ],
                                          staticStyle: { color: "red" },
                                          attrs: {
                                            icon: "el-icon-delete",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delMeeting(
                                                scope.row.venue_id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ]
                          : _c(
                              "span",
                              {
                                staticClass: "handSpan",
                                on: { click: _vm.clearsearch },
                              },
                              [_vm._v("清除")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.routerUrl == 3,
                  expression: "routerUrl == 3",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "auctionRef",
              staticClass: "aucTab",
              attrs: {
                data: _vm.acuResultData,
                "element-loading-text": "Loading",
                height: "100%",
                fit: "",
                border: "",
                "highlight-current-row": "",
                "row-key": "product_id",
              },
              on: { "cell-click": _vm.auctionCellClick },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc1",
                attrs: {
                  label: "拍卖会名称",
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.venue
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(_vm._s(row.venue.title)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc2",
                attrs: {
                  label: "状态",
                  width: "100",
                  prop: "",
                  "search-key": "status",
                  "search-type": "radio",
                  options: [
                    { label: "全部", value: "" },
                    { label: "进行中", value: "2" },
                    { label: "预展中", value: "1" },
                    { label: "已结束", value: "4" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auction_status === -2
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("已撤拍"),
                            ])
                          : scope.row.time_status === 1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("\n            预展中")]
                            )
                          : scope.row.time_status === 2
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("进行中"),
                            ])
                          : scope.row.time_status === 3 ||
                            scope.row.time_status === 4
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "info" } },
                              [_vm._v("已结束")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc3",
                attrs: {
                  label: "LOT",
                  width: "80",
                  prop: "",
                  "search-key": "lot",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.lot) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc7",
                attrs: {
                  label: "拍品封面",
                  width: "80",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product
                          ? _c("img", {
                              staticStyle: {
                                width: "25px",
                                "margin-top": "5px",
                                cursor: "pointer",
                              },
                              attrs: {
                                src:
                                  scope.row.product.image.split(",")[0] +
                                  "!120a",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showImageAction(
                                    scope.row.product.image.split(",")
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc4",
                attrs: {
                  label: "拍品名称",
                  "min-width": "150",
                  prop: "",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          scope.row.product
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row.product.store_name)),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc5",
                attrs: {
                  label: "拍品ID",
                  width: "100",
                  prop: "",
                  "search-key": "product_id",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product_id
                          ? _c("span", [_vm._v(_vm._s(scope.row.product_id))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc6",
                attrs: {
                  label: "成交状态",
                  width: "100",
                  "search-key": "auction_status",
                  "search-type": "radio",
                  options: [
                    { label: "交割失败", value: "-1" },
                    { label: "保留价待确认", value: "3" },
                    { label: "流拍", value: "4" },
                    { label: "成交", value: "5" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auction_status == -1
                          ? [_vm._v("\n            交割失败\n          ")]
                          : scope.row.auction_status == 3
                          ? [_vm._v("\n            保留价待确认\n          ")]
                          : scope.row.auction_status == 4
                          ? [_vm._v("\n            流拍\n          ")]
                          : scope.row.auction_status > 4
                          ? [_vm._v("成交\n          ")]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc8",
                attrs: {
                  label: "所属专场",
                  "min-width": "200",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "album_id",
                  "search-type": "radio",
                  selectFilter: "",
                  options: _vm.albumlistOption,
                  "search-query": _vm.searchQuery,
                },
                on: {
                  changeVal: _vm.searchAction,
                  selectFilterChange: _vm.onSelectFilterChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.album
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.album.album_name) +
                                  "\n          "
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc9",
                attrs: {
                  label: "开拍时间",
                  width: "150",
                  prop: "",
                  "search-key": "start_time_date",
                  "search-type": "date",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.start_time
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.start_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc10",
                attrs: {
                  label: "结拍时间",
                  width: "150",
                  prop: "",
                  "search-key": "end_time_date",
                  "search-type": "date",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.end_time
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.end_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品价格",
                  width: "120",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v("起拍价: " + _vm._s(scope.row.start_price)),
                        ]),
                        _vm._v(" "),
                        scope.row.reference_price &&
                        scope.row.reference_price != 0
                          ? _c("div", [
                              _vm._v(
                                "参考价: " + _vm._s(scope.row.start_price)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.retain_price && scope.row.retain_price != 0
                          ? _c("div", [
                              _vm._v(
                                "保留价: " + _vm._s(scope.row.retain_price)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.top_price && scope.row.top_price != 0
                          ? _c("div", [
                              _vm._v("一口价: " + _vm._s(scope.row.top_price)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc24",
                attrs: {
                  label: "落槌价",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auction_status == 3
                          ? _c("div", [_c("div", [_vm._v("保留价待确认")])])
                          : scope.row.auction_status == 4
                          ? _c("div", [_c("div", [_vm._v("流拍 可洽购")])])
                          : scope.row.auction_status == 5 ||
                            scope.row.auction_status == 6
                          ? _c("div", [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(scope.row.now_price)
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc15",
                attrs: {
                  label: "拍品保证金",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.bond_price) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc16",
                attrs: {
                  permission: "merchantAuctionList",
                  "permission-column": "participationInfo",
                  label: "围观",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "has-click",
                            on: {
                              click: function ($event) {
                                return _vm.showAlbumVisitDataAction(
                                  scope.row.album_id,
                                  scope.row.click_uv,
                                  scope.row.product.browse,
                                  scope.row.product_id
                                )
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(scope.row.click_uv))]),
                            _vm._v("人 "),
                            scope.row.produc
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.product.browse)),
                                ])
                              : _vm._e(),
                            _vm._v("次\n          "),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "auc17",
                attrs: {
                  permission: "merchantAuctionList",
                  "permission-column": "participationInfo",
                  label: "出价",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "has-click",
                            on: {
                              click: function ($event) {
                                return _vm.showAuctionBidRecordAction(
                                  scope.row.product_id
                                )
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(scope.row.bid_num_uv))]),
                            _vm._v("人\n          "),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }