var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "选择模板", "has-back": true, "is-fixed": true },
      }),
      _vm._v(" "),
      _c("el-card", [
        _c("div", { staticClass: "porBox" }, [
          _c(
            "div",
            { staticClass: "absoBox", staticStyle: { top: "-50px" } },
            [
              _c("el-input", {
                staticClass: "w300",
                attrs: { placeholder: "搜索作品" },
                on: {
                  blur: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.make_title,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "make_title", $$v)
                  },
                  expression: "tableFrom.make_title",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticClass: "mt20 logBox",
            staticStyle: { height: "calc(100vh - 170px)" },
          },
          [
            _vm.videoLogList.length > 0
              ? _c(
                  "div",
                  { staticClass: "logListBox" },
                  _vm._l(_vm.videoLogList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "logListItem",
                        on: {
                          click: function ($event) {
                            return _vm.changeselect(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "logListVideo" }, [
                          _c("div", { staticClass: "videoTag" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.makeTagJson[item.make_tag])),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "vidPlayDia",
                              class: {
                                selected: _vm.selectid == item.template_id,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "prieview-button",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onClickView(item)
                                    },
                                  },
                                },
                                [_vm._v("预览")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "logItemImg" }, [
                            _c("img", {
                              staticClass: "logItemImg",
                              attrs: { src: item.image + "!m640", alt: "" },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex justify-b",
                            staticStyle: { "padding-top": "10px" },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.title) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : !_vm.listLoading
              ? _c(
                  "div",
                  {
                    staticClass: "tableEmpty",
                    staticStyle: {
                      height: "100%",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex justify-b orderFoot" },
          [
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _vm.type == "manage"
                  ? [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "4px" },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.createModel },
                        },
                        [_vm._v("创建模板")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "4px" },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.editModel },
                        },
                        [_vm._v("编辑模板")]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("el-pagination", {
              attrs: {
                background: "",
                "page-sizes": [20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, prev, pager, next, sizes",
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: " ",
            width: "430px",
            top: "5vh",
            "custom-class": "dialog-hide-bg",
            "show-close": false,
            "close-on-click-modal": false,
            "before-close": _vm.closeAction,
          },
        },
        [
          _vm.visible
            ? _c("preview", {
                attrs: {
                  configuration: _vm.configuration,
                  pages: _vm.previewData,
                  temid: _vm.previewDetail.template_id,
                  marketid: _vm.$route.query.marketid,
                  title: _vm.previewDetail.title,
                  "page-info": _vm.previewDetail.config_data,
                  price: _vm.previewDetail.template_price,
                  type: "outer",
                },
                on: {
                  closeAction: _vm.closeAction,
                  edit: _vm.editAction,
                  setColor: function (i) {
                    return _vm.setThemeAction(1, i)
                  },
                  setConfiguration: function (i) {
                    return _vm.setConfiguration(1, i)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: {
          "has-more-data": "1",
          "all-image": "true",
          query: { venue_id: _vm.venue_id, album_id: _vm.album_id },
          "current-data": [],
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }