"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.typed.uint8-array");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.string.link");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _guide = _interopRequireDefault(require("@/components/guide/guide"));
var _pagetitle = _interopRequireDefault(require("@/components/pagetitle/pagetitle"));
var _attribute = _interopRequireDefault(require("@/views/decoration/modules/minePage/attribute"));
var _attribute2 = _interopRequireDefault(require("@/views/decoration/modules/package/attribute"));
var _attribute3 = _interopRequireDefault(require("@/views/decoration/modules/footer/attribute"));
var _panels_mine = _interopRequireDefault(require("../../libs/panels_mine.js"));
var _modules = _interopRequireDefault(require("@/views/decoration/modules"));
var _system = require("@/api/system");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)('setting'),
  mapGetters = _createNamespacedHelp.mapGetters;
var _default = exports.default = {
  name: 'CustomPage',
  components: {
    pageAttribute: _attribute.default,
    titleAttribute: _attribute2.default,
    guide: _guide.default,
    pagetitle: _pagetitle.default,
    footerAttribute: _attribute3.default
  },
  data: function data() {
    return {
      panels: _panels_mine.default,
      modules: _modules.default,
      is_totop: false,
      fullHeight: document.documentElement.clientHeight,
      qrcode: {
        wechat: {}
      },
      avatar: '',
      headerHover: false,
      footerHover: false,
      isEdit: false,
      showImg: false,
      showImgType: 1,
      showImgUrl: ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, mapGetters({
    getStore: 'getStore',
    version: 'getVersion'
  })), {}, {
    scrollHeight: function scrollHeight() {
      return {
        height: parseInt(this.fullHeight) - 65 + 'px'
      };
    },
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    index: function index() {
      return this.$store.state.finish.index;
    },
    attribute: function attribute() {
      return this.$store.getters.attribute;
    },
    is_attribute: function is_attribute() {
      return this.$store.state.finish.pages.length > 0;
    },
    is_lock: function is_lock() {
      return this.$store.state.finish.is_lock;
    },
    footerBackground: function footerBackground() {
      if (this.pageInfo.hide_footer) {
        return 'none';
      } else {
        return this.pageInfo.footerSetting.footer_background;
      }
    }
  }),
  created: function created() {
    this.avatar = localStorage.getItem('avatar');
  },
  methods: {
    handleTplOpen: function handleTplOpen() {
      var _this2 = this;
      return new Promise(function (resolve) {
        _this2.$confirm('选择新模板后，当前页面内容将被替换且不被保存， 请确认是否需要手动保存。', '提示', {
          confirmButtonText: '继续选择模板',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(function () {
          resolve(true);
        }).catch(function () {
          resolve(false);
        });
      });
    },
    /**
     * 处理返回顶部
     * @return {[type]} [description]
     */
    handleTotop: function handleTotop() {
      this.scrollbar.$el.firstChild.scrollTop = 0;
    },
    /**
     * 处理返回顶部
     * @return {[type]} [description]
     */
    handleGuide: function handleGuide() {
      window.localStorage.setItem('is_guide', 0);
      this.$refs.guide.is_close = false;
      this.$refs.guide.onSetPage();
    },
    /**
     * 处理名称
     * @param  {[type]} value [description]
     * @return {[type]}       [description]
     */
    getPageTitle: function getPageTitle(value) {
      if (value && Number(value.length) === 13) {
        return '默认微页面' + this.pageInfo.id;
      } else {
        return value || '默认微页面';
      }
    },
    /**
     * [handlePageName description]
     * @return {[type]} [description]
     */
    handlePageName: function handlePageName() {
      this.$store.commit('finish/setIndex', -2);
    },
    /**
     * 设置组件排序
     * @return {[type]} [description]
     */
    handlePackage: function handlePackage() {
      this.$store.commit('finish/setIndex', -1);
    },
    handleFooterPage: function handleFooterPage() {
      this.$store.commit('finish/setIndex', -3);
    },
    /**
     * 执行页面保存操作
     * @return {[type]} [description]
     */
    handlePageSave: function handlePageSave() {
      var _this3 = this;
      var loading = this.$loading({
        lock: true,
        text: '保存中……',
        spinner: 'el-icon-loading'
      });
      if (this.pageInfo.title === '默认微页') {
        return;
      }
      this.actionPageSave().then(function () {
        loading.close();
        _this3.$message({
          title: '成功',
          message: '保存成功',
          type: 'success',
          duration: 1000
        });
        _this3.isEdit = true;
        // this.$router.back();
      }).catch(function (error) {
        loading.close();
        console.error('错误信息', error);
      });
    },
    /**
     * 处理数据保存
     * @return {[type]} [description]
     */
    actionPageSave: function actionPageSave() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var content = _this.$store.state.finish.pages;
        var select_num_type = [];
        content.forEach(function (m, i) {
          if (m.content && m.content.data && m.content.data.length > 0) {
            m.content.data.forEach(function (item, index) {
              if (item.link.select_num_type) {
                select_num_type.push({
                  key: "".concat(i, "-").concat(index),
                  type: item.link.select_num_type,
                  desc: item.right_title
                });
              }
            });
          }
        });
        var data = {
          name: '个人中心',
          content: JSON.stringify(content),
          config_data: JSON.stringify({
            background: _this.pageInfo.background,
            headerSetting: _this.pageInfo.headerSetting,
            bg_image: _this.pageInfo.bg_image,
            bg_image_mode: _this.pageInfo.bg_image_mode,
            userInfoSetting: _this.pageInfo.userInfoSetting,
            select_number: select_num_type.length > 0 ? select_num_type : []
          }),
          is_release: 0,
          type: 'templatePersonalCenter'
        };
        if (_this.$route.query.personal_center) {
          data.type = 'personal_center';
        }
        if (_this.$route.query.personal_center_tiktok) {
          data.type = 'personal_center_tiktok';
        }
        (0, _system.diypageCreate)('', data).then(function (res) {
          resolve(true);
        }).catch(function (message) {
          return reject(message);
        });
      });
    },
    sendAction: function sendAction() {
      var _this4 = this;
      if (!this.isEdit) {
        this.$message.error('请先保存您修改的样式');
        return false;
      }
      this.$modalYouSure("\u70B9\u51FB\u786E\u8BA4\u540E\uFF0C\u5C0F\u7A0B\u5E8F\u9875\u9762\u4F1A\u5373\u65F6\u5E94\u7528\u81F3\u8BE5\u65B0\u6A21\u677F\u98CE\u683C\uFF0C\u8BF7\u786E\u8BA4\uFF01").then(function () {
        (0, _system.releasePageApi)({
          type: 'templatePersonalCenter'
        }).then(function () {
          _this4.$message.success('发布成功');
          _this4.isEdit = false;
        }).catch(function () {});
      }).catch(function () {});
    },
    downloadFile: function downloadFile(content, fileName) {
      // 下载base64图片
      var base64ToBlob = function base64ToBlob(code) {
        var parts = code.split(';base64,');
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {
          type: contentType
        });
      };
      var aLink = document.createElement('a');
      var blob = base64ToBlob(content); // new Blob([content]);
      var evt = document.createEvent('HTMLEvents');
      evt.initEvent('click', true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    },
    /**
     * 设置为首页
     * 确认设置该微页面为首页？
     * @return {[type]} [description]
     */
    handlePageSetting: function handlePageSetting() {
      // this.$confirm('确认设置该微页面为首页？', '提示', {
      //   confirmButtonText: '确认',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   center: true
      // })
      //   .then(() => {
      //
      //   })
      //   .catch(() => {});
      this.$message.error('暂未开放');
    },
    backAction: function backAction() {
      this.$router.back();
    },
    /**
     * 拖拽拿起事件
     * sort 排序  drag 拖拽
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    dragStart: function dragStart($event) {
      this.$store.commit('finish/setComs', $event);
    },
    /**
     * 拖拽放下事件
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    dragEnd: function dragEnd($event) {
      this.$store.commit('finish/setComs', '');
      $event.preventDefault();
    },
    mouseOver: function mouseOver(key) {
      this[key] = true;
    },
    mouseleave: function mouseleave(key) {
      this[key] = false;
    },
    handleCancelAction: function handleCancelAction() {
      this.$refs.childView.id = this.pageInfo.id;
      console.log(this.pageInfo.id);
      this.$refs.childView.loadPageInfo();
    },
    previewAction: function previewAction() {
      var path = '/pages/users/index?is_preview=1';
      this.showImgUrl = '';
      this.previewCode(path);
    },
    previewCode: function previewCode(path) {
      var _this5 = this;
      this.showImgType = 2;
      this.showImg = true;
      (0, _system.createQRCodeMiniApi)({
        path: path,
        type: 1
      }).then(function (res) {
        _this5.showImgUrl = res.data.base_image;
      });
    }
  }
};