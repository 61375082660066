var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.addAction },
              },
              [_vm._v("新增签名")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20", staticStyle: { height: "calc(100vh - 200px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                height: "100%",
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "签名", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(row.sign) + " "),
                          row.is_default
                            ? _c("span", { staticClass: "default-sign" }, [
                                _vm._v("默认"),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "签名类型", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.source == 0
                          ? _c("div", [_vm._v("企事业单位")])
                          : row.source == 1
                          ? _c("div", [_vm._v("已备案网站")])
                          : row.source == 2
                          ? _c("div", [_vm._v("已上线APP")])
                          : row.source == 3
                          ? _c("div", [_vm._v("公众号或小程序")])
                          : row.source == 4
                          ? _c("div", [_vm._v("电商平台店铺名")])
                          : row.source == 5
                          ? _c("div", [_vm._v("已注册商标名")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "说明", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.remark))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.create_time))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 10
                          ? _c("div", { staticClass: "color-gray" }, [
                              _vm._v("待提交"),
                            ])
                          : row.status == 0
                          ? _c("div", { staticStyle: { color: "#F4A800" } }, [
                              _vm._v("审核中"),
                            ])
                          : row.status == 2
                          ? _c("div", { staticStyle: { color: "#FF0000" } }, [
                              _vm._v("审核失败"),
                            ])
                          : row.status == 1
                          ? _c("div", { staticStyle: { color: "#5DB741" } }, [
                              _vm._v("审核成功"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "22",
                attrs: { label: "操作", align: "center", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 1 && !row.is_default
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setDefaultAction(row.id)
                                    },
                                  },
                                },
                                [_vm._v("设为默认")]
                              ),
                            ]
                          : row.status == 10
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.smsSignSubmitAction(row.id)
                                    },
                                  },
                                },
                                [_vm._v("提交审核")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAction(row)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { "icon-class": "commonedit" },
                                  }),
                                  _vm._v("编辑"),
                                ],
                                1
                              ),
                            ]
                          : row.status == 2
                          ? [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "left-start",
                                    title: "失败原因",
                                    width: "200",
                                    trigger: "hover",
                                    content: row.reason,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        size: "mini",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("失败原因")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        row.status != 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(row.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: { "current-change": _vm.getList },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.newDeliverDetail.id ? "修改签名" : "新增签名",
            visible: _vm.newDeliverDetail.show,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.newDeliverDetail, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.newDeliverDetail,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "签名", prop: "sign" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入签名" },
                    model: {
                      value: _vm.newDeliverDetail.sign,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "sign", $$v)
                      },
                      expression: "newDeliverDetail.sign",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "签名类型", prop: "source" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.newDeliverDetail.source,
                        callback: function ($$v) {
                          _vm.$set(_vm.newDeliverDetail, "source", $$v)
                        },
                        expression: "newDeliverDetail.source",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "0", label: "企事业单位" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "1", label: "已备案网站" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "2", label: "已上线APP" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "3", label: "公众号或小程序" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "4", label: "电商平台店铺名" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "5", label: "已注册商标名" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审核资料", prop: "attachments" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "line-height": "30px",
                        "margin-top": "2px",
                      },
                    },
                    [
                      _vm._v(
                        "1.请上传签名归属方的企业营业执照、组织机构代码证、税务登记证 三证合一 的证明，支持jpg、png、gif、jpeg格式的图片，每张图片不大于2MB"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "line-height": "30px" } },
                    [
                      _vm._v("2.下载\n          "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: { click: _vm.downloadAction },
                        },
                        [_vm._v("授权委托书模板")]
                      ),
                      _vm._v(
                        "，支持jpg、png、gif、jpeg格式的图片，每张图片不大于2MB\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _vm.newDeliverDetail.attachments.length > 0
                        ? _vm._l(
                            _vm.newDeliverDetail.attachments,
                            function (m, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "img-list mr10" },
                                [
                                  _c("img", {
                                    attrs: { src: m + "!120a" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showImageAction(i)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "del",
                                      on: {
                                        click: function ($event) {
                                          return _vm.delImgAction(i)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-close" })]
                                  ),
                                ]
                              )
                            }
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newDeliverDetail.attachments.length < 15
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "upload-image flex align-items-c justify-c mr10",
                              on: { click: _vm.changeImg },
                            },
                            [_c("i", { staticClass: "el-icon-picture" })]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "说明", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "说明" },
                    model: {
                      value: _vm.newDeliverDetail.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "remark", $$v)
                      },
                      expression: "newDeliverDetail.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.newDeliverDetail.show = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.newDeliverDetail.attachments[_vm.imagesKey] },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }