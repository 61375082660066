"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _benefits = _interopRequireDefault(require("./modules/benefits"));
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Benefits: _benefits.default
  },
  data: function data() {
    return {
      id: '',
      ruleForm: {
        level_name: '',
        level_icon: '',
        integral_multiple: '',
        upgrade_growth_value: '',
        product_discount_percent: '100',
        ticket_discount_percent: '100',
        identification_discount_percent: '100',
        identification_study_discount_percent: '100'
      },
      rules: {
        level_name: {
          required: true,
          message: '请输入等级名称',
          trigger: 'blur'
        },
        level_icon: {
          required: true,
          message: '请选择等级图标',
          trigger: 'change'
        },
        integral_multiple: [{
          required: true,
          message: '请输入积分倍数',
          trigger: 'blur'
        }],
        upgrade_growth_value: [{
          required: true,
          message: '请输入所需成长值',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '成长值数量必须为数字',
          trigger: 'blur'
        }],
        product_discount_percent: [{
          required: true,
          message: '请输入商品折扣',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '商品折扣必须为数字',
          trigger: 'blur'
        }],
        ticket_discount_percent: [{
          required: true,
          message: '请输入门票折扣',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '门票折扣必须为数字',
          trigger: 'blur'
        }],
        identification_discount_percent: [{
          required: true,
          message: '请输入鉴定折扣',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '鉴定折扣必须为数字',
          trigger: 'blur'
        }]
      },
      benefitsArr: [],
      showImage: false,
      imagesViewKey: 'auction_images'
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.loadDetail();
    }
  },
  methods: {
    loadDetail: function loadDetail() {
      var _this = this;
      (0, _member.membershipLevelDetailApi)(this.id).then(function (res) {
        var data = res.data;
        _this.ruleForm = {
          level_name: data.level_name,
          level_icon: data.level_icon,
          integral_multiple: data.integral_multiple,
          upgrade_growth_value: data.upgrade_growth_value,
          product_discount_percent: data.product_discount_percent,
          ticket_discount_percent: data.ticket_discount_percent,
          identification_discount_percent: data.identification_discount_percent,
          identification_study_discount_percent: data.extend_data && data.extend_data.identification_study_discount_percent
        };
        var arr = [];
        data.rightItem.forEach(function (m) {
          if (m.right_type === 2 || m.right_type === 3 || m.right_type === 6) {
            m.goodsCount = 0;
            m.right_give_data.forEach(function (c) {
              m.goodsCount += Number(c.number);
            });
          }
          switch (m.right_type) {
            case 0:
              m.showDesc = m.right_type_text;
              break;
            case 1:
              m.showDesc = "\u8D60\u9001".concat(m.right_type_text);
              break;
            case 2:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u4EF6\u5546\u54C1");
              break;
            case 3:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u5F20\u95E8\u7968");
              break;
            case 4:
              m.showDesc = "\u8D60\u9001".concat(m.right_give_data[0].number, "\u79EF\u5206");
              break;
            case 5:
              m.showDesc = "\u8D60\u9001".concat(m.right_give_data[0].number, "\u4F59\u989D");
              break;
            case 6:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u5F20\u4F18\u60E0\u5238");
              break;
          }
          arr.push({
            name: m.right_name,
            image: m.right_image,
            id: m.right_id,
            showDesc: m.showDesc
          });
        });
        _this.benefitsArr = arr;
      });
    },
    changeImg: function changeImg(key) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.ruleForm[key] = img[0];
        _this2.$refs['ruleForm'].validateField(key);
      }, 1, '1');
    },
    showImageAction: function showImageAction(key) {
      this.imagesViewKey = key;
      this.showImage = true;
    },
    delImgAction: function delImgAction(key) {
      this.ruleForm[key] = '';
      this.$refs['ruleForm'].validateField(key);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      this.$refs['benefits'].changeshow();
    },
    selectMessage: function selectMessage(data) {
      this.benefitsArr = data.data;
    },
    delProductAction: function delProductAction(i) {
      this.benefitsArr.splice(i, 1);
    },
    saveAction: function saveAction(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this3.ruleForm));
          console.log(data);
          if (isNaN(data.integral_multiple)) {
            _this3.$message.error('积分倍数必须为数字');
            return false;
          }
          if (_this3.benefitsArr && _this3.benefitsArr.length) {
            data.right_ids = _this3.benefitsArr.map(function (m) {
              return m.id;
            });
          }
          var loading = _this3.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _member.membershipLevelCreateApi;
          if (_this3.id) {
            action = _member.membershipLevelUpdateApi;
          }
          action(_this3.id, data).then(function () {
            loading.close();
            _this3.$message.success('操作成功');
            _this3.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          _this3.$message.error('请填写完整信息');
          return false;
        }
      });
    }
  }
};