var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _vm.collectMoney.show
        ? _c("div", { attrs: { id: "printorder" } }, [
            _c("div", { staticClass: "detail-box" }, [
              _c("div", { staticClass: "detail-left" }, [
                _c("div", { staticClass: "detail-left-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.collectMoney.data.merConfig.company_name) +
                      " | 竞买协议\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "14px",
                      "font-family": "'printEN'",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.collectMoney.data.merConfig.company_english_name
                        ) +
                        ", Ltd   AGREEMENT OF THE BIDDER\n        "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-left-title",
                staticStyle: { "margin-top": "40px" },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.collectMoney.data.venueInfo.title) + " "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-list",
                staticStyle: { "margin-top": "5px" },
              },
              [
                _c("div", [
                  _vm._v("竞投人姓名 BUYER'S NAME "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-size": "16px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.collectMoney.data.user_verify.real_name)
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("竞投号牌 PADDLE NO. "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-size": "16px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.collectMoney.data.number))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("办牌日期 DATE OF SALE "),
                  _c("span", {
                    staticStyle: { "font-weight": "500", "font-size": "16px" },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-list",
                staticStyle: { "margin-top": "5px" },
              },
              [
                _c("div", [
                  _vm._v("证件类型ID TYPE "),
                  _c("span", {
                    staticStyle: { "font-weight": "500", "font-size": "16px" },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("证件号码NO. "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-size": "16px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.collectMoney.data.user_verify.idcard))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("手机MOBILE PHONE "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-size": "16px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.collectMoney.data.user_phone))]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-listOne",
                staticStyle: { "margin-top": "5px" },
              },
              [
                _c("div", [
                  _vm._v("证件地址："),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-size": "16px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.collectMoney.data.user_verify.address))]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-listOne",
                staticStyle: { "margin-top": "5px" },
              },
              [
                _c("div", [
                  _vm._v("邮寄信息："),
                  _vm.collectMoney.data.user_address.province
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "16px",
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.collectMoney.data.user_address.province
                              ) +
                              _vm._s(_vm.collectMoney.data.user_address.city) +
                              _vm._s(
                                _vm.collectMoney.data.user_address.detail
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { margin: "15px 0 10px" } }, [
              _c("img", {
                staticStyle: { width: "350px", "margin-right": "20px" },
                attrs: {
                  src: _vm.collectMoney.data.user_verify.idcard_just_photo,
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("img", {
                staticStyle: { width: "350px" },
                attrs: {
                  src: _vm.collectMoney.data.user_verify.idcard_back_photo,
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "font-weight": "500", "font-size": "16px" } },
              [
                _vm._v(
                  "竞投人已详细阅读并理解《" +
                    _vm._s(_vm.collectMoney.data.merConfig.company_name) +
                    "拍卖规则》，同意按规则各项条款参与此次拍卖会。"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "font-weight": "500",
                  "font-size": "16px",
                  "margin-top": "10px",
                },
              },
              [_vm._v("协议内容如下：")]
            ),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                "font-weight": "500",
                "font-size": "16px",
                "margin-top": "10px",
              },
              domProps: {
                innerHTML: _vm._s(_vm.collectMoney.data.agreementInfo.message),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-sign",
                staticStyle: { "margin-top": "50px" },
              },
              [
                _vm._v("预交保证金"),
                _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                  _vm._v(" DEPOSIT"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "detail-listOne" }, [
              _c("div", { staticStyle: { width: "25%" } }, [
                _vm._v("RMB："),
                _c(
                  "span",
                  {
                    staticStyle: { "font-weight": "500", "font-size": "16px" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.formatPriceNumber(
                          _vm.collectMoney.data.add_wallet_price,
                          2
                        )
                      )
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "75%" } }, [
                _vm._v("大写AMOUNT IN WORDS"),
                _c(
                  "span",
                  {
                    staticStyle: { "font-weight": "500", "font-size": "16px" },
                  },
                  [_vm._v(_vm._s(_vm.collectMoney.data.DxMoney))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "print-line",
              staticStyle: { "margin-top": "30px" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "detail-sign" }, [
              _vm._v("竞投人签字"),
              _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                _vm._v(" SIGNATURE"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "print-line" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-listOne",
                staticStyle: { "margin-top": "30px" },
              },
              [
                _c("div", { staticStyle: { width: "25%" } }, [
                  _vm._v(
                    "制单人：" +
                      _vm._s(_vm.collectMoney.data.adminInfo.real_name)
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { width: "25%" } }, [
                  _vm._v("单位盖章："),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { width: "25%" } }, [
                  _vm._v("收款人："),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { width: "25%" } }, [
                  _vm._v("发牌人："),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }