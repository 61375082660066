"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _index = require("@/utils/index");
var _contract = require("@/api/contract");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      total_rmb: ''
    };
  },
  methods: {
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      (0, _printJs.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page {margin:0 10mm}"
      });
    },
    loadData: function loadData(ids, uid, source) {
      var _this = this;
      this.source = source;
      (0, _contract.printProductOrderApi)({
        check_auction_ids: ids
      }).then(function (res) {
        console.log(res.data.checkAuctionData);
        // m.pmAuction.now_price
        var total = 0;
        var obj = {
          succ: 0,
          err: 0
        };
        var configPrice = JSON.parse(JSON.stringify(res.data.checkAuctionData[0].price_data));
        configPrice.forEach(function (item) {
          item.forEach(function (ele) {
            ele.deduction_amount = 0;
          });
        });
        res.data.checkAuctionData.forEach(function (item) {
          total += item.pmAuction.now_price ? Number(item.pmAuction.now_price) : 0;
          if (item.auction_status <= 4) {
            obj.err += 1;
          } else {
            obj.succ += 1;
          }
          item.price_data.forEach(function (a, i) {
            a.forEach(function (ele, index) {
              if (configPrice[i][index]) configPrice[i][index].deduction_amount += ele.deduction_amount ? ele.deduction_amount : 0;
            });
          });
        });
        console.log(configPrice);
        res.data.configPrice = configPrice;
        res.data.obj = obj;
        res.data.nowTotal = total;
        _this.collectMoney = {
          data: res.data,
          show: true
        };
        // this.getCNPrice();
      }).catch(function () {});
    },
    delAction: function delAction(i) {
      this.collectMoney.data.auctionData.splice(i, 1);
      this.getCNPrice();
    },
    getCNPrice: function getCNPrice() {
      var _this2 = this;
      var mer_id = localStorage.getItem('mer_id');
      var total_price = 0;
      this.collectMoney.data.auctionData.forEach(function (m) {
        total_price += m.should_pay_price;
      });
      this.total_price = total_price;
      (0, _contract.numberToRmbApi)({
        number: this.total_price,
        mer_id: mer_id
      }).then(function (res) {
        _this2.total_rmb = res.data.rmb;
      });
    }
  }
};