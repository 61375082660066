var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "收款结算单",
        width: "930px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticClass: "flex-one" }, [
          _c("span", [_vm._v("买家：")]),
          _vm._v(" "),
          _c("span", { staticStyle: { color: "#347FF4" } }, [
            _vm._v(_vm._s(_vm.user.nickname)),
          ]),
          _vm._v(" "),
          _vm.userNumberPlate
            ? _c("span", { staticClass: "ml30" }, [_vm._v("号牌：")])
            : _vm._e(),
          _vm._v(" "),
          _vm.userNumberPlate
            ? _c("span", [_vm._v(_vm._s(_vm.userNumberPlate.number))])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.adminInfo
          ? _c("div", [_vm._v("制单人：" + _vm._s(_vm.adminInfo))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.collectMoney.show &&
      _vm.collectMoney.data &&
      _vm.collectMoney.data.orderList
        ? [
            _c(
              "el-table",
              {
                staticClass: "collect-money mt20",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.collectMoney.data.orderList,
                  size: "mini",
                  border: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("template", { slot: "empty" }, [
                  _c("div", { staticClass: "tableEmpty" }, [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "图片", width: "50", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAllToall
                              ? [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "40px" } },
                                    [_vm._v("合计")]
                                  ),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "item-flex-center" },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "40px",
                                          height: "40px",
                                          display: "block",
                                        },
                                        attrs: {
                                          src:
                                            scope.row.auction_info.image +
                                            "!120a",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2951583867
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "图录号", align: "center", width: "60" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(_vm._s(scope.row.auction_info.lot)),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3298173227
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "拍品名称", width: "280" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(_vm._s(scope.row.auction_info.store_name)),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3143545851
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "product_price",
                    label: "落槌价",
                    width: "90",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticStyle: { "line-height": "30px" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      scope.row.product_price,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1853874350
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "commission_price",
                    label: "佣金",
                    width: "90",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.commission_price,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    563103612
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "total_postage", label: "运费", width: "90" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.total_postage,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2645342533
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "admin_discount_price",
                    label: "优惠",
                    width: "120",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAllToall
                              ? [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "40px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            _vm.all_admin_discount_price,
                                            2
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "discount" },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: { type: "number" },
                                          on: {
                                            focus: function ($event) {
                                              return _vm.focus($event)
                                            },
                                            change: function ($event) {
                                              return _vm.editDiscountPrice(
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.admin_discount_all_price[
                                                scope.$index
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.admin_discount_all_price,
                                                scope.$index,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "admin_discount_all_price[scope.$index]",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("元"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1365759884
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "pay_price", label: "金额", width: "108" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAllToall
                              ? [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "40px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_pay_price,
                                            2
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          scope.row.pay_price,
                                          2
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2334641645
                  ),
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "deduct mt20" }, [
              _c("div", { staticClass: "item item-align-center" }, [
                _c(
                  "div",
                  { staticClass: "td", staticStyle: { width: "300px" } },
                  [
                    _vm._v("保证金账户余额："),
                    _c("span", { staticClass: "fw500" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.collectMoney.data.userWalletInfo.balance,
                            2
                          )
                        )
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "td" }, [_vm._v("本次结算使用：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "td" },
                  [
                    _c("el-input-number", {
                      staticClass: "inp",
                      attrs: {
                        controls: false,
                        min: 0,
                        max: _vm.collectMoney.data.userWalletInfo.balance,
                      },
                      model: {
                        value: _vm.use_wallet_price,
                        callback: function ($$v) {
                          _vm.use_wallet_price = $$v
                        },
                        expression: "use_wallet_price",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "td1" }),
              ]),
              _vm._v(" "),
              _vm.collectMoney.data.entrustWalletInfo.balance
                ? _c("div", { staticClass: "item item-align-center" }, [
                    _c(
                      "div",
                      { staticClass: "td", staticStyle: { width: "300px" } },
                      [
                        _vm._v("委托方账户余额："),
                        _c("span", { staticClass: "fw500" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.entrustWalletInfo.balance,
                                2
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "td" }, [
                      _vm._v("本次结算使用："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "td" },
                      [
                        _c("el-input-number", {
                          staticClass: "inp",
                          attrs: {
                            controls: false,
                            min: 0,
                            max: _vm.collectMoney.data.entrustWalletInfo
                              .balance,
                          },
                          model: {
                            value: _vm.use_entrust_wallet_price,
                            callback: function ($$v) {
                              _vm.use_entrust_wallet_price = $$v
                            },
                            expression: "use_entrust_wallet_price",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "td1" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item item-align-center",
                  staticStyle: { "border-left-color": "#228B22" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "td", staticStyle: { width: "300px" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          attrs: { placeholder: "付款方式" },
                          model: {
                            value: _vm.pay_type,
                            callback: function ($$v) {
                              _vm.pay_type = $$v
                            },
                            expression: "pay_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: "bank_transfer",
                              label: "银行转账",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: {
                              value: "offline_wechat",
                              label: "线下微信",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: {
                              value: "offline_alipay",
                              label: "线下支付宝",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "offline_cash", label: "现金" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "offline_pos", label: "pos机" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "td" }, [_vm._v("线下收款金额：")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "td price" }, [
                    _vm._v(_vm._s(_vm.pay_money)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "td1 flex" },
                    [
                      _vm.pay_evidence
                        ? _c(
                            "div",
                            { staticClass: "relative mr20" },
                            [
                              _c("el-image", {
                                staticClass: "pay_evidence_img",
                                attrs: {
                                  "preview-src-list": [_vm.pay_evidence],
                                  src: _vm.pay_evidence + "!m640",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "del",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delImgAction(
                                        _vm.i,
                                        "auction_images"
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.changeImg } }, [
                        _vm._v("上传凭证"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "mt20",
                attrs: { type: "primary" },
                on: { click: _vm.payAction },
              },
              [_vm._v("确认结算")]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { height: "40px" } }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }