var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "50", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像", width: "50", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "25px", height: "25px" },
                      attrs: {
                        src: scope.row.user.avatar
                          ? scope.row.user.avatar
                          : _vm.moren,
                        "preview-src-list": [
                          scope.row.user.avatar || _vm.moren,
                        ],
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "名称", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", {
                        domProps: { textContent: _vm._s(row.user.nickname) },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "真实姓名", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.user.real_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "手机号", prop: "user.phone", "min-width": "90" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "申请时间", prop: "add_time", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "积分", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.integral_num) + "分")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "未使用优惠券", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.coupon_num) + "张")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "钱包余额", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.balance_num) + "元")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == 10
                      ? _c("div", [_vm._v("待审核")])
                      : row.status == 20
                      ? _c("div", [_vm._v("已注销")])
                      : row.status == 30
                      ? _c("div", [_vm._v("已驳回")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "100",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 10
                      ? _c(
                          "el-button",
                          {
                            staticClass: "m0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmCancel(scope.row)
                              },
                            },
                          },
                          [_vm._v("确认注销\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 10
                      ? _c(
                          "el-button",
                          {
                            staticClass: "m0",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.beRejected(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          驳回申请\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.userFrom.limit,
              "current-page": _vm.userFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.realNameAuthentication.show,
            width: "40%",
            title: "确认注销",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.realNameAuthentication, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "cdialog-con",
              staticStyle: { "margin-bottom": "10px" },
            },
            [
              _c("p", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "注销后该用户将不再持有相关资产和数据，请确认已与用户达成一致！以免出现纠纷和法律责任"
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "cdialog-con" }, [
            _c("p", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("用户数据"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("数字藏品持仓：" + _vm._s(_vm.userObj.nft_num || 0)),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("数字盲盒持仓：" + _vm._s(_vm.userObj.box_num || 0)),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("积分：" + _vm._s(_vm.userObj.integral_num || 0) + "分"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "未用优惠券：" + _vm._s(_vm.userObj.coupon_num || 0) + "张"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "钱包余额：" + _vm._s(_vm.userObj.balance_num || 0) + "元"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }