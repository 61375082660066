"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _setting = require("@/api/setting");
var _settings = require("@/settings");
var _basic = require("@/api/basic");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formValidate: {
        erpAuditAdmin: [],
        financeAuditAdmin: []
      },
      adminarr: [],
      formValidateJson: {
        "erp_v2_contract": "",
        // "erp_v2_contract_admin": "",
        "erp_v2_contract_admin": [],
        "erp_v2_enter_warehouse": "",
        // "erp_v2_enter_warehouse_admin": "",
        "erp_v2_enter_warehouse_admin": [],
        "erp_v2_out_warehouse": "",
        // "erp_v2_out_warehouse_admin": "",
        "erp_v2_out_warehouse_admin": [],
        "erp_v2_transfers_warehouse": "",
        // "erp_v2_transfers_warehouse_admin": "",
        "erp_v2_transfers_warehouse_admin": [],
        "erp_v2_stock_take": "",
        "erp_v2_stock_take_admin": [],
        "market_media_make": "",
        "market_media_make_admin": []
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getadminList();
    (0, _system.getConfigClassKeys)('approval_config').then(function (res) {
      var json = res.data;
      if (json.erp_v2_contract_admin) {
        json.erp_v2_contract_admin = json.erp_v2_contract_admin.split(',').map(function (item) {
          return Number(item);
        });
      } else {
        json.erp_v2_contract_admin = [];
      }
      if (json.erp_v2_stock_take_admin) {
        json.erp_v2_stock_take_admin = json.erp_v2_stock_take_admin.split(',').map(function (item) {
          return Number(item);
        });
      } else {
        json.erp_v2_stock_take_admin = [];
      }
      if (json.market_media_make_admin) {
        json.market_media_make_admin = json.market_media_make_admin.split(',').map(function (item) {
          return Number(item);
        });
      } else {
        json.market_media_make_admin = [];
      }
      if (json.erp_v2_out_warehouse_admin) {
        json.erp_v2_out_warehouse_admin = json.erp_v2_out_warehouse_admin.split(',').map(function (item) {
          return Number(item);
        });
      } else {
        json.erp_v2_out_warehouse_admin = [];
      }
      if (json.erp_v2_enter_warehouse_admin) {
        json.erp_v2_enter_warehouse_admin = json.erp_v2_enter_warehouse_admin.split(',').map(function (item) {
          return Number(item);
        });
      } else {
        json.erp_v2_enter_warehouse_admin = [];
      }
      if (json.erp_v2_transfers_warehouse_admin) {
        json.erp_v2_transfers_warehouse_admin = json.erp_v2_transfers_warehouse_admin.split(',').map(function (item) {
          return Number(item);
        });
      } else {
        json.erp_v2_transfers_warehouse_admin = [];
      }
      _this.formValidateJson = json;
    });

    // getpreviewsetting().then(res=>{
    //     if(res.data.erp){
    //         let erparr = [];
    //         res.data.erp.forEach(item => {
    //             erparr.push(item.merchant_admin_id)
    //         });
    //         this.formValidate.erpAuditAdmin = erparr;
    //     }
    // })
  },
  methods: {
    getadminList: function getadminList() {
      var _this2 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this2.adminarr = res.data.list;
      });
    },
    save: function save() {
      var _this3 = this;
      console.log(this.formValidateJson);
      var json = JSON.parse(JSON.stringify(this.formValidateJson));
      json.erp_v2_contract_admin = json.erp_v2_contract_admin.join(',');
      json.erp_v2_enter_warehouse_admin = json.erp_v2_enter_warehouse_admin.join(',');
      json.erp_v2_out_warehouse_admin = json.erp_v2_out_warehouse_admin.join(',');
      json.erp_v2_transfers_warehouse_admin = json.erp_v2_transfers_warehouse_admin.join(',');
      json.erp_v2_stock_take_admin = json.erp_v2_stock_take_admin.join(',');
      json.market_media_make_admin = json.market_media_make_admin.join(',');
      (0, _system.configClassKeysSave)('approval_config', json).then(function (res) {
        _this3.$message.success('保存成功');
      });
      // setpreviewsetting(this.formValidate).then(res=>{
      //     this.$message.success('保存成功');
      // })
    }
  }
};