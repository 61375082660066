var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "标题栏" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("风格模板选择"),
            ]),
          ]),
          _vm._v(" "),
          _c("choose", {
            attrs: {
              data: _vm.styleData,
              width: "100%",
              height: "auto",
              margin: "13px 0 0 0",
            },
            model: {
              value: _vm.content.style,
              callback: function ($$v) {
                _vm.$set(_vm.content, "style", $$v)
              },
              expression: "content.style",
            },
          }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("标题文字"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c("el-input", {
                      attrs: { type: "text", placeholder: "设置文字" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return (function () {})($event)
                        },
                      },
                      model: {
                        value: _vm.content.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "title", $$v)
                        },
                        expression: "content.title",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.content.style === 1 || _vm.content.style === 3
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("子标题文字")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          attrs: { type: "text", placeholder: "设置文字" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return (function () {})($event)
                            },
                          },
                          model: {
                            value: _vm.content.subtitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.content, "subtitle", $$v)
                            },
                            expression: "content.subtitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style !== 4
                ? _c(
                    "div",
                    { staticClass: "item-align-center mt20" },
                    [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("更多文字")]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: { type: "text", placeholder: "设置文字" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function () {})($event)
                          },
                        },
                        model: {
                          value: _vm.content.moretitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "moretitle", $$v)
                          },
                          expression: "content.moretitle",
                        },
                      }),
                      _vm._v(" "),
                      _vm.content.morelink.name1 || _vm.content.morelink.name
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end ml10 cur-pointer",
                              on: { click: _vm.setCategory },
                            },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.content.morelink.name1 ||
                                      _vm.content.morelink.name
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                              on: { click: _vm.setCategory },
                            },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v("设置跳转链接"),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("标题带图标"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: { click: _vm.uploadIconAction },
                  },
                  [
                    _vm.content.title_img
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delImg($event)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.title_img
                      ? _c("img", {
                          attrs: { src: _vm.content.title_img + "!120a" },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-align-center flex-one justify-end" },
                  [
                    _c(
                      "span",
                      { staticStyle: { "font-size": "12px", color: "#999" } },
                      [_vm._v("示例")]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "ml10",
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/e8c77dd830fcf47bd222c2001807e9ed.png",
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("风格参数设置"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.content.style !== 3
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "页面边距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.page_padding,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "page_padding", $$v)
                          },
                          expression: "facade.page_padding",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#333333" },
                    model: {
                      value: _vm.facade.title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_color", $$v)
                      },
                      expression: "facade.title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题字号" } },
                [
                  _c("slider", {
                    attrs: { min: 12, max: 50 },
                    model: {
                      value: _vm.facade.title_font_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_font_size", $$v)
                      },
                      expression: "facade.title_font_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.style === 1 || _vm.content.style === 3
                ? _c("div", { staticClass: "h30" })
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 1 || _vm.content.style === 3
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "子标题颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#999999" },
                        model: {
                          value: _vm.facade.subtitle_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "subtitle_color", $$v)
                          },
                          expression: "facade.subtitle_color",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 1 || _vm.content.style === 3
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "子标题字号" } },
                    [
                      _c("slider", {
                        attrs: { min: 12, max: 50 },
                        model: {
                          value: _vm.facade.subtitle_font_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "subtitle_font_size", $$v)
                          },
                          expression: "facade.subtitle_font_size",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style !== 4
                ? _c("div", { staticClass: "h30" })
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style !== 4
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "更多颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#999999" },
                        model: {
                          value: _vm.facade.more_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "more_color", $$v)
                          },
                          expression: "facade.more_color",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style !== 4
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "更多字号" } },
                    [
                      _c("slider", {
                        attrs: { min: 12, max: 50 },
                        model: {
                          value: _vm.facade.more_font_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "more_font_size", $$v)
                          },
                          expression: "facade.more_font_size",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 1 || _vm.content.style === 3
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "箭头图标" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.more_img,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "more_img", $$v)
                            },
                            expression: "facade.more_img",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("黑色"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("白色"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }