var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: (_vm.id ? "修改" : "创建") + "出库单", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "库房", prop: "out_warehouse_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        disabled: _vm.id != "",
                        placeholder: "请选择库房",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.selectActiveGoods },
                      model: {
                        value: _vm.formValidate.out_warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "out_warehouse_id", $$v)
                        },
                        expression: "formValidate.out_warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeArr, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "出库类型", prop: "out_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        placeholder: "出库类型",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.out_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "out_type", $$v)
                        },
                        expression: "formValidate.out_type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "销售出库",
                          value: "out_warehouse_order",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "调拨出库",
                          value: "out_warehouse_transfers",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "退回出库",
                          value: "out_warehouse_refund",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物流方式", prop: "out_mode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        placeholder: "物流方式",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.out_mode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "out_mode", $$v)
                        },
                        expression: "formValidate.out_mode",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "物流", value: "express" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "自提", value: "self_take" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "委托他人自提",
                          value: "entrust_self_take",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formValidate.out_mode == "express"
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "收货人姓名", prop: "express_user_name" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "请选择收货人",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.searchUserAction,
                          },
                          on: { change: _vm.onUserChange },
                          model: {
                            value: _vm.formValidate.express_user_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formValidate,
                                "express_user_name",
                                $$v
                              )
                            },
                            expression: "formValidate.express_user_name",
                          },
                        },
                        [
                          _vm._l(_vm.usershList, function (m, i) {
                            return _c(
                              "el-option",
                              {
                                key: "user" + i,
                                attrs: { value: m.uid, label: m.nickname },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "40px",
                                        height: "40px",
                                        "border-radius": "50%",
                                        "margin-right": "12px",
                                      },
                                      attrs: { src: m.avatar, alt: "" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "line-height": "18px",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            " " + _vm._s(m.nickname) + " "
                                          ),
                                          m.real_name
                                            ? _c("span", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(m.real_name) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v(_vm._s(m.phone))]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("template", { slot: "empty" }, [
                            _c("div", { staticClass: "select-empty" }, [
                              _vm._v("没有此用户"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "select-button" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: { click: _vm.createUser },
                                  },
                                  [_vm._v(" 确认创建 ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.out_mode == "express"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人地址",
                        prop: "express_user_address",
                      },
                    },
                    [
                      _vm.addrList.length > 0
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: {
                                placeholder: "请选择地址",
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.onUserAddre },
                              model: {
                                value: _vm.formValidate.express_user_address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "express_user_address",
                                    $$v
                                  )
                                },
                                expression: "formValidate.express_user_address",
                              },
                            },
                            _vm._l(_vm.addrList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.detail,
                                  value: item.detail,
                                },
                              })
                            }),
                            1
                          )
                        : _c("el-input", {
                            staticStyle: { width: "150px" },
                            model: {
                              value: _vm.formValidate.express_user_address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "express_user_address",
                                  $$v
                                )
                              },
                              expression: "formValidate.express_user_address",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.out_mode == "express"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人手机号",
                        prop: "express_user_phone",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        model: {
                          value: _vm.formValidate.express_user_phone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formValidate,
                              "express_user_phone",
                              $$v
                            )
                          },
                          expression: "formValidate.express_user_phone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: {
                      rows: 3,
                      type: "textarea",
                      placeholder: "请输入备注",
                    },
                    model: {
                      value: _vm.formValidate.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "remark", $$v)
                      },
                      expression: "formValidate.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: {
                    data: _vm.goodsArr,
                    size: "small",
                    "highlight-current-row": "",
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片", "min-width": "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "36px",
                                    height: "36px",
                                  },
                                  attrs: {
                                    src: scope.row.image,
                                    "preview-src-list": [scope.row.image],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "货品名称",
                      prop: "product_name",
                      "min-width": "140",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.id > 0
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.product_name) +
                                      "\n            "
                                  ),
                                ])
                              : _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: scope.row.product_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "product_name",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.product_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "保留价", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.retain_price) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "属性",
                      prop: "order_pay_amount",
                      align: "center",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(scope.row.attrInfo, function (m, i) {
                            return _c("span", { key: "attr_" + i }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(m.attr_name) +
                                  ":" +
                                  _vm._s(m.attr_value_name) +
                                  "\n            "
                              ),
                            ])
                          })
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delGoods(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                删除\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("formValidate")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            确认" +
                              _vm._s(_vm.$route.query.id ? "修改" : "创建") +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "font-size": "20px", "font-weight": "500" } },
            [_vm._v("\n      从库中选择\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "outorTabelRef",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
                width: "100%",
                border: "",
              },
            },
            [
              _c("ytx-table-column", {
                attrs: {
                  label: "编号",
                  prop: "product_no",
                  "min-width": "100",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.product_no))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "货架",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.shelf
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.shelf.shelf_no)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方",
                  width: "120",
                  prop: "",
                  "search-key": "entrust_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? _c("div", [
                              _vm._v(
                                _vm._s(scope.row.entrustUser.real_name) +
                                  "(" +
                                  _vm._s(scope.row.entrustUser.phone) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "货品名称",
                  width: "120",
                  prop: "",
                  "search-key": "product_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.product_name) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "合同编号",
                  "min-width": "140",
                  prop: "",
                  "search-key": "contract_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contract
                          ? _c("div", [
                              _vm._v(_vm._s(scope.row.contract.contract_no)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "业务员",
                  "min-width": "140",
                  prop: "",
                  "search-key": "salesman_admin_id",
                  "search-type": "radio",
                  options: _vm.userList,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contract && scope.row.contract.salesmanAdmin
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.contract.salesmanAdmin.real_name
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "签约时间",
                  prop: "",
                  "min-width": "150",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(" " + _vm._s(scope.row.create_time) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "60",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "font-size": "14px" },
                            attrs: { type: "text", size: "small" },
                            on: { click: _vm.saleGoodsAll },
                          },
                          [_vm._v(" 全选 ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saleGoods(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.goodsIdArr.indexOf(scope.row.id) > -1
                                        ? "已选择货品"
                                        : "选择货品"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block paginaBox" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddUserCom", { ref: "userREf" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }