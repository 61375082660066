"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _warehouse = require("@/api/warehouse");
var _basic = require("@/api/basic");
var _printJs = _interopRequireDefault(require("print-js"));
var _settings = require("@/settings");
var _system = require("@/api/system");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _transfersOrder = _interopRequireDefault(require("@/components/printOrder/transfersOrder"));
var _user = require("@/api/user.js");
var _review = require("@/api/review");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Review',
  components: {
    fileList: _fileList.default,
    transfersOrder: _transfersOrder.default
  },
  data: function data() {
    var _ref;
    return _ref = {
      roterPre: _settings.roterPre,
      showconfig: false,
      logisticsName: 'refund',
      id: 0,
      type: '',
      tableData: {
        data: [],
        total: 0
      },
      invoiceStatusList: [{
        label: '已开票',
        value: 1
      }, {
        label: '未开票',
        value: 0
      }],
      listLoading: true,
      tableFrom: {
        category: 'transfers',
        username: '',
        type: '',
        date: '',
        page: 1,
        limit: 20,
        receipt_sn: '',
        order_type: '3',
        keyword: '',
        status: ''
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: '',
        mark: ''
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      showreview: false,
      mask: '',
      storeList: [],
      showdetail: false,
      detailjson: {},
      rowkeys: [],
      showsearch: false,
      pushheaderList: [],
      pushHideList: [],
      searchQuery: {},
      userList: [],
      merchant_admin_id: ''
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "mask", ''), "showreview", false), "audit_id", ''), "rowLinkID", ''), "sdjusVisible", false), "sdjusForm", {}), "sdjusList", []), "house", {}), "pusHouse", {}), "currentrow", {}), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "showhouse", false), "showouthouse", false), "expressList", []), "rules", {
      image: [{
        required: true,
        message: "请上传凭证"
      }]
    });
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_transfers_warehouse_list'
    }).then(function (res) {
      _this2.pushheaderList = res.data.erp_v2_transfers_warehouse_list.show_list;
      _this2.pushHideList = res.data.erp_v2_transfers_warehouse_list.hide_list;
      console.log(_this2.pushheaderList);
    });
    this.getList(1);
    this.getStoreList(1);
    this.getexpressList();
    (0, _user.getBaseInfo)().then(function (res) {
      _this2.merchant_admin_id = res.data.merchantAdmin.merchant_admin_id;
    });
    (0, _setting.adminListApi)().then(function (res) {
      // console.log(res);
      _this2.userList = res.data.list.map(function (item) {
        return {
          label: item.real_name,
          value: item.merchant_admin_id
        };
      });
    });
  },
  methods: {
    onUpd: function onUpd(row) {
      this.$router.push({
        path: '/merchant/warehouse/transferscreate',
        query: {
          id: row.id
        }
      });
    },
    losewarehouseorder: function losewarehouseorder(row) {
      var _this3 = this;
      var that = this;
      this.$prompt('', '提示', {
        confirmButtonText: '确认作废',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入作废理由',
        inputErrorMessage: '请输入作废理由'
      }).then(function (_ref2) {
        var value = _ref2.value;
        (0, _warehouse.invalidTransfersApi)({
          id: row.id
        }).then(function () {
          that.$message.success('作废成功');
          that.getList();
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    pushhouse: function pushhouse(row) {
      this.pusHouse = row;
      this.showhouse = true;
    },
    outhouse: function outhouse(row) {
      console.log(row);
      this.currentrow = row;
      this.house.image = "";
      this.house.remark = "";
      this.house.delivery_name = "";
      this.house.delivery_id = "";
      this.showouthouse = true;
    },
    cursorpush: function cursorpush() {
      var _this4 = this;
      if (!this.pusHouse.image) {
        this.$message.error("请上传凭证");
        return;
      }
      (0, _warehouse.confirmpushOrderApiV2)({
        id: this.pusHouse.id,
        enter_image: this.pusHouse.image
      }).then(function () {
        _this4.$message.success("入库成功");
        _this4.showhouse = false;
        _this4.getList();
      });
    },
    cursorouthouse: function cursorouthouse() {
      var _this5 = this;
      if (!this.house.image) {
        this.$message.error("请上传凭证");
        return;
      }
      (0, _warehouse.confirmoutOrderApiV2)({
        id: this.currentrow.id,
        out_image: this.house.image,
        remark: this.house.remark,
        delivery_name: this.house.delivery_name || "",
        delivery_id: this.house.delivery_id || ""
      }).then(function (res) {
        if (res.status == 200) {
          _this5.$message.success("出库成功");
          _this5.getList();
          _this5.showouthouse = false;
        }
      }).catch(function (err) {
        console.log(err);
        _this5.$message.error(err.message);
      });
    },
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this6 = this;
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === "1" && !num) {
          _this.house.image = img[0];
        }
        if (tit === "2" && !num) {
          _this.pusHouse.image = img[0];
        }
        _this6.$forceUpdate();
      }, tit);
    },
    getexpressList: function getexpressList() {
      var _this7 = this;
      (0, _system.expressListApi)().then(function (res) {
        _this7.expressList = res.data;
      });
    },
    onSdjus: function onSdjus(row) {
      var _this8 = this;
      (0, _basic.shelfList)({
        warehouse_id: row.warehouse_id,
        page: 1,
        limit: 9999
      }).then(function (res) {
        _this8.sdjusList = res.data.list;
        _this8.sdjusForm = {
          id: row.id,
          order_id: row.order_id
        };
        _this8.sdjusVisible = true;
      });
    },
    onSubmit: function onSubmit() {
      var _this9 = this;
      (0, _warehouse.updateShelfApi)(this.sdjusForm).then(function (res) {
        _this9.$message.success('操作成功');
        _this9.rowkeys = [];
        _this9.getList();
        _this9.sdjusVisible = false;
      });
    },
    revieworder: function revieworder(row) {
      this.audit_id = row.audit_admin_id;
      this.rowLinkID = row.id;
      this.showreview = true;
      this.mask = '';
    },
    handlereview: function handlereview(state) {
      var _this10 = this;
      if (state == -1 && !this.mask) {
        this.$message.error('请填写拒绝备注');
        return;
      }
      (0, _review.review)(this.audit_id, {
        status: state,
        remark: this.mask,
        type: 'erp_v2_transfers_order',
        link_id: this.rowLinkID
      }).then(function (res) {
        _this10.$message.success('操作成功');
        _this10.getList();
        _this10.showreview = false;
      }).catch(function (err) {
        _this10.$message.error(err.message);
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        order_type: 3,
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    changeColumnWidth: function changeColumnWidth(newWidth, oldWidth, column) {
      console.log(column);
      var index = column.index;
      this.pushheaderList[index].width = newWidth;
      (0, _system.setCustomHeader)({
        types: 'erp_v2_transfers_warehouse_list',
        show_data: this.pushheaderList,
        hide_data: this.pushHideList
      }).then(function (res) {}).catch(function (res) {});
      // this.columnWidthArr[index] = newWidth;
      // this.setColumnsWidth();
    },
    exchangeExpand: function exchangeExpand(row) {
      if (!this.$store.getters.userPression.merchantErpV2WarehouseOrderProductList) return;
      if (row.expandData.length) {
        return;
      }
      if (!row.id) {
        return;
      }
      this.rowkeys = [row.id];
      row.loading = true;
      (0, _warehouse.getwarehouseOrderGoodsListApiV2)({
        order_id: row.id
      }).then(function (res) {
        row.expandData = res.data;
        row.loading = false;
      });
    },
    cellClick: function cellClick(row, column, cell, event) {
      if (!this.rowkeys.includes(row.id)) {
        this.rowkeys = [row.id];
        this.exchangeExpand(row);
      } else {
        this.rowkeys.splice(this.rowkeys.indexOf(row.id), 1);
      }
    },
    getdetail: function getdetail(id) {
      var _this11 = this;
      // this.$router.push({
      //   path: `${roterPre}/warehouse/transfersgoods`,
      //   query: { id: id },
      // });
      // return;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(id).then(function (res) {
        if (res.status == 200) {
          _this11.detailjson = res.data;
          _this11.showdetail = true;
        }
      });
    },
    bindPrintorder: function bindPrintorder() {
      (0, _printJs.default)({
        printable: 'printorder',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        scanStyles: true,
        maxWidth: 1000,
        font_size: 'auto',
        style: '@page {margin:0 10mm}'
      });
    },
    getalldetail: function getalldetail() {
      var _this12 = this;
      if (!this.multipleSelection.length) {
        this.$message.error('请选择调拨单');
        return;
      }
      if (this.multipleSelection.length > 1) {
        this.$message.error('请选择单个调拨单');
        return;
      }
      // console.log(this.multipleSelection[0]);
      (0, _warehouse.getwarehouseOrderDetailApiV2)(this.multipleSelection[0].id).then(function (res) {
        if (res.status == 200) {
          _this12.detailjson = res.data;
          _this12.showdetail = true;
        }
      });
    },
    getStoreList: function getStoreList(num) {
      var _this13 = this;
      (0, _product.getStoreList)({
        page: 1
      }).then(function (res) {
        _this13.storeList = res.data.list.map(function (item) {
          return {
            label: item.name,
            value: item.warehouse_id
          };
        });
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    // revieworder(row) {
    //   this.handlerow = row;
    //   this.showreview = true;
    //   this.mask = "";
    // },
    // handlereview(state) {
    //   if (state == -1 && !this.mask) {
    //     this.$message.error("请填写拒绝备注");
    //     return;
    //   }
    //   review(this.handlerow.id, { status: state, remark: this.mask }).then((res) => {
    //     this.$message.success("审核成功");
    //   });
    // },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: 'OrderList',
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this14 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _warehouse.getwarehouseOrderListApiV2)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.expandData = [];
        });
        _this14.orderChartType = res.data.stat;
        _this14.tableData.data = res.data.list;
        _this14.tableData.total = res.data.count;
        _this14.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this14.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    exportOrder: function exportOrder() {
      var _this15 = this;
      this.tableFrom.export_type = 'erpTransfersWarehouseOrder';
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this15.$createElement;
        _this15.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this15.$msgbox.close();
                _this15.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('erpTransfersWarehouseOrder');
    }
  }
};