"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _warehouse = require("@/api/warehouse");
var _settings = require("@/settings");
var _product = require("@/api/product");
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
var _printJs = _interopRequireDefault(require("print-js"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _outwareOrder = _interopRequireDefault(require("@/components/printOrder/outwareOrder"));
var _setting = require("@/api/setting");
var _user = require("@/api/user.js");
var _review = require("@/api/review");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'OutWarehouse',
  components: {
    'el-image-viewer': function elImageViewer() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('element-ui/packages/image/src/image-viewer'));
      });
    },
    fileList: _fileList.default,
    outwareOrder: _outwareOrder.default
  },
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      roterPre: _settings.roterPre,
      showconfig: false,
      logisticsName: 'refund',
      id: 0,
      type: '',
      tableData: {
        data: [],
        total: 0
      },
      storeList: [],
      listLoading: true,
      tableFrom: {
        order_type: 2,
        username: '',
        type: '',
        date: '',
        page: 1,
        limit: 20,
        receipt_sn: '',
        keyword: '',
        out_status: ''
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      showreview: true,
      mask: '',
      showlabel: false,
      labelarr: [],
      showdetail: false,
      detailjson: {},
      house: {
        image: '',
        delivery_name: '',
        delivery_id: '',
        remark: ''
      },
      showouthouse: false,
      rules: {
        image: [{
          required: true,
          message: '请上传凭证'
        }]
      },
      showimage: false,
      imagearr: [],
      urlshow: false,
      urlshowList: [],
      expressList: [],
      currentrow: {},
      column: [{
        label: '出库单号',
        width: 120,
        type: 'order_no'
      }, {
        label: '出库状态',
        width: 100,
        type: 'status'
      }, {
        label: '出库库房',
        width: 100,
        type: 'outWarehouse'
      },
      // {
      //   label: "出库人",
      //   width: 80,
      //   type: "outAdmin",
      // },
      {
        label: '出库类型',
        width: 100,
        type: 'outType'
      }, {
        label: '数量',
        width: 80,
        type: 'erp_product_number'
      }, {
        label: '出库方式',
        width: 100,
        type: 'outModel'
      }, {
        label: '业务员',
        width: 80,
        type: 'outnAdmin'
      },
      // {
      //   label: "客户昵称",
      //   width: 100,
      //   type: "nickname",
      // },
      // {
      //   label: "真实姓名",
      //   width: 100,
      //   type: "reaname",
      // },
      // {
      //   label: "手机号",
      //   width: 100,
      //   type: "phone",
      // },
      {
        label: '出库时间',
        width: 120,
        type: 'out_time'
      }, {
        label: '制单人',
        width: 80,
        type: 'createAdmin'
      }, {
        label: '制单时间',
        width: 120,
        type: 'create_time'
      }],
      showsearch: false,
      searchjson: {},
      rowkeys: [],
      timeval: [],
      outheaderList: [],
      outHideList: [],
      searchQuery: {},
      userList: [],
      merchant_admin_id: ''
    }, "mask", ''), "showreview", false), "audit_id", ''), "rowLinkID", '');
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_out_warehouse_list'
    }).then(function (res) {
      _this2.outheaderList = res.data.erp_v2_out_warehouse_list.show_list;
      _this2.outHideList = res.data.erp_v2_out_warehouse_list.hide_list;
      console.log(_this2.outheaderList);
    });
    this.getList(1);
    this.getStoreList(1);
    this.getexpressList();
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this2.merData = res.data;
      _this2.merchant_admin_id = res.data.merchantAdmin.merchant_admin_id;
    });
    (0, _setting.adminListApi)().then(function (res) {
      // console.log(res);
      _this2.userList = res.data.list.map(function (item) {
        return {
          label: item.real_name,
          value: item.merchant_admin_id
        };
      });
    });
  },
  methods: {
    onSee: function onSee(row) {
      var _this3 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(row.id).then(function (res) {
        console.log(res);
        if (res.status == 200) {
          res.data.outImageList = res.data.out_image ? res.data.out_image.split(',') : [];
          _this3.detailjson = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data), {}, {
            type: 'view'
          });
          _this3.showdetail = true;
        }
      });
    },
    revieworder: function revieworder(row) {
      this.audit_id = row.audit_admin_id;
      this.rowLinkID = row.id;
      this.showreview = true;
      this.mask = '';
    },
    handlereview: function handlereview(state) {
      var _this4 = this;
      if (state == -1 && !this.mask) {
        this.$message.error('请填写拒绝备注');
        return;
      }
      (0, _review.review)(this.audit_id, {
        status: state,
        remark: this.mask,
        type: 'erp_v2_out_warehouse',
        link_id: this.rowLinkID
      }).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.getList();
        _this4.showreview = false;
      }).catch(function (err) {
        _this4.$message.error(err.message);
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        order_type: 2,
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    changeColumnWidth: function changeColumnWidth(newWidth, oldWidth, column) {
      console.log(column);
      var index = column.index;
      this.outheaderList[index].width = newWidth;
      (0, _system.setCustomHeader)({
        types: 'erp_v2_out_warehouse_list',
        show_data: this.outheaderList,
        hide_data: this.outHideList
      }).then(function (res) {}).catch(function (res) {});
      // this.columnWidthArr[index] = newWidth;
      // this.setColumnsWidth();
    },
    exchangeExpand: function exchangeExpand(row) {
      if (!this.$store.getters.userPression.merchantErpV2WarehouseOrderProductList) return;
      if (row.expandData.length) {
        return;
      }
      if (!row.id) {
        return;
      }
      this.rowkeys = [row.id];
      row.loading = true;
      (0, _warehouse.getwarehouseOrderGoodsListApiV2)({
        order_id: row.id
      }).then(function (res) {
        row.expandData = res.data;
        row.loading = false;
      });
    },
    cellClick: function cellClick(row, column, cell, event) {
      if (!this.rowkeys.includes(row.id)) {
        this.rowkeys = [row.id];
        this.exchangeExpand(row);
      } else {
        this.rowkeys.splice(this.rowkeys.indexOf(row.id), 1);
      }
    },
    searchList: function searchList(type) {
      this.getList(1);
    },
    clearsearch: function clearsearch(type) {
      if (type == 'all') {
        this.searchjson = {};
        this.timeVal = [];
      }
      var flag = false;
      for (var i in this.searchjson) {
        if (this.searchjson[i]) {
          flag = true;
        }
      }
      if (!flag) {
        this.showsearch = false;
      }
    },
    getexpressList: function getexpressList() {
      var _this5 = this;
      (0, _system.expressListApi)().then(function (res) {
        _this5.expressList = res.data;
      });
    },
    delwarehouseorder: function delwarehouseorder(row) {
      var _this6 = this;
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '不删除',
        type: 'warning'
      }).then(function () {
        (0, _warehouse.delwarehouse)(row.order_id).then(function () {
          _this6.$message.success('删除成功');
          _this6.getList();
        });
      });
    },
    losewarehouseorder: function losewarehouseorder(row) {
      var _this7 = this;
      var that = this;
      this.$prompt('', '提示', {
        confirmButtonText: '确认作废',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入作废理由',
        inputErrorMessage: '请输入作废理由'
      }).then(function (_ref2) {
        var value = _ref2.value;
        (0, _warehouse.invalidOutApi)({
          id: row.id
        }).then(function () {
          that.$message.success('作废成功');
          that.getList();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    getIntoshelfCard: function getIntoshelfCard(row) {
      this.urlshow = true;
      this.urlshowList = [row.credentials];
      return;
      this.showimage = true;
      var imagearr = [row.credentials];
      //  row.orderProduct.forEach(item => {
      //    imagearr.push(item.credentials)
      //  });
      this.imagearr = imagearr;
    },
    outhouse: function outhouse(row) {
      // this.
      console.log(row);
      this.currentrow = row;
      this.house.image = [];
      this.house.remark = '';
      this.house.delivery_name = '';
      this.house.delivery_id = '';
      this.showouthouse = true;
    },
    onUpd: function onUpd(row) {
      this.$router.push({
        path: '/merchant/warehouse/createoutorder',
        query: {
          id: row.id
        }
      });
    },
    cursorouthouse: function cursorouthouse() {
      var _this8 = this;
      if (!this.house.image) {
        this.$message.error('请上传凭证');
        return;
      }
      (0, _warehouse.confirmoutOrderApiV2)({
        id: this.currentrow.id,
        out_image: this.house.image.join(','),
        remark: this.house.remark,
        delivery_name: this.house.delivery_name || '',
        delivery_no: this.house.delivery_no || '',
        delivery_id: this.house.delivery_id || ''
      }).then(function (res) {
        if (res.status == 200) {
          _this8.$message.success('出库成功');
          _this8.getList();
          _this8.showouthouse = false;
        }
      }).catch(function (err) {
        console.log(err);
        _this8.$message.error(err.message);
      });
    },
    getStoreList: function getStoreList(num) {
      var _this9 = this;
      (0, _product.getStoreList)({
        page: 1
      }).then(function (res) {
        _this9.storeList = res.data.list.map(function (item) {
          return {
            label: item.name,
            value: item.warehouse_id
          };
        });
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    getdetail: function getdetail(id) {
      var _this10 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(id).then(function (res) {
        if (res.status == 200) {
          _this10.detailjson = res.data;
          _this10.showdetail = true;
        }
      });
    },
    getalldetail: function getalldetail() {
      var _this11 = this;
      if (!this.multipleSelection.length) {
        this.$message.error('请选择出库单');
        return;
      }
      if (this.multipleSelection.length > 1) {
        this.$message.error('请选择单个出库单');
        return;
      }
      console.log(this.multipleSelection[0]);
      (0, _warehouse.getwarehouseOrderDetailApiV2)(this.multipleSelection[0].id).then(function (res) {
        if (res.status == 200) {
          _this11.detailjson = res.data;
          _this11.showdetail = true;
        }
      });
    },
    getlabel: function getlabel(row) {
      this.showlabel = true;
      var ids = row.orderProduct.map(function (item) {
        return item.product_id;
      });
      this.getecode(ids.join(','));
    },
    getecode: function getecode(ids) {
      var _this12 = this;
      (0, _warehouse.getbarcode)({
        product_ids: ids
      }).then(function (res) {
        console.log(res);
        if (res.status == 200) {
          _this12.labelarr = res.data;
        }
      });
    },
    bindPrint: function bindPrint() {
      (0, _printJs.default)({
        printable: 'printTable',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        style: '@page {margin:0 10mm}'
      });
    },
    // revieworder(row) {
    //   this.handlerow = row;
    //   this.showreview = true;
    //   this.mask = "";
    // },
    // handlereview(state) {
    //   if (state == -1 && !this.mask) {
    //     this.$message.error("请填写拒绝备注");
    //     return;
    //   }
    //   review(this.handlerow.id, { status: state, remark: this.mask }).then((res) => {
    //     this.$message.success("审核成功");
    //   });
    // },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: 'OrderList',
        query: {
          order_id: order_id
        }
      });
    },
    bindPrintorder: function bindPrintorder() {
      (0, _printJs.default)({
        printable: 'printorder',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        scanStyles: true,
        maxWidth: 1000,
        font_size: 'auto',
        font: 'printNum',
        style: '@page {margin:0 10mm}'
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this13 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _warehouse.getwarehouseOrderListApiV2)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.expandData = [];
        });
        _this13.orderChartType = res.data.stat;
        _this13.tableData.data = res.data.list;
        _this13.tableData.total = res.data.count;
        _this13.listLoading = false;
        _this13.$nextTick(function () {
          if (_this13.$refs.multipleSelection) _this13.$refs.multipleSelection.doLayout();
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this13.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this14 = this;
      // console.log(tit, num, i);
      var _this = this;
      this.$modalUpload(function (img) {
        // console.log(img);
        if (tit === '1' && !num) {
          _this.house.image = [].concat((0, _toConsumableArray2.default)(_this.house.image), (0, _toConsumableArray2.default)(img));
        }
        if (tit === '2' && !num) {
          _this.shelf.image = img[0];
        }
        _this14.$forceUpdate();
      }, tit === '1' ? 9 : 1);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this15 = this;
      this.tableFrom.export_type = 'erpOutWarehouseOrder';
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this15.$createElement;
        _this15.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this15.$msgbox.close();
                _this15.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('erpOutWarehouseOrder');
    }
  }
};