var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: _vm.title, is_tab: false } },
    [
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.facade.type_setting[_vm.content.style],
              "label-width": "100px",
              size: "mini",
              "label-position": "left",
            },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "拍品底色" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].itemBackground,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "itemBackground",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].itemBackground",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "底色渐变" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style]
                        .itemGradientBackground,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "itemGradientBackground",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].itemGradientBackground",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "渐变方向" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value:
                        _vm.facade.type_setting[_vm.content.style]
                          .itemGradientDirection,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade.type_setting[_vm.content.style],
                          "itemGradientDirection",
                          $$v
                        )
                      },
                      expression:
                        "facade.type_setting[content.style].itemGradientDirection",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "to bottom" } }, [
                      _vm._v("向下"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: "to right" } }, [
                      _vm._v("向右"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: "to right bottom" } }, [
                      _vm._v("右下"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "拍品间距" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].itemMargin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "itemMargin",
                        $$v
                      )
                    },
                    expression: "facade.type_setting[content.style].itemMargin",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "拍品圆角" } },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "radius-list tl" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .itemTopLeftRadius,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "itemTopLeftRadius",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].itemTopLeftRadius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list tr" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .itemTopRightRadius,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "itemTopRightRadius",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].itemTopRightRadius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list bl" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .itemBottomLeftRadius,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "itemBottomLeftRadius",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].itemBottomLeftRadius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list br" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .itemBottomRightRadius,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "itemBottomRightRadius",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].itemBottomRightRadius",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "阴影" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].itemShadow,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "itemShadow",
                        $$v
                      )
                    },
                    expression: "facade.type_setting[content.style].itemShadow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "阴影大小" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].itemShadowSize,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "itemShadowSize",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].itemShadowSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _vm.content.style === 5 || _vm.content.style === 4
              ? _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("图片尺寸"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "item-align-center" },
                      [
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "80px" },
                            attrs: { type: "text", placeholder: "宽" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value:
                                _vm.facade.type_setting[_vm.content.style]
                                  .imgWidth,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade.type_setting[_vm.content.style],
                                  "imgWidth",
                                  $$v
                                )
                              },
                              expression:
                                "facade.type_setting[content.style].imgWidth",
                            },
                          },
                          [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                          2
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: { margin: "0 6px" },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "80px" },
                            attrs: { type: "text", placeholder: "宽" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value:
                                _vm.facade.type_setting[_vm.content.style]
                                  .imgHeight,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade.type_setting[_vm.content.style],
                                  "imgHeight",
                                  $$v
                                )
                              },
                              expression:
                                "facade.type_setting[content.style].imgHeight",
                            },
                          },
                          [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "图片背景颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].imgBackground,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "imgBackground",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].imgBackground",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "图片区域圆角" } },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "radius-list tl" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .imgTopLeftRadius,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "imgTopLeftRadius",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].imgTopLeftRadius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list tr" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .imgTopRightRadius,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "imgTopRightRadius",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].imgTopRightRadius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list bl" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .imgBottomLeftRadius,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "imgBottomLeftRadius",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].imgBottomLeftRadius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list br" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .imgBottomRightRadius,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "imgBottomRightRadius",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].imgBottomRightRadius",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "内容距离" } },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "radius-list t" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .descMarginTop,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "descMarginTop",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].descMarginTop",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list r" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .descMarginRight,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "descMarginRight",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].descMarginRight",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list b" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .descMarginBottom,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "descMarginBottom",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].descMarginBottom",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list l" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .descMarginLeft,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "descMarginLeft",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].descMarginLeft",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _vm.content.style == 4
              ? [
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "LOT距离" } },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          { staticClass: "radius-list t" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value:
                                  _vm.facade.type_setting[_vm.content.style]
                                    .lotMarginTop,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade.type_setting[_vm.content.style],
                                    "lotMarginTop",
                                    $$v
                                  )
                                },
                                expression:
                                  "facade.type_setting[content.style].lotMarginTop",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "LOT颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style].lotColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "lotColor",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].lotColor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "LOT字号" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .lotFontSize,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "lotFontSize",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].lotFontSize",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "LOT样式" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.facade.type_setting[_vm.content.style]
                                .lotFontWeight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade.type_setting[_vm.content.style],
                                "lotFontWeight",
                                $$v
                              )
                            },
                            expression:
                              "facade.type_setting[content.style].lotFontWeight",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("正常"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "进行中颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .ingStatusColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "ingStatusColor",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].ingStatusColor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "预展中颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .endStatusColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "endStatusColor",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].endStatusColor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "状态字号" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .ingStatusFontSize,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "ingStatusFontSize",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].ingStatusFontSize",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "状态样式" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.facade.type_setting[_vm.content.style]
                                .ingStatusFontWeight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade.type_setting[_vm.content.style],
                                "ingStatusFontWeight",
                                $$v
                              )
                            },
                            expression:
                              "facade.type_setting[content.style].ingStatusFontWeight",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("正常"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "标题距离" } },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "radius-list t" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .nameMarginTop,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "nameMarginTop",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].nameMarginTop",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "标题颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value: _vm.facade.type_setting[_vm.content.style].nameColor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "nameColor",
                        $$v
                      )
                    },
                    expression: "facade.type_setting[content.style].nameColor",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "标题字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].nameFontSize,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "nameFontSize",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].nameFontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "标题样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value:
                        _vm.facade.type_setting[_vm.content.style]
                          .nameFontWeight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade.type_setting[_vm.content.style],
                          "nameFontWeight",
                          $$v
                        )
                      },
                      expression:
                        "facade.type_setting[content.style].nameFontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "估价/标签距离" } },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "radius-list t" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .appraisalPriceMarginTop,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "appraisalPriceMarginTop",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].appraisalPriceMarginTop",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "估价颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style]
                        .appraisalPriceColor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "appraisalPriceColor",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].appraisalPriceColor",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "估价字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style]
                        .appraisalPriceFontSize,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "appraisalPriceFontSize",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].appraisalPriceFontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "估价样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value:
                        _vm.facade.type_setting[_vm.content.style]
                          .appraisalPriceFontWeight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade.type_setting[_vm.content.style],
                          "appraisalPriceFontWeight",
                          $$v
                        )
                      },
                      expression:
                        "facade.type_setting[content.style].appraisalPriceFontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "价格区域距离" } },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "radius-list t" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value:
                            _vm.facade.type_setting[_vm.content.style]
                              .priceMarginTop,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade.type_setting[_vm.content.style],
                              "priceMarginTop",
                              $$v
                            )
                          },
                          expression:
                            "facade.type_setting[content.style].priceMarginTop",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].priceTextColor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "priceTextColor",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].priceTextColor",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style]
                        .priceTextFontSize,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "priceTextFontSize",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].priceTextFontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value:
                        _vm.facade.type_setting[_vm.content.style]
                          .priceTextFontWeight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade.type_setting[_vm.content.style],
                          "priceTextFontWeight",
                          $$v
                        )
                      },
                      expression:
                        "facade.type_setting[content.style].priceTextFontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "价格颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#FFFFFF" },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].priceColor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "priceColor",
                        $$v
                      )
                    },
                    expression: "facade.type_setting[content.style].priceColor",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "价格字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value:
                      _vm.facade.type_setting[_vm.content.style].priceFontSize,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.facade.type_setting[_vm.content.style],
                        "priceFontSize",
                        $$v
                      )
                    },
                    expression:
                      "facade.type_setting[content.style].priceFontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "价格样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value:
                        _vm.facade.type_setting[_vm.content.style]
                          .priceFontWeight,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade.type_setting[_vm.content.style],
                          "priceFontWeight",
                          $$v
                        )
                      },
                      expression:
                        "facade.type_setting[content.style].priceFontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }