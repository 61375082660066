"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/basic");
var _contract = require("@/api/contract");
var _user = require("@/api/user");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "inventory",
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      unitconfig: {
        1: "(元/件)",
        2: "%"
      },
      configflag: false,
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        username: "",
        type: "",
        date: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: ""
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: "",
        mark: ""
      },
      inventoryConfig: {
        switch: {
          other_price_switch: 0,
          sold_price_switch: 0,
          unsold_price_switch: 0
        },
        price: {
          other_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          sold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          unsold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }]
        }
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      lastAuction: [],
      lastAuctionId: "",
      formvisible: {
        logistics_type: "express",
        logistics_user_name: "",
        logistics_phone: "",
        logistics_address: ""
      },
      showflag: false,
      searchQuery: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    // this.getList(1);
    this.getconfig();
    (0, _contract.getLastAuctionApi)().then(function (res) {
      _this.lastAuction = res.data.list;
      if (localStorage.getItem('lastAuctionId')) {
        _this.lastAuctionId = Number(localStorage.getItem('lastAuctionId'));
        _this.venueName = localStorage.getItem('venueName');
        _this.getList(1);
      } else if (res.data.list[0]) {
        _this.lastAuctionId = res.data.list[0].venue_id;
        _this.venueName = res.data.list[0].title;
        _this.getList(1);
      }
    }).catch(function (res) {
      //
    });
  },
  methods: {
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    logisticsOrder: function logisticsOrder() {
      var _this2 = this;
      if (!this.multipleSelection.length) {
        this.$message({
          message: "请选择拍品",
          type: "warning"
        });
        return;
      }
      var row = this.multipleSelection[0];
      var arr = this.multipleSelection.filter(function (item) {
        return item.entrustUser.uid != row.entrustUser.uid;
      });
      if (arr.length > 0) {
        this.$message({
          message: "请选择同一委托人",
          type: "warning"
        });
        return;
      }
      var ids = this.multipleSelection.map(function (item) {
        return item.id;
      });
      this.formvisible = {
        logistics_type: "express",
        logistics_user_name: "",
        logistics_phone: "",
        logistics_address: row.logistics_address,
        check_auction_ids: ids.join(',')
      };
      this.row = row;
      this.showflag = true;
      (0, _user.getUserDetail)(row.entrustUser.uid).then(function (res) {
        console.log(res);
        if (res.data.userVerify) {
          _this2.formvisible.logistics_user_name = res.data.userVerify.real_name;
        }
        _this2.formvisible.logistics_phone = res.data.phone;
        // this.formvisible.logistics_address = res.data.userAddress || "";
        _this2.formvisible.logistics_address = res.data.userAddress ? "".concat(res.data.userAddress.province).concat(res.data.userAddress.city).concat(res.data.userAddress.district).concat(res.data.userAddress.detail) : row.logistics_address;
      });
    },
    createPushOrder: function createPushOrder() {
      var _this3 = this;
      console.log(this.multipleSelection);
      if (!this.multipleSelection.length) {
        this.$message({
          message: "请选择拍品",
          type: "warning"
        });
        return;
      }
      this.multipleSelection.forEach(function (item) {
        if (!item.erpProduct.warehouse_id) {
          _this3.$message.error('所选货品存在未关联仓库的货品');
          return false;
        }
        if (item.erpProduct.product_warehouse_status != 2) {
          _this3.$message.error('所选货品中有出库中的货品');
          return false;
        }
        if (!item.contract_id) {
          _this3.$message.error('所选货品存在未关联合同的货品');
          return false;
        }
        if (!item.logistics_type) {
          _this3.$message.error('所选货品存在未设置物流方式的货品');
          return false;
        }
      });
      var ids = [];
      this.multipleSelection.forEach(function (item) {
        ids.push(item.id);
      });
      this.$router.push({
        path: "/merchant/contract/returntooutorder",
        query: {
          ids: ids.join(',')
        }
      });
    },
    changeAuctionId: function changeAuctionId() {
      var _this4 = this;
      // 查找lastAuction里的id
      this.lastAuction.forEach(function (item) {
        if (item.venue_id == _this4.lastAuctionId) {
          _this4.venueName = item.title;
        }
      });
      localStorage.setItem("lastAuctionId", this.lastAuctionId);
      localStorage.setItem("venueName", this.venueName);
      this.getList(1);
    },
    onSubmit: function onSubmit() {
      var _this5 = this;
      if (this.formvisible.logistics_type == 'express' && !this.formvisible.logistics_user_name) {
        this.$message({
          message: "请填写姓名",
          type: "warning"
        });
        return;
      }
      if (this.formvisible.logistics_type == 'express' && !this.formvisible.logistics_phone) {
        this.$message({
          message: "请填写电话",
          type: "warning"
        });
        return;
      }
      if (this.formvisible.logistics_type == 'express' && !this.formvisible.logistics_address) {
        this.$message({
          message: "请填写地址",
          type: "warning"
        });
        return;
      }
      (0, _contract.updateLogisticsApi)(this.formvisible).then(function (res) {
        _this5.$message({
          message: "修改成功",
          type: "success"
        });
        _this5.multipleSelection = [];
        _this5.getList(1);
        _this5.showflag = false;
      });
    },
    editstatic: function editstatic(row) {
      var _this6 = this;
      this.formvisible = {
        logistics_type: "express",
        logistics_user_name: "",
        logistics_phone: "",
        logistics_address: row.logistics_address,
        check_auction_ids: row.id
      };
      this.row = row;
      this.showflag = true;
      (0, _user.getUserDetail)(row.entrustUser.uid).then(function (res) {
        console.log(res);
        if (res.data.userVerify) {
          _this6.formvisible.logistics_user_name = res.data.userVerify.real_name;
        }
        _this6.formvisible.logistics_phone = res.data.phone;
        // this.formvisible.logistics_address = res.data.userAddress || "";
        _this6.formvisible.logistics_address = res.data.userAddress ? "".concat(res.data.userAddress.province).concat(res.data.userAddress.city).concat(res.data.userAddress.district).concat(res.data.userAddress.detail) : row.logistics_address;
      });
    },
    getconfig: function getconfig() {
      var _this7 = this;
      (0, _basic.contractConfig)().then(function (res) {
        console.log(res);
        if (res.status == 200) {
          if (res.data.price && res.data.price.sold_price && res.data.price.sold_price.length > 1) {
            _this7.inventoryConfig = res.data;
            _this7.configflag = true;
            _this7.$forceUpdate();
          }
        }
      });
    },
    saveconfig: function saveconfig() {
      var _this8 = this;
      (0, _basic.editcontractConfig)({
        entrust_order_field_config: JSON.stringify(this.inventoryConfig)
      }).then(function (res) {
        if (res.status == 200) {
          _this8.showconfig = false;
          _this8.$message.success("保存成功");
        }
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList("");
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList("");
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this9 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.tableFrom.is_entrust_refund = 1;
      this.tableFrom.venue_id = this.lastAuctionId;
      (0, _contract.getCheckOrderListApi)(this.tableFrom).then(function (res) {
        _this9.orderChartType = res.data.stat;
        _this9.tableData.data = res.data.list;
        _this9.tableData.total = res.data.count;
        _this9.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this9.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    }
  }
};