"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _author = _interopRequireDefault(require("@/views/aiMarketing/module/author"));
var _auction = require("@/api/auction");
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'videoAuction',
  components: {
    SelectAuthorView: _author.default
  },
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    hasMoreData: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    allImage: {
      type: String,
      default: ''
    },
    openType: {
      type: String,
      default: ''
    },
    openTitle: {
      type: String,
      default: ''
    },
    venue_id: {
      type: String | Number
    },
    relation_id: {
      type: String | Number
    }
  },
  data: function data() {
    return {
      radioVal: '1',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      auctionName: '',
      venueLoading: false,
      // venue_id: '',
      album_id: '',
      venueList: [],
      albumList: [],
      isAll: false,
      auctionForm: {
        make_tag: ['1'],
        type: ['1']
      },
      makeTagOption: [],
      isAuction: false,
      auction_id: ''
    };
  },
  watch: {
    flag: function flag(val) {
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.showSelectAuction = this.flag;
    (0, _aiMark.getMediaTypeApi)().then(function (res) {
      var arr = [];
      for (var key in res.data.make_tag) {
        arr.push({
          label: res.data.make_tag[key],
          value: key
        });
      }
      _this.makeTagOption = arr;
    });
    // document.addEventListener('click', this.toggleVisibility);
    // this.artSearch('');
  },
  // beforeDestroy() {
  //   document.removeEventListener('click', this.toggleVisibility);
  // },
  methods: {
    // toggleVisibility(event) {
    //   if (!event.target.closest('.selectDom')) {
    //     this.artisId = '';
    //   }
    // },
    onSeleAuthor: function onSeleAuthor(row) {
      var arr = [];
      if (row.artist_info && row.artist_info.length > 0) {
        arr = row.artist_info.map(function (item) {
          return {
            id: item.id,
            avatar: item.avatar,
            name: item.name,
            intro: item.intro
          };
        });
      }
      this.auction_id = row.product_id;
      this.$refs.authoViewRef.openInit(arr);
    },
    // onClickArt(id,list) {
    //   if (list.length > 0) {
    //     this.artSearch('',2,id);
    //   }
    //   else {
    //     this.artisId = id;
    //     this.$forceUpdate();
    //   }
    //   // this.$nextTick(() => {
    //   //   this.$refs[`selse${id}`][0].focus();
    //   // });
    //   // this.$forceUpdate();
    // },
    onAuthorDele: function onAuthorDele(row, id) {
      this.auction_id = row.product_id;
      var index = row.artist_info.findIndex(function (item) {
        return item.id == id;
      });
      row.artist_info.splice(index, 1);
      this.onAuthorMessage(row.artist_info);
    },
    onAuthorMessage: function onAuthorMessage(data) {
      var _this2 = this;
      var ids = data.map(function (item) {
        return item.id;
      });
      (0, _auction.setAuctionArtistApi)({
        auction_id: this.auction_id,
        artist_id: ids.join(',')
      }).then(function (res) {
        _this2.loadAuction();
        // this.artisId = '';
      });
    },
    // onArtBlur(auction_id,artist_id) {
    //   setAuctionArtistApi({auction_id,artist_id}).then(res => {
    //     this.loadAuction();
    //     this.artisId = '';
    //   })
    // },
    artSearch: function artSearch(val, type, id) {
      var _this3 = this;
      artistListApi({
        page: 1,
        limit: 15,
        name: val
      }).then(function (res) {
        // console.log('1---',res);
        _this3.artistList = res.data.list;
        if (type) {
          _this3.artisId = id;
        }
        _this3.$forceUpdate();
      });
    },
    changeshow: function changeshow(num) {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      // if (this.resultData.length === 0) {
      this.queryParams = {
        page: 1,
        limit: 10
      };
      this.loadAuction();
      this.isAuction = num == 1 ? true : false;
      // } else {
      //   this.setAllStatus();
      // }

      this.showSelectAuction = true;
    },
    searchAAction: function searchAAction() {
      this.queryParams.album_id = this.relation_id ? this.relation_id : this.album_id;
      this.queryParams.keyword = this.auctionName;
      this.queryParams.page = 1;
      this.loadAuction();
    },
    loadAuction: function loadAuction(fun) {
      var _this4 = this;
      this.queryParams.venue_id = this.venue_id;
      if (this.radioVal == '2') this.queryParams.product_ids = this.selectedAuctionIdList.join(',');else this.queryParams.product_ids = '';
      if (this.relation_id) this.queryParams.album_id = this.relation_id;
      (0, _auction.aiAuctionSList)(this.queryParams).then(function (response) {
        _this4.total = response.data.count;
        // console.log(response.data);
        response.data.list.forEach(function (item) {
          if (item.artist_info) {
            item.artist_id = item.artist_info.map(function (item) {
              return item.id;
            });
          } else item.artist_id = [];
        });
        _this4.resultData = response.data.list;
        _this4.setAllStatus();
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        image: info.product.image + '!300',
        name: info.product.store_name,
        id: info.product_id,
        price: info.now_price || info.start_price
      };
      if (this.hasMoreData) {
        data.image = info.product.image + '!m640';
        data.attr_info = info.attr_info;
        data.store_info = info.product.store_info;
        data.content = info.content && info.content.content ? info.content.content : '';
        data.desc = '';
      }
      if (this.allImage) {
        data.slider_image = info.product.slider_image;
      }
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      // if (this.size === '1') {
      //   this.cursorSelect();
      // }
      this.setAllStatus();
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      if (this.openType == 'auctionVideo' && !this.auctionForm.make_type) {
        this.$message.error('请选择拍品类型');
        return false;
      }
      if (this.openType == 'auctionVideo' && !this.auctionForm.make_tag) {
        this.$message.error('请选择业务场景');
        return false;
      }
      if (this.selectedAuction.length == 0) {
        this.$message.error('请选择拍品');
        return false;
      }
      if (this.isAuction && this.selectedAuction.length > 1) {
        this.$message.error('只能选择一件拍品');
        return false;
      }
      if (this.openType == 'auctionVideo') {
        this.$emit('createAuction', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.auctionForm), {}, {
          selectRow: this.selectedAuction
        }));
      } else this.$emit('selectMessage', this.selectedAuction);
      this.showSelectAuction = false;
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    },
    searchVenueAction: function searchVenueAction(data) {
      var _this5 = this;
      var params = {
        keyword: data,
        venue_type: '',
        page: 1,
        limit: 10
      };
      (0, _auction.meetingList)(params).then(function (res) {
        _this5.venueList = res.data.list;
      });
    },
    searchAlbumAction: function searchAlbumAction(data) {
      var _this6 = this;
      var params = {
        venue_id: this.venue_id,
        keyword: data,
        page: 1,
        limit: 10
      };
      (0, _auction.albumlist)(params).then(function (res) {
        _this6.albumList = res.data.list;
      });
    },
    // changeMeetingAction(e, noLoad) {
    //   this.album_id = '';
    //   const params = {
    //     venue_id: this.venue_id,
    //     page: 1,
    //     limit: 100
    //   };
    //   albumlist(params).then(res => {
    //     this.albumList = res.data.list;
    //   });
    //   if (!noLoad) {
    //     this.searchAAction();
    //   }
    // },
    setAllStatus: function setAllStatus() {
      var isAll = true;
      for (var i = 0; i < this.resultData.length; i += 1) {
        var m = this.resultData[i];
        var index = this.selectedAuctionIdList.indexOf(m.product_id);
        if (index === -1) {
          isAll = false;
          break;
        }
      }
      this.isAll = isAll;
    },
    auctionSelectedAllAction: function auctionSelectedAllAction() {
      var _this7 = this;
      if (this.isAll) {
        var allData = JSON.parse(JSON.stringify(this.selectedAuction));
        this.resultData.forEach(function (m) {
          var ids = allData.map(function (m) {
            return m.id;
          });
          var i = ids.indexOf(m.product_id);
          if (i !== -1) {
            allData.splice(i, 1);
          }
        });
        this.selectedAuction = allData;
      } else {
        var data = [];
        this.resultData.forEach(function (m) {
          var i = _this7.selectedAuctionIdList.indexOf(m.product_id);
          if (i === -1) {
            var d = {
              image: m.product.image + '!300',
              name: m.product.store_name,
              id: m.product_id,
              price: m.now_price || m.start_price
            };
            if (_this7.hasMoreData) {
              d.image = m.product.image + '!m640';
              d.attr_info = m.attr_info;
              d.store_info = m.product.store_info;
              d.content = m.content && m.content.content ? m.content.content : '';
              d.desc = '';
            }
            data.push(d);
          }
        });
        this.selectedAuction = this.selectedAuction.concat(data);
      }
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
    }
  }
};