"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCodeVersion = addCodeVersion;
exports.appPushTaskList = appPushTaskList;
exports.attackDataApi = attackDataApi;
exports.attackDataDetails = attackDataDetails;
exports.attackDetailsExports = attackDetailsExports;
exports.attackExports = attackExports;
exports.batchAddBroadcastGoodsApi = batchAddBroadcastGoodsApi;
exports.batchDisableApi = batchDisableApi;
exports.boxTradeListApi = boxTradeListApi;
exports.broadcastDeleteApi = broadcastDeleteApi;
exports.broadcastDetailApi = broadcastDetailApi;
exports.broadcastGoodsDeleteApi = broadcastGoodsDeleteApi;
exports.broadcastGoodsImportApi = broadcastGoodsImportApi;
exports.broadcastListApi = broadcastListApi;
exports.broadcastProDeleteApi = broadcastProDeleteApi;
exports.broadcastProDetailApi = broadcastProDetailApi;
exports.broadcastProListApi = broadcastProListApi;
exports.broadcastProRemarksApi = broadcastProRemarksApi;
exports.broadcastRemarksApi = broadcastRemarksApi;
exports.cancelBoxConsignApi = cancelBoxConsignApi;
exports.canuseGame = canuseGame;
exports.changeProDisplayApi = changeProDisplayApi;
exports.changeStudioRoomDisplayApi = changeStudioRoomDisplayApi;
exports.combinationActivityLst = combinationActivityLst;
exports.combinationCreateProApi = combinationCreateProApi;
exports.combinationDataApi = combinationDataApi;
exports.combinationDetailApi = combinationDetailApi;
exports.combinationProDeleteApi = combinationProDeleteApi;
exports.combinationProDetailApi = combinationProDetailApi;
exports.combinationProLst = combinationProLst;
exports.combinationProSort = combinationProSort;
exports.combinationProUpdateApi = combinationProUpdateApi;
exports.combinationStatusApi = combinationStatusApi;
exports.confirmSettleApi = confirmSettleApi;
exports.consignBoxListApi = consignBoxListApi;
exports.consignConfEditApi = consignConfEditApi;
exports.consignListApi = consignListApi;
exports.consignNftBoxConfEditApi = consignNftBoxConfEditApi;
exports.consignNftBoxListApi = consignNftBoxListApi;
exports.consignNftListApi = consignNftListApi;
exports.consignNftRecordListApi = consignNftRecordListApi;
exports.consignStatusEditApi = consignStatusEditApi;
exports.couponCloneApi = couponCloneApi;
exports.couponCreateApi = couponCreateApi;
exports.couponCreatedApi = couponCreatedApi;
exports.couponDeleteApi = couponDeleteApi;
exports.couponDetailApi = couponDetailApi;
exports.couponIssueApi = couponIssueApi;
exports.couponIssueDeleteApi = couponIssueDeleteApi;
exports.couponIssueListApi = couponIssueListApi;
exports.couponIssuePushApi = couponIssuePushApi;
exports.couponIssueStatusApi = couponIssueStatusApi;
exports.couponListApi = couponListApi;
exports.couponSelectApi = couponSelectApi;
exports.couponSendApi = couponSendApi;
exports.couponSendLstApi = couponSendLstApi;
exports.couponUpdateApi = couponUpdateApi;
exports.createAppPushTask = createAppPushTask;
exports.createBroadcastApi = createBroadcastApi;
exports.createBroadcastProApi = createBroadcastProApi;
exports.createPlane = createPlane;
exports.delAppPushTask = delAppPushTask;
exports.delCodeVersion = delCodeVersion;
exports.delGameCanuseCollection = delGameCanuseCollection;
exports.delPlane = delPlane;
exports.delexchangeGoods = delexchangeGoods;
exports.disableNftApi = disableNftApi;
exports.distributorAddTeamApi = distributorAddTeamApi;
exports.distributorCreateApi = distributorCreateApi;
exports.distributorDeleteApi = distributorDeleteApi;
exports.distributorDeleteTeamApi = distributorDeleteTeamApi;
exports.distributorListApi = distributorListApi;
exports.distributorOrderListApi = distributorOrderListApi;
exports.distributorStatisticsApi = distributorStatisticsApi;
exports.distributorSwitchStatusApi = distributorSwitchStatusApi;
exports.distributorTeamListApi = distributorTeamListApi;
exports.drawActiveCreate = drawActiveCreate;
exports.drawActiveDel = drawActiveDel;
exports.drawActiveDetail = drawActiveDetail;
exports.drawActiveEdit = drawActiveEdit;
exports.drawActiveList = drawActiveList;
exports.drawActiveRecord = drawActiveRecord;
exports.drawActiveSend = drawActiveSend;
exports.drawActiveStop = drawActiveStop;
exports.editCodeVersion = editCodeVersion;
exports.editHelpInviteNumApi = editHelpInviteNumApi;
exports.editPlane = editPlane;
exports.exchangeGoodsCreate = exchangeGoodsCreate;
exports.exchangeGoodsList = exchangeGoodsList;
exports.exchangeGoodsRecords = exchangeGoodsRecords;
exports.exportReceiveListApi = exportReceiveListApi;
exports.exportTradeListApi = exportTradeListApi;
exports.exportTransactionListApi = exportTransactionListApi;
exports.fissionCreateApi = fissionCreateApi;
exports.fissionDetailApi = fissionDetailApi;
exports.fissionEditApi = fissionEditApi;
exports.fissionList = fissionList;
exports.fissionSwitchStatusApi = fissionSwitchStatusApi;
exports.fissionTaskListApi = fissionTaskListApi;
exports.furnaceCreateApi = furnaceCreateApi;
exports.furnaceCreateRuleApi = furnaceCreateRuleApi;
exports.furnaceDelRuleApi = furnaceDelRuleApi;
exports.furnaceEditRuleApi = furnaceEditRuleApi;
exports.furnaceGetInfoApi = furnaceGetInfoApi;
exports.furnaceRecordApi = furnaceRecordApi;
exports.furnaceRuleDetailApi = furnaceRuleDetailApi;
exports.furnaceRuleListApi = furnaceRuleListApi;
exports.getGameCanuseCollection = getGameCanuseCollection;
exports.getGameTowerConfig = getGameTowerConfig;
exports.getPlaneDetail = getPlaneDetail;
exports.getSmsSignApi = getSmsSignApi;
exports.getSmsTempApi = getSmsTempApi;
exports.getUserNum = getUserNum;
exports.getplaneList = getplaneList;
exports.hasCodeVersion = hasCodeVersion;
exports.helpCreatedApi = helpCreatedApi;
exports.helpDetailApi = helpDetailApi;
exports.helpEditApi = helpEditApi;
exports.helpListApi = helpListApi;
exports.helpRankingApi = helpRankingApi;
exports.helpTaskListApi = helpTaskListApi;
exports.integralConfGetInfoApi = integralConfGetInfoApi;
exports.integralConfSaveApi = integralConfSaveApi;
exports.integralConfStatisticsApi = integralConfStatisticsApi;
exports.integralDetailedApi = integralDetailedApi;
exports.integralRankingCreateApi = integralRankingCreateApi;
exports.integralRankingDataApi = integralRankingDataApi;
exports.integralRankingDelApi = integralRankingDelApi;
exports.integralRankingEditApi = integralRankingEditApi;
exports.integralRankingExporterApi = integralRankingExporterApi;
exports.integralRankingListApi = integralRankingListApi;
exports.integralReceivingRecordListApi = integralReceivingRecordListApi;
exports.integralRuleCreateApi = integralRuleCreateApi;
exports.integralRuleDelApi = integralRuleDelApi;
exports.integralRuleDetailsApi = integralRuleDetailsApi;
exports.integralRuleEditApi = integralRuleEditApi;
exports.integralRuleGiveApi = integralRuleGiveApi;
exports.integralRuleListApi = integralRuleListApi;
exports.integralUseRecordListApi = integralUseRecordListApi;
exports.inviteLetterCreateApi = inviteLetterCreateApi;
exports.inviteLetterDelApi = inviteLetterDelApi;
exports.inviteLetterDetailApi = inviteLetterDetailApi;
exports.inviteLetterEditApi = inviteLetterEditApi;
exports.inviteLetterListApi = inviteLetterListApi;
exports.inviteLetterUserCreateApi = inviteLetterUserCreateApi;
exports.inviteLetterUserDelApi = inviteLetterUserDelApi;
exports.inviteLetterUserListApi = inviteLetterUserListApi;
exports.inviteLetterUserSwitchStatusApi = inviteLetterUserSwitchStatusApi;
exports.issueApi = issueApi;
exports.jfdelexchangeGoods = jfdelexchangeGoods;
exports.jfexchangeGoodsCreate = jfexchangeGoodsCreate;
exports.jfexchangeGoodsList = jfexchangeGoodsList;
exports.jfexchangeGoodsRecords = jfexchangeGoodsRecords;
exports.jfsendExchangeGoods = jfsendExchangeGoods;
exports.marketBoxListApi = marketBoxListApi;
exports.marketNftListApi = marketNftListApi;
exports.merchantChannelConfigApi = merchantChannelConfigApi;
exports.nftIntegralConfig = nftIntegralConfig;
exports.nftIntegralConfigSave = nftIntegralConfigSave;
exports.nftIntegralCreate = nftIntegralCreate;
exports.nftIntegralDelete = nftIntegralDelete;
exports.nftIntegralDetail = nftIntegralDetail;
exports.nftIntegralList = nftIntegralList;
exports.nftIntegralUpdate = nftIntegralUpdate;
exports.playgameTDrank = playgameTDrank;
exports.pushConfigListApi = pushConfigListApi;
exports.receiveListApi = receiveListApi;
exports.removeCodeVersion = removeCodeVersion;
exports.saveServiceRateApi = saveServiceRateApi;
exports.saveServiceRateNftBoxApi = saveServiceRateNftBoxApi;
exports.sendCodeVersionList = sendCodeVersionList;
exports.sendExchangeGoods = sendExchangeGoods;
exports.setGameCanuseCollection = setGameCanuseCollection;
exports.setGameCanuseCollectionProperties = setGameCanuseCollectionProperties;
exports.setGameTowerConfig = setGameTowerConfig;
exports.setGameTowerSwitch = setGameTowerSwitch;
exports.setPushConfigApi = setPushConfigApi;
exports.settlementRecordListApi = settlementRecordListApi;
exports.shareInviteListApi = shareInviteListApi;
exports.shareListApi = shareListApi;
exports.smsSignCreateApi = smsSignCreateApi;
exports.smsSignDelApi = smsSignDelApi;
exports.smsSignDetailApi = smsSignDetailApi;
exports.smsSignListApi = smsSignListApi;
exports.smsSignSetDefaultApi = smsSignSetDefaultApi;
exports.smsSignSubmitApi = smsSignSubmitApi;
exports.smsSignUpdateApi = smsSignUpdateApi;
exports.smsTaskCreatedApi = smsTaskCreatedApi;
exports.smsTaskDelApi = smsTaskDelApi;
exports.smsTaskInfoApi = smsTaskInfoApi;
exports.smsTaskListApi = smsTaskListApi;
exports.smsTaskUpdateApi = smsTaskUpdateApi;
exports.smsTempCreatedApi = smsTempCreatedApi;
exports.smsTempDelApi = smsTempDelApi;
exports.smsTempSubmitApi = smsTempSubmitApi;
exports.smsTempUpdateApi = smsTempUpdateApi;
exports.smsTempcreateAndSubmitApi = smsTempcreateAndSubmitApi;
exports.stopCodeVersion = stopCodeVersion;
exports.stopCodeVersionList = stopCodeVersionList;
exports.studioEdit = studioEdit;
exports.studioProList = studioProList;
exports.synthesisAddPlayApi = synthesisAddPlayApi;
exports.synthesisAddPlayNewApi = synthesisAddPlayNewApi;
exports.synthesisCreateApi = synthesisCreateApi;
exports.synthesisDeleteApi = synthesisDeleteApi;
exports.synthesisDeleteGoodsApi = synthesisDeleteGoodsApi;
exports.synthesisDeletePlayApi = synthesisDeletePlayApi;
exports.synthesisDeletePlayNewApi = synthesisDeletePlayNewApi;
exports.synthesisDetailApi = synthesisDetailApi;
exports.synthesisEditApi = synthesisEditApi;
exports.synthesisEditPlayApi = synthesisEditPlayApi;
exports.synthesisEditPlayNewApi = synthesisEditPlayNewApi;
exports.synthesisListApi = synthesisListApi;
exports.synthesisPlayListApi = synthesisPlayListApi;
exports.synthesisPlayListNewApi = synthesisPlayListNewApi;
exports.synthesisRecordListApi = synthesisRecordListApi;
exports.synthesisSwitchStatusApi = synthesisSwitchStatusApi;
exports.synthesisUpdateGoodsApi = synthesisUpdateGoodsApi;
exports.tradeListApi = tradeListApi;
exports.transactionListApi = transactionListApi;
exports.updateAppPushTask = updateAppPushTask;
exports.updateBroadcastApi = updateBroadcastApi;
exports.updateDistributorApi = updateDistributorApi;
exports.userhasPlateHistory = userhasPlateHistory;
exports.userhasPlateList = userhasPlateList;
exports.wineCreateApi = wineCreateApi;
exports.wineDeleteApi = wineDeleteApi;
exports.wineDetailApi = wineDetailApi;
exports.wineListApi = wineListApi;
exports.winePriceRecordApi = winePriceRecordApi;
exports.winePriceRecordCreateApi = winePriceRecordCreateApi;
exports.winePriceRecordDeleteApi = winePriceRecordDeleteApi;
exports.wineShowApi = wineShowApi;
exports.wineSortApi = wineSortApi;
exports.wineUpdateApi = wineUpdateApi;
var _order = require("./order");
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 优惠券模板 -- 新增表单
 */
function couponCreateApi() {
  return _request.default.get("store/coupon/create/form");
}

/**
 * @description 已发布优惠券 -- 列表
 */
function couponIssueListApi(data) {
  return _request.default.get("store/coupon/lst", data);
}
/**
 * @description 已发布优惠券 -- 添加优惠券
 */
function couponIssuePushApi() {
  return _request.default.get("store/coupon/create/form");
}
/**
 * @description 优惠券列表 -- 删除
 */
function couponIssueDeleteApi(id) {
  return _request.default.delete("store/coupon/issue/".concat(id));
}
/**
 * @description 优惠券列表 -- 复制
 */
function couponCloneApi(id) {
  return _request.default.get("store/coupon/clone/form/".concat(id));
}
/**
 * @description 优惠券列表 -- 领取记录
 */
function issueApi(data) {
  return _request.default.get("store/coupon/issue", data);
}
/**
 * @description 赠送优惠券组件列表 -- 列表
 */
function couponSelectApi(data) {
  return _request.default.get("store/coupon/select", data);
}
/**
 * @description 优惠劵 -- 发送
 */
function couponSendApi(data) {
  return _request.default.post("store/coupon/send", data);
}
/**
 * @description 优惠券发送记录 -- 列表
 */
function couponSendLstApi(data) {
  return _request.default.get("store/coupon_send/lst", data);
}
/**
 * @description 直播间 -- 创建直播间
 */
function createBroadcastApi() {
  return _request.default.get("broadcast/room/create/form");
}

/**
 * @description 直播间 -- 直播间列表
 */
function broadcastListApi(data) {
  return _request.default.get("broadcast/room/lst", data);
}
/**
 * @description 直播间 -- 直播间详情
 */
function broadcastDetailApi(id) {
  return _request.default.get("broadcast/room/detail/".concat(id));
}
/**
 * @description 直播间 -- 备注
 */
function broadcastRemarksApi(id, mark) {
  return _request.default.post("broadcast/room/mark/".concat(id), {
    mark: mark
  });
}
/**
 * @description 直播间商品 -- 创建直播间商品
 */
function createBroadcastProApi() {
  return _request.default.get("broadcast/goods/create/form");
}
/**
 * @description 直播间商品 -- 编辑
 */
function updateBroadcastApi(id) {
  return _request.default.get("broadcast/goods/update/form/".concat(id));
}
/**
 * @description 直播间商品 -- 直播间商品列表
 */
function broadcastProListApi(data) {
  return _request.default.get("broadcast/goods/lst", data);
}
/**
 * @description 直播间商品 -- 直播间商品详情
 */
function broadcastProDetailApi(id) {
  return _request.default.get("broadcast/goods/detail/".concat(id));
}
/**
 * @description 直播间商品 -- 修改显示状态（上下架）
 */
function changeProDisplayApi(id, data) {
  return _request.default.post("broadcast/goods/status/".concat(id), data);
}
/**
 * @description 直播间 -- 商品导入
 */
function broadcastGoodsImportApi(data) {
  return _request.default.post("broadcast/room/export_goods", data);
}
/**
 * @description 直播间 -- 备注
 */
function broadcastProRemarksApi(id, mark) {
  return _request.default.post("broadcast/goods/mark/".concat(id), {
    mark: mark
  });
}

/**
 * @description 直播间-- 修改显示状态（上下架）
 */
function changeStudioRoomDisplayApi(id, data) {
  return _request.default.post("broadcast/room/status/".concat(id), data);
}
/**
 * @description 直播间 -- 直播间商品
 */
function studioProList(id, data) {
  return _request.default.get("broadcast/room/goods/".concat(id), data);
}
/**
 * @description 直播间商品 -- 删除
 */
function broadcastProDeleteApi(broadcast_goods_id) {
  return _request.default.delete("broadcast/goods/delete/".concat(broadcast_goods_id));
}
/**
 * @description 直播间 -- 删除
 */
function broadcastDeleteApi(broadcast_room_id) {
  return _request.default.delete("broadcast/room/delete/".concat(broadcast_room_id));
}
/**
 * @description 直播间商品 -- 批量添加
 */
function batchAddBroadcastGoodsApi(data) {
  return _request.default.post("broadcast/goods/batch_create", data);
}
/**
 * @description 直播间已导入商品 -- 删除
 */
function broadcastGoodsDeleteApi(data) {
  return _request.default.post("broadcast/room/rm_goods", data);
}
/**
 * @description 直播间 -- 编辑
 */
function studioEdit(id) {
  return _request.default.get("broadcast/room/update/form/".concat(id));
}
/**
 * @description 拼团 -- 获取数据
 */
function combinationDataApi() {
  return _request.default.get("config/others/group_buying");
}
/**
 * @description 拼团 -- 创建拼团商品
 */
function combinationCreateProApi(data) {
  return _request.default.post("store/product/group/create", data);
}
/**
 * @description 拼团 -- 编辑拼团商品
 */
function combinationProUpdateApi(id, data) {
  return _request.default.post("store/product/group/update/".concat(id), data);
}
/**
 * @description 拼团 -- 商品列表
 */
function combinationProLst(data) {
  return _request.default.get("store/product/group/lst", data);
}
/**
 * @description 拼团 -- 商品列表（查看详情）
 */
function combinationProDetailApi(id) {
  return _request.default.get("store/product/group/detail/".concat(id));
}
/**
 * @description 拼团 -- 商品列表（删除）
 */
function combinationProDeleteApi(id) {
  return _request.default.delete("store/product/group/delete/".concat(id));
}

/**
 * @description 拼团商品列表 -- 显示状态（上下架）
 */
function combinationStatusApi(id, status) {
  return _request.default.post("store/product/group/status/".concat(id), {
    status: status
  });
}
/**
 * @description 拼团活动 -- 活动列表
 */
function combinationActivityLst(data) {
  return _request.default.get("store/product/group/buying/lst", data);
}
/**
 * @description 拼团活动 -- 查看详情
 */
function combinationDetailApi(id, data) {
  return _request.default.get("store/product/group/buying/detail/".concat(id), data);
}
/**
 * @description 拼团商品 -- 列表排序
 */
function combinationProSort(id, data) {
  return _request.default.post("/store/product/group/sort/".concat(id), data);
}
/**
 * @description 粉丝裂变 -- 活动列表
 */
function fissionList(data) {
  return _request.default.get("activity/fission/list", data);
}
/**
 * @description 粉丝裂变 -- 活动详情
 */
function fissionDetailApi(id) {
  return _request.default.get("activity/fission/info/".concat(id));
}
/**
 * @description 粉丝裂变 -- 创建活动
 */
function fissionCreateApi(data) {
  return _request.default.post("activity/fission/create", data);
}
/**
 * @description 粉丝裂变 -- 修改活动
 */
function fissionEditApi(data) {
  return _request.default.post("activity/fission/edit", data);
}
/**
 * @description 粉丝裂变 -- 修改活动
 */
function fissionSwitchStatusApi(id, data) {
  return _request.default.post("activity/fission/switchStatus/".concat(id), data);
}
/**
 * @description 粉丝裂变 -- 活动任务列表
 */
function fissionTaskListApi(data) {
  return _request.default.get("activity/fission/taskList", data);
}

/**
 * @description 助力有奖 -- 活动列表
 */
function helpListApi(data) {
  return _request.default.get("activity/help/list", data);
}

/**
 * @description 助力有奖 -- 活动任务列表
 */
function helpTaskListApi(data) {
  return _request.default.get("activity/help/taskList", data);
}

/**
 * @description 助力有奖 -- 活动详情
 */
function helpDetailApi(id) {
  return _request.default.get("activity/help/info/".concat(id));
}
/**
 * @description 助力有奖 -- 创建活动
 */
function helpCreatedApi(data) {
  return _request.default.post("activity/help/create", data);
}
/**
 * @description 助力有奖 -- 修改活动
 */
function helpEditApi(data) {
  return _request.default.post("activity/help/edit", data);
}
/**
 * @description 合成列表 -- 活动列表
 */
function synthesisListApi(data) {
  return _request.default.get("activity/synthetic/album/lst", data);
}
/**
 * @description 合成 -- 活动详情
 */
function synthesisDetailApi(id) {
  return _request.default.get("activity/synthetic/album/detail/".concat(id));
}
/**
 * @description 合成 -- 添加活动
 */
function synthesisCreateApi(data) {
  return _request.default.post("activity/synthetic/album/create", data);
}
/**
 * @description 合成 -- 编辑活动
 */
function synthesisEditApi(id, data) {
  return _request.default.post("activity/synthetic/album/update/".concat(id), data);
}
/**
 * @description 合成 -- 修改活动展示状态
 */
function synthesisSwitchStatusApi(id, data) {
  return _request.default.post("activity/synthetic/album/switchShowStatus/".concat(id), data);
}
/**
 * @description 合成 -- 删除活动
 */
function synthesisDeleteApi(id) {
  return _request.default.post("activity/synthetic/album/delete/".concat(id));
}
/**
 * @description 合成 -- 合成记录列表
 */
function synthesisRecordListApi(data) {
  return _request.default.get("activity/synthetic/record/lst", data);
}
/**
 * @description 合成 -- 玩法列表
 */
function synthesisPlayListApi(data) {
  return _request.default.get("activity/synthetic/lst", data);
}
/**
 * @description 合成 -- 添加活动玩法列表
 */
function synthesisAddPlayApi(data) {
  return _request.default.post("activity/synthetic/create", data);
}
/**
 * @description 合成 -- 编辑活动玩法列表
 */
function synthesisEditPlayApi(id, data) {
  return _request.default.post("activity/synthetic/update/".concat(id), data);
}
/**
 * @description 合成 -- 删除活动玩法
 */
function synthesisDeletePlayApi(id) {
  return _request.default.post("activity/synthetic/delete/".concat(id));
}
/**
 * @description 合成 -- 修改合成藏品
 */
function synthesisUpdateGoodsApi(id, data) {
  return _request.default.post("activity/synthetic/updateProduct/".concat(id), data);
}
/**
 * @description 合成 -- 删除合成材料
 */
function synthesisDeleteGoodsApi(syntheticId, productId) {
  return _request.default.post("activity/synthetic/material/delete/".concat(syntheticId, "/").concat(productId));
}

/**
 * @description 合成 -- 添加活动玩法列表
 */
function synthesisAddPlayNewApi(data) {
  return _request.default.post("activity/synthetic/V2/create", data);
}
/**
 * @description 合成 -- 编辑活动玩法列表
 */
function synthesisEditPlayNewApi(id, data) {
  return _request.default.post("activity/synthetic/V2/update/".concat(id), data);
}
/**
 * @description 合成 -- 玩法列表
 */
function synthesisPlayListNewApi(data) {
  return _request.default.get("activity/synthetic/V2/lst", data);
}
/**
 * @description 合成 -- 删除活动玩法
 */
function synthesisDeletePlayNewApi(id) {
  return _request.default.post("activity/synthetic/V2/delete/".concat(id));
}
/**
 * @description 群发短信 - 详情
 */
function smsTaskInfoApi(data) {
  return _request.default.get("system/sms/taskInfo", data);
}
/**
 * @description 群发短信 - 列表
 */
function smsTaskListApi(data) {
  return _request.default.get("system/sms/taskList", data);
}
/**
 * @description 群发短信 - 删除
 */
function smsTaskDelApi(data) {
  return _request.default.post("system/sms/delTask", data);
}
/**
 * @description 群发短信 - 创建
 */
function smsTaskCreatedApi(data) {
  return _request.default.post("system/sms/createTask", data);
}
/**
 * @description 群发短信 - 创建
 */
function smsTaskUpdateApi(data) {
  return _request.default.post("system/sms/updateTask", data);
}
/**
 * @description 群发短信 - 模板 签名
 */
function getSmsSignApi(data) {
  return _request.default.get("system/sms/temp/getSmsSign", data);
}
/**
 * @description 群发短信 - 模板列表
 */
function getSmsTempApi(data) {
  return _request.default.get("system/sms/temp/lst", data);
}
/**
 * @description 群发短信 - 创建模板
 */
function smsTempCreatedApi(data) {
  return _request.default.post("system/sms/temp/create", data);
}
/**
 * @description 群发短信 - 保存并审核模板
 */
function smsTempcreateAndSubmitApi(data) {
  return _request.default.post("system/sms/temp/createAndSubmit", data);
}
/**
 * @description 群发短信 - 提交审核
 */
function smsTempSubmitApi(data) {
  return _request.default.post("system/sms/temp/submit", data);
}
/**
 * @description 群发短信 - 删除模板
 */
function smsTempDelApi(data) {
  return _request.default.post("system/sms/temp/del", data);
}
/**
 * @description 群发短信 - 修改模板
 */
function smsTempUpdateApi(data) {
  return _request.default.post("system/sms/temp/update", data);
}
/**
 * @description 群发短信 - 签名列表
 */
function smsSignListApi(data) {
  return _request.default.get("system/sms/sign/lst", data);
}
/**
 * @description 群发短信 - 签名详情
 */
function smsSignDetailApi(data) {
  return _request.default.get("system/sms/sign/detail", data);
}
/**
 * @description 群发短信 - 创建签名
 */
function smsSignCreateApi(data) {
  return _request.default.post("system/sms/sign/create", data);
}
/**
 * @description 群发短信 - 修改签名
 */
function smsSignUpdateApi(data) {
  return _request.default.post("system/sms/sign/update", data);
}
/**
 * @description 群发短信 - 签名提交审核
 */
function smsSignSubmitApi(data) {
  return _request.default.post("system/sms/sign/submit", data);
}
/**
 * @description 群发短信 - 删除签名
 */
function smsSignDelApi(data) {
  return _request.default.post("system/sms/sign/del", data);
}
/**
 * @description 群发短信 - 删除签名
 */
function smsSignSetDefaultApi(data) {
  return _request.default.post("system/sms/sign/setDefault", data);
}

/**
 * @description 寄售 - 藏品/系列列表
 */
function consignListApi(data) {
  return _request.default.get("consign/conf/list", data);
}
/**
 * @description 寄售 - 盲盒列表
 */
function consignNftBoxListApi(data) {
  return _request.default.get("consign/nftBoxConf/list", data);
}
/**
 * @description 寄售 - 售卖列表
 */
function consignNftListApi(data) {
  return _request.default.get("consign/conf/nftList", data);
}
/**
 * @description 寄售 - 盲盒 - 售卖列表
 */
function consignBoxListApi(data) {
  return _request.default.get("consign/box/consignList", data);
}
/**
 * @description 寄售 - 售卖列表 - 全部列表
 */
function marketNftListApi(data) {
  return _request.default.get("consign/nft/list", data);
}
/**
 * @description 寄售 - 盲盒 - 售卖列表 - 全部列表
 */
function marketBoxListApi(data) {
  return _request.default.get("consign/box/consignLog", data);
}
/**
 * @description 寄售 - 售卖日志列表
 */
function consignNftRecordListApi(data) {
  return _request.default.get("consign/log/list", data);
}
/**
 * @description 寄售 - 修改限价规则
 */
function consignConfEditApi(data) {
  return _request.default.post("consign/conf/save", data);
}
/**
 * @description 寄售 - 修改限价规则 - 盲盒
 */
function consignNftBoxConfEditApi(data) {
  return _request.default.post("consign/nftBoxConf/setConf", data);
}
/**
 * @description 寄售 - 开启/关闭寄售
 */
function consignStatusEditApi(data) {
  return _request.default.post("consign/conf/setStatus", data);
}
/**
 * @description 寄售 - 交易列表
 */
function tradeListApi(data) {
  return _request.default.get("consign/log/tradeList", data);
}

/**
 * @description 寄售 - 盲盒 - 交易列表
 */
function boxTradeListApi(data) {
  return _request.default.get("consign/box/tradeList", data);
}

// 下架所有寄售nft
function batchDisableApi(data) {
  return _request.default.post("consign/conf/disableNft", data);
}

// 下架单个寄售nft
function disableNftApi(data) {
  return _request.default.post("consign/conf/disable", data);
}

// 取消盲盒寄售
function cancelBoxConsignApi(data) {
  return _request.default.post("consign/box/cancelConsign", data);
}

// 修改服务费率
function saveServiceRateApi(data) {
  return _request.default.post("consign/conf/saveServiceRate", data);
}

// 修改服务费率 - 盲盒
function saveServiceRateNftBoxApi(data) {
  return _request.default.post("consign/nftBoxConf/setServiceRate", data);
}
// 导出二手交易记录
function exportTradeListApi(data) {
  return _request.default.get("consign/log/exportTradeList", data);
}

// 分销员列表
function distributorListApi(data) {
  return _request.default.get("user/salesman/lst", data);
}

// 分销员创建
function distributorCreateApi(data) {
  return _request.default.post("user/salesman/create", data);
}

// 分销员更改状态
function distributorSwitchStatusApi(id, data) {
  return _request.default.post("user/salesman/switchStatus/".concat(id), data);
}

// 分销员删除
function distributorDeleteApi(id) {
  return _request.default.delete("user/salesman/delete/".concat(id));
}
//编辑分销员
function updateDistributorApi(id, data) {
  return _request.default.post("user/salesman/update/".concat(id), data);
}
// 分销员添加团队
function distributorAddTeamApi(data) {
  return _request.default.post("user/salesman/addTeamUser", data);
}

// 分销员删除团队
function distributorDeleteTeamApi(data) {
  return _request.default.post("user/salesman/delTeamUser", data);
}

// 分销员成员列表
function distributorTeamListApi(data) {
  return _request.default.get("user/salesman/teamUserList", data);
}

// 分销员订单列表
function distributorOrderListApi(data) {
  return _request.default.get("user/salesman/orderList", data);
}

// 分销员统计接口
function distributorStatisticsApi(data) {
  return _request.default.get("user/salesman/totalNumber", data);
}

// 新优惠券 - 创建优惠券
function couponCreatedApi(data) {
  return _request.default.post("coupon/create", data);
}

// 新优惠券 - 修改优惠券
function couponUpdateApi(data) {
  return _request.default.post("coupon/setInfo", data);
}

/**
 * @description 优惠券 -- 列表
 */
function couponListApi(data) {
  return _request.default.post("coupon/getList", data);
}
/**
 * @description 优惠劵 -- 删除
 */
function couponDeleteApi(data) {
  return _request.default.post("coupon/delete", data);
}

/**
 * @description 优惠券 -- 发行
 */
function couponIssueApi(data) {
  return _request.default.post("coupon/issue", data);
}
/**
 * @description 已发布优惠券 -- 修改状态
 */
function couponIssueStatusApi(data) {
  return _request.default.post("coupon/switch", data);
}
/**
 * @description 优惠券 -- 详情
 */
function couponDetailApi(data) {
  return _request.default.post("coupon/getInfo", data);
}
/**
 * @description 优惠券 -- 领取记录
 */
function receiveListApi(data) {
  return _request.default.post("coupon/receiveList", data);
}
/**
 * @description 优惠券 -- 领取记录 - 导出
 */
function exportReceiveListApi(data) {
  return _request.default.post("coupon/exportReceiveList", data);
}
/**
 * @description 优惠券 -- 使用记录
 */
function transactionListApi(data) {
  return _request.default.post("coupon/transactionList", data);
}
/**
 * @description 优惠券 -- 使用记录 - 导出
 */
function exportTransactionListApi(data) {
  return _request.default.post("coupon/exportTransactionList", data);
}
/**
 * @description 积分 - 查看配置详情
 */
function integralConfGetInfoApi(data) {
  return _request.default.post("integralConf/getInfo", data);
}
/**
 * @description 积分 - 统计
 */
function integralConfStatisticsApi(data) {
  return _request.default.post("UserIntegral/statistics", data);
}
/**
 * @description 积分 - 保存配置详情
 */
function integralConfSaveApi(data) {
  return _request.default.post("integralConf/save", data);
}

/**
 * @description 积分 - 使用记录
 */
function integralUseRecordListApi(data) {
  return _request.default.post("UserIntegralChangeRecord/getList", data);
}
/**
 * @description 积分 - 发放记录
 */
function integralReceivingRecordListApi(data) {
  return _request.default.post("UserIntegralRecord/getList", data);
}
/**
 * @description 积分 - 积分明细
 */
function integralDetailedApi(data) {
  return _request.default.post("UserIntegral/getList", data);
}
/**
 * @description 积分 - 排行榜列表
 */
function integralRankingListApi(data) {
  return _request.default.post("IntegralRankingList/getList", data);
}
// 导出排行榜
function integralRankingExporterApi(data) {
  return _request.default.post("IntegralRankingList/exportRankList", data);
}
/**
 * @description 积分 - 排行榜统计数据
 */
function integralRankingDataApi(data) {
  return _request.default.post("IntegralRankingList/getIntegralRankListData", data);
}
/**
 * @description 积分 - 创建排行榜统计
 */
function integralRankingCreateApi(data) {
  return _request.default.post("IntegralRankingList/create", data);
}
/**
 * @description 积分 - 修改排行榜统计
 */
function integralRankingEditApi(data) {
  return _request.default.post("IntegralRankingList/setInfo", data);
}
/**
 * @description 积分 - 删除排行榜统计
 */
function integralRankingDelApi(data) {
  return _request.default.post("IntegralRankingList/del", data);
}
/**
 * @description 积分 - 创建规则
 */
function integralRuleCreateApi(data) {
  return _request.default.post("activity/integralCheckIn/create", data);
}
/**
 * @description 积分 - 修改规则
 */
function integralRuleEditApi(data) {
  return _request.default.post("activity/integralCheckIn/setInfo", data);
}
/**
 * @description 积分 - 规则列表
 */
function integralRuleListApi(data) {
  return _request.default.post("activity/integralCheckIn/getList", data);
}
/**
 * @description 积分 - 规则详情
 */
function integralRuleDetailsApi(data) {
  return _request.default.post("activity/integralCheckIn/getInfo", data);
}
/**
 * @description 积分 - 规则删除
 */
function integralRuleDelApi(data) {
  return _request.default.post("activity/integralCheckIn/delete", data);
}
/**
 * @description 积分 - 领取记录
 */
function integralRuleGiveApi(data) {
  return _request.default.post("activity/integralCheckIn/record", data);
}
/**
 * @description 分享员 -- 分享员列表
 */
function shareListApi(data) {
  return _request.default.get("user/getSpradUserList", data);
}
/**
 * @description 分享员 -- 邀请列表
 */
function shareInviteListApi(data) {
  return _request.default.get("user/getOneLevelUserList", data);
}

/**
 * @description 活动 -- 助力排行
 */
function helpRankingApi(data) {
  return _request.default.get("activity/help/helpRanking", data);
}

/**
 * @description 活动 -- 修改助力
 */
function editHelpInviteNumApi(data) {
  return _request.default.post("activity/help/editHelpInviteNum", data);
}

/**
 * @description 小游戏 -- 创建战机
 */
function createPlane(data) {
  return _request.default.post("game/aircraft/create", data);
}
/**
 * @description 小游戏 -- 战机详情
 */
function getPlaneDetail(data) {
  return _request.default.post("game/aircraft/getInfo", data);
}
/**
 * @description 小游戏 -- 战机列表
 */
function getplaneList(data) {
  return _request.default.post("game/aircraft/getList", data);
}
/**
 * @description 小游戏 -- 修改
 */
function editPlane(data) {
  return _request.default.post("game/aircraft/setInfo", data);
}
/**
 * @description 小游戏 -- 删除
 */
function delPlane(data) {
  return _request.default.post("game/aircraft/delete", data);
}
/**
 * @description 小游戏 -- 用户拥有战机的数量
 */
function userhasPlateList(data) {
  return _request.default.post("game/aircraft/userAircraft", data);
}
/**
 * @description 小游戏 -- 用户拥有战机的历史记录
 */
function userhasPlateHistory(data) {
  return _request.default.post("game/aircraft/miningRecord", data);
}
/**
 * 小游戏 - 查询商户是否可以游戏
 */
function canuseGame(data) {
  return _request.default.post("game/conf", data);
}
/**
 * 兑换商城 - 兑换商城列表
 */
function exchangeGoodsList(data) {
  return _request.default.get("game/aircraft/product/list", data);
}
/**
 * 兑换商城 - 兑换商城历史记录
 */
function exchangeGoodsRecords(data) {
  return _request.default.get("game/aircraft/product/exchangeList", data);
}
/**
 * 兑换商城 - 创建兑换商品
 */
function exchangeGoodsCreate(data) {
  return _request.default.post("game/aircraft/product/create", data);
}
/**
 * 兑换商城 - 删除兑换商品
 */
function delexchangeGoods(data) {
  return _request.default.post("game/aircraft/product/delete", data);
}
/**
 * 兑换商城 - 兑换商品发货
 */
function sendExchangeGoods(data) {
  return _request.default.post("game/aircraft/product/sendProductDelivery", data);
}

/**
 * 兑换商城 - 兑换商城列表
 */
function jfexchangeGoodsList(data) {
  return _request.default.get("integral/shop/list", data);
}
/**
 * 兑换商城 - 兑换商城历史记录
 */
function jfexchangeGoodsRecords(data) {
  return _request.default.get("integral/shop/exchangeList", data);
}
/**
 * 兑换商城 - 创建兑换商品
 */
function jfexchangeGoodsCreate(data) {
  return _request.default.post("integral/shop/create", data);
}
/**
 * 兑换商城 - 删除兑换商品
 */
function jfdelexchangeGoods(data) {
  return _request.default.post("integral/shop/delete", data);
}
/**
 * 兑换商城 - 兑换商品发货
 */
function jfsendExchangeGoods(data) {
  return _request.default.post("integral/shop/sendProductDelivery", data);
}
/**
 * 塔防游戏 - 获取游戏配置详情
 */
function getGameTowerConfig() {
  return _request.default.post("game/towerDefense/getInfo");
}
/**
 * 塔防游戏 - 修改游戏进行状态
 */
function setGameTowerSwitch(data) {
  return _request.default.post("game/towerDefense/switch", data);
}
/**
 * 塔防游戏 - 修改游戏配置详情
 */
function setGameTowerConfig(data) {
  return _request.default.post("game/towerDefense/setInfo", data);
}
/**
 * 塔防游戏 - 获取可投放藏品列表
 */
function getGameCanuseCollection(data) {
  return _request.default.post("game/towerDefensePut/getList", data);
}
/**
 * 塔防游戏 - 创建可投放藏品列表
 */
function setGameCanuseCollection(data) {
  return _request.default.post("game/towerDefensePut/create", data);
}
/**
 * 塔防游戏 - 删除可投放藏品
 */
function delGameCanuseCollection(data) {
  return _request.default.post("game/towerDefensePut/delete", data);
}
/**
 * 塔防游戏 - 创建可投放藏品概率
 */
function setGameCanuseCollectionProperties(data) {
  return _request.default.post("game/towerDefensePut/setInfo", data);
}
/**
 * 塔防游戏 - 进攻数据
 */
function attackDataApi(data) {
  return _request.default.post("game/rounds/getList", data);
}
/**
 * 塔防游戏 - 导出进攻数据
 */
function attackExports(data) {
  return _request.default.post("game/rounds/exportList", data);
}
/**
 * 塔防游戏 - 进攻数据明细
 */
function attackDataDetails(data) {
  return _request.default.post("game/rounds/getSettlementList", data);
}
/**
 * 塔防游戏 - 导出进攻数据明细
 */
function attackDetailsExports(data) {
  return _request.default.post("game/rounds/exportSettlementList", data);
}
/**
 * 塔防游戏 - 塔防用户积分
 */
function playgameTDrank(data) {
  return _request.default.post("game/integral/getList", data);
}
/**
 * 兑换码 - 列表
 */
function sendCodeVersionList(data) {
  return _request.default.post("redemptionCode/rule/getList", data);
}

/**
 * 兑换码 - 新增
 */
function addCodeVersion(data) {
  return _request.default.post("redemptionCode/rule/create", data);
}
/**
 * 兑换码 - 删除
 */
function removeCodeVersion(data) {
  return _request.default.post("redemptionCode/rule/delete", data);
}

/**
 * 兑换码 - 结束兑换
 */
function stopCodeVersion(data) {
  return _request.default.post("redemptionCode/rule/stop", data);
}
/**
 * 兑换码 - 列表
 */
function stopCodeVersionList(data) {
  return _request.default.post("redemptionCode/code/getList", data);
}
/**
 * 兑换码 - 编辑
 */
function editCodeVersion(data) {
  return _request.default.post("redemptionCode/rule/setInfo", data);
}
/**
 * 兑换码 - 作废
 */
function delCodeVersion(data) {
  return _request.default.post("redemptionCode/code/invalid", data);
}
/**
 * d兑换码 - 已兑换
 */
function hasCodeVersion(data) {
  return _request.default.post("redemptionCode/record/getList", data);
}
/**
 * 抽奖活动 - 抽奖列表
 */
function drawActiveList(data) {
  return _request.default.post("activity/lottery/getList", data);
}
/**
 * 抽奖活动 - 抽奖活动创建
 */
function drawActiveCreate(data, opts) {
  return _request.default.post("activity/lottery/create", data, opts);
}
/**
 * 抽奖活动 - 抽奖活动修改
 */
function drawActiveEdit(data, opts) {
  return _request.default.post("activity/lottery/setInfo", data, opts);
}
/**
 * 抽奖活动 - 抽奖活动详情
 */
function drawActiveDetail(data) {
  return _request.default.post("activity/lottery/getInfo", data);
}
/**
 * 抽奖活动 - 抽奖活动停止
 */
function drawActiveStop(data) {
  return _request.default.post("activity/lottery/stop", data);
}
/**
 * 抽奖活动 - 抽奖活动删除
 */
function drawActiveDel(data) {
  return _request.default.post("activity/lottery/delete", data);
}
/**
 * 抽奖活动 - 抽奖活动参与记录
 */
function drawActiveRecord(data) {
  return _request.default.post("activity/lottery/recordList", data);
}
/**
 * 抽奖活动 - 抽奖活动发货
 */
function drawActiveSend(data) {
  return _request.default.post("activity/lottery/sendProductDelivery", data);
}
/**
 * 熔炉 - 创建熔炉
 */
function furnaceCreateApi(data) {
  return _request.default.post("activity/furnaceConf/create", data);
}
/**
 * 熔炉 - 获取熔炉详情
 */
function furnaceGetInfoApi(data) {
  return _request.default.post("activity/furnaceConf/getInfo", data);
}
/**
 * 熔炉 - 创建规则
 */
function furnaceCreateRuleApi(data) {
  return _request.default.post("activity/furnaceRule/create", data);
}
/**
 * 熔炉 - 修改规则
 */
function furnaceEditRuleApi(data) {
  return _request.default.post("activity/furnaceRule/setInfo", data);
}
/**
 * 熔炉 - 删除规则
 */
function furnaceDelRuleApi(data) {
  return _request.default.post("activity/furnaceRule/delete", data);
}
/**
 * 熔炉 - 规则列表
 */
function furnaceRuleListApi(data) {
  return _request.default.post("activity/furnaceRule/getList", data);
}
/**
 * 熔炉 - 规则详情
 */
function furnaceRuleDetailApi(data) {
  return _request.default.post("activity/furnaceRule/getInfo", data);
}
/**
 * 熔炉 - 融化记录
 */
function furnaceRecordApi(data) {
  return _request.default.post("activity/furnaceRecord/getList", data);
}

/**
 * 邀请函 列表
 */
function inviteLetterListApi(data) {
  return _request.default.get("activity/inviteLetter/lst", data);
}

/**
 * 邀请函 详情
 */
function inviteLetterDetailApi(id) {
  return _request.default.get("activity/inviteLetter/detail/".concat(id));
}

/**
 * 邀请函 创建
 */
function inviteLetterCreateApi(id, data) {
  return _request.default.post("activity/inviteLetter/create", data);
}

/**
 * 邀请函 修改
 */
function inviteLetterEditApi(id, data) {
  return _request.default.post("activity/inviteLetter/update/".concat(id), data);
}

/**
 * 邀请函 删除
 */
function inviteLetterDelApi(id) {
  return _request.default.post("activity/inviteLetter/delete/".concat(id));
}

/**
 * 邀请函 邀请用户列表
 */
function inviteLetterUserListApi(data) {
  return _request.default.get("activity/inviteLetter/user/lst", data);
}

/**
 * 邀请函 新增邀请用户
 */
function inviteLetterUserCreateApi(data) {
  return _request.default.post("activity/inviteLetter/user/create", data);
}

/**
 * 邀请函 删除邀请用户
 */
function inviteLetterUserDelApi(id) {
  return _request.default.post("activity/inviteLetter/user/delete/".concat(id));
}

/**
 * 邀请函 邀请用户 核销
 */
function inviteLetterUserSwitchStatusApi(id) {
  return _request.default.post("activity/inviteLetter/user/switchStatus/".concat(id));
}

/**
 * 分销 - 结算记录 - 列表
 */
function settlementRecordListApi(obj) {
  return _request.default.get("user/salesman/settleRecordList", obj);
}
/**
 * 分销 - 结算记录 - 确认结算
 */
function confirmSettleApi(mer_id, obj) {
  return _request.default.post("/user/salesman/settleRecordCreate?mer_id=".concat(mer_id), obj);
}

/**
 * 大盘管理
 */
function wineListApi(data) {
  return _request.default.get("/marketing/wine/list", data);
}

/**
 * 大盘管理
 */
function wineDetailApi(id) {
  return _request.default.get("marketing/wine/detail/".concat(id));
}

/**
 * 大盘管理 - 创建产品
 */
function wineCreateApi(id, data) {
  return _request.default.post("marketing/wine/create", data);
}

/**
 * 大盘管理 - 编辑产品
 */
function wineUpdateApi(id, data) {
  return _request.default.post("marketing/wine/update/".concat(id), data);
}

/**
 * 大盘管理 - 修改排序
 */
function wineSortApi(id, data) {
  return _request.default.post("marketing/wine/sort/".concat(id), data);
}

/**
 * 大盘管理 - 删除
 */
function wineDeleteApi(id, data) {
  return _request.default.post("marketing/wine/delete/".concat(id), data);
}

/**
 * 大盘管理 - 隐藏 展示
 */
function wineShowApi(id, data) {
  return _request.default.post("marketing/wine/isShow/".concat(id), data);
}

/**
 * 大盘管理 - 价格列表
 */
function winePriceRecordApi(data) {
  return _request.default.get("marketing/wine/priceRecord/list", data);
}

/**
 * 大盘管理 - 创建价格记录
 */
function winePriceRecordCreateApi(data) {
  return _request.default.post("marketing/wine/priceRecord/create", data);
}

/**
 * 大盘管理 - 删除价格记录
 */
function winePriceRecordDeleteApi(id) {
  return _request.default.post("marketing/wine/priceRecord/delete/".concat(id));
}

/**
 * 订阅通知配置列表
 */
function pushConfigListApi() {
  return _request.default.get("system/pushConfig/list");
}

/**
 * 设置订阅通知配置
 */
function setPushConfigApi(data) {
  return _request.default.post("system/pushConfig/setPushConfig", data);
}

/**
 * 商户渠道信息
 */
function merchantChannelConfigApi() {
  return _request.default.get("system/pushConfig/merchantChannelConfig");
}

/**
 * app推送列表
 */
function appPushTaskList(data) {
  return _request.default.get("auction/pushTask/appPushTaskList", data);
}

/**
 * 创建app推送任务
 */
function createAppPushTask(data) {
  return _request.default.post("auction/pushTask/createAppPushTask", data);
}

/**
 * 修改APP推送任务
 */
function updateAppPushTask(data) {
  return _request.default.post("auction/pushTask/updateAppPushTask", data);
}

/**
 * 删除APP推送任务
 */
function delAppPushTask(data) {
  return _request.default.post("auction/pushTask/delAppPushTask", data);
}

/**
 * 获取用户数量
 */
function getUserNum(data) {
  return _request.default.get("auction/pushTask/getUserNum", data);
}

/**
 * nft积分管理列表
 */
function nftIntegralList(data) {
  return _request.default.get("marketing/jingtan/nft/list", data);
}

/**
 * nft积分管理详情
 */
function nftIntegralDetail(id, data) {
  return _request.default.get("marketing/jingtan/nft/detail/".concat(id), data);
}

/**
 * nft积分管理创建
 */
function nftIntegralCreate(data) {
  return _request.default.post("marketing/jingtan/nft/create", data);
}

/**
 * nft积分管理编辑
 */
function nftIntegralUpdate(id, data) {
  return _request.default.post("marketing/jingtan/nft/update/".concat(id), data);
}

/**
 * nft积分管理删除
 */
function nftIntegralDelete(id) {
  return _request.default.post("marketing/jingtan/nft/delete/".concat(id));
}

/**
 * nft积分管理获取配置
 */
function nftIntegralConfig() {
  return _request.default.get("config/getConfigClassKeys/kuanTangIntegralConfig");
}
/**
 * nft积分管理保存配置
 */
function nftIntegralConfigSave(data) {
  return _request.default.post("config/save/kuanTangIntegralConfig", data);
}