"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {},
      rules: {}
    };
  },
  created: function created() {
    var _this = this;
    (0, _system.getConfigClassKeys)('launch_page_set').then(function (res) {
      console.log(res.data.launch_page);
      if (res.data.launch_page) {
        _this.ruleForm = JSON.parse(res.data.launch_page);
      } else {
        _this.ruleForm = {
          isOpen: 1,
          showFrequency: 1,
          residenceTime: 3,
          postImage: [],
          jumpUrl: ''
        };
      }
    });
  },
  methods: {
    changeImg: function changeImg(key, index) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.ruleForm.postImage = _this2.ruleForm.postImage.concat(img);
      }, 9, '1');
    },
    delImgAction: function delImgAction(i) {
      this.ruleForm.postImage.splice(i, 1);
    },
    saveStartPage: function saveStartPage() {
      var _this3 = this;
      (0, _system.configClassKeysSave)('launch_page_set', {
        launch_page: JSON.stringify(this.ruleForm)
      }).then(function (res) {
        _this3.$message.success('设置成功');
      }).catch(function () {});
    }
  }
};