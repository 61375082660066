var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sprBox" },
    [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "el-select",
            {
              staticClass: "w300",
              attrs: {
                placeholder: "请选择",
                filterable: "",
                "remote-method": _vm.searchUserAction,
              },
              on: { change: _vm.changeSelect },
              model: {
                value: _vm.queryParams.venue_id,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "venue_id", $$v)
                },
                expression: "queryParams.venue_id",
              },
            },
            _vm._l(_vm.optionList, function (m, i) {
              return _c("el-option", {
                key: i,
                attrs: { label: m.title, value: m.venue_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ft14 fWeight500 t flex align-items-c" }, [
            _c("span", { staticClass: "ml20" }, [
              _vm._v(
                "服务号粉丝：" + _vm._s(_vm.pushNumData.official_num) + "人"
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "ml20" }, [
              _vm._v("小程序订阅：" + _vm._s(_vm.pushNumData.mini_num) + "人"),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt10 ft12 btnnopadding",
          attrs: {
            border: "",
            data: _vm.list,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "tableEmpty" }, [
              _c("img", {
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "推送内容", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(scope.row.type === 1 ? "拍卖会" : "专场") +
                          "：" +
                          _vm._s(scope.row.name)
                      ),
                    ]),
                    _vm._v(" "),
                    scope.row.type === 2
                      ? _c("div", [_vm._v(" ID:" + _vm._s(scope.row.album_id))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "推送类型", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.task_list, function (m, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        m.send_type == 1
                          ? [_vm._v("开拍提醒")]
                          : m.send_type == 2
                          ? [_vm._v("结拍提醒")]
                          : m.send_type == 3
                          ? [_vm._v("预展提醒")]
                          : _vm._e(),
                      ],
                      2
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "推送渠道",
              "min-width": "110",
              prop: "send_channel_text",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "推送用户", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _vm._v(
                          _vm._s(scope.row.send_user_object_text) +
                            "\n              "
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.setUserAction(scope.row.task_list)
                              },
                            },
                          },
                          [_vm._v("设置\n              ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "推送时间",
              "min-width": "150",
              prop: "create_time",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.task_list, function (m, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        m.send_type == 1
                          ? [_vm._v("开拍：")]
                          : m.send_type == 2
                          ? [_vm._v("结拍：")]
                          : m.send_type == 3
                          ? [_vm._v("预展：")]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "zw" }, [
                          _vm._v(
                            _vm._s(m.push_time ? m.push_time_format : "-")
                          ),
                        ]),
                        _vm._v(" "),
                        m.push_time
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setTimeClearAction(m)
                                  },
                                },
                              },
                              [_vm._v("取消\n              ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setTimeAction(m)
                                  },
                                },
                              },
                              [_vm._v("设置\n              ")]
                            ),
                      ],
                      2
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "执行状态", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.task_list, function (m, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        m.send_type == 1
                          ? [_vm._v("开拍提醒：")]
                          : m.send_type == 2
                          ? [_vm._v("结拍提醒：")]
                          : m.send_type == 3
                          ? [_vm._v("预展提醒：")]
                          : _vm._e(),
                        _vm._v(" "),
                        m.send_status == -1
                          ? _c("span", [_vm._v("待设置")])
                          : m.send_status == 0
                          ? _c("span", [_vm._v("待推送")])
                          : m.send_status == 1
                          ? _c("span", [_vm._v("已完成")])
                          : m.send_status == 2
                          ? _c("span", [_vm._v("推送失败")])
                          : _vm._e(),
                      ],
                      2
                    )
                  })
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频号商品",
            visible: _vm.videogoodsflag,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videogoodsflag = $event
            },
            close: function ($event) {
              _vm.videogoodsflag = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "货品封面图：", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      attrs: { title: "750*750px" },
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1")
                        },
                      },
                    },
                    [
                      _vm.formValidate.image
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formValidate.image },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.formValidate.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "title", $$v)
                      },
                      expression: "formValidate.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品价格", prop: "price" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品价格" },
                    model: {
                      value: _vm.formValidate.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "price", $$v)
                      },
                      expression: "formValidate.price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "跳转类型", prop: "path_type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formValidate.path_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "path_type", $$v)
                        },
                        expression: "formValidate.path_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "other" } }, [
                        _vm._v("普通地址"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "venue" } }, [
                        _vm._v("拍卖会"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formValidate.path_type == "other"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "跳转链接", prop: "path" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入跳转链接" },
                        model: {
                          value: _vm.formValidate.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "path", $$v)
                          },
                          expression: "formValidate.path",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "拍卖会id", prop: "path" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入拍卖会id" },
                        model: {
                          value: _vm.formValidate.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "path", $$v)
                          },
                          expression: "formValidate.path",
                        },
                      }),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品分类", prop: "third_cat_id" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.formValidate.third_cat_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "third_cat_id", $$v)
                        },
                        expression: "formValidate.third_cat_id",
                      },
                    },
                    _vm._l(_vm.videocatearr, function (item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item, value: key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.setUserTag.show,
            "close-on-click-modal": false,
            title: "设置推送用户",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setUserTag, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c" }, [
            _c("div", [_vm._v("选择用户标签：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { filterable: "", multiple: "" },
                    model: {
                      value: _vm.setUserTag.tag,
                      callback: function ($$v) {
                        _vm.$set(_vm.setUserTag, "tag", $$v)
                      },
                      expression: "setUserTag.tag",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { value: "all", label: "全部用户" },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.tagList, function (m, i) {
                      return _c("el-option", {
                        key: "tagList" + i,
                        attrs: { value: m.label_id, label: m.label_name },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.setOkAction } },
                [_vm._v("确认设置\n      ")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.closeSetTag } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.setTimeData.show,
            "close-on-click-modal": false,
            title: "设置推送时间",
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setTimeData, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "flex item-flex-center" }, [
            _c(
              "div",
              [
                _vm._v("选择\n        "),
                _vm.setTimeData.type == 1
                  ? [_vm._v("开拍")]
                  : _vm.setTimeData.type == 2
                  ? [_vm._v("结拍")]
                  : _vm.setTimeData.type == 3
                  ? [_vm._v("预展")]
                  : _vm._e(),
                _vm._v("\n        提醒时间：\n      "),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "datetime",
                    format: "yyyy-MM-dd HH:mm",
                    placeholder: "请选择",
                    "picker-options": _vm.pickerOptions,
                  },
                  model: {
                    value: _vm.setTimeData.time,
                    callback: function ($$v) {
                      _vm.$set(_vm.setTimeData, "time", $$v)
                    },
                    expression: "setTimeData.time",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setTimeOkAction },
                },
                [_vm._v("确认设置\n      ")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.closeSetTimeAction } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }