var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: {
          name: _vm.$route.query.id ? "编辑委托" : "新增委托",
          hasBack: true,
          isFixed: true,
        },
      }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "formRef",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            rules: _vm.ruleValidate,
            model: _vm.formState,
            "label-width": "130px",
            "label-position": "right",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择客户:", prop: "entrust_uid" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w300",
                  attrs: {
                    placeholder: "搜索客户昵称",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.searchUserAction,
                  },
                  model: {
                    value: _vm.formState.entrust_uid,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "entrust_uid", $$v)
                    },
                    expression: "formState.entrust_uid",
                  },
                },
                _vm._l(_vm.userList, function (m, i) {
                  return _c(
                    "el-option",
                    {
                      key: "user" + i,
                      attrs: { value: m.uid, label: m.nickname },
                    },
                    [
                      _c("div", { staticClass: "avatar flex align-items-c" }, [
                        _c("img", { attrs: { src: m.avatar, alt: "" } }),
                        _vm._v(" "),
                        _c("div", { staticClass: "user-r flex-one" }, [
                          _c(
                            "div",
                            { staticClass: "line1" },
                            [
                              m.real_name
                                ? [_vm._v(_vm._s(m.real_name) + " | ")]
                                : _vm._e(),
                              _vm._v(_vm._s(m.nickname)),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(m.phone))]),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择专场:", prop: "album_id" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w300",
                  attrs: { placeholder: "选择专场" },
                  on: { change: _vm.onChangeAlbum },
                  model: {
                    value: _vm.formState.album_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "album_id", $$v)
                    },
                    expression: "formState.album_id",
                  },
                },
                _vm._l(_vm.albumData, function (m, i) {
                  return _c("el-option", {
                    key: "album" + i,
                    attrs: { value: m.album_id, label: m.album_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formState.album_id
            ? _c(
                "el-form-item",
                { attrs: { label: "选择拍品:", prop: "auction_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "搜索拍品名称",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchAuctionAction,
                      },
                      model: {
                        value: _vm.formState.auction_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formState, "auction_id", $$v)
                        },
                        expression: "formState.auction_id",
                      },
                    },
                    _vm._l(_vm.auctionData, function (m, i) {
                      return _c("el-option", {
                        key: "auction" + i,
                        attrs: {
                          value: m.product_id,
                          label: m.lot + "-" + m.product.store_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "委托价格:", prop: "entrust_price" } },
            [
              _c("el-input", {
                staticClass: "w300",
                attrs: { placeholder: "请输入委托价格" },
                model: {
                  value: _vm.formState.entrust_price,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "entrust_price", $$v)
                  },
                  expression: "formState.entrust_price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注:", prop: "" } },
            [
              _c("el-input", {
                staticClass: "w300",
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "请输入备注内容",
                },
                model: {
                  value: _vm.formState.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "remark", $$v)
                  },
                  expression: "formState.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formFixedBox" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveAction },
            },
            [_vm._v("确认提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }