"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _auction = require("@/api/auction");
var _user = require("@/api/user");
var _contract = require("@/api/contract");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 获取视频号商品
var _default = exports.default = {
  data: function data() {
    return {
      list: null,
      listLoading: true,
      queryParams: {},
      venue_id: '',
      pushNumData: {},
      createSchemeBtnLoading: false,
      btnLoading: false,
      tagList: [],
      type: '1',
      setUserTag: {
        show: false,
        task_ids: '',
        tag: []
      },
      setTimeData: {
        show: false,
        type: 1,
        task_id: '',
        time: ''
      },
      videogoodsflag: false,
      videogoodsList: [],
      formValidate: {
        title: '',
        image: '',
        price: '',
        third_cat_id: '',
        path: '',
        path_type: 'other'
      },
      ruleValidate: {
        title: [{
          required: true,
          message: '请输入商品名称',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请上传货品图',
          trigger: 'change'
        }],
        price: [{
          required: true,
          message: '请输入商品价格',
          trigger: 'blur'
        }],
        third_cat_id: [{
          required: true,
          message: '请选择商品分类',
          trigger: 'change'
        }],
        path: [{
          required: true,
          message: '请输入商品链接',
          trigger: 'blur'
        }]
      },
      videocatearr: {},
      pickerOptions: {
        disabledDate: function disabledDate(date) {
          return date.getTime() < Date.now() - 8.64e7; // 禁用小于今天之前的日期
        }
      },
      optionList: []
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _user.labelLstApi)({
      page: 1,
      limit: 100
    }).then(function (res) {
      _this2.tagList = res.data.list;
    });
    (0, _auction.pushAreaNumStat)().then(function (res) {
      _this2.pushNumData = res.data;
    });
    (0, _system.getVideoClassApi)().then(function (res) {
      _this2.videocatearr = res.data.wechat_catetory;
    });
    // this.venue_id = this.$route.query.vid || '';
    // this.queryParams.venue_id = this.$route.query.vid || '';
    (0, _contract.getLastAuctionApi)({
      page: 1,
      limit: 10
    }).then(function (res) {
      _this2.optionList = res.data.list;
      _this2.venue_id = res.data.list[0].venue_id;
      _this2.queryParams.venue_id = res.data.list[0].venue_id;
      _this2.load();
      _this2.loadvideogoods();
    });
  },
  methods: {
    searchUserAction: function searchUserAction(data) {
      var _this3 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 10
      };
      (0, _contract.getLastAuctionApi)(params).then(function (res) {
        _this3.optionList = res.data.list;
      });
    },
    changeSelect: function changeSelect(val) {
      this.venue_id = val;
      this.queryParams.venue_id = val;
      this.load();
      this.loadvideogoods();
    },
    createviedegoods: function createviedegoods() {
      this.videogoodsflag = true;
      this.formValidate = {
        title: '',
        image: '',
        price: '',
        third_cat_id: '',
        path: '',
        path_type: 'other'
      };
    },
    handleSubmit: function handleSubmit(name) {
      var _this4 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          if (_this4.formValidate.id) {
            (0, _system.editVideoGoodsApi)(_this4.formValidate.id, _this4.formValidate).then(function (res) {
              _this4.$message({
                message: '修改成功',
                type: 'success'
              });
              _this4.videogoodsflag = false;
              _this4.loadvideogoods();
            });
            return;
          }
          (0, _system.createVideoGoodsApi)(_this4.formValidate).then(function (res) {
            _this4.videogoodsflag = false;
            _this4.loadvideogoods();
          }).catch(function (err) {
            _this4.$message.error(err.message);
          });
        }
      });
    },
    loadvideogoods: function loadvideogoods() {
      var _this5 = this;
      (0, _system.getVideoGoodsListApi)({
        page: 1,
        limit: 100
      }).then(function (res) {
        _this5.videogoodsList = res.data.list;
      });
    },
    editviedegoods: function editviedegoods(item) {
      var rowjson = JSON.parse(JSON.stringify(item));
      rowjson.third_cat_id = rowjson.third_cat_id + '';
      this.formValidate = rowjson;
      this.videogoodsflag = true;
    },
    editviedegoodsstatus: function editviedegoodsstatus(item, type) {
      var _this6 = this;
      var text = type == 1 ? '确认上架' : '确认下架';
      this.$confirm(text, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _system.editVideoGoodsStatusApi)(item.id, {
          status: type == 1 ? 'listing' : 'delisting'
        }).then(function (res) {
          _this6.loadvideogoods();
        });
      }).catch(function (err) {
        console.error(err);
        _this6.$message.error(err.message);
      });
    },
    editviedegoodspath: function editviedegoodspath(item) {
      var _this7 = this;
      var that = this;
      this.$prompt('', '修改地址', {
        confirmButtonText: '确认修改',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入地址',
        inputErrorMessage: '请输入地址',
        inputValue: item.path
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _system.editVideoGoodsUrlApi)(item.id, {
          path: value
        }).then(function (res) {
          that.$message.success('修改成功,请等待审核');
          that.loadvideogoods();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    editviedegoodsPrice: function editviedegoodsPrice(item) {
      var _this8 = this;
      var that = this;
      this.$prompt('', '修改价格', {
        confirmButtonText: '确认修改',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入价格',
        inputErrorMessage: '请输入价格',
        inputValue: item.price
      }).then(function (_ref2) {
        var value = _ref2.value;
        (0, _system.editVideoGoodsPriceApi)(item.id, {
          price: value
        }).then(function (res) {
          that.loadvideogoods();
        });
      }).catch(function () {
        _this8.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    setTimeAction: function setTimeAction(m) {
      this.setTimeData = {
        show: true,
        type: m.send_type,
        task_id: m.id,
        time: m.push_time ? m.push_time : ''
      };
    },
    closeSetTimeAction: function closeSetTimeAction() {
      this.setTimeData = {
        show: false,
        type: 1,
        task_id: '',
        time: ''
      };
    },
    setTimeClearAction: function setTimeClearAction(m) {
      var _this9 = this;
      this.$confirm('确认取消推送', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.cancelPushTimeApi)({
          task_id: m.id
        }).then(function () {
          _this9.load();
          _this9.$message({
            type: 'success',
            message: '操作成功！'
          });
        }).catch(function () {});
      }).catch(function (err) {
        console.error(err);
      });
    },
    setTimeOkAction: function setTimeOkAction() {
      var _this10 = this;
      if (!this.setTimeData.time) {
        this.$message.error('请选择推送时间');
        return false;
      }
      var data = {
        task_id: this.setTimeData.task_id,
        push_time: Date.parse(this.setTimeData.time) / 1000
      };
      this.$confirm('确认设置推送时间', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.setPushTimeApi)(data).then(function () {
          _this10.closeSetTimeAction();
          _this10.load();
          _this10.$message({
            type: 'success',
            message: '操作成功！'
          });
        }).catch(function () {});
      }).catch(function (err) {
        console.error(err);
      });
    },
    setOkAction: function setOkAction() {
      var _this11 = this;
      if (this.setUserTag.tag.length === 0) {
        this.$message.error('请选择推送用户');
        return false;
      }
      var data = {
        task_ids: this.setUserTag.task_ids
      };
      var index = this.setUserTag.tag.indexOf('all');
      if (index !== -1) {
        data.type = 1;
      } else {
        data.type = 2;
        data.user_label = this.setUserTag.tag.join(',');
      }
      // const tags = this.setUserTag.tag.map(m => m.tag_id);
      this.$confirm('确认设置推送用户', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.editSendUserObjectApi)(data).then(function () {
          _this11.closeSetTag();
          _this11.load();
          _this11.$message({
            type: 'success',
            message: '操作成功！'
          });
        }).catch(function () {});
      }).catch(function (err) {
        console.error(err);
      });
    },
    closeSetTag: function closeSetTag() {
      this.setUserTag = {
        show: false,
        task_ids: '',
        tag: []
      };
    },
    load: function load() {
      var _this12 = this;
      this.listLoading = true;
      console.log(this.queryParams);
      (0, _auction.pushNoticeList)(this.queryParams).then(function (response) {
        _this12.list = response.data;
        _this12.listLoading = false;
      });
    },
    setUserAction: function setUserAction(item) {
      var ids = item.map(function (m) {
        return m.id;
      });
      this.setUserTag = {
        show: true,
        task_ids: ids.join(','),
        tag: []
      };
    },
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.formValidate.image = img[0];
        }
        if (tit === '2' && !num) {
          img.map(function (item) {
            attr.push(item.attachment_src);
            _this.formValidate.slider_image.push(item);
            if (_this.formValidate.slider_image.length > 10) {
              _this.formValidate.slider_image.length = 10;
            }
          });
        }
        if (tit === '1' && num === 'dan') {
          _this.OneattrValue[0].image = img[0];
        }
        if (tit === '1' && num === 'duo') {
          _this.ManyAttrValue[i].image = img[0];
        }
        if (tit === '1' && num === 'pi') {
          _this.oneFormBatch[0].image = img[0];
        }
      }, tit);
    }
  }
};