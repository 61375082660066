var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "videoBox" },
    [
      _c(
        "div",
        { staticClass: "mt20", staticStyle: { height: "calc(100vh - 230px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              attrs: {
                border: "",
                height: "100%",
                data: _vm.resultData,
                "span-method": _vm.spanMethod,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _vm.status == 2
                ? _c("ytx-table-column", {
                    attrs: {
                      label: "专场名称",
                      width: "220",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.albumInfo
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row.albumInfo.album_name)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1231565199
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.status == 3
                ? _c("ytx-table-column", {
                    attrs: {
                      label: "拍品名称",
                      width: "220",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.auctionInfo
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "line-height": "1.5",
                                        padding: "5px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.auctionInfo.product
                                            .store_name
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1701615629
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: _vm.titlelist[Number(_vm.status) - 1],
                  "min-width": "220",
                  prop: "",
                  "search-key": "make_title",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticClass: "sortColOne",
                          attrs: { type: "textarea", autosize: "" },
                          on: {
                            change: function ($event) {
                              return _vm.mackTitChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.make_title,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "make_title", $$v)
                            },
                            expression: "scope.row.make_title",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "内容定位",
                  width: "100",
                  prop: "",
                  "search-key": "make_tag",
                  "search-type": "radio",
                  options: [
                    { label: "拍卖导览", value: "1" },
                    { label: "结拍战报", value: "2" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.makeTagText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "内容形式",
                  width: "100",
                  prop: "",
                  "search-key": "type",
                  "search-type": "radio",
                  options: [
                    { label: "视频", value: "1" },
                    { label: "推文", value: "2" },
                    { label: "图文", value: "4" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.typeText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "创建时间",
                  width: "160",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.create_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "计划发布日期",
                  width: "222",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-date-picker", {
                          staticClass: "sortColOne",
                          attrs: {
                            type: "datetime",
                            clearable: false,
                            "value-format": "timestamp",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onDatePickChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.extend_data.expected_release_time,
                            callback: function ($$v) {
                              _vm.$set(
                                scope.row.extend_data,
                                "expected_release_time",
                                $$v
                              )
                            },
                            expression:
                              "scope.row.extend_data.expected_release_time",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "制作状态",
                  width: "130",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 1
                          ? _c("div", { staticStyle: { padding: "0 5px" } }, [
                              scope.row.auction_count == 0 &&
                              scope.row.make_status == 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onOpenAuction(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { type: "text" } },
                                        [_vm._v("生成")]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline cur",
                                        staticStyle: { color: "red" },
                                      }),
                                    ],
                                    1
                                  )
                                : scope.row.make_status == -3
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showVideoAction(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("截取视频")]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.make_status == 1 ||
                                  scope.row.make_status == 2
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                    },
                                    [
                                      scope.row.make_status == 1
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "warning",
                                              },
                                            },
                                            [_vm._v("排队中")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.make_status == 2
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "warning",
                                              },
                                            },
                                            [_vm._v("生成中")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : scope.row.make_status == -1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content:
                                              scope.row.extend_data.err_msg,
                                            placement: "top",
                                            effect: "light",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "info",
                                              },
                                            },
                                            [_vm._v("生成失败")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.make_status == -2
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onOpenAuction(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { type: "text" } },
                                        [_vm._v("重新生成")]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline cur",
                                        staticStyle: { color: "red" },
                                      }),
                                    ],
                                    1
                                  )
                                : scope.row.make_status == 3 ||
                                  scope.row.make_status == 4
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onOpenDetails(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("查看\n                "),
                                          scope.row.extend_data.video_info
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatVideoTime")(
                                                      scope.row.extend_data
                                                        .video_info.duration
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-circle-check cur",
                                        staticStyle: { color: "green" },
                                      }),
                                    ],
                                    1
                                  )
                                : scope.row.auction_count > 0 ||
                                  scope.row.make_status == 1 ||
                                  scope.row.make_status == 2
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onOpenUpdAuction(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { type: "text" } },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                scope.row.extend_data
                                                  .preview_video &&
                                                  scope.row.extend_data
                                                    .preview_video.TimelineOut
                                                  ? "预览"
                                                  : "生成中"
                                              ) +
                                              " \n                "
                                          ),
                                          scope.row.extend_data.preview_video
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatVideoTime")(
                                                      scope.row.extend_data
                                                        .preview_video
                                                        .TimelineOut
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline cur",
                                        staticStyle: { color: "red" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type == 2
                          ? _c("div", { staticStyle: { padding: "0 5px" } }, [
                              scope.row.extend_data &&
                              scope.row.extend_data.tweet_content
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goWatchTweet(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { type: "text" } },
                                        [_vm._v("查看")]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline cur",
                                        staticStyle: { color: "red" },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-b align-items-c",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goCreateTweet(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { type: "text" } },
                                        [_vm._v("生成")]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline cur",
                                        staticStyle: { color: "red" },
                                      }),
                                    ],
                                    1
                                  ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发布账号",
                  "min-width": "200",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.releasedChannels)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  align: "center",
                  fixed: "right",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCopy(scope.row)
                                  },
                                },
                              },
                              [_vm._v("复制内容")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDele(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除计划")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
              disabled: _vm.listLoading,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "1100px",
            "before-close": _vm.onDiaClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", [
            _vm.moduleVisible
              ? _c("div", { staticClass: "dialogBody" }, [
                  _c("div", { staticClass: "dialogContent" }, [
                    _c(
                      "div",
                      {
                        staticClass: "conBox",
                        staticStyle: { "padding-top": "8px" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "dialSele",
                            staticStyle: {
                              width: "110px",
                              "margin-bottom": "20px",
                            },
                            on: { change: _vm.visSeleChange },
                            model: {
                              value: _vm.video_ratio,
                              callback: function ($$v) {
                                _vm.video_ratio = $$v
                              },
                              expression: "video_ratio",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "随机", value: "" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "横版", value: "16:9" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "竖版", value: "9:16" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "正版", value: "1:1" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "configItem",
                            class: { coItemActive: _vm.carousIndex == 0 },
                            on: {
                              click: function ($event) {
                                return _vm.onTaggleCarous(0)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("所选拍品")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "fItemTag" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.auctionForm.auctList
                                    ? _vm.auctionForm.auctList.length
                                    : 0
                                ) + "件"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "configItem",
                            class: { coItemActive: _vm.carousIndex == 1 },
                            on: {
                              click: function ($event) {
                                return _vm.onTaggleCarous(1)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("字幕校对")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "fItemTag" }, [
                              _vm._v("检查字幕"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "configItem",
                            class: { coItemActive: _vm.carousIndex == 2 },
                            on: {
                              click: function ($event) {
                                return _vm.onTaggleCarous(2)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("声音设置")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "fItemTag" }, [
                              _vm._v(
                                _vm._s(_vm.aiUserName ? _vm.aiUserName : "暂无")
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "configItem",
                            class: { coItemActive: _vm.carousIndex == 3 },
                            on: {
                              click: function ($event) {
                                return _vm.onTaggleCarous(3)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("背景音乐")]),
                            _vm._v(" "),
                            _c("span", { staticClass: "fItemTag" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isUseBackName ? _vm.isUseBackName : "暂无"
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "configItem",
                            class: { coItemActive: _vm.carousIndex == 4 },
                            on: {
                              click: function ($event) {
                                return _vm.onTaggleCarous(4)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("字体设置")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "fItemTag",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "center",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isUseFontName
                                      ? _vm.isUseFontName
                                      : "跟随模版"
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "carousBox" },
                      [
                        _c(
                          "el-carousel",
                          {
                            ref: "carouselRef",
                            staticStyle: {
                              width: "100%",
                              height: "calc(100% - 47px)",
                            },
                            attrs: {
                              "initial-index": _vm.carousIndex,
                              height: "100%",
                              direction: "vertical",
                              autoplay: false,
                            },
                          },
                          [
                            _c("el-carousel-item", [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _vm._v(
                                    "\n                  已选" +
                                      _vm._s(
                                        _vm.auctionForm.auctList &&
                                          _vm.auctionForm.auctList.length
                                      ) +
                                      "件拍品\n                  "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.auctionForm.make_type != 3,
                                          expression:
                                            "auctionForm.make_type!=3",
                                        },
                                      ],
                                      staticStyle: {
                                        color: "#704af3 !important",
                                        "margin-left": "20px",
                                      },
                                      attrs: { type: "text" },
                                      on: { click: _vm.onUpdAuc },
                                    },
                                    [_vm._v("添加拍品")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass: "captionsUl",
                                  staticStyle: {
                                    "padding-top": "10px",
                                    height: "calc(100% - 40px)",
                                  },
                                },
                                _vm._l(
                                  _vm.auctionForm.auctList,
                                  function (item, index) {
                                    return _c(
                                      "li",
                                      { key: index, staticClass: "captionsLi" },
                                      [
                                        item.product.image
                                          ? _c("el-image", {
                                              staticStyle: {
                                                width: "30px",
                                                height: "30px",
                                                display: "block",
                                              },
                                              attrs: {
                                                src:
                                                  item.product.image + "!120a",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              flex: "1",
                                              "margin-left": "25px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.product.store_name)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "120px" } },
                                          [_vm._v("LOT: " + _vm._s(item.lot))]
                                        ),
                                        _vm._v(" "),
                                        _vm.auctionForm.make_type != 3
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  width: "68px",
                                                  background:
                                                    "#704af3 !important",
                                                  border:
                                                    "1px solid #704af3 !important",
                                                },
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onDeleAuction(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-carousel-item", [
                              _c(
                                "ul",
                                { staticClass: "captionsUl" },
                                [
                                  _vm.proofreadList.length > 0
                                    ? _vm._l(
                                        _vm.proofreadList,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "captionsLi",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("numberFormater")(
                                                      item.TimelineIn
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                staticClass: "sortCol",
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.captionsChange(
                                                      item
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.Content,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "Content",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.Content",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    : _c(
                                        "li",
                                        { staticClass: "captionsLi justify-c" },
                                        [_vm._v("暂无字幕")]
                                      ),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-carousel-item", [
                              _c(
                                "ul",
                                { staticClass: "captionsUl" },
                                _vm._l(
                                  _vm.iceVideoList,
                                  function (item, index) {
                                    return _c(
                                      "li",
                                      { key: index, staticClass: "captionsLi" },
                                      [
                                        _c("div", { staticClass: "flex-one" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex align-items-c" },
                                          [
                                            _vm.audioUrl == item.audioAddress
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-video-pause ziColor",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.pauseAudio()
                                                    },
                                                  },
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-video-play ziColor",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onPlayAudio(
                                                        item.audioAddress
                                                      )
                                                    },
                                                  },
                                                }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.auctionForm.make_status == 0
                                          ? [
                                              _vm.aiUserAudio === item.id
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        width: "68px",
                                                        background:
                                                          "#efefef !important",
                                                        border:
                                                          "1px solid #efefef !important",
                                                        color: "#b3b3b3",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.voicePlanChange,
                                                      },
                                                    },
                                                    [_vm._v("取消")]
                                                  )
                                                : _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        width: "68px",
                                                        background:
                                                          "#704af3 !important",
                                                        border:
                                                          "1px solid #704af3 !important",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onAiUserAudio(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("使用")]
                                                  ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-carousel-item", [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                _vm._l(
                                  _vm.backgroundMusicList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "musTabItem cur",
                                        class: {
                                          musTabInd: _vm.musIndex == index,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.musIndex = index
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _vm.backgroundMusicList.length > 0
                                ? _c(
                                    "ul",
                                    { staticClass: "captionsUl" },
                                    _vm._l(
                                      _vm.backgroundMusicList[_vm.musIndex]
                                        .children,
                                      function (item, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass: "captionsLi",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex-one" },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex align-items-c",
                                              },
                                              [
                                                _vm.audioUrl == item.url
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-video-pause ziColor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.pauseAudio()
                                                        },
                                                      },
                                                    })
                                                  : _c("i", {
                                                      staticClass:
                                                        "el-icon-video-play ziColor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onPlayAudio(
                                                            item.url
                                                          )
                                                        },
                                                      },
                                                    }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.auctionForm.make_status == 0
                                              ? [
                                                  _vm.isUseAudio == item.url
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            background:
                                                              "#efefef !important",
                                                            border:
                                                              "1px solid #efefef !important",
                                                            color: "#b3b3b3",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                            size: "mini",
                                                            disabled: "",
                                                          },
                                                        },
                                                        [_vm._v("已使用")]
                                                      )
                                                    : _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            width: "68px",
                                                            background:
                                                              "#704af3 !important",
                                                            border:
                                                              "1px solid #704af3 !important",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onUseAudio(
                                                                item.url,
                                                                item.name
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("使用")]
                                                      ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("el-carousel-item", [
                              _c(
                                "ul",
                                { staticClass: "captionsUl" },
                                _vm._l(
                                  _vm.videoFontList,
                                  function (item, index) {
                                    return _c(
                                      "li",
                                      { key: index, staticClass: "captionsLi" },
                                      [
                                        _c("div", { staticClass: "flex-one" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            style:
                                              'font-family: "' +
                                              item.name +
                                              '";margin-right: 20px;',
                                          },
                                          [_vm._v("效果体验文字 AaBbCc")]
                                        ),
                                        _vm._v(" "),
                                        _vm.auctionForm.make_status == 0
                                          ? [
                                              _vm.isUseFontId == item.id
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        background:
                                                          "#efefef !important",
                                                        border:
                                                          "1px solid #efefef !important",
                                                        color: "#b3b3b3",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        size: "mini",
                                                        disabled: "",
                                                      },
                                                    },
                                                    [_vm._v("已使用")]
                                                  )
                                                : _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        width: "68px",
                                                        background:
                                                          "#704af3 !important",
                                                        border:
                                                          "1px solid #704af3 !important",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onUseFont(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("使用")]
                                                  ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            class: { conBut: true, opc: _vm.isUpdBut },
                            attrs: { type: "primary", disabled: _vm.isUpdBut },
                            on: { click: _vm.onConfirmPreview },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.iscomf ? "确认预览" : "重新预览") +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "vcporBox" },
                      [
                        _vm.videoLoading
                          ? _c("div", { staticClass: "vcpoa" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/load.gif"),
                                  alt: "",
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("VideoComponent", {
                          ref: "videoTimeRef",
                          on: { load: _vm.onLoad },
                        }),
                        _vm._v(" "),
                        _vm.auctionForm.make_status == 0 ||
                        _vm.auctionForm.make_status == -1
                          ? _c(
                              "div",
                              { staticClass: "dialogFoot" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      background: "#704af3 !important",
                                      border: "1px solid #704af3 !important",
                                    },
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-refresh",
                                    },
                                    on: { click: _vm.onAgain },
                                  },
                                  [_vm._v("换一换")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      background: "#704af3 !important",
                                      border: "1px solid #704af3 !important",
                                    },
                                    attrs: {
                                      type: "primary",
                                      loading: _vm.isNo,
                                    },
                                    on: { click: _vm.onSubmit },
                                  },
                                  [_vm._v("确认使用")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("audio", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                      ref: "audioPlayer",
                      attrs: { src: _vm.audioUrl, controls: "" },
                      on: { ended: _vm.pauseAudio },
                    }),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.videoVisible,
            "close-on-click-modal": false,
            "before-close": _vm.closeBeforeAction,
            center: "",
            title: "视频截取",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _vm.videoVisible
              ? _c("video", { attrs: { id: "id_test_video" } })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-b align-items-c mt20" },
            [
              _c(
                "div",
                [
                  _vm._v(
                    "\n        开始时间: " +
                      _vm._s(
                        _vm._f("formatVideoTime")(_vm.videoInfo.start_time)
                      ) +
                      " "
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.onHandMark("start_time")
                        },
                      },
                    },
                    [_vm._v("标记")]
                  ),
                  _vm._v(
                    "\n        结束时间: " +
                      _vm._s(
                        _vm._f("formatVideoTime")(_vm.videoInfo.end_time)
                      ) +
                      " "
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.onHandMark("end_time")
                        },
                      },
                    },
                    [_vm._v("标记")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmitVideo },
                },
                [_vm._v("确认截取")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("SelectAuctionView", {
        ref: "selectAuctionViewRef",
        attrs: {
          venue_id: _vm.venue_id,
          relation_id: _vm.relation_id,
          currentData: _vm.currentData,
          openTitle: _vm.make_title,
        },
        on: { selectMessage: _vm.onAuctionMessage },
      }),
      _vm._v(" "),
      _c("videoDetails", {
        ref: "videoViewRef",
        on: { load: _vm.onDetailsLoad },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }