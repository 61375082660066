var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.userTit,
        visible: _vm.createFlag,
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.createFlag = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formValidate",
          staticClass: "formValidate mt20",
          attrs: {
            rules: _vm.ruleValidate,
            model: _vm.formValidate,
            "label-width": "120px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户昵称：", prop: "nickname" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入用户昵称：" },
                        model: {
                          value: _vm.formValidate.nickname,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "nickname", $$v)
                          },
                          expression: "formValidate.nickname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号：", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号" },
                        model: {
                          value: _vm.formValidate.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "phone", $$v)
                          },
                          expression: "formValidate.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别：", prop: "sex" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formValidate.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "sex", $$v)
                            },
                            expression: "formValidate.sex",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("男"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("女"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { label: "头像：", prop: "avatar" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "750*750px" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("3")
                                },
                              },
                            },
                            [
                              _vm.formValidate.avatar
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.avatar },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "实名认证：", prop: "" } },
                    [
                      _c("el-switch", {
                        on: { change: _vm.switChange },
                        model: {
                          value: _vm.isSM,
                          callback: function ($$v) {
                            _vm.isSM = $$v
                          },
                          expression: "isSM",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isSM
            ? [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "真实姓名：", prop: "real_name" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入真实姓名" },
                              model: {
                                value: _vm.formValidate.real_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "real_name", $$v)
                                },
                                expression: "formValidate.real_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "证件类型：", prop: "idcard_type" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择证件类型" },
                                on: { change: _vm.editIdCardType },
                                model: {
                                  value: _vm.formValidate.idcard_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "idcard_type",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.idcard_type",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "中国大陆", value: "1" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "香港", value: "2" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "澳门", value: "3" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "台湾", value: "4" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "其他", value: "5" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "证件号码：", prop: "idcard" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入证件号码" },
                              model: {
                                value: _vm.formValidate.idcard,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "idcard", $$v)
                                },
                                expression: "formValidate.idcard",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: { label: "证件照片：", prop: "" },
                          },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-right": "30px",
                                    display: "flex",
                                    "flex-direction": "column",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      attrs: { title: "750*750px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap("1")
                                        },
                                      },
                                    },
                                    [
                                      _vm.formValidate.idcard_just_photo
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "pictrue",
                                              staticStyle: {
                                                "margin-right": "0",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.formValidate
                                                    .idcard_just_photo,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [_vm._v("正面")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      attrs: { title: "750*750px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap("2")
                                        },
                                      },
                                    },
                                    [
                                      _vm.formValidate.idcard_back_photo
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "pictrue",
                                              staticStyle: {
                                                "margin-right": "0",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.formValidate
                                                    .idcard_back_photo,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [_vm._v("反面")]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "委托方：", prop: "" } },
                    [
                      _c("el-switch", {
                        attrs: { disabled: _vm.entrustDisable },
                        model: {
                          value: _vm.isNo,
                          callback: function ($$v) {
                            _vm.isNo = $$v
                          },
                          expression: "isNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isNo
            ? [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "委托方联系人：",
                              prop: "entrust_contact_name",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入委托方联系人" },
                              model: {
                                value: _vm.formValidate.entrust_contact_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "entrust_contact_name",
                                    $$v
                                  )
                                },
                                expression: "formValidate.entrust_contact_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "国籍：", prop: "nationality" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入国籍" },
                              model: {
                                value: _vm.formValidate.nationality,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "nationality", $$v)
                                },
                                expression: "formValidate.nationality",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "通讯地址：",
                              prop: "contact_address",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入通讯地址" },
                              model: {
                                value: _vm.formValidate.contact_address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "contact_address",
                                    $$v
                                  )
                                },
                                expression: "formValidate.contact_address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "邮编：", prop: "postal_code" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入邮编" },
                              model: {
                                value: _vm.formValidate.postal_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "postal_code", $$v)
                                },
                                expression: "formValidate.postal_code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "微信号：", prop: "weixin" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入微信号" },
                              model: {
                                value: _vm.formValidate.weixin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "weixin", $$v)
                                },
                                expression: "formValidate.weixin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "固定电话：", prop: "fixed_phone" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入固定电话" },
                              model: {
                                value: _vm.formValidate.fixed_phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "fixed_phone", $$v)
                                },
                                expression: "formValidate.fixed_phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "传真：", prop: "fax" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入传真" },
                              model: {
                                value: _vm.formValidate.fax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "fax", $$v)
                                },
                                expression: "formValidate.fax",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Email：", prop: "email" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入邮箱" },
                              model: {
                                value: _vm.formValidate.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "email", $$v)
                                },
                                expression: "formValidate.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "开户行：", prop: "account_bank" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.formValidate.account_bank,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "account_bank",
                                    $$v
                                  )
                                },
                                expression: "formValidate.account_bank",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "开户名称：",
                              prop: "account_name",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.formValidate.account_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "account_name",
                                    $$v
                                  )
                                },
                                expression: "formValidate.account_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "银行账号：",
                              prop: "account_bank_card_number",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value:
                                  _vm.formValidate.account_bank_card_number,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "account_bank_card_number",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.account_bank_card_number",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "期初应付：",
                              prop: "begin_amount",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.formValidate.begin_amount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "begin_amount",
                                    $$v
                                  )
                                },
                                expression: "formValidate.begin_amount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "期末应付：", prop: "end_amount" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.formValidate.end_amount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "end_amount", $$v)
                                },
                                expression: "formValidate.end_amount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "税率：", prop: "rate" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.formValidate.rate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "rate", $$v)
                                },
                                expression: "formValidate.rate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "备注：", prop: "remark" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入备注" },
                      model: {
                        value: _vm.formValidate.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "remark", $$v)
                        },
                        expression: "formValidate.remark",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "submission",
                  staticStyle: { width: "100px" },
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit("formValidate")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }