"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var panels = {
  1: {
    pBackground: 'rgba(236, 235, 233, 1)',
    themeData: {
      pageBackground: 'rgba(156, 128, 79, 1)',
      // 区域背景颜色
      pageColor: '#fff',
      // 区域文字颜色
      borderColor: 'rgba(156, 128, 79, 1)',
      // 边框颜色
      specialColor: 'rgba(156, 128, 79, 1)',
      // 文字区域 特殊颜色

      textBackground: 'rgba(156, 128, 79, 0.19)',
      // 专场文字区域背景色
      titleColor: 'rgba(62, 62, 62, 1)',
      // 标题文字颜色
      preTimeTextColor: 'rgba(62, 62, 62, 1)',
      // 预展时间标签颜色
      preTimeColor: 'rgba(62, 62, 62, 1)',
      // 预展时间颜色
      timeTextColor: 'rgba(62, 62, 62, 1)',
      // 开拍时间标签颜色
      timeColor: 'rgba(62, 62, 62, 1)',
      // 开拍时间颜色

      animate_color: 'rgb(124, 124, 124)',
      // 动画提示文字颜色
      auction_spacing_color: 'rgba(193, 193, 193, 0.43)',
      // 拍品间距线颜色
      item_background: '',
      content_background: 'rgba(236, 235, 233, 1)',
      border_color: 'rgba(156, 128, 79, 1)',
      // 边框颜色
      border_shawdow_color: 'rgba(156, 128, 79, 0.43)',
      // 边框颜色
      lot_color: 'rgba(156, 128, 79, 1)',
      // lot文字颜色
      title_color: 'rgba(156, 128, 79, 1)',
      // 标题文字颜色
      desc_color: 'rgba(156, 128, 79, 1)',
      // 其他文字颜色
      price_color: 'rgba(156, 128, 79, 1)',
      // 价格文字颜色
      content_color: 'rgba(156, 128, 79, 1)',
      // 价格文字颜色

      contact_pageBackground: 'rgba(236, 235, 233, 1)',
      // 联系我们背景颜色
      contact_lineBackgound: 'rgba(76, 133, 212, 0.07)',
      // 联系我们分割线颜色
      contact_titleColor: 'rgba(156, 128, 79, 1)',
      // 联系我们标题颜色
      contact_pageColor: 'rgba(168, 168, 168, 1)',
      // 联系我们文字颜色

      color: 'rgba(62, 62, 62, 1)' // 图文组件文字颜色
    }
  },
  2: {
    pBackground: 'rgba(223, 230, 230, 1)',
    themeData: {
      pageBackground: 'rgba(0, 125, 98, 1)',
      // 区域背景颜色
      pageColor: '#fff',
      // 区域文字颜色
      borderColor: 'rgba(0, 125, 98, 0.67)',
      // 边框颜色
      specialColor: 'rgba(0, 125, 98, 1)',
      // 文字区域 特殊颜色

      textBackground: 'rgba(101, 152, 143, 0.19)',
      // 专场文字区域背景色
      titleColor: 'rgba(51, 51, 51, 0.8)',
      // 标题文字颜色
      preTimeTextColor: 'rgba(51, 51, 51, 0.8)',
      // 预展时间标签颜色
      preTimeColor: 'rgba(51, 51, 51, 0.8)',
      // 预展时间颜色
      timeTextColor: 'rgba(51, 51, 51, 0.8)',
      // 开拍时间标签颜色
      timeColor: 'rgba(62, 62, 62, 1)',
      // 开拍时间颜色

      animate_color: 'rgb(124, 124, 124)',
      // 动画提示文字颜色
      auction_spacing_color: 'rgba(193, 193, 193, 0.43)',
      // 拍品间距线颜色
      item_background: '',
      // 拍品区域背景色
      content_background: 'rgba(223, 230, 230, 1)',
      // 拍品文字区域背景色
      border_color: 'rgba(0, 125, 98, 0.67)',
      // 边框颜色
      border_shawdow_color: 'rgba(0, 125, 98, 0.43)',
      // 边框颜色
      lot_color: 'rgba(1, 125, 99, 1)',
      // lot文字颜色
      title_color: 'rgba(1, 125, 99, 1)',
      // 标题文字颜色
      desc_color: 'rgba(1, 125, 99, 1)',
      // 其他文字颜色
      price_color: 'rgba(1, 125, 99, 1)',
      // 价格文字颜色
      content_color: 'rgba(1, 125, 99, 1)',
      // 价格文字颜色

      contact_pageBackground: 'rgba(223, 230, 230, 1)',
      // 联系我们背景颜色
      contact_lineBackgound: 'rgba(76, 133, 212, 0.07)',
      // 联系我们分割线颜色
      contact_titleColor: 'rgba(1, 125, 99, 1)',
      // 联系我们标题颜色
      contact_pageColor: 'rgba(84, 84, 84, 1)',
      // 联系我们文字颜色

      color: 'rgba(51, 51, 51, 0.8)' // 图文组件文字颜色
    }
  },
  3: {
    pBackground: 'rgba(239, 247, 234, 1)',
    themeData: {
      pageBackground: 'rgba(161, 181, 145, 1)',
      // 区域背景颜色
      pageColor: '#fff',
      // 区域文字颜色
      borderColor: 'rgba(73, 134, 66, 1)',
      // 边框颜色
      specialColor: 'rgba(153, 204, 0, 1)',
      // 文字区域 特殊颜色

      textBackground: 'rgba(239, 247, 234, 0.49)',
      // 专场文字区域背景色
      titleColor: 'rgba(62, 62, 62, 1)',
      // 标题文字颜色
      preTimeTextColor: 'rgba(62, 62, 62, 1)',
      // 预展时间标签颜色
      preTimeColor: 'rgba(62, 62, 62, 1)',
      // 预展时间颜色
      timeTextColor: 'rgba(62, 62, 62, 1)',
      // 开拍时间标签颜色
      timeColor: 'rgba(62, 62, 62, 1)',
      // 开拍时间颜色

      animate_color: 'rgb(124, 124, 124)',
      // 动画提示文字颜色
      auction_spacing_color: 'rgba(193, 193, 193, 0.43)',
      // 拍品间距线颜色
      item_background: '',
      // 拍品区域背景色
      content_background: 'rgba(239, 247, 234, 0.49)',
      // 拍品文字区域背景色
      border_color: 'rgba(180, 197, 161, 1)',
      // 边框颜色
      border_shawdow_color: 'rgba(180, 197, 161, 0.43)',
      // 边框颜色
      lot_color: 'rgba(73, 134, 66, 1)',
      // lot文字颜色
      title_color: 'rgba(73, 134, 66, 1)',
      // 标题文字颜色
      desc_color: 'rgba(73, 134, 66, 1)',
      // 其他文字颜色
      price_color: 'rgba(73, 134, 66, 1)',
      // 价格文字颜色
      content_color: 'rgba(73, 134, 66, 1)',
      // 价格文字颜色

      contact_pageBackground: 'rgba(239, 247, 234, 1)',
      // 联系我们背景颜色
      contact_lineBackgound: 'rgba(76, 133, 212, 0.07)',
      // 联系我们分割线颜色
      contact_titleColor: 'rgba(1, 125, 99, 1)',
      // 联系我们标题颜色
      contact_pageColor: 'rgba(84, 84, 84, 1)',
      // 联系我们文字颜色

      color: 'rgba(51, 51, 51, 0.8)' // 图文组件文字颜色
    }
  },
  4: {
    pBackground: 'rgba(232, 237, 244, 1)',
    themeData: {
      pageBackground: 'rgba(9, 94, 146, 1)',
      // 区域背景颜色
      pageColor: '#fff',
      // 区域文字颜色
      borderColor: 'rgba(9, 94, 146, 1)',
      // 边框颜色
      specialColor: 'rgba(9, 94, 146, 0.96)',
      // 文字区域 特殊颜色

      textBackground: 'rgba(232, 237, 244, 1)',
      // 专场文字区域背景色
      titleColor: 'rgba(62, 62, 62, 1)',
      // 标题文字颜色
      preTimeTextColor: 'rgba(62, 62, 62, 1)',
      // 预展时间标签颜色
      preTimeColor: 'rgba(62, 62, 62, 1)',
      // 预展时间颜色
      timeTextColor: 'rgba(62, 62, 62, 1)',
      // 开拍时间标签颜色
      timeColor: 'rgba(62, 62, 62, 1)',
      // 开拍时间颜色

      animate_color: 'rgb(124, 124, 124)',
      // 动画提示文字颜色
      auction_spacing_color: 'rgba(193, 193, 193, 0.43)',
      // 拍品间距线颜色
      item_background: '',
      // 拍品区域背景色
      content_background: 'rgba(232, 237, 244, 1)',
      // 拍品文字区域背景色
      border_color: 'rgba(9, 94, 146, .63)',
      // 边框颜色
      border_shawdow_color: 'rgba(9, 94, 146, 0.33)',
      // 边框颜色
      lot_color: 'rgba(9, 94, 146, 0.96)',
      // lot文字颜色
      title_color: 'rgba(9, 94, 146, 0.96)',
      // 标题文字颜色
      desc_color: 'rgba(9, 94, 146, 0.96)',
      // 其他文字颜色
      price_color: 'rgba(9, 94, 146, 0.96)',
      // 价格文字颜色
      content_color: 'rgba(9, 94, 146, 0.96)',
      // 价格文字颜色

      contact_pageBackground: 'rgba(232, 237, 244, 1)',
      // 联系我们背景颜色
      contact_lineBackgound: 'rgba(76, 133, 212, 0.07)',
      // 联系我们分割线颜色
      contact_titleColor: 'rgba(9, 94, 146, 0.96)',
      // 联系我们标题颜色
      contact_pageColor: 'rgba(61, 61, 61, 1)',
      // 联系我们文字颜色

      color: 'rgba(61, 61, 61, 1)' // 图文组件文字颜色
    }
  },
  5: {
    pBackground: 'rgba(175, 187, 184, 1)',
    themeData: {
      pageBackground: 'rgba(70, 104, 105, 1)',
      // 区域背景颜色
      pageColor: '#fff',
      // 区域文字颜色
      borderColor: 'rgba(70, 104, 105, 1)',
      // 边框颜色
      specialColor: 'rgba(4, 92, 103, 1)',
      // 文字区域 特殊颜色

      textBackground: 'rgba(239, 238, 234, 1)',
      // 专场文字区域背景色
      titleColor: 'rgba(4, 92, 103, 1)',
      // 标题文字颜色
      preTimeTextColor: 'rgba(4, 92, 103, 1)',
      // 预展时间标签颜色
      preTimeColor: 'rgba(4, 92, 103, 1)',
      // 预展时间颜色
      timeTextColor: 'rgba(4, 92, 103, 1)',
      // 开拍时间标签颜色
      timeColor: 'rgba(4, 92, 103, 1)',
      // 开拍时间颜色

      animate_color: 'rgb(124, 124, 124)',
      // 动画提示文字颜色
      auction_spacing_color: 'rgba(193, 193, 193, 0.43)',
      // 拍品间距线颜色
      item_background: '',
      // 拍品区域背景色
      content_background: 'rgba(239, 238, 234, 1)',
      // 拍品文字区域背景色
      border_color: 'rgba(70, 104, 105, 1)',
      // 边框颜色
      border_shawdow_color: 'rgba(70, 104, 105, 0.33)',
      // 边框颜色
      lot_color: 'rgba(4, 92, 103, 1)',
      // lot文字颜色
      title_color: 'rgba(4, 92, 103, 1)',
      // 标题文字颜色
      desc_color: 'rgba(4, 92, 103, 1)',
      // 其他文字颜色
      price_color: 'rgba(4, 92, 103, 1)',
      // 价格文字颜色
      content_color: 'rgba(4, 92, 103, 1)',
      // 价格文字颜色

      contact_pageBackground: 'rgba(239, 238, 234, 1)',
      // 联系我们背景颜色
      contact_lineBackgound: 'rgba(76, 133, 212, 0.07)',
      // 联系我们分割线颜色
      contact_titleColor: 'rgba(4, 92, 103, 1)',
      // 联系我们标题颜色
      contact_pageColor: 'rgba(61, 61, 61, 1)',
      // 联系我们文字颜色

      color: 'rgba(239, 238, 234, 1)' // 图文组件文字颜色
    }
  }
};
var _default = exports.default = panels;