var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
    [
      _c("header-breadcrumb", {
        attrs: { name: "库房管理" },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { color: "#3480F4", cursor: "pointer" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                            alt: "",
                          },
                        }),
                        _vm._v("\n          配置\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("1")
                                },
                              },
                            },
                            [_vm._v("入库单")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("2")
                                },
                              },
                            },
                            [_vm._v("出库单")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("3")
                                },
                              },
                            },
                            [_vm._v("调拨单")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("4")
                                },
                              },
                            },
                            [_vm._v("仓库")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticStyle: { height: "auto" } },
        [
          _c("div", { staticClass: "top-view" }, [
            _c(
              "div",
              {
                staticStyle: {
                  padding: "0 20px",
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo default-tabs mt10",
                    attrs: {
                      "default-active": _vm.routerUrl,
                      mode: "horizontal",
                    },
                    on: { select: _vm.changeTab },
                  },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/warehouse/manage/pushwarehouse",
                        },
                      },
                      [_vm._v("入库单")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/warehouse/manage/outwarehouse",
                        },
                      },
                      [_vm._v("出库单")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/warehouse/manage/transfers",
                        },
                      },
                      [_vm._v("调拨单")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      { attrs: { index: "/merchant/warehouse/manage/check" } },
                      [_vm._v("盘点单")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _vm.key == "/merchant/warehouse/manage/pushwarehouse"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "merchantErpV2WarehouseOrderCreate",
                                expression:
                                  "'merchantErpV2WarehouseOrderCreate'",
                              },
                            ],
                            staticStyle: { height: "32px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              icon: "el-icon-circle-plus-outline",
                            },
                            on: { click: _vm.createPush },
                          },
                          [_vm._v("创建入库单")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.key == "/merchant/warehouse/manage/outwarehouse"
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { height: "32px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              icon: "el-icon-circle-plus-outline",
                            },
                            on: { click: _vm.createOut },
                          },
                          [_vm._v("创建出库单")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.key == "/merchant/warehouse/manage/transfers"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value:
                                  "merchantErpV2WarehouseOrderCreateTransfers",
                                expression:
                                  "'merchantErpV2WarehouseOrderCreateTransfers'",
                              },
                            ],
                            staticStyle: { height: "32px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              icon: "el-icon-circle-plus-outline",
                            },
                            on: { click: _vm.createTransfers },
                          },
                          [_vm._v("创建调拨单")]
                        )
                      : _vm._e(),
                    _vm.key == "/merchant/warehouse/manage/check"
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "merchantErpStockTakeTaskCreate",
                                expression: "'merchantErpStockTakeTaskCreate'",
                              },
                            ],
                            staticStyle: { height: "32px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              icon: "el-icon-circle-plus-outline",
                            },
                            on: { click: _vm.createCheck },
                          },
                          [_vm._v("创建盘点单")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key, ref: "warehouse" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }