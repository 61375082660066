"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
var _index = require("@/utils/index");
var _webSocket = _interopRequireDefault(require("@/utils/webSocket"));
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPrice: _index.formatPrice,
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      w: 0,
      h: 0,
      fix: 1,
      minutes: '',
      second: '',
      auction: {
        product: {}
      },
      albumData: {},
      recordList: [],
      nowRecord: {},
      lastRecord: {},
      interval: null,
      client_id: '',
      sdata: {
        socketServe: _webSocket.default.Instance
      },
      album_id: this.$route.query.id,
      nowPrice: 0,
      end: false,
      albumEndData: {},
      countDownTime: [],
      countDownTimeUser: [],
      countDownInt: null
    };
  },
  created: function created() {
    var _this = this;
    this.h = document.documentElement.clientHeight || document.body.clientHeight;
    this.w = document.documentElement.clientWidth || document.body.clientWidth;
    this.fix = this.w / 1920;
    if (!_webSocket.default.Instance.ws) {
      _webSocket.default.Instance.connect();
      this.sdata.socketServe = _webSocket.default.Instance;
      this.sdata.socketServe.registerCallBack('getMessage', function (data) {
        _this.sockedMes(data);
      });
      this.sdata.socketServe.registerCallBack('openSocket', function () {
        // sendData({
        //   type: "join_auction_room",
        //   auction_id: id.value,
        // });
      });
    } else if (this.client_id) {
      this.joinRoomAction();
      // sendData({
      //   type: "join_auction_room",
      //   auction_id: id.value,
      // });
    }
    var date = new Date();
    this.minutes = this.formatString(date.getHours());
    this.second = this.formatString(date.getMinutes());
    this.interval = setInterval(this.loadTime, 10000);
  },
  methods: {
    sockedMes: function sockedMes(d) {
      var _this2 = this;
      if (d.type === 'init') {
        // 初始化
        this.client_id = d.client_id;
        this.joinRoomAction();
      } else if (d.type === 'auction_start') {
        // 确认起拍价
        this.lastRecord = {};
        this.nowRecord = {};
        if (d.data.auction_info && d.data.auction_info.bid_record) {
          this.recordList = d.data.auction_info.bid_record;
        } else {
          this.recordList = [];
        }
        this.auction = d.data.auction_info;
      } else if (d.type === 'auction_bid') {
        // 出价
        if (this.auction && this.auction.product_id === d.data.id) {
          if (this.recordList && this.recordList[0]) {
            this.lastRecord = JSON.parse(JSON.stringify(this.recordList[0]));
          }
          this.nowRecord = d.data.bid_record;
          this.recordList = [d.data.bid_record].concat(this.recordList);
          if (d.data.bid_record.is_confirm !== 0) {
            this.nowPrice = d.data.now_price;
          }
        }
      } else if (d.type === 'auction_bid_proxy') {
        // 出价
        if (this.auction && this.auction.product_id === d.data.id) {
          this.recordList = d.data.bid_record_list;
          if (d.data.bid_record_list[1]) {
            this.lastRecord = JSON.parse(JSON.stringify(d.data.bid_record_list[1]));
          }
          if (d.data.bid_record_list[0]) {
            this.nowRecord = JSON.parse(JSON.stringify(d.data.bid_record_list[0]));
          }
          this.nowPrice = d.data.now_price;
        }
      } else if (d.type === 'auction_bid_del') {
        // 删除最高价
        if (this.auction && this.auction.product_id === d.data.id) {
          this.recordList = d.data.bid_list;
          if (d.data.bid_list[1]) {
            this.lastRecord = JSON.parse(JSON.stringify(d.data.bid_list[1]));
          }
          if (d.data.bid_list[0]) {
            this.nowRecord = JSON.parse(JSON.stringify(d.data.bid_list[0]));
          }
          this.nowPrice = d.data.now_price;
        }
      } else if (d.type === 'auction_end' || d.type === 'auction_revoke') {
        if (d.data.next_auction) {
          this.lastRecord = {};
          this.nowRecord = {};
          this.recordList = [];
          this.auction = d.data.next_auction;
          this.nowPrice = 0;
        } else {
          (0, _auction.albumAuctionStatistical)({
            album_id: this.album_id
          }).then(function (res) {
            _this2.albumEndData = res.data;
            _this2.albumEndData.end_time = res.server_time;
            _this2.end = true;
          });
        }
      } else if (d.type === 'auction_remake') {
        this.lastRecord = {};
        this.nowRecord = {};
        this.recordList = [];
        this.auction = d.data.auction_info;
        this.nowPrice = 0;
      } else if (d.type === 'auction_bid_confirm') {
        if (this.recordList.length > 0) {
          for (var i = 0; i < this.recordList.length; i += 1) {
            var m = this.recordList[i];
            if (m.bid_id === d.data.bid_record.bid_id) {
              this.recordList[i].is_confirm = 1;
              break;
            }
          }
        }
        this.nowPrice = d.data.now_price;
      } else if (d.type === 'album_switch' && d.data && d.data.next_album_info) {
        window.location.href = '/merchant/auctioneer?id=' + d.data.next_album_info.album_id;
      } else if (d.type === 'auction_bid_price_remind_set') {
        this.countDownTime.push({
          time: d.data.remindSecond + 1,
          uid: d.data.userNumber.uid,
          number: d.data.userNumber.number,
          countDownInt: null
        });
        this.countDownTimeUser = this.countDownTime.map(function (m) {
          return m.uid;
        });
        var _i = this.countDownTime.length - 1;
        this.djs(this.countDownTime[_i].uid, this.countDownTime[_i].time);
      } else if (d.type === 'auction_bid_price_remind_delayed') {
        var _i2 = this.countDownTimeUser.indexOf(d.data.userNumber.uid);
        if (_i2 !== -1) {
          if (this.countDownTime[_i2].countDownInt) clearTimeout(this.countDownTime[_i2].countDownInt);
          this.countDownTime[_i2].time = d.data.surplusSecond + d.data.remindSecond + 1;
          this.djs(this.countDownTime[_i2].uid, this.countDownTime[_i2].time);
        }
      } else if (d.type === 'auction_bid_price_remind_cancel') {
        var _i3 = this.countDownTimeUser.indexOf(d.data.userNumber.uid);
        if (_i3 !== -1) {
          if (this.countDownTime[_i3].countDownInt) clearTimeout(this.countDownTime[_i3].countDownInt);
          this.countDownTime.splice(_i3, 1);
          this.countDownTimeUser = this.countDownTime.map(function (m) {
            return m.uid;
          });
        }
      }
    },
    djs: function djs(uid, time) {
      var _this3 = this;
      (0, _index.countDown)(time, function (h, m, s) {
        var i = _this3.countDownTimeUser.indexOf(uid);
        if (s) {
          if (_this3.countDownTime[i]) _this3.countDownTime[i].time = s;
        } else {
          _this3.countDownTime.splice(i, 1);
          _this3.countDownTimeUser = _this3.countDownTime.map(function (m) {
            return m.uid;
          });
        }
      }, function (sInt) {
        var i = _this3.countDownTimeUser.indexOf(uid);
        if (_this3.countDownTime[i]) _this3.countDownTime[i].countDownInt = sInt;
      });
    },
    joinRoomAction: function joinRoomAction() {
      var _this4 = this;
      (0, _auction.joinRoom)({
        album_id: this.album_id,
        client_id: this.client_id
      }).then(function (res) {
        _this4.albumData = res.data.album_info;
        _this4.auction = res.data.now_auction;
        _this4.nowPrice = res.data.now_auction.now_price;
        _this4.recordList = res.data.now_auction && res.data.now_auction.bid_record && res.data.now_auction.bid_record.length > 0 ? res.data.now_auction.bid_record : [];
        if (res.data.now_auction && res.data.now_auction.bid_record && res.data.now_auction.bid_record[0]) {
          _this4.nowRecord = res.data.now_auction.bid_record[0];
        }
        if (res.data.now_auction && res.data.now_auction.bid_record && res.data.now_auction.bid_record[1]) {
          _this4.lastRecord = res.data.now_auction.bid_record[1];
        }
      }).catch(function () {});
    },
    loadTime: function loadTime() {
      if (this.$route.name === 'auctionAuctioneer') {
        var date = new Date();
        this.minutes = this.formatString(date.getHours());
        this.second = this.formatString(date.getMinutes());
      } else if (this.interval) {
        clearInterval(this.interval);
      }
    },
    formatString: function formatString(v) {
      return v.toString().length === 1 ? "0".concat(v) : v;
    },
    recordConfirmAction: function recordConfirmAction(id) {
      var _this5 = this;
      if (this.saveLock) return false;
      this.saveLock = true;
      (0, _auction.confirmBidPrice)({
        bid_id: id,
        auction_id: this.auction.product_id
      }).then(function () {
        _this5.saveLock = false;
      }).catch(function (message) {
        _this5.saveLock = false;
      });
      return false;
    },
    trimHtml: function trimHtml(val) {
      var str = val;
      str = str.replace(/(\n)/g, '');
      str = str.replace(/(\t)/g, '');
      str = str.replace(/(\r)/g, '');
      str = str.replace(/<\/?[^>]*>/g, '');
      str = str.replace(/\s*/g, '');
      str = str.replace(/<[^>]*>/g, '');
      return str;
    }
  }
};