"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _admin = _interopRequireDefault(require("./admin"));
var _dashboard = require("@/api/dashboard");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Dashboard',
  components: {
    adminDashboard: _admin.default
  },
  data: function data() {
    return {
      currentRole: 'adminDashboard',
      formatPriceNumber: _index.formatPriceNumber,
      statisTotal: {
        approvalStatistics: {},
        orderStatistics: {},
        financialStatistics: {},
        businessStatistics: {}
      },
      date: 'today',
      orderTotal: {},
      timeValue: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['roles'])),
  created: function created() {
    var _this = this;
    // if (!this.roles.includes('admin2')) {
    //   this.currentRole = 'editorDashboard'
    // }
    (0, _dashboard.homePageStatisticsApi)().then(function (res) {
      // console.log(res);
      _this.statisTotal = res.data;
    });
    this.init();
  },
  methods: {
    onchangeTime: function onchangeTime(e) {
      console.log(this.timeValue);
      this.onTabChange(this.timeValue.join('-'));
    },
    init: function init() {
      var _this2 = this;
      (0, _dashboard.lastDataApi)({
        date: this.date
      }).then(function (res) {
        // console.log(res);
        _this2.orderTotal = res.data;
      });
    },
    onTabChange: function onTabChange(val) {
      this.date = val;
      this.init();
    },
    onGoUrl: function onGoUrl(url) {
      this.$router.push({
        path: url
      });
    },
    changeTab: function changeTab(data) {
      if (data && data !== this.$route.path) {
        this.$router.push({
          path: data
        });
      }
    }
  }
};