var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _vm.collectMoney.show
        ? _c("div", { attrs: { id: "printorder" } }, [
            _c("div", { staticClass: "detail-box" }, [
              _c("div", { staticClass: "detail-left" }, [
                _c("div", { staticClass: "detail-left-title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.collectMoney.data.merConfig.company_name) +
                      " | 委托对账账单\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "14px",
                      "font-family": "'printEN'",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.collectMoney.data.merConfig.company_english_name
                        ) +
                        "  Collection settlement bill\n        "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "secTit" }, [
              _vm._v(
                "您好！感谢您对我公司的大力支持，希望您能一如既往地支持我公司，谢谢！"
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-left-desc",
                staticStyle: {
                  "font-size": "13px",
                  "font-family": "'printEN'",
                  "font-weight": "500",
                },
              },
              [
                _vm._v(
                  "Thanks for your support to our company, we beg your understanding and also hope you can support us as usual!"
                ),
              ]
            ),
            _vm._v(" "),
            _vm.collectMoney.data.venueInfo
              ? _c(
                  "div",
                  {
                    staticClass: "detail-left-title",
                    staticStyle: { "margin-top": "40px" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.collectMoney.data.venueInfo.title) + " "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-list",
                staticStyle: { "margin-top": "5px" },
              },
              [
                _c("div", [
                  _vm._v("委托人姓名 SELLER'S NAME "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-size": "16px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.collectMoney.data.entrustUser.real_name
                            ? _vm.collectMoney.data.entrustUser.real_name
                            : _vm.collectMoney.data.entrustUser.nickname
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("合同编号 CONTRACT NO. "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-size": "16px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.collectMoney.data.contractInfo
                            ? _vm.collectMoney.data.contractInfo.contract_no
                            : ""
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("交易日期 DATE OF SALE "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "500",
                        "font-size": "16px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.collectMoney.data.contractInfo.sign_date)
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ptd-box" },
              [
                _c(
                  "div",
                  {
                    staticClass: "ptd",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ptd-title", staticStyle: { flex: "1" } },
                      [
                        _c("span", [_vm._v("图录号")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "font-family": "'printEN'" } },
                          [_vm._v("LOT NO.")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-title", staticStyle: { flex: "2" } },
                      [
                        _c("span", [_vm._v("拍品名称")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "font-family": "'printEN'" } },
                          [_vm._v("DESCRIPTION")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-title", staticStyle: { flex: "2" } },
                      [
                        _c("span", [_vm._v("成交状态")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "font-family": "'printEN'" } },
                          [_vm._v("SALES STATUS")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-title", staticStyle: { flex: "2" } },
                      [
                        _c("span", [_vm._v("落槌价")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "font-family": "'printEN'" } },
                          [_vm._v("HAMMER PRICE")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-title", staticStyle: { flex: "4" } },
                      [
                        _c("span", [_vm._v("佣金明细")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "font-family": "'printEN'" } },
                          [_vm._v("REMUNERATION")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-title", staticStyle: { flex: "2" } },
                      [
                        _c("span", [_vm._v("金额")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "font-family": "'printEN'" } },
                          [_vm._v("SUBTOTAL")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.collectMoney.data.checkAuctionData, function (m, i) {
                  return _c("div", { key: "order" + i, staticClass: "ptd" }, [
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                      [_vm._v(_vm._s(m.pmAuction.lot))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _c("span", [
                          _vm._v(_vm._s(m.pmAuction.product.store_name)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [_vm._v(_vm._s(m.auctionStatusText))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(m.pmAuction.now_price, 2)
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        staticStyle: { flex: "4", "flex-direction": "column" },
                      },
                      [
                        m.auction_status <= 4
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  width: "100%",
                                  "justify-content": "space-around",
                                },
                              },
                              _vm._l(m.price_data[1], function (e, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: "1" + ind,
                                    staticStyle: {
                                      "line-height": "20px",
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    e.name && e.deduction_amount != 0
                                      ? [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(e.name) +
                                                _vm._s(
                                                  e.unit == 1 ? "" : e.number
                                                ) +
                                                _vm._s(e.unit == 1 ? "" : "%")
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          e.deduction_amount
                                            ? _c("div", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(e.deduction_amount) +
                                                    "元"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  width: "100%",
                                  "justify-content": "space-around",
                                },
                              },
                              _vm._l(m.price_data[0], function (e, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: "0" + ind,
                                    staticStyle: {
                                      "line-height": "20px",
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    e.name && e.deduction_amount != 0
                                      ? [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(e.name) +
                                                _vm._s(
                                                  e.unit == 1 ? "" : e.number
                                                ) +
                                                _vm._s(e.unit == 1 ? "" : "%")
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          e.deduction_amount
                                            ? _c("div", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(e.deduction_amount) +
                                                    "元"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              width: "100%",
                              "justify-content": "space-around",
                            },
                          },
                          _vm._l(m.price_data[2], function (e, ind) {
                            return _c(
                              "div",
                              {
                                key: "2" + ind,
                                staticStyle: {
                                  "line-height": "20px",
                                  display: "flex",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                e.name && e.deduction_amount != 0
                                  ? [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(e.name) +
                                            _vm._s(
                                              e.unit == 1 ? "" : e.number
                                            ) +
                                            _vm._s(e.unit == 1 ? "" : "%")
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      e.deduction_amount
                                        ? _c("div", [
                                            _vm._v(
                                              "-" +
                                                _vm._s(e.deduction_amount) +
                                                "元"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(_vm.formatPriceNumber(m.settlement_amount, 2))
                        ),
                      ]
                    ),
                  ])
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ptd" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      staticStyle: { flex: "1", "flex-direction": "column" },
                    },
                    [
                      _c("span", [_vm._v("合计")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'printEN'" } },
                        [_vm._v("TOTAL")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.collectMoney.data.checkAuctionData.length
                          ) + "件"
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "ptd-body",
                    staticStyle: { flex: "2" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.collectMoney.data.nowTotal,
                            2
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      staticStyle: { flex: "4", "flex-direction": "column" },
                    },
                    [
                      _vm.collectMoney.data.obj.err > 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                width: "100%",
                                "justify-content": "space-around",
                              },
                            },
                            _vm._l(
                              _vm.collectMoney.data.configPrice[1],
                              function (e, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: "1" + ind,
                                    staticStyle: {
                                      "line-height": "20px",
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    e.name && e.deduction_amount != 0
                                      ? [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(e.name) +
                                                _vm._s(
                                                  e.unit == 1 ? "" : e.number
                                                ) +
                                                _vm._s(e.unit == 1 ? "" : "%")
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          e.deduction_amount
                                            ? _c("div", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(e.deduction_amount) +
                                                    "元"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.collectMoney.data.obj.succ > 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                width: "100%",
                                "justify-content": "space-around",
                              },
                            },
                            _vm._l(
                              _vm.collectMoney.data.configPrice[0],
                              function (e, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: "0" + ind,
                                    staticStyle: {
                                      "line-height": "20px",
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    e.name && e.deduction_amount != 0
                                      ? [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(e.name) +
                                                _vm._s(
                                                  e.unit == 1 ? "" : e.number
                                                ) +
                                                _vm._s(e.unit == 1 ? "" : "%")
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          e.deduction_amount
                                            ? _c("div", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(e.deduction_amount) +
                                                    "元"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            width: "100%",
                            "justify-content": "space-around",
                          },
                        },
                        _vm._l(
                          _vm.collectMoney.data.configPrice[2],
                          function (e, ind) {
                            return _c(
                              "div",
                              {
                                key: "2" + ind,
                                staticStyle: {
                                  "line-height": "20px",
                                  display: "flex",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                e.name && e.deduction_amount != 0
                                  ? [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(e.name) +
                                            _vm._s(
                                              e.unit == 1 ? "" : e.number
                                            ) +
                                            _vm._s(e.unit == 1 ? "" : "%")
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      e.deduction_amount
                                        ? _c("div", [
                                            _vm._v(
                                              "-" +
                                                _vm._s(e.deduction_amount) +
                                                "元"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.collectMoney.data.totalSettlementAmount,
                            2
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ptd" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ptd-body blb",
                      staticStyle: { flex: "3", "flex-direction": "column" },
                    },
                    [
                      _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("追加金额"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-family": "'printEN'",
                            "text-align": "left",
                          },
                        },
                        [_vm._v("DISCOUNT AMOUNT")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-body blb", staticStyle: { flex: "4" } },
                    [
                      _vm._v(
                        "+" +
                          _vm._s(
                            _vm.formatPriceNumber(
                              _vm.collectMoney.data.totalExtraSettlementAmount,
                              2
                            )
                          )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      staticStyle: { flex: "4", "flex-direction": "column" },
                    },
                    [
                      _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("实付金额"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-family": "'printEN'",
                            "text-align": "left",
                          },
                        },
                        [_vm._v("ACTUAL PAYMENT AMOUNT")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.collectMoney.data.totalPaySettlementAmount,
                            2
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ptd blb" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      staticStyle: { flex: "3", "flex-direction": "column" },
                    },
                    [
                      _c("span", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("人民币（大写）"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-family": "'printEN'",
                            "text-align": "left",
                          },
                        },
                        [_vm._v("CAPITAL IN RMB")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      staticStyle: {
                        flex: "8",
                        "border-left": "2px solid transparent",
                        "padding-right": "118px",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.collectMoney.data.totalPaySettlementAmountRmb
                        )
                      ),
                    ]
                  ),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "secTit",
                staticStyle: { "margin-top": "30px", "margin-bottom": "10px" },
              },
              [
                _vm._v(
                  "* 尊敬的委托人您好！非常感谢对我公司的支持和帮助，现将您委托我公司拍卖的作品成交结果呈上，请您查收并核对。恳请您一如既往地支持我公司。谢谢。"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-left-desc",
                staticStyle: {
                  "font-size": "13px",
                  "font-family": "'printEN'",
                  "font-weight": "500",
                },
              },
              [
                _vm._v(
                  "Dear client, hello! Thank you very much for your support and assistance to our company. We are now submitting the transaction results of the works you entrusted our company to auction. Please check and verify them. Please continue to support our company as always. thank you."
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "print-line",
              staticStyle: { "margin-top": "30px" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "detail-sign" }, [
              _vm._v("委托人签字"),
              _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                _vm._v("（SIGNATURE）"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "print-line" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-list",
                staticStyle: { "margin-top": "30px" },
              },
              [
                _c("div", [
                  _vm._v(
                    "制单人：" +
                      _vm._s(_vm.collectMoney.data.adminInfo.real_name)
                  ),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("单位盖章：")]),
                _vm._v(" "),
                _c("div", [_vm._v("收款人：")]),
                _vm._v(" "),
                _c("div", [_vm._v("单据编号：")]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }