"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import OSS from 'ali-oss';
var _default = exports.default = {
  name: 'UserList',
  data: function data() {
    return {
      userType: 'canel',
      realNameAuthentication: {
        show: false
      },
      moren: require('@/assets/images/f.png'),
      tableData: {
        data: [],
        total: 0
      },
      pwd: '',
      userObj: {},
      // 用户数据
      listLoading: true,
      userFrom: {
        page: 1,
        limit: 20,
        status: '',
        nameOrPhone: ''
      },
      userList: [],
      lock: false
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    cancel: function cancel() {
      this.realNameAuthentication.show = false;
    },
    submit: function submit() {
      var _this = this;
      // if (!this.pwd) {
      //   this.$message({
      //     type: 'warning',
      //     message: '请输入密码'
      //   })
      //   return;
      // }
      if (this.lock) return;
      this.lock = true;
      (0, _user.upgradeRejectSure)({
        id: this.userObj.id
      }).then(function (res) {
        _this.$message({
          type: 'success',
          message: '注销成功'
        });
        _this.lock = false;
        _this.realNameAuthentication.show = false;
        _this.getList(1);
      }).catch(function () {
        _this.lock = false;
      });
    },
    // 确定取消
    confirmCancel: function confirmCancel(row) {
      this.userObj = row;
      this.realNameAuthentication.show = true;
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.userFrom.page = num || this.userFrom.page;
      (0, _user.upgradeList)(this.userFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.userFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.limit = val;
      this.getList('');
    },
    beRejected: function beRejected(row) {
      var _this3 = this;
      this.$confirm("\u9A73\u56DE\u7533\u8BF7\u540E\u7528\u6237\u6B64\u6B21\u7533\u8BF7\u5C06\u53D6\u6D88\uFF0C\u7528\u6237\u82E5\u60F3\u518D\u6B21\u6CE8\u9500\uFF0C\u53EF\u5728\u4E2A\u4EBA\u4E2D\u5FC3\u518D\u6B21\u53D1\u8D77", '驳回申请', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.upgradeReject)({
          id: row.id
        }).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList(1);
        }).catch(function (message) {});
      }).catch(function () {});
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};