"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {};
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {},
  methods: {
    changeTab: function changeTab(data) {
      if (data == '/merchant/set_screen_projection' || data == '/merchant/set_led_setting') {
        window.open(data);
        return;
      }
      if (data == '/merchant/systemForm/systemAuction/numberPlatevip') {
        this.$router.replace({
          path: data,
          query: {
            isNo: true
          }
        });
        return;
      }
      console.log(data);
      if (data == '/merchant/systemForm/systemAuction/numberPlatets' || data == '/merchant/systemForm/systemAuction/numberPlatexc') {
        this.$router.replace({
          path: data,
          query: {
            isNo: false
          }
        });
        return;
      }
      if (data !== this.$route.path) {
        this.$router.replace({
          path: data
        });
      }
    }
  }
};