var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "视频生成纪录", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c("el-card", [
        _c(
          "div",
          { staticClass: "top-view", staticStyle: { padding: "0 20px" } },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo default-tabs mt10",
                attrs: {
                  "default-active": _vm.tableFrom.make_type,
                  mode: "horizontal",
                },
                on: { select: _vm.changeTab },
              },
              [
                _c("el-menu-item", { attrs: { index: "" } }, [_vm._v("全部")]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "3" } }, [_vm._v("拍品")]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "2" } }, [
                  _vm._v("拍卖专场"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("拍卖会"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "porBox" }, [
          _c(
            "div",
            { staticClass: "absoBox", staticStyle: { top: "-50px" } },
            [
              _c("el-input", {
                staticClass: "w300",
                attrs: { placeholder: "搜索作品" },
                on: {
                  blur: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.make_title,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "make_title", $$v)
                  },
                  expression: "tableFrom.make_title",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt20 logBox",
            staticStyle: { height: "calc(100vh - 170px)" },
          },
          [
            _vm.videoLogList.length > 0
              ? _c(
                  "div",
                  { staticClass: "logListBox" },
                  _vm._l(_vm.videoLogList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "logListItem" },
                      [
                        _c("div", { staticClass: "logListVideo" }, [
                          _c("div", { staticClass: "videoTag" }, [
                            item.make_type == 1
                              ? _c("span", [_vm._v("拍卖会")])
                              : _vm._e(),
                            _vm._v(" "),
                            item.make_type == 2
                              ? _c("span", [_vm._v("专场")])
                              : _vm._e(),
                            _vm._v(" "),
                            item.make_type == 3 || item.make_type == 4
                              ? _c("span", [_vm._v("拍品")])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "videoTitBox",
                              style: {
                                color:
                                  item.make_status == 1 || item.make_status == 2
                                    ? "#000"
                                    : "#fff",
                              },
                            },
                            [
                              item.make_type == 1
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(item.make_title) +
                                        _vm._s(item.makeTagText)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.make_type == 2
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(item.make_title) +
                                        _vm._s(item.makeTagText)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.make_type == 3 || item.make_type == 4
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(item.makeTypeText) +
                                        _vm._s(item.makeTagText) +
                                        "-" +
                                        _vm._s(item.make_title)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.make_status == 3 || item.make_status == 4
                                ? _c("i", {
                                    staticClass: "el-icon-download logIcon cur",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDownload(item)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    item.make_status == 1 ||
                                    item.make_status == 2,
                                  expression:
                                    "item.make_status == 1||item.make_status == 2",
                                },
                              ],
                              staticClass: "vidPlayDia",
                              attrs: {
                                "element-loading-text": "生成中",
                                "element-loading-background": "#404040",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-video-play palyIcon cur",
                                on: {
                                  click: function ($event) {
                                    return _vm.onClickView(
                                      item.extend_data.video_info.url
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          item.extend_data.video_info
                            ? _c("div", { staticClass: "logItemImg" }, [
                                _c("img", {
                                  staticClass: "logItemImg",
                                  attrs: {
                                    src:
                                      "" + item.extend_data.video_info.coverUrl,
                                    alt: "",
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex justify-b",
                            staticStyle: { "padding-top": "10px" },
                          },
                          [
                            item.extend_data.video_info
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("formatVideoTime")(
                                          item.extend_data.video_info.duration
                                        )
                                      ) +
                                      " \n              "
                                  ),
                                  item.extend_data.video_info.size
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("bytesToMB")(
                                              item.extend_data.video_info.size
                                            )
                                          ) + "MB"
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(item.make_time))]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  {
                    staticClass: "tableEmpty",
                    staticStyle: {
                      height: "100%",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]
                ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex justify-b orderFoot" },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                "page-sizes": [20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, prev, pager, next, sizes",
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频预览",
            visible: _vm.visible,
            top: "5vh",
            "close-on-click-modal": false,
            "z-index": "999999999",
            "destroy-on-close": "",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("video", {
              staticClass: "video",
              attrs: { controls: "", src: _vm.videoUrl },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }