var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showconfig,
            direction: "rtl",
            width: "700px",
            title: "字段配置",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showconfig = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex edit-config" }, [
            _c("div", { staticClass: "flex-one" }, [
              _c("div", { staticClass: "t ft14 fWeight500" }, [
                _vm._v("已选择"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c" },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        attrs: { list: _vm.showList, "set-data": _vm.setData },
                      },
                      "draggable",
                      _vm.$attrs,
                      false
                    ),
                    _vm._l(_vm.showList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "show_" + index,
                          staticClass: "draw-list",
                          attrs: { draggable: "false" },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "mb2",
                            attrs: { "icon-class": "drag_new" },
                          }),
                          _vm._v(" "),
                          _c("el-checkbox", {
                            staticClass: "checkbox",
                            on: {
                              change: function ($event) {
                                return _vm.changeselected(index, "show")
                              },
                            },
                            model: {
                              value: item.select,
                              callback: function ($$v) {
                                _vm.$set(item, "select", $$v)
                              },
                              expression: "item.select",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "name ml5" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-one ml40" }, [
              _c("div", { staticClass: "t ft14 fWeight500" }, [
                _vm._v("未选择"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c" },
                _vm._l(_vm.hideList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "hide_" + index,
                      staticClass: "draw-list",
                      attrs: { draggable: "false" },
                    },
                    [
                      _c("el-checkbox", {
                        staticClass: "checkbox",
                        on: {
                          change: function ($event) {
                            return _vm.changeselected(index, "hide")
                          },
                        },
                        model: {
                          value: item.select,
                          callback: function ($$v) {
                            _vm.$set(item, "select", $$v)
                          },
                          expression: "item.select",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "name ml5" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt40 mb20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.saveheadervonfig },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }