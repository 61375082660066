"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {};
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {},
  methods: {
    onAdd: function onAdd() {
      this.$refs.userREf.adduser();
    },
    onGOUrl: function onGOUrl(url) {
      this.$router.push({
        name: url
      });
    },
    changeTab: function changeTab(data) {
      if (data !== this.$route.path) {
        this.$router.replace({
          path: data
        });
      }
    }
  }
};