var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("添加身份管理")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            data: _vm.tableData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "role_id", label: "ID", "min-width": "60" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "role_name", label: "身份名称", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "是否显示", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function ($event) {
                          return _vm.onchangeIsShow(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "创建时间",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "100", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(scope.row.role_id, scope.row)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "commonedit" },
                        }),
                        _vm._v("编辑"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(
                              scope.row.role_id,
                              scope.$index
                            )
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block paginaBox" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-top": "-6vh" },
          attrs: {
            title: "身份权限",
            visible: _vm.showruleProp,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showruleProp = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm._v("\n      身份名称：\n      "),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入身份名称" },
                model: {
                  value: _vm.rulename,
                  callback: function ($$v) {
                    _vm.rulename = $$v
                  },
                  expression: "rulename",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rule-box", staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "div",
                { staticClass: "rule-list" },
                [
                  _c(
                    "div",
                    { staticClass: "rule-header" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { label: "" },
                          on: {
                            change: function ($event) {
                              return _vm.changeChildSelect(
                                _vm.oneall,
                                _vm.oneRuleList,
                                "one"
                              )
                            },
                          },
                          model: {
                            value: _vm.oneall,
                            callback: function ($$v) {
                              _vm.oneall = $$v
                            },
                            expression: "oneall",
                          },
                        },
                        [_vm._v("菜单")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.oneRuleList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "one" + index,
                        staticClass: "rule-body",
                        class: { cursor: _vm.oneitem.id == item.id },
                        on: {
                          click: function ($event) {
                            return _vm.clickoneRule(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              on: {
                                change: function ($event) {
                                  return _vm.changeChildSelect(
                                    item.selected,
                                    item.children,
                                    "one"
                                  )
                                },
                              },
                              model: {
                                value: item.selected,
                                callback: function ($$v) {
                                  _vm.$set(item, "selected", $$v)
                                },
                                expression: "item.selected",
                              },
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(item.title) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.children.length
                          ? _c("i", { staticClass: "el-icon-arrow-right" })
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.twoRuleList.length
                ? _c(
                    "div",
                    { staticClass: "rule-list" },
                    [
                      _c("div", { staticClass: "rule-header" }, [
                        _vm._v("\n          页面\n        "),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.twoRuleList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "two" + index,
                            staticClass: "rule-body",
                            class: { cursor: _vm.twoitem.id == item.id },
                            on: {
                              click: function ($event) {
                                return _vm.clickTwoRule(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeChildSelect(
                                        item.selected,
                                        item.children,
                                        "two"
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.selected,
                                    callback: function ($$v) {
                                      _vm.$set(item, "selected", $$v)
                                    },
                                    expression: "item.selected",
                                  },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.title) +
                                    "\n          "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            item.children.length
                              ? _c("i", { staticClass: "el-icon-arrow-right" })
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.fourRuleList.length
                ? _c(
                    "div",
                    { staticClass: "rule-list" },
                    [
                      _c("div", { staticClass: "rule-header" }, [
                        _vm._v("\n          二级页面\n        "),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.fourRuleList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "two" + index,
                            staticClass: "rule-body",
                            class: { cursor: _vm.fouritem.id == item.id },
                            on: {
                              click: function ($event) {
                                return _vm.clickFourRule(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeChildSelect(
                                        item.selected,
                                        item.children,
                                        "four"
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.selected,
                                    callback: function ($$v) {
                                      _vm.$set(item, "selected", $$v)
                                    },
                                    expression: "item.selected",
                                  },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.title) +
                                    "\n          "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            item.children.length
                              ? _c("i", { staticClass: "el-icon-arrow-right" })
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.threeRuleList.length
                ? _c(
                    "div",
                    { staticClass: "rule-list" },
                    [
                      _c("div", { staticClass: "rule-header" }, [
                        _vm._v("\n          按钮\n        "),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.threeRuleList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "three" + index,
                            staticClass: "rule-body",
                            class: { cursor: _vm.threeitem.id == item.id },
                            on: {
                              click: function ($event) {
                                return _vm.clickThreeRule(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeChildSelect(
                                        item.selected,
                                        item.children,
                                        "three",
                                        item
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.selected,
                                    callback: function ($$v) {
                                      _vm.$set(item, "selected", $$v)
                                    },
                                    expression: "item.selected",
                                  },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.title) +
                                    "\n          "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            item.properties &&
                            ((item.properties.dataRule &&
                              item.properties.dataRule.length) ||
                              (item.properties.fields &&
                                item.properties.fields.length))
                              ? _c("i", { staticClass: "el-icon-arrow-right" })
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.infoRuleList.length
                ? _c(
                    "div",
                    { staticClass: "rule-list" },
                    [
                      _c("div", { staticClass: "rule-header" }, [
                        _vm._v("\n          数据权限\n        "),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.infoRuleList, function (item, index) {
                        return _c(
                          "div",
                          { key: "info" + index, staticClass: "rule-body" },
                          [
                            _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeLast(item, "data")
                                    },
                                  },
                                  model: {
                                    value: item.selected,
                                    callback: function ($$v) {
                                      _vm.$set(item, "selected", $$v)
                                    },
                                    expression: "item.selected",
                                  },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.fieldRuleList.length
                ? _c(
                    "div",
                    { staticClass: "rule-list" },
                    [
                      _c("div", { staticClass: "rule-header" }, [
                        _vm._v("\n          字段权限\n        "),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.fieldRuleList, function (item, index) {
                        return _c(
                          "div",
                          { key: "field" + index, staticClass: "rule-body" },
                          [
                            _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeLast(item)
                                    },
                                  },
                                  model: {
                                    value: item.selected,
                                    callback: function ($$v) {
                                      _vm.$set(item, "selected", $$v)
                                    },
                                    expression: "item.selected",
                                  },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.field_name) +
                                    "\n          "
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "300px", "margin-top": "20px" },
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.saverule },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }