"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _modules = _interopRequireDefault(require("./modules"));
var _covermask = _interopRequireDefault(require("../../components/customPage/covermask.vue"));
var _auction = require("@/api/auction");
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    TitleWeChat: _modules.default.title_weChat.widget,
    Rubik: _modules.default.rubik_weChat.widget,
    Blank: _modules.default.blank.widget,
    AuctionWeChat: _modules.default.auction_weChat.widget,
    AuctionAlbumWeChat: _modules.default.auction_album_weChat.widget,
    textView: _modules.default.textView.widget,
    BannerWeChat: _modules.default.banner_weChat.widget,
    ContactInformation: _modules.default.contact_information.widget,
    logoWeChat: _modules.default.logo_weChat.widget,
    contractWeChat: _modules.default.contract_weChat.widget,
    positionWeChat: _modules.default.position_weChat.widget,
    auctionNoticeWeChat: _modules.default.auction_notice_weChat.widget,
    ImgWeChat: _modules.default.img_weChat.widget,
    TextWeChat: _modules.default.text_weChat.widget,
    WeChatVideo_weChat: _modules.default.weChatVideo_weChat.widget,
    Covermask: _covermask.default
  },
  data: function data() {
    return {
      is_empty: false,
      id: this.$route.query.id || '',
      temid: this.$route.query.temid || '',
      type: this.$route.query.type || '',
      marketid: this.$route.query.marketid || ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    dragIndex: {
      get: function get() {
        return this.$store.state.finish.dragIndex;
      },
      set: function set(value) {
        this.$store.commit('finish/setDragIndex', value);
      }
    },
    position: {
      get: function get() {
        return this.$store.state.finish.position;
      },
      set: function set(value) {
        this.$store.commit('finish/setDragPosition', value);
      }
    },
    select: function select() {
      return this.$store.state.finish.index;
    },
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set(value) {
        this.$store.commit('finish/setPagesData', value);
      }
    },
    coms: function coms() {
      return this.$store.state.finish.coms;
    }
  },
  mounted: function mounted() {
    this.loadPageInfo();
  },
  methods: {
    /**
     * 加载数据操作
     * @return {[type]} [description]
     */
    loadPageInfo: function loadPageInfo() {
      var _this = this;
      if (this.id) {
        (0, _auction.tweetInfoApi)(this.id).then(function (res) {
          var configData = res.data.config_data;
          var data = {
            name: res.data.name,
            id: res.data.id,
            status: res.data.status,
            updated_time: res.data.updated_time,
            visit_number: res.data.visit_number,
            merchant_id: res.data.merchant_id,
            content: res.data.content,
            background: configData.background,
            login_config: configData.login_config || 3,
            hide_header: configData.hide_header || false,
            hide_header_view: configData.hide_header_view || false,
            headerSetting: configData.headerSetting,
            bg_image: configData.bg_image,
            bg_image_mode: configData.bg_image_mode,
            share_image: configData.share_image,
            hide_footer: configData.hide_footer || false,
            share_txt: configData.share_txt || '',
            footerSetting: configData.footerSetting,
            commonData: {
              img_position: 'center',
              theme: 1,
              page_margin: 0,
              // 页面间距
              item_margin: 15,
              // 内容间距
              item_background: '#FFFFFF',
              // 内容背景颜色
              item_padding: 15,
              // 内间距
              radius: 4,
              // 内容圆角
              title_margin: 10,
              title_color: '#000000',
              // 标题颜色
              title_weight: 500,
              title_size: 14,
              title_text_align: 'left',
              desc_margin: 15,
              desc_color: '#999999',
              desc_weight: 400,
              desc_size: 12,
              desc_line_height: 20,
              desc_text_align: 'left',
              price_margin: 15,
              price_color: '#999999',
              price_weight: 400,
              price_size: 12,
              price_line_height: 20,
              price_text_align: 'left',
              lot_margin: 15,
              lot_color: '#999999',
              lot_weight: 400,
              lot_size: 12,
              lot_line_height: 20,
              lot_text_align: 'left'
            }
          };
          _this.pages = data.content;
          _this.$store.commit('finish/setPageInfo', data);
        });
      } else if (this.type == 'edit') {
        (0, _aiMark.marketMediaDetailApi)(this.marketid).then(function (res) {
          var extend_data = res.data.extend_data;
          var configData = extend_data.config_data;
          var data = {
            name: extend_data.name,
            id: extend_data.id,
            status: extend_data.status,
            updated_time: extend_data.updated_time,
            visit_number: extend_data.visit_number,
            merchant_id: extend_data.merchant_id,
            content: extend_data.tweet_content,
            background: configData.background,
            login_config: configData.login_config || 3,
            hide_header: configData.hide_header || false,
            hide_header_view: configData.hide_header_view || false,
            headerSetting: configData.headerSetting,
            bg_image: configData.bg_image,
            bg_image_mode: configData.bg_image_mode,
            share_image: configData.share_image,
            hide_footer: configData.hide_footer || false,
            share_txt: configData.share_txt || '',
            footerSetting: configData.footerSetting,
            commonData: {
              title: configData.title,
              coverImg: configData.image,
              img_position: 'center',
              theme: 1,
              page_margin: 0,
              // 页面间距
              item_margin: 15,
              // 内容间距
              item_background: '#FFFFFF',
              // 内容背景颜色
              item_padding: 15,
              // 内间距
              radius: 4,
              // 内容圆角
              title_margin: 10,
              title_color: '#000000',
              // 标题颜色
              title_weight: 500,
              title_size: 14,
              title_text_align: 'left',
              desc_margin: 15,
              desc_color: '#999999',
              desc_weight: 400,
              desc_size: 12,
              desc_line_height: 20,
              desc_text_align: 'left',
              price_margin: 15,
              price_color: '#999999',
              price_weight: 400,
              price_size: 12,
              price_line_height: 20,
              price_text_align: 'left',
              lot_margin: 15,
              lot_color: '#999999',
              lot_weight: 400,
              lot_size: 12,
              lot_line_height: 20,
              lot_text_align: 'left'
            }
          };
          _this.pages = data.content;
          _this.$store.commit('finish/setPageInfo', data);
        });
      } else if (this.temid) {
        (0, _auction.tweetTemplateDetailApi)({
          template_id: this.temid
        }).then(function (res) {
          var configData = res.data.config_data;
          var data = {
            name: res.data.name,
            id: res.data.id,
            status: res.data.status,
            updated_time: res.data.updated_time,
            visit_number: res.data.visit_number,
            merchant_id: res.data.merchant_id,
            content: res.data.content,
            background: configData.background,
            login_config: configData.login_config || 3,
            hide_header: configData.hide_header || false,
            hide_header_view: configData.hide_header_view || false,
            headerSetting: configData.headerSetting,
            share_image: configData.share_image,
            hide_footer: configData.hide_footer || false,
            share_txt: configData.share_txt || '',
            footerSetting: configData.footerSetting,
            commonData: {
              title: res.data.title,
              coverImg: res.data.image,
              pageBackground: configData.pageBackground,
              fontFamily: configData.fontFamily || '',
              author: configData.author || '',
              digest: configData.digest || '',
              img_position: 'center',
              theme: 1,
              page_margin: 0,
              // 页面间距
              item_margin: 15,
              // 内容间距
              item_background: '#FFFFFF',
              // 内容背景颜色
              item_padding: 15,
              // 内间距
              radius: 4,
              // 内容圆角
              title_margin: 10,
              title_color: '#000000',
              // 标题颜色
              title_weight: 500,
              title_size: 14,
              title_text_align: 'left',
              desc_margin: 15,
              desc_color: '#999999',
              desc_weight: 400,
              desc_size: 12,
              desc_line_height: 20,
              desc_text_align: 'left',
              price_margin: 15,
              price_color: '#999999',
              price_weight: 400,
              price_size: 12,
              price_line_height: 20,
              price_text_align: 'left',
              lot_margin: 15,
              lot_color: '#999999',
              lot_weight: 400,
              lot_size: 12,
              lot_line_height: 20,
              lot_text_align: 'left',
              bg_image: configData.bg_image,
              bg_image_mode: configData.bg_image_mode
            }
          };
          data.content.forEach(function (m) {
            if (!m.facade.temp) m.facade.temp = [];
          });
          var imgTextData = sessionStorage.getItem('imgTextData');
          if (imgTextData) {
            var json = JSON.parse(imgTextData);
            if (json.temid == _this.temid && json.content && json.content.length) {
              _this.pages = json.content;
            } else {
              _this.pages = data.content;
            }
            sessionStorage.setItem('imgTextData', '');
          } else {
            _this.pages = data.content;
          }
          // this.pages = data.content;
          _this.$store.commit('finish/setPageInfo', data);
        });
      } else {
        var data = {
          background: '#FFFFFF',
          headerSetting: {
            header_color: 'black'
          },
          userInfoSetting: {
            avatar_width: 120,
            avatar_height: 120,
            top_padding: 0,
            left_padding: 30,
            uname_size: 32,
            uname_margin_left: 30,
            uname_color: '#FFFFFF',
            uname_style: 400,
            open_level: true,
            progress_color: '#FFFFFF',
            progress_margin_top: 8,
            progress_txt_margin_top: 10,
            progress_txt_color: '#FFFFFF',
            progress_txt_size: 24,
            progress_txt_style: 400,
            settingImg: ''
          },
          // 用户信息配置

          bg_image: '',
          //  背景图片
          bg_image_mode: 4,
          // 背景图片缩略模式

          content: '[]',
          created_time: 1632577555,
          deleted_time: null,
          goods_number: 0,
          is_deleted: 0,
          id: '',
          name: '推文制作',
          status: 0,
          updated_time: 1632745632,
          visit_number: 0,
          commonData: {
            img_position: 'center',
            theme: 1,
            page_margin: 0,
            // 页面间距
            item_margin: 15,
            // 内容间距
            item_background: '#FFFFFF',
            // 内容背景颜色
            item_padding: 15,
            // 内间距
            radius: 4,
            // 内容圆角
            title_margin: 10,
            title_color: '#000000',
            // 标题颜色
            title_weight: 500,
            title_size: 14,
            title_text_align: 'left',
            desc_margin: 15,
            desc_color: '#999999',
            desc_weight: 400,
            desc_size: 12,
            desc_line_height: 20,
            desc_text_align: 'left',
            price_margin: 15,
            price_color: '#999999',
            price_weight: 400,
            price_size: 12,
            price_line_height: 20,
            price_text_align: 'left',
            lot_margin: 15,
            lot_color: '#999999',
            lot_weight: 400,
            lot_size: 12,
            lot_line_height: 20,
            lot_text_align: 'left'
          }
        };
        this.pages = JSON.parse(data.content);
        this.$store.commit('finish/setPageInfo', data);
      }
      // diypageDetail({ type: 'personal_center' })
      //   .then(res => {
      //     if (res.data.id) {
      //       const configData = res.data.config_data;
      //       this.pages = res.data.content;
      //       const data = {
      //         background: configData.background,
      //         headerSetting: configData.headerSetting,
      //         userInfoSetting: configData.userInfoSetting, // 用户信息配置

      //         bg_image: configData.bg_image, //  背景图片
      //         bg_image_mode: configData.bg_image_mode, // 背景图片缩略模式

      //         content: '[]',
      //         created_time: 1632577555,
      //         deleted_time: null,
      //         goods_number: 0,
      //         is_deleted: 0,
      //         id: '',
      //         name: '个人中心',
      //         status: 0,
      //         title: '1632577470131',
      //         updated_time: 1632745632,
      //         visit_number: 0
      //       };
      //       this.$store.commit('finish/setPageInfo', data);
      //     } else {
      //       const data = {
      //         background: '#F7F7F7',
      //         headerSetting: {
      //           header_color: 'black'
      //         },
      //         userInfoSetting: {
      //           avatar_width: 120,
      //           avatar_height: 120,
      //           top_padding: 0,
      //           left_padding: 30,
      //           uname_size: 32,
      //           uname_margin_left: 30,
      //           uname_color: '#FFFFFF',
      //           uname_style: 400,

      //           open_level: true,
      //           progress_color: '#FFFFFF',
      //           progress_margin_top: 8,
      //           progress_txt_margin_top: 10,
      //           progress_txt_color: '#FFFFFF',
      //           progress_txt_size: 24,
      //           progress_txt_style: 400,

      //           settingImg:
      //             'https://saas.cdn.yunzongbu.cn/image/20211209/0995c825a3d1a76d6c43a7683a79c74a.png'
      //         }, // 用户信息配置

      //         bg_image:
      //           'https://saas.cdn.yunzongbu.cn/merchant/23/2021129/image/1639063418952104002.png', //  背景图片
      //         bg_image_mode: 4, // 背景图片缩略模式

      //         content: '[]',
      //         created_time: 1632577555,
      //         deleted_time: null,
      //         goods_number: 0,
      //         is_deleted: 0,
      //         id: '',
      //         name: '个人中心',
      //         status: 0,
      //         title: '1632577470131',
      //         updated_time: 1632745632,
      //         visit_number: 0
      //       };
      //       this.pages = JSON.parse(data.content);
      //       this.$store.commit('finish/setPageInfo', data);
      //     }
      //   })
      //   .catch(() => {});
      // setTimeout(() => {
      // }, 500)
    },
    /**
     * 拖拽激活
     * @param  {[type]} index    [description]
     * @param  {[type]} position [description]
     * @return {[type]}          [description]
     */
    dragActive: function dragActive(index, position) {
      if (this.dragIndex === index && this.position == position && this.coms) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 拖拽过程事件
     * @return {[type]} [description]
     */
    allowDrop: function allowDrop($event) {
      var index = parseInt($event.target.dataset.index);
      if (index || index === 0) {
        this.dragIndex = index;
        // 判断是否为拖拽元素
        if ($event.type === 'dragover' && $event.target.dataset) {
          // 判断是否是拖拽区域
          if ($event.target.dataset.type === 'widget') {
            // 获取Y轴移动值
            var y = parseFloat($event.offsetY);
            // 由于缩小50%，需要除去双倍
            var h = parseFloat($event.target.clientHeight / 4);
            if (y < h) {
              this.position = 'top';
            } else {
              this.position = 'down';
            }
          }
        }
      }
      if (index == -2) {
        this.dragIndex = index;
        this.position = 'down';
      }
      $event.preventDefault();
    },
    /**
     * 执行放置，只要在范围内
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    drop: function drop() {
      this.$store.dispatch('finish/pushPagesComs');
    },
    draggableStart: function draggableStart() {
      this.$store.commit('finish/setLock', true);
    },
    draggableEnd: function draggableEnd() {
      this.$store.commit('finish/setLock', false);
    },
    selectMessage: function selectMessage(data) {
      console.log(data);
    }
  }
};