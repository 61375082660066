var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "拍卖相关配置", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "div",
            { staticClass: "top-view plr20" },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: {
                    "default-active": _vm.$route.path,
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/systemForm/systemAuction/numberPlatevip",
                      },
                    },
                    [_vm._v("VIP号牌")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/systemForm/systemAuction/rule",
                      },
                    },
                    [_vm._v("拍卖规则")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/systemForm/systemAuction/order",
                      },
                    },
                    [_vm._v("订单与售后")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/systemForm/systemAuction/store",
                      },
                    },
                    [_vm._v("自提点设置")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/systemForm/systemAuction/range",
                      },
                    },
                    [_vm._v("竞价阶梯")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/systemForm/systemAuction/numberPlatets",
                      },
                    },
                    [_vm._v("号牌库")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }