"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.replace");
var _setting = require("@/api/setting");
var _user = require("@/api/user.js");
var _map = _interopRequireDefault(require("@/components/map/map.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Information',
  components: {
    Maps: _map.default
  },
  data: function data() {
    var checkPhone = function checkPhone(rule, value, callback) {
      // if (value === "") {
      //   callback(new Error("请输入客服电话"));
      // } else {
      //   let regPone = null;
      //   let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
      //   let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
      //   if (value.charAt(0) == 0) {
      //     // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
      //     regPone = tel;
      //   } else {
      //     regPone = mobile;
      //   }
      //   if (!regPone.test(value)) {
      //     return callback(new Error("请填写客服电话(座机格式'区号-座机号码')"));
      //   }
      //   callback();
      // }
    };
    return {
      merModel: false,
      merData: {},
      // 默认数据
      mapKey: '',
      // 地图key
      address: '',
      submitLoading: false,
      // 提交loading
      rules: {
        mer_banner: [{
          required: false,
          message: '请上传店铺banner'
        }],
        mer_logo: [{
          required: true,
          message: '请上传LOGO'
        }],
        mer_avatar: [{
          required: true,
          message: '请上传店铺头像'
        }],
        mer_info: [{
          required: true,
          message: '请输入商户简介',
          trigger: 'blur'
        }, {
          min: 3,
          max: 200,
          message: '长度在 3 到 200 个字符',
          trigger: 'blur'
        }],
        mer_keyword: [{
          required: false,
          message: '请输入商户关键字',
          trigger: 'blur'
        }],
        service_phone: [{
          required: false,
          validator: checkPhone,
          trigger: 'blur'
        }],
        mer_address: [{
          required: false,
          message: '请输入商户地址',
          trigger: 'blur'
        }],
        company_name: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getMapInfo();
    this.getInfo();
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  methods: {
    // 地图信息获取
    getCoordinates: function getCoordinates(data) {
      // this.merData.mer_address = data.address;
      this.merData.lat = data.location.lat || 34.34127;
      this.merData.long = data.location.lng || 108.93984;
      console.log(data);
    },
    // 获取历史信息
    getInfo: function getInfo() {
      var _this2 = this;
      this.merModel = false;
      (0, _user.getBaseInfo)().then(function (res) {
        res.data.bank_address = res.data.config.bank_address;
        res.data.bank = res.data.config.bank;
        res.data.bank_name = res.data.config.bank_name;
        res.data.bank_number = res.data.config.bank_number;
        res.data.company_english_name = res.data.config.company_english_name;
        res.data.contact_phone = res.data.config.contact_phone;
        res.data.customer_service_url = res.data.config.customer_service_url;
        res.data.company_address = res.data.config.company_address;
        res.data.h5_domain_icon = res.data.config.h5_domain_icon;
        _this2.merData = res.data;
        _this2.merModel = true;
      });
    },
    // 提交/修改
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var dataKey = Object.keys(_this3.rules);
          var reqData = {};
          [].concat(dataKey, ['mer_state', 'long', 'lat', 'mini_banner', 'bank_address', 'bank_name', 'bank', 'bank_number', 'company_address', 'company_english_name', 'contact_phone', 'customer_service_url', 'fee_rate', 'h5_domain_icon', 'mer_name']).map(function (item) {
            reqData[item] = _this3.merData[item];
          });
          _this3.submitLoading = true;
          (0, _user.merchantUpdate)(reqData).then(function (res) {
            console.log(res);
            _this3.submitLoading = false;
            _this3.$message.success('提交成功');
          }).catch(function (rej) {
            _this3.submitLoading = false;
            _this3.$message.error(rej.data.message);
          });
        } else {
          _this3.$message('请完善信息后再进行提交');
          _this3.submitLoading = false;
          return false;
        }
      });
    },
    // 查找位置
    onSearch: function onSearch() {
      // console.log(this.$refs)
      this.$refs.mapChild.searchKeyword(this.merData.mer_address);
    },
    // 获取 map key
    getMapInfo: function getMapInfo() {
      var _this4 = this;
      var that = this;
      (0, _setting.storeGetInfoApi)().then(function (res) {
        console.log(res);
        _this4.mapKey = res.data.tx_map_key;
      }).catch(function (res) {
        that.$message.error(res.message);
      });
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this5 = this;
      console.log(tit, num, i);
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        console.log(img);
        if (tit === '1' && !num) {
          _this.merData.mer_logo = img[0];
        }
        if (tit === '2' && !num) {
          _this.merData.mer_avatar = img[0];
        }
        if (tit === '3' && !num) {
          _this.merData.mini_banner = img[0];
        }
        if (tit === '4' && !num) {
          _this.merData.h5_domain_icon = img[0];
        }
        _this5.$forceUpdate();
        console.log(_this5.merData);
      }, tit);
    }
  }
};