"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _webSocket = _interopRequireDefault(require("@/utils/webSocket"));
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      id: this.$route.query.id || "",
      background_color: "#2e3b58",
      background_image: "",
      main_color: "#ffffff",
      main_image: "",
      word_color: "#ffffff",
      gap: "3",
      transition: "0",
      showvideo: false,
      selectall: [],
      showmodel: "1",
      second: "3",
      origin: "1",
      sdata: {
        socketServe: _webSocket.default.Instance
      },
      albumData: {},
      auctionData: {},
      animateindex: 0,
      playvideo: false,
      csstext: "",
      endcss: "",
      duration: "",
      clientWidth: 0,
      clientHeight: 0,
      scrollimgcss: "",
      speed: 0,
      video: null,
      showRmb: false,
      showUsd: false,
      showEur: false,
      showHkd: false,
      rmbRate: 1,
      usdRate: 0,
      eurRate: 0,
      hkdRate: 0,
      imgdisplat: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.clientWidth = document.getElementById("screen-center").clientWidth;
    this.clientHeight = document.getElementById("screen-center").clientHeight;
    (0, _system.getConfigClassKeys)("setting_led_config").then(function (res) {
      if (res.data.led_data) {
        var leddata = JSON.parse(res.data.led_data);
        console.log(leddata);
        _this.background_color = leddata.background_color;
        _this.main_color = leddata.main_color;
        _this.word_color = leddata.word_color;
        _this.gap = leddata.gap;
        _this.transition = leddata.transition;
        _this.second = leddata.second;
        _this.showvideo = leddata.showvideo;
        _this.selectall = leddata.selectall;
        _this.showmodel = leddata.showmodel;
        _this.origin = leddata.origin;
        _this.background_image = leddata.background_image || "";
        _this.main_image = leddata.main_image || "";
        if (_this.selectall.includes("0")) {
          _this.showRmb = true;
        }
        if (_this.selectall.includes("1")) {
          _this.showUsd = true;
        }
        if (_this.selectall.includes("2")) {
          _this.showEur = true;
        }
        if (_this.selectall.includes("3")) {
          _this.showHkd = true;
        }
        var csstext = "";
        var endcss = "";
        var transition = _this.transition;
        if (transition != 0) {
          _this.duration = "transition-duration: .8s;";
          if (transition == 1) {
            csstext += "opacity:0;";
            endcss += "opacity:0;";
          } else if (transition == 2) {
            csstext += "transform:translateX(100%);";
            endcss += "transform:translateX(-100%);";
          } else if (transition == 3) {
            csstext += "transform:translateY(100%);";
            endcss += "transform:translateY(-100%);";
          }
        } else {
          console.log("是这里吗");
          csstext += "z-index: -1;";
        }
        _this.csstext = csstext;
        _this.endcss = endcss;
        _this.speed = 100 / _this.second;
      }
    });
    if (!_webSocket.default.Instance.ws) {
      _webSocket.default.Instance.connect();
      this.sdata.socketServe = _webSocket.default.Instance;
      this.sdata.socketServe.registerCallBack("getMessage", function (data) {
        _this.sockedMes(data);
      });
      this.sdata.socketServe.registerCallBack("openSocket", function () {
        // sendData({
        //   type: "join_auction_room",
        //   auction_id: id.value,
        // });
      });
    } else if (this.client_id) {
      this.joinRoomAction();
      // sendData({
      //   type: "join_auction_room",
      //   auction_id: id.value,
      // });
    }
    (0, _system.getConfigClassKeys)('screen_projection').then(function (res) {
      if (res.data.data_three) {
        var abbreviationList = JSON.parse(res.data.data_three).abbreviationList;
        if (abbreviationList && abbreviationList.length > 0) {
          console.log(abbreviationList);
          abbreviationList.forEach(function (item) {
            if (item.abb == 'USD') {
              _this.usdRate = item.rate;
            } else if (item.abb == "EUR") {
              _this.eurRate = item.rate;
            } else if (item.abb == "HKD") {
              _this.hkdRate = item.rate;
            }
          });
        }
      }
    });
  },
  methods: {
    sockedMes: function sockedMes(d) {
      var _this2 = this;
      if (d.type === "init") {
        // 初始化
        this.client_id = d.client_id;
        this.joinRoomAction();
      } else if (d.type === "auction_start") {
        // 确认起拍价
        this.auctionData = d.data.auction_info;
      } else if (d.type === "auction_bid") {
        // 出价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          if (d.data.bid_record.is_confirm !== 0) {
            this.auctionData.now_price = d.data.now_price;
          }
        }
      } else if (d.type === "auction_bid_proxy") {
        // 出价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          this.auctionData.now_price = d.data.now_price;
        }
      } else if (d.type === "auction_bid_del") {
        // 删除最高价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          this.auctionData.now_price = d.data.now_price;
          // this.settingPrice(this.auctionData.now_price);
        }
      } else if (d.type === "auction_end" || d.type === "auction_revoke") {
        if (d.data.next_auction) {
          var val = JSON.parse(JSON.stringify(this.auctionData));
          this.lastEndAuctionInfo = {
            lot: val.lot,
            userInfo: null,
            now_price: ""
          };
          if (d.data.auction_info && d.data.type === 1) {
            this.lastEndAuctionInfo.now_price = val.now_price;
          }
          this.auctionData = d.data.next_auction;
          if (this.origin == 2) {
            this.animateindex = 0;
            this.animate();
          }
        } else {
          (0, _auction.albumAuctionStatistical)({
            album_id: this.id
          }).then(function (res) {
            _this2.albumEndData = res.data;
            _this2.albumEndData.end_time = res.server_time;
            _this2.end = true;
          });
        }
      } else if (d.type === "auction_remake") {
        this.auctionData = d.data.auction_info;
        if (this.origin == 2) {
          this.animateindex = 0;
          this.animate();
        }
      } else if (d.type === "auction_bid_confirm") {
        this.auctionData.now_price = d.data.now_price;
      } else if (d.type === "album_switch" && d.data && d.data.next_album_info) {
        window.location.href = "/merchant/led?id=" + d.data.next_album_info.album_id;
      }
    },
    joinRoomAction: function joinRoomAction() {
      var _this3 = this;
      (0, _auction.joinRoom)({
        album_id: this.id,
        client_id: this.client_id
      }).then(function (res) {
        _this3.albumData = res.data.album_info;
        _this3.auctionData = res.data.now_auction;
        _this3.lastEndAuctionInfo = res.data.lastEndAuctionInfo;
        setTimeout(function () {
          _this3.animateindex = 0;
          if (_this3.origin == 2) {
            _this3.animate();
          } else {
            // this.animateImg();
          }
        }, 1000);
      }).catch(function () {});
    },
    moneyAdd: function moneyAdd(money, rate, decimal) {
      var decimal1 = decimal > 0 && decimal <= 20 ? decimal : 2;
      var money2 = money;
      money2 = (0, _index.formatPrice)((0, _index.formatPrice)(money) * (0, _index.formatPrice)((0, _index.formatPrice)(rate))) / 100000000;
      if (!decimal) {
        money2 = money2.toFixed(0);
      }
      // const money1 = `${parseFloat((`${money2}`).replace(/[^\d-]/g, '')).toFixed(decimal1)}`;
      var money1 = "".concat(money2);
      var d = money1.split(".")[0].split("").reverse();
      var y = money1.split(".")[1] ? ".".concat(money1.split(".")[1]) : "";
      var q = "";
      for (var i = 0; i < d.length; i += 1) {
        q += d[i] + ((i + 1) % 3 === 0 && i + 1 !== d.length ? "," : "");
      }
      var w = "";
      if (decimal1 > 0 && y) {
        w = y.substr(0, decimal1 + 1);
      }
      return "".concat(q.split("").reverse().join("")).concat(w);
    },
    onVideoLoadedMetadata: function onVideoLoadedMetadata() {
      var _this4 = this;
      this.video = document.getElementById("video");
      setTimeout(function () {
        if (_this4.video) {
          _this4.video.play();
          // 监听'ended'事件
          _this4.video.addEventListener("ended", function () {
            _this4.animateindex = 0;
            _this4.playvideo = false;
            _this4.animate();
          });
        }
      }, 600);
    },
    animate: function animate() {
      var _this5 = this;
      if (this.auctionData.product && this.auctionData.product.slider_image) {
        // if (
        //   this.animateindex == this.auctionData.product.slider_image.length - 1 &&
        //   this.showvideo
        // ) {
        //   this.playvideo = true;
        //   this.video = document.getElementById("video");
        //   setTimeout(() => {
        //     if (this.video) {
        //       this.video.play();
        //       // 监听'ended'事件
        //       this.video.addEventListener("ended", () => {
        //         this.animateindex = 0;
        //         this.playvideo = false;
        //         this.animate();
        //       });
        //     }
        //   }, 600);
        //   return;
        // }
      } else {
        this.animateindex = 0;
      }
      setTimeout(function () {
        _this5.animateImg();
      }, 600);
      if (this.animateTimeout) {
        clearTimeout(this.animateTimeout);
      }
      this.animateTimeout = setTimeout(function () {
        if (_this5.animateindex < _this5.auctionData.product.slider_image.length - 1) {
          _this5.animateindex++;
        } else {
          _this5.animateindex = 0;
        }
        _this5.animate();
      }, this.gap * 1000);
    },
    animateload: function animateload(e) {
      this.animateindex = 0;
      var imgdom = document.getElementsByClassName("solidimg")[0];
      // 如果图片已加载，直接获取宽度和高度
      var width = imgdom.naturalWidth;
      var height = imgdom.naturalHeight;
      if (width < height) {
        this.imgdisplat[0] = "shu";
      } else if (width > height) {
        this.imgdisplat[0] = "heng";
      } else {
        this.imgdisplat[0] = "fang";
      }
      if (this.showmodel == 3) {
        this.animateImg(this.imgdisplat[0]);
      }
    },
    animatesolid: function animatesolid(item, index) {
      var imgdom = document.getElementsByClassName("solidimg")[index];
      // 如果图片已加载，直接获取宽度和高度
      var width = imgdom.naturalWidth;
      var height = imgdom.naturalHeight;
      console.log(width, height);
      if (width < height) {
        this.imgdisplat[index] = "shu";
      } else if (width > height) {
        this.imgdisplat[index] = "heng";
      } else {
        this.imgdisplat[0] = "fang";
      }
      this.$forceUpdate();
    },
    animateImg: function animateImg(type) {
      var _this6 = this;
      var imagebox = document.getElementsByClassName("solidimg");
      var clientWidth = imagebox[this.animateindex].clientWidth;
      var clientHeight = imagebox[this.animateindex].clientHeight;
      var css = "";
      var time = 0;
      if (clientHeight > this.clientHeight) {
        time = (clientHeight - this.clientHeight) / this.speed;
        css += "transition-duration: " + time + "s;";
        css += "transform:translateY(" + (-clientHeight + this.clientHeight) + "px);";
      } else if (clientWidth > this.clientWidth) {
        time = (clientWidth - this.clientWidth) / this.speed;
        css += "transition-duration: " + time + "s;";
        css += "transform:translateX(" + (-clientWidth + this.clientWidth) + "px);";
        // this.scrollimgcss = css;
      } else if (type == "fang") {
        time = 10;
        css += "transition-duration: 10s;";
        css += "transform:scale(1.1);";
      }
      if (this.origin == 1) {
        if (this.animateO && this.animateindex == 0) {
          this.animateO = false;
          if (clientWidth == clientHeight) {
            this.scrollimgcss = "transform:scale(1.1); ";
          } else {
            this.scrollimgcss = "transition-duration: " + time + "s;";
          }
        } else {
          this.animateO = true;
          this.scrollimgcss = css;
        }
        if (this.huiscrool) {
          clearTimeout(this.huiscrool);
        }
        this.huiscrool = setTimeout(function () {
          _this6.animateImg();
        }, time * 1000 + 100);
      } else {
        this.scrollimgcss = css;
        if (this.animateTimeout) {
          clearTimeout(this.animateTimeout);
        }
        this.animateTimeout = setTimeout(function () {
          if (_this6.animateindex < _this6.auctionData.product.slider_image.length - 1) {
            _this6.animateindex++;
          } else {
            _this6.animateindex = 0;
          }
          _this6.animate();
        }, time * 1000 + this.gap * 1000);
      }
    }
  }
};